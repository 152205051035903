.classScheduleModel .modal-dialog.modal-lg{ 
    width: 100%;
    max-width: 70% !important;
}

#ClassSchedule .teachernameDiv,
.classtimingDiv {
    width: 50%;
    float: left;
}

#ClassSchedule .teachernameDiv h5 span,
.classtimingDiv h5 span,
.classnameDiv h5 span {
    font-weight: 500;
}

#ClassSchedule hr {
    margin-top: 0px;
    margin-bottom: 0px;
}
#ClassSchedule .table-responsive {
    width: 100%;
    overflow-x: auto;
}
#ClassSchedule table {
    border-color: #877676;
    width: 100%;
}

#ClassSchedule table tbody>tr>td:first-child {
    background: #ffffff;
    position: sticky;
    width: 450px !important;
    max-width: 450px !important;
    left: 0;
  }
  #ClassSchedule table  thead>tr>th:first-child{
    position: sticky;
    width: 450px !important;
    max-width: 450px !important;
    left: 0;
  }

  #ClassSchedule table  thead>tr>th:first-child{
    position: sticky;
    width: 450px !important;
    max-width: 450px !important;
    left: 0;
  }

#ClassSchedule table th {
    font-size: 16px;
    background-color: #329fff;
    color: white;
}

#ClassSchedule table .subheading {
    background-color: rgb(231, 231, 231);
    font-size: 14px;
    font-weight: 600;
}
#ClassSchedule table .subheading .coursename{
    background-color: rgb(231, 231, 231);
}
#ClassSchedule table td {
    color: #232323;
    font-size: 16px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* #ClassSchedule table td span {
    font-size: 13px;
} */
