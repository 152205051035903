#GrowthSurvey .topHeading span{
    font-size: 22px;
    display: flex;
    align-items: center;
    font-weight: 600;
    display: flex;
    align-items: start;
}

#GrowthSurvey .topHeading h3{
    font-size: 22px;
}
#GrowthSurvey .topHeading span img{
    max-width:32px;
    width: 32px;
    margin-top: -4px;
}
#GrowthSurvey .topHeading span svg{
    font-size: 28px;
}

#GrowthSurvey .introContent p{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 30px;
    padding-left: 27px;
}
#GrowthSurvey .disabled{
    color: #ccc;
}

#GrowthSurvey .anchor-disabled{
    cursor: not-allowed !important;
}

#GrowthSurvey .smqzQueslist,.skill_aquization_survery .smqzQueslist{
    padding-left: 6.5% !important;
    height: 65.2vh;
}
#GrowthSurvey .questionOptions ,.skill_aquization_survery .questionOptions{
    padding-left: 6% !important;
}
#GrowthSurvey .smqzQueslist label.Selcheckbox span.checkmark {
    top: 15px;
    left: 8px;
    width: 25px;
    height: 25px;
}
#GrowthSurvey .smqzQueslist .Selcheckbox .checkmark:after {
    left: 9px;
    top: 3px;
    width: 8px;
    height: 15px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}
#GrowthSurvey .smqzQueslist label.Selcheckbox,.skill_aquization_survery  label.Selcheckbox  {
    margin: 0px 0;
    padding: 12px 20px 12px 45px;
    font-size: 20px !important;
    font-weight: 400;
    color: #313131;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    border: none;
}
#GrowthSurvey .smqzQueslist label.Selcheckbox:hover,.skill_aquization_survery label.Selcheckbox:hover {
    background: none;
    border: none;
    box-shadow: none;
}
#GrowthSurvey .quizblok,.quizblok.skill_aquization_survery{
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;
    margin-top: 20px;
    justify-content: space-around;
    align-items: center;
}
#GrowthSurvey .quizblok .Progresslistitem,.quizblok.skill_aquization_survery .Progresslistitem {
    width: 75% !important;
    margin: 10px 10px !important;
    height: 20px;
    background: #fff;
    border: 2px solid #00b050;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
}
#GrowthSurvey .topHeading img{
    width: 30px;
}
#GrowthSurvey .starthereHeading{
    font-size: 32px;
    font-weight: 700;
    color: red;
    padding-bottom: 10px;
}

.growthsurveyPreview .question{
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
}
.growthsurveyPreview .answer{
    font-size: 14px;
    font-weight: 400;
    padding-left: 20px;
}
.growthsurveyPreview .answer img{
    width: 14px;
    max-width: 20px;
}
.growthsurveyPreview .question svg{
    color: #00000082;
    font-size: 15px;
}

.rightPanelIntroScreen p{
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 20px;
}

.growthsurveyAttemptQuestionMain{
    display: flex;
    flex: 1 1;
    flex-direction: column;
    /* height: 100%; */
    padding-top: 0;
}

#GrowthSurvey .growthsurveyAttemptQuestionMainDiv {
    flex: 1 1;
    height: auto;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 100%;
}

#GrowthSurvey .growthsurveyAttemptQuestioChildDiv {
    overflow-y: auto;
}

/*Start add this css use for growth survey Output */
.growthsurveyOutputMain {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 100%;
    padding-top: 0;
}
#GrowthSurvey .growthsurveyOutputMainDiv{
    flex: 1 1;
    height: auto;
    overflow: hidden;
    position: relative;
    text-align: center;
    width: 100%;

}
#GrowthSurvey .growthsurveyOutputChildDiv{
    height: 73.4vh;
    overflow-y: scroll;
    overflow-x: hidden;
}
#GrowthSurvey .growthsurveyOutput .verticalLine,.StugrowthSrvy_bg .verticalLine{
    border-left: 2px solid #000000;
    height: 18px;
    padding-right: 8px;
    margin-left: 8px;
  }
#GrowthSurvey .growthsurveyOutput hr,.StugrowthSrvy_bg hr{
    height: 1.5px;
    background-color: black;
    border: none;
    opacity: 2.25;
    margin-top: 8px;
    margin-bottom: 5px;
}
#GrowthSurvey .secondHrLine,.StugrowthSrvy_bg .secondHrLine{
    margin-top: 1.5px !important;

}
#GrowthSurvey .growthsurveyOutput a ,.panel-collapse.StugrowthSrvy_bg a{
    font-size: 20px;
    font-weight: 600;
}
#GrowthSurvey .growthsurveyOutput .graphbatteryprogress ,
.StudGrowthservey .pre_growth_Servy .graphbatteryprogress{
    width: 100px;
    height: 160px;
    box-sizing: inherit;
    box-sizing: border-box;
    position: relative;
    background: #e3e3e3;
    margin: 20px;
    min-width: 50px;
    left: 0px;
    top: 25px;
}
#GrowthSurvey .growthsurveyOutput span.batterycells,
.StudGrowthservey .pre_growth_Servy .batterycells{
    width: 100px;
    height: 78%;
    display: table;
    background: #329fff;
    display: block;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
}
.StudGrowthservey .Studimensional_progress  .Coursecompprcent circle.rc-progress-circle-path {
    stroke: #329fff !important;
}
#GrowthSurvey .growthsurveyOutput span.secProgTxt ,.pre_growth_Servy span.secProgTxt {
    font-size: 42px;
    text-align: center;
    position: absolute;
    top: -55px;
    font-weight: 600;
    left: 50%;
    transform: translateX(-50%);
}
#GrowthSurvey .growthsurveyOutput span.secProgTxtSmallText ,.pre_growth_Servy span.secProgTxtSmallText{
    font-size: 20px;
    font-weight: 600;
    color: #808080ad;
}
#GrowthSurvey .growthsurveyOutput .indivisectionprog{
    padding-top: 60px;
}


#GrowthSurvey .growthsurveyOutput .Coursecompprcent{
    /* width: 100%; */
    width: 163px;
 }
 #GrowthSurvey .growthsurveyOutput .PercentCompleted{
     position: absolute;
     top: 60px;
     border: 0;
     background: none;
     color: #232323;
     font-weight: 600;
     font-size: 42px;
     line-height: 1;
     text-align: center;
     display: flex;
     justify-content: center;
     width: 100%;
     align-items: baseline;
 }
 #GrowthSurvey .growthsurveyOutput .rc-progress-circle{
        border-radius: 50%;
 }
 #GrowthSurvey .growthsurveyOutput .rc-progress-circle-trail, #GrowthSurvey .growthsurveyOutput .rc-progress-circle-path {
    stroke-width: 24px;
}
 #GrowthSurvey .growthsurveyOutput .percenrSign {
     font-size: 22px;
     margin-left: 0px;
 }
#GrowthSurvey .growthsurveyOutput .middleContentMainDiv img,.StugrowthSrvy_bg .middleContentMainDiv img{ 
    width: 230px;
}
#GrowthSurvey .growthsurveyOutput .middleContentMainDiv h3{
    font-size: 28px;
}
#GrowthSurvey .growthsurveyOutput .middleContentMainDiv .content1{
   position: relative;
   top: 14%;
}
#GrowthSurvey .growthsurveyOutput .middleContentMainDiv .content2{
    position: relative;
    top: 32%;
}

#GrowthSurvey .growthsurveyOutput .middleContentMainDiv .moveTopContent1 ,
.StugrowthSrvy_bg .middleContentMainDiv .moveTopContent1 
{
    position: relative;
    top: 10%;
}
#GrowthSurvey .growthsurveyOutput .middleContentMainDiv .moveTopContent2 ,
.StugrowthSrvy_bg .middleContentMainDiv .moveTopContent2 {
    position: relative;
    top: 30%;
}

#GrowthSurvey .growthsurveyOutput h3,.StugrowthSrvy_bg h3{
    font-size: 26px !important;
}

#GrowthSurvey .growthsurveyOutput .disabledDiv{
    position: relative;
    padding: 25px 20px 24px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#GrowthSurvey .growthsurveyOutput .disabledDivChild{
    background: #6e5353;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    opacity: 0.5;
}
#GrowthSurvey .emptyDivHeigth ,.growthsurveyPreview  .emptyDivHeigth{
    height: 7.5%;
}

#GrowthSurvey .growthsurveyOutputCompleted {
    height: 100%;
    overflow-y: auto;
    /* padding-top: 0px !important; */
    padding-bottom: 10px !important;
}

#GrowthSurvey .growthsurveyOutput .CompletedDisabledDiv {
    padding: 0px 0px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#GrowthSurvey .growthsurveyOutput .middleContentMainDivCompleted,
.StugrowthSrvy_bg .middleContentMainDiv.middleContentMainDivCompleted{
    position: relative;
    top: -25px;
}

/*end add this css use for growth survey Output */

#GrowthSurvey .skipText{
    font-size: 15px;
    color: #424242;
    font-weight: 600;
    text-decoration:underline;
}
#GrowthSurvey .gotoLessonText, .growthsurveyPreview  .gotoLessonText{ 
    font-size: 16px;
}

#GrowthSurvey .warningIcon{
    width: 28px;
}
#GrowthSurvey .warningButtonIcon{
    font-size: 16px;
}

#GrowthSurvey .warningButtonIcon{
    font-size: 16px;
}

/* This css use for scroll */
.growthsurveyOutputMainDiv ::-webkit-scrollbar, .growthsurveyAttemptQuestionMain ::-webkit-scrollbar{
    width: 4px !important;
}
.growthsurveyOutputMainDiv ::-webkit-scrollbar-track, .growthsurveyAttemptQuestionMain ::-webkit-scrollbar-track{
    background: #f1f1f1; 
}

/* Target the scrollbar thumb */
.growthsurveyOutputMainDiv ::-webkit-scrollbar-thumb, .growthsurveyAttemptQuestionMain ::-webkit-scrollbar-thumb{
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    scrollbar-width: thin;
}

/* Change the scrollbar thumb when hovered */
.growthsurveyOutputMainDiv ::-webkit-scrollbar-thumb:hover, .growthsurveyAttemptQuestionMain ::-webkit-scrollbar-thumb:hover{
    background: #555; 
}
.StugrowthSrvy_bg .moveTopContent1 h5 ,.StugrowthSrvy_bg  .moveTopContent2 h5{
    padding: 0;
    text-align: center;
    box-shadow: none;
    justify-content: center;
}
#GrowthSurvey .growthsurveyOutput span.semicircle-percent-value{
    left: 0px !important;
    position: absolute;
    top: 70px;
    border: 0;
    background: none;
    color: #232323;
    font-weight: 700;
    font-size: 32px;
    line-height: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: baseline;
}
