@font-face {
  font-family: 'BicycletteThin';
  src: url('../fonts/Bicyclette-Thin.eot');
  src: local('Bicyclette Thin'), local('Bicyclette-Thin'),
      url('../fonts/Bicyclette-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Bicyclette-Thin.woff2') format('woff2'),
      url('../fonts/Bicyclette-Thin.woff') format('woff'),
      url('../fonts/Bicyclette-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'BicycletteBold';
  src: url('../fonts/Bicyclette-Bold.eot');
  src: local('Bicyclette Bold'), local('Bicyclette-Bold'),
      url('../fonts/Bicyclette-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Bicyclette-Bold.woff2') format('woff2'),
      url('../fonts/Bicyclette-Bold.woff') format('woff'),
      url('../fonts/Bicyclette-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'BicycletteRegular';
  src: url('../fonts/Bicyclette-Regular.eot');
  src: local('Bicyclette Regular'), local('Bicyclette-Regular'),
      url('../fonts/Bicyclette-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Bicyclette-Regular.woff2') format('woff2'),
      url('../fonts/Bicyclette-Regular.woff') format('woff'),
      url('../fonts/Bicyclette-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BicycletteUltra';
  src: url('../fonts/Bicyclette-Ultra.eot');
  src: local('Bicyclette Ultra'), local('Bicyclette-Ultra'),
      url('../fonts/Bicyclette-Ultra.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Bicyclette-Ultra.woff2') format('woff2'),
      url('../fonts/Bicyclette-Ultra.woff') format('woff'),
      url('../fonts/Bicyclette-Ultra.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BicycletteBlack';
  src: url('../fonts/Bicyclette-Black.eot');
  src: local('Bicyclette Black'), local('Bicyclette-Black'),
      url('../fonts/Bicyclette-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Bicyclette-Black.woff2') format('woff2'),
      url('../fonts/Bicyclette-Black.woff') format('woff'),
      url('../fonts/Bicyclette-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'BicycletteItalic';
  src: url('../fonts/Bicyclette-Italic.eot');
  src: local('Bicyclette Italic'), local('Bicyclette-Italic'),
      url('../fonts/Bicyclette-Italic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Bicyclette-Italic.woff2') format('woff2'),
      url('../fonts/Bicyclette-Italic.woff') format('woff'),
      url('../fonts/Bicyclette-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'BicycletteLight';
  src: url('../fonts/Bicyclette-Light.eot');
  src: local('Bicyclette Light'), local('Bicyclette-Light'),
      url('../fonts/Bicyclette-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Bicyclette-Light.woff2') format('woff2'),
      url('../fonts/Bicyclette-Light.woff') format('woff'),
      url('../fonts/Bicyclette-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}



.NHPreogressouter {
  width: 180px;
  height: 180px;
  background: #e3e3e3;
  border-radius: 50%;
  position: relative;
  margin: auto;
  border: 4.5px solid #e3e3e3;
  box-sizing: content-box;
}

.NHProgressinner {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -4.5px;
  width: 65%;
  height: 65%;
  font-size: 10px;
  color: #fff;
  border-radius: 50%;
  background: #e8b62f;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NHProgressinner p {
  font-size: 16px;
  /* color: #fff; */
}
.NhPageWrap {
  padding: 10px 10px;
}

.NhPageWrap h3 {
  padding: 0px 60px;
}

.NHProgreschartList.flex {
  padding: 20px 0;
  align-items: flex-start;
}

.NHProgresslistitem.NhHolisticProgress .NHPreogressouter,
.NHProgresslistitem.NHProgresslistitem.simneskillcombo .NHPreogressouter {
  width: 250px;
  height: 250px;
}
.NHDimeProgName img {
  max-width: 24px;
  margin-right: 5px;
}
.NHDimeProgName img.chat_icon,
.HolisticgrowthFlwr img.chat_icon,
.skillsliitem h4 img.chat_icon {
  max-width: 16px;
  margin-left: 5px;
  vertical-align: middle;
}
.NHProgresslistitem {
  width: 30%;
  margin: 10px auto 0px;
}
.NHProgresslistitem:nth-child(3n + 2) {
  width: 40%;
}
.NHProgresslistitem.SocialitemsNH .NHProgressinner {
  background: #ff9900;
}

.allitemsNH .NHProgressinner {
  background: royalblue;
  transform: translate(-50%, 50%);
  bottom: 50%;
}
.NHProgresslistitem.EmotionalitemsNH .NHProgressinner {
  background: #dd6363;
}
.NHProgresslistitem.MindfulnessitemsNH .NHProgressinner {
  background: #a769d9;
}
.NHProgresslistitem.PhysicalitemsNH .NHProgressinner {
  background: #1ec1d9;
}
.NHProgresslistitem.IntellectualitemsNH .NHProgressinner {
  background: #83b612;
}
.heading h2 img.chat_icon,
.Home_two img.chat_icon {
  max-width: 20px;
}
/* .Home_two .tabgrid:before {
  border: 0;
} */
.backpageStrip a {
  font-size: 16px;
}
.backpageStrip {
  padding: 10px 10px 15px;
}
.skillsliitem .NHProgressinner {
  background: #0096fe;
}
.NHProgresslistitem.simneskillcombo .NHProgressinner {
  bottom: 50%;
  transform: translate(-50%, 50%);
}
.HGmaincircle {
  width: 180px;
  height: 180px;
  margin: auto;
  position: relative;
  border: 1px solid #ccc;
  background: #e3e3e3;
  border-radius: 50%;
}
.Dimen_Circle_inner p {
  position: absolute;
  left: -18px;
  transform: translateY(-50%);
  top: 35%;
  font-weight: 600;
}
.Dimen_Circle_inner p.planetProgress {
  left: -26px;
}
.Dimen_Circle_inner p.planetprog {
  left: -28px;
}
.planetprog .HGInner_cirlce p {
  font-weight: 600;
}
.HolinnerProgcircle.HGMindfulnesscrl .Dimen_Circle_inner p,
.HolinnerProgcircle.HGIntellectualcrl .Dimen_Circle_inner p {
  left: auto;
  right: -18px;
}
.HGEmotionalcrl .Dimen_Circle_inner p.fullprogress {
  left: -32px;
}
.HolinnerProgcircle.HGMindfulnesscrl .Dimen_Circle_inner p.fullprogress {
  right: -32px;
}
.HolinnerProgcircle.HGPhysicalcrl .Dimen_Circle_inner p {
  right: -18px;
  top: 90%;
}
.HolinnerProgcircle.HGIntellectualcrl .Dimen_Circle_inner p {
  right: -18px;
  top: 90%;
}
.HolinnerProgcircle.HGMindfulnesscrl .Dimen_Circle_inner p.planetProgress {
  right: -26px;
}
.HolinnerProgcircle.HGMindfulnesscrl .Dimen_Circle_inner p.planetprog {
  right: -28px;
}
.HolinnerProgcircle.HGIntellectualcrl .Dimen_Circle_inner p.planetProgress,
.HolinnerProgcircle.HGPhysicalcrl .Dimen_Circle_inner p.planetProgress {
  right: -26px;
}
.HolinnerProgcircle.HGIntellectualcrl .Dimen_Circle_inner p.planetprog,
.HolinnerProgcircle.HGPhysicalcrl .Dimen_Circle_inner p.planetprog {
  right: -28px;
}
.HolinnerProgcircle.HGSocialcrl .Dimen_Circle_inner p {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -20px;
}
.HolisticgrowthFlwr {
  padding: 100px 0 0;
}

.HolinnerProgcircle {
  width: 150px;
  height: 150px;
  /* background: #ddd; */
  position: absolute;
  border-radius: 50%;
}
.HolinnerProgcircle.HGSocialcrl .Dimen_Circle_inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #ff9900;
}

.HolinnerProgcircle.HGSocialcrl {
  top: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.HolinnerProgcircle.HGEmotionalcrl.flextwo {
  top: 33%;
  left: 2.8%;
  transform: translate(-50%, -50%);
}

.HolinnerProgcircle.HGEmotionalcrl.flextwo .Dimen_Circle_inner {
  width: 100%;
  height: 100%;
  background: #e46666;
  border-radius: 50%;
}

.HolinnerProgcircle.HGMindfulnesscrl {
  top: 33%;
  left: 97.4%;
  transform: translate(-50%, -50%);
}
.HolinnerProgcircle.HGMindfulnesscrl .Dimen_Circle_inner {
  background: #a769d9;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.HolinnerProgcircle.HGPhysicalcrl {
  top: 90%;
  transform: translate(-50%, -50%);
  left: 20%;
}

.HolinnerProgcircle.HGPhysicalcrl .Dimen_Circle_inner {
  width: 100%;
  height: 100%;
  background: #1ec1d9;
  border-radius: 50%;
}
.HGInner_cirlce {
  min-width: 120%;
  height: 120%;
  background: royalblue;
  border-radius: 50%;
  z-index: 1;
  flex-wrap: wrap;
  border: 1px solid #fff;
  align-content: center;
}
.HGInner_cirlce p,
.HGInner_cirlce h3 {
  color: #fff;
}

.HolinnerProgcircle.HGIntellectualcrl {
  top: 90%;
  transform: translate(-50%, -50%);
  left: 80.5%;
}

.Dimen_Circle_inner {
  border-radius: 50%;
  background: #83b612;
  width: 100%;
  border: 1px solid #fff;
  position: relative;
  height: 100%;
}
.NHProgresslistitem:nth-child(3n + 2) {
  margin-top: 95px;
}
.NHPreogressouter .NHProgressinner p {
  position: absolute;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%);
  top: -22px;
}
.NHProgresslistitem:nth-child(4),
.NHProgresslistitem:nth-child(6) {
  margin-top: -75px;
}
/* .Hometwowrap .NHProgresslistitem:nth-child(4),
.Hometwowrap .NHProgresslistitem:nth-child(6) {
  margin-top: -100px;
} */
.NHProgresslistitem:nth-child(5) {
  margin-top: 67px;
}
.HomeProgresstilt {
  padding: 10px 10px;
  background: #f5f9fd;
  border-bottom: 1px solid #c9c9c9;
  cursor: pointer;
  font-size: 17px;
}
.proficencyprog circle.rc-progress-circle-path {
  stroke: #3ec093 !important;
}
.TimProgress circle.rc-progress-circle-path {
  stroke: #ffb300 !important;
}
.CompltProgress circle.rc-progress-circle-path {
  stroke: #329fff !important;
}
.TimProgress.growth_Survey circle.rc-progress-circle-path{
  stroke: #e46666 !important;
}
.progressHomes {
  overflow: auto;
  max-height: 78vh;
}

/* .NHProgresslistitem {
    animation-name: example;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-direction: alternate;
    animation-iteration-count: 1;
  }
  
  @keyframes example {
    0%   { left:50%; bottom:50%;}
    25%  { left:50%;bottom:0%}
    50%  {left:50%; bottom:25%}
    75%  {left:50%;bottom:25%}
    100% { left:50%;bottom:0px}
  } */

.Socialclr {
  color: #e9b72f;
}
.Emotinalclr {
  color: #e46666;
}
.Mindfulnessclr {
  color: #a86adb;
}
.Physicalclr {
  color: #1ec1d9;
}
.Intellectualclr {
  color: #85b812;
}
.NHProgresslistitem h4 img {
  max-width: 30px;
  margin-right: 5px;
}
.NHProgresslistitem h4 .chat_icon {
  max-width: 16px;
  margin-left: 5px;
  vertical-align: middle;
  margin-top: -1px;
}
/* .NhPageWrap.FourCircle .NHProgresslistitem,.NhPageWrap.SevenCircle .NHProgresslistitem{
    margin: 10px  0px;
  } */
.NhPageWrap.ThreeCircle .NHProgresslistitem.skillsliitem:nth-child(4),
.NhPageWrap.SixCircle .NHProgresslistitem.skillsliitem:nth-child(7) {
  margin-top: 67px;
}
.Hometwowrap .NHProgresslistitem:nth-child(4),
.Hometwowrap .NHProgresslistitem:nth-child(6) {
  margin-top: -67px;
}
/* .NhPageWrap.FourCircle .NHProgresslistitem:nth-child(4),
.NhPageWrap.FourCircle .NHProgresslistitem:nth-child(5) {
  margin: 0 0;
  margin-top: -67px;
  width: 30%;
} */
/* .Hometwowrap .FourCircle.NhPageWrap .NHProgresslistitem:nth-child(4){
     margin-top: 0;
} */
/* .NhPageWrap.SixCircle .NHProgresslistitem.skillsliitem:nth-child(7){
    margin-top: 67px;
} */
.NHPreogressouter .NHProgressinner.flextwo p {
  position: static;
  transform: none;
  z-index: 9;
  color: #fff;
  bottom: 36% !important;
}
.NHProgresslistitem.NhHolisticProgress.allitemsNH
  .NHPreogressouter.pointer.flextwo
  p {
  margin-top: -44px;
}
.NhPageWrap .allitemsNH h3 {
  font-size: 24px;
  white-space: nowrap;
}
/* .madeYourownJourney.mb-3.mt-3 {
  background: #329fff;
  padding: 12px 0;
  margin: 0 10px;
  border-radius: 7px;
} */
.madeYourownJourney.mb-3.mt-3 a {
  color: #329fff;
}
/* .pagescrollauto {
  overflow: auto;
    max-height: 71vh;
} */
.percentage_sign {
  font-size: 10px;
}
.skillprogrs span.percentage_sign {
  width: auto;
  font-size: 10px;
  font-size: 12px;
  padding: 0;
  align-items: center;
  padding: 0;
  top: 2px;
  background: transparent;
}
.skillprogrs span{
  position: relative;
}
.ourcommitmentWrap {
  margin: 11px -10px;
  padding: 10px 10px;
}
.ourcommitmentWrap h4 {
  background: #ededed;
  padding: 10px 10px;
  margin: 0px -10px 20px;
}
.progressstyl span.percenrSign {
  font-size: 10px;
}
.OneCircle .NHProgresslistitem {
  margin: 10px 0 0;
}
.OneCircle .NHProgresslistitem:nth-child(3n + 2) {
  margin-top: 95px;
}
.OneCircle .NHProgreschartList.flex {
  justify-content: left;
}
.AllSkillschart .skill_pnlcircle {
  max-width: 150px;
  width: 100px;
  height: 100px;
}
.Homethree .Courseporogresspopup {
  top: 99px;
}
.Homethree .Courseporogresspopup:after {
  left: 69%;
  border-bottom: 15px solid #666666;
  top: -11px;
}
.NhPageWrap h3 a {
  font-size: 19px;
}
.NHProgresslistitem.SocialitemsNH.soicialitemsNH.simneskillcombo a{
  font-size: 18px;
}
.Homethree .aboutProgdesc .Courseporogresspopup {
  top: 30px;
  width: 313px;
  left: 0%;
  transform: translateX(0%);
}
.Courseporogresspopup p {
  text-align: left;
  font-weight: 400;
  color: #fff;
}
.Homethree .CompltProgress .Courseporogresspopup {
  top: 30px;
  width: 313px;
  left: 65%;
  transform: translateX(-50%);
}
.ScenecerelateddQuiz .Courseporogresspopup h4{
  margin-bottom: 0px !important;
  color: #fff;
}
.Homethree .TimProgress .Courseporogresspopup {
  top: 30px;
  left: auto;
  width: 313px;
  transform: translateX(0%);
  right: 0;
}
.Homethree .aboutProgdesc .Courseporogresspopup:after {
  left: 10%;
}
.Homethree .CompltProgress .aboutProgdesc .Courseporogresspopup:after {
  left: 50%;
}
.Homethree .TimProgress .aboutProgdesc .Courseporogresspopup:after {
  left: 80%;
}
.devlopidea {
  margin: 25px 0 10px;
  color: #329fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}
.devlopidea img {
  max-width: 30px;
  margin-right: 5px;
  /* vertical-align: text-top; */
  vertical-align: bottom;
}
.devlopidea a {
  font-weight: 600;
}
/* .NHProgresslistitem:nth-child(8){
margin-top: 16px;
} */
.NHProgresslistitem.EmotionalitemsNH.soicialitemsNH.simneskillcombo a{
  font-size: 17px;
}
.popularcourse ul li img {
  max-width: 42px;
  margin-right: 5px;
  border-radius: 50%;
  height: 42px;
}
.popularcourse ul li  .btn-login {
  padding: 5px 8px 5px 8px;
}
.ScenecerelateddQuiz .Homethree h4.HomeProgresstilt {
  margin-bottom: 0 !important;
}
.courseskillbox {
  padding: 0px 10px 5px;
}
/* Start Added By Alfaiz on 23-02-2024 for Latest News Right-Panel Section*/
#latestNews .section-headding {
	margin: 0px -10px 10px -10px;
	padding: 10px 0px;
}

#latestNews .section-headding h4 {
	background: #ededed;
	padding: 10px 10px;
	margin: 0px 0px;
	font-size: 17px;
	font-weight: 600;
	line-height: 1.3;
}

#latestNews span {
	font-size: 16px;
	line-height: 1;
	margin-bottom: 0px;
	text-align: left;
	font-weight: 600;
	padding-right: 4px;
}
#latestNews .font-weight-400{
  font-weight: 400 !important;
}

#latestNews .font-weight-400 {
	font-weight: 400 !important;
}

#latestNews p {
	font-weight: 400;
	font-size: 15px;
  margin-top: 0px;
}

#latestNews img {
	max-width: 14px;
}

#latestNews .newest-course {
	flex: 0 0 auto;
}

#latestNews .newest-course-text {
	color: #329fff;
	flex: 0 1 auto;
}

#latestNews .coming-course {
	flex: 0 0 auto;
}

#latestNews .coming-course-text {
	flex: 0 1 auto;
}

#latestNews .new-feature-img {
	max-width: 20px;
}

#latestNews .new-feature-img1 {
	max-width: 49%;
}

#latestNews .share-course {
	margin: 20px 0 10px;
	color: #329fff;
}

#latestNews .share-icon {
	max-width: 18px;
	margin-right: 5px;
	vertical-align: bottom;
}
.HolinnerProgcircle.HGEmotionalcrl.flextwo .Dimen_Circle_inner p.planetProgress,.HolinnerProgcircle.HGMindfulnesscrl .Dimen_Circle_inner p.planetProgress {
  left: -32px;
}
.Homethree .TimProgress.PaceProgress .Courseporogresspopup {
  right: -75px;
}
.Homethree .TimProgress.PaceProgress .aboutProgdesc .Courseporogresspopup:after {
  left: 68%;
}
/* 110% media query */
@media only screen and (min-width: 1396px) and (max-width: 1405px) {
	#latestNews span {
		font-size: 13px;
	}

	#latestNews p {
		font-size: 12px;
	}
}

/* 125% media query */
@media (min-width: 1201px) and (max-width: 1250px) {
	#latestNews span {
		font-size: 13px;
	}

	#latestNews p {
		font-size: 12px;
	}

	#latestNews .section-headding h4 {
		font-size: 15px;
	}
}

/* End By Alfaiz on 23-02-2024 for Latest News Right-Panel Section*/


/* Report Css Start*/
.Course_report table {
    width: 100%;
    border-spacing: 8px;
    border-collapse: separate;
}

.Course_report {
    padding: 15px;
}
.Course_report  .Coursecompprcent {
  width: 40px;
}
.Course_report table tr  th,.Course_report table tr td {
    padding: 3px 15px;
    font-size: 16px;
    text-align: center;
    position: relative;
    width: 300px;
    min-width: 100%;
}
.Course_report table.GrowthSurvytbl td,.Course_report table.GrowthSurvytbl th{
  font-size: 14px;
  padding: 3px 5px;
}
.Course_report table tr th {
  padding: 12px 15px
}

.Course_report  table ,.Course_report table {
  padding-bottom: 15px;
}

.Course_report table th  p,.Course_report table td p{
  max-width: 160px;
  white-space:nowrap;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Course_report table td {
    background: transparent;
    color: #000;
}

.Course_report  img, .Course_report img {
  width: 25px;
  padding: 0;
  margin-right: 5px;
  height: 25px;
}
.Course_report .tabluser img{
  width: 16px;
  padding: 0;
  height: auto;
  margin-right: 0;
}

.StudentIng {
  min-width: 36px;
  height: 36px;
  margin-right: 10px;
  text-align: center;
  line-height: 36px;
  border-radius: 50%;
  background: #cce5ff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Course_report .StudentIng img {
  max-width: 100%;
  border-radius: 50%;
  margin-right: 0;
  width: 100%;
  height: 100%;
}
.Course_report .Child_card .StudentIng img {
   border-radius: 50%;
}
.Course_report table th {
  font-weight: 600;
  color: #000;
  text-align: center;
  padding: 10px 10px;
}
.Course_report table th:first-child,.Course_report table td:first-child{
  border-radius: 30px 0 0 30px !important;
  padding: 0 0 0 5px;
  max-width: 22px;
  min-width: 22px;

}
.Course_report table th:last-child,.Course_report table td:last-child{
  border-radius: 0px 30px 30px 0px;
 }
 .breadCrum li.Brumprofile ul li .BDsubmitbutton.Addchieldbtn span{
  margin-left: 8px;
  width: 25px;
  font-size:18px !important;
  height: 25px;
  margin-left: 0;
 }
 .breadCrum li .BDsubmitbutton.Addchieldbtn.addEnrichbtn strong{
  display: flex !important;
  align-items: center;
 }
 .breadCrum li .PPAddNewChild{
  background: none;
  border: 0;
  padding: 0;
 }
/* .Course_report table tr:nth-child(odd) td {
    border-bottom: 1px solid #ff8799;
    background: #fff4f2;
} */

.Course_report table th .fa-user {
  color: #fff;
  margin-right: 0 !important;
  margin-top: 5px;
}
.Child_card {
  width: calc(33.33% - 8px);
  padding: 10px;
  border: 1px solid #bdbdbd;
  margin-bottom: 12px;
  border-radius: 6px;
  box-shadow: 0 0 4px #a2a2a2;
  background: #fff8ed;
  margin-right: 12px;
}
.Child_card:nth-child(3n+3){
  margin-right: 0;
}
.Course_report.coures_Report_Listing {
  display: flex;
  flex-wrap: wrap;
  justify-content:left;
  padding: 15px 0;
}

.child_Card_dtl {
  background: #fff;
  padding: 10px;
  border: 1px solid #ff8799;
  border-radius: 4px;
}

.child_Card_dtl p {
  color: #303030;
  padding: 5px 0;
}

.Student_nameid.flex {
  padding: 10px 0;
}
p.CrdseeMore{
  text-align: center;
    background: #ffd79c;
    margin: 10px 0 0;
}
.Course_report table.overallprogress_report_ tr td:nth-child(3) circle.rc-progress-circle-path,
.Studimensional_progress .Coursecompprcent circle.rc-progress-circle-path 
{
  stroke: royalblue !important;
}
.Course_report table.overallprogress_report_ tr td:nth-child(4) circle.rc-progress-circle-path,
.Studimensional_progress .perticulardim_prog:nth-child(2) .Coursecompprcent circle.rc-progress-circle-path 
{
  stroke: #ff9900 !important;
}
.Course_report table.overallprogress_report_ tr td:nth-child(5) circle.rc-progress-circle-path,
.Studimensional_progress .perticulardim_prog:nth-child(3)  .Coursecompprcent circle.rc-progress-circle-path 
{
  stroke: #e46666 !important;
}
.Course_report table.overallprogress_report_ tr td:nth-child(6) circle.rc-progress-circle-path,
.Studimensional_progress .perticulardim_prog:nth-child(4) .Coursecompprcent circle.rc-progress-circle-path 
{
  stroke: #85b812 !important;
}

/* Holistic View  */
.SchHolistcFlowrthree .HolinnerProgcircle.HGEmotionalcrl.flextwo {
  top: 75%;
  left: 7%;
  transform: translate(-50%, -50%);
}
.SchHolistcFlowrthree .HolinnerProgcircle.HGIntellectualcrl {
  top: 75%;
  transform: translate(-50%, -50%);
  left: 94%;
}
.SchHolistcFlowrthree .HolinnerProgcircle.HGIntellectualcrl .Dimen_Circle_inner p {
  right: -18px;
  top: 34%;
}
.Course_report table td span {
  font-size: 14px;
  color: #636363;
}
.Course_report table td.PgrowSer {
  font-weight: 600;
  background: #81aaff !important;
  padding: 10.5px 0;
  color: #fff;
  border-left: 3px solid #fff !important;
  font-size: 16px;
}
.Course_report table td span.tblpercnt {
  font-size: 10px;
}
.Course_report table.GrowthSurvytbl tr th:nth-child(3),.Course_report table.GrowthSurvytbl tr th:nth-child(7){
  border-left: 3px solid #c8c8c8
}
.Course_report table.GrowthSurvytbl tr td:nth-child(3),.Course_report table.GrowthSurvytbl tr td:nth-child(7){
  border-left: 3px solid #c8c8c8
}
.Course_report table td.blank_td{
  background: #81aaff !important;
}
span.tabluser {
  width: 25px;
  height: 25px;
  display: inline-block;
  line-height: 30px;
  font-size: 16px;
  background: #329fff;;
  margin-right: 5px;
  border-radius: 50%;
  line-height: 23px;
  text-align: center;
}
.Course_report table tr th:nth-child(3) span.tabluser {
  background: royalblue;
}
.Course_report .percenrSign {
  font-size: 10px !important;
}
.Course_report table td span,.stuprogskillList span.PercentCompleted {
  font-size: 12px;
  color: #636363;
  top: 14px;
}
/* ----- */
.GrowthSurveyreport ul li {
    width: 15.5%;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: 5px;
}

.GrowthSurveyreport ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px dotted #a3a3a3;
}
.GrowthSurveyreport ul:first-child{
  border-bottom: 0;
}
.GrowthSurveyreport ul li:nth-child(1) {
    background: #addbff;
    max-width: 30px;
    margin-bottom: 5px;
}

.GrowthSurveyreport ul li:nth-child(2) {
  background: #addbff;
  border-radius: 0 8px 8px 0;
  margin-bottom: 5px;
} 
 .GrowthSurveyreport ul:nth-child(even) li:nth-child(2),.GrowthSurveyreport ul:nth-child(even) li:nth-child(1) {
  background-color: #78c4ff;
}


 .GrowthSurveyreport ul:first-child li:nth-child(3),
.GrowthSurveyreport ul:first-child li:nth-child(4),
.GrowthSurveyreport ul:first-child li:nth-child(5),
.GrowthSurveyreport ul:first-child li:nth-child(6),
.GrowthSurveyreport ul:first-child li:nth-child(7) {
    background: #a1b901;
    border-radius: 8px;
    margin-bottom: 6px;
    text-align: center;
    position: relative;
}
.GrowthSurveyreport ul:first-child li:nth-child(4){
  background: #ffd56b;
}
.GrowthSurveyreport ul:first-child li:nth-child(5){
  background: #ffa774;
}
.GrowthSurveyreport ul:first-child li:nth-child(6){
  background: #ff7172;
}
.GrowthSurveyreport ul:first-child li:nth-child(7){
  background: #e6c7ff;
}  
.GrowthSurveyreport ul:first-child li:nth-child(3):before,
.GrowthSurveyreport ul:first-child li:nth-child(4):before,
.GrowthSurveyreport ul:first-child li:nth-child(5):before,
.GrowthSurveyreport ul:first-child li:nth-child(6):before,
.GrowthSurveyreport ul:first-child li:nth-child(7):before{
    content: "";
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #a1b901;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    top: 35px;
}
.GrowthSurveyreport ul:first-child li:nth-child(4):before{
  border-top: 15px solid #ffd56b
}
.GrowthSurveyreport ul:first-child li:nth-child(5):before{
  border-top: 15px solid #ffa774;
}
.GrowthSurveyreport ul:first-child li:nth-child(6):before{
  border-top: 15px solid #ff7172;
}
.GrowthSurveyreport ul:first-child li:nth-child(7):before{
  border-top: 15px solid #e6c7ff;
}
.AccordianTabl h5 {
  display: flex;
  justify-content: space-between;
  padding: 10px 15px;
  background: #fff;
  border-radius: 25px 25px 25px 25px;
  box-shadow: 0px 0px 5px 1px #ccc;
}
.AccordianTabl {
  background: #fff;
  border-radius: 20px;
  padding: 0 0px 0px;
  box-shadow: -1px 1px 5px 0px #b2b2b2;
}
.GrowthSurveyreport ul li p {
  font-size: 15px;
  color: #000;
  white-space: nowrap;
  text-align: center;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.GrowthSurveyreport ul li p span {
  font-size: 14px;
  color: #8d8d8d;
  display: inline-block;
  margin-top: 5px;
}
.progscorecrl{
  border: 1px solid #4169e1;
  border-radius: 16px;
  padding: 7px 4px;
  background: #e8eeff;
  min-width: 50px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(3), .GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(4), .GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(5), .GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(6), .GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(7) {
  background: none;
}
.GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(3):before, .GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(4):before, .GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(5):before, .GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(6):before, .GrowthSurveyreport.growthsrtabletwo ul:first-child li:nth-child(7):before{
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #00006c
}
.GrowthSurveyreport.growthsrtabletwo ul li {
  background: #ffffff;
}
.GrowthSurveyreport.growthsrtabletwo ul li:nth-child(1) ,.GrowthSurveyreport.growthsrtabletwo ul li:nth-child(2) {
  background: #E3F2FD;
}
.GrowthSurveyreport.growthsrtabletwo  ul li:nth-child(1){
  background: #addbff;
}
.GrowthSurveyreport.growthsrtabletwo  ul li{
  width: 17%;
  margin: 0;
}
.GrowthSurveyreport.growthsrtabletwo  ul{
  border: 0;
  margin-top: 15px;
  margin-bottom: 15px;
  box-shadow: 0 0 5px #808080;
  border-radius: 8px
}
.GrowthSurveyreport.growthsrtabletwo  ul li:last-child{
  border-radius: 0 6px 6px 0;
}
.GrowthSurveyreport.growthsrtabletwo .progscorecrl{
  background: transparent;
  border: 0;
} 
.Course_report table th:before ,.Course_report table td:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 45px;
  background: #fff;
  right: -8px;
  top: 0px;
}
.Course_report table.GrowthSurvytbl th:before{
  background: #e5e4f9;
}
.Course_report table.GrowthSurvytbl tr:first-child td:before {
  background: #81aaff !important;
  height: 45px !important;
  top: 0px;
}
.Course_report table.GrowthSurvytbl tr:first-child td:nth-child(2)::before {
  top: 0px !important;
  right: -10px !important;
}
.Course_report table tr:nth-child(odd) td:first-child:before {
  background: #ddefff;
  height: 55px;
}
.Course_report table.GrowthSurvytbl tr:first-child td:last-child::before{
  display: none;
}
.Course_report table.GrowthSurvytbl th:last-child::before{
  display: none;
}
.Course_report table.GrowthSurvytbl td.blank_td:nth-child(2){
 border: 0 !important;
 border-radius: 0 ;
}
.Course_report table.GrowthSurvytbl th{
  background:#e5e4f9
}
.Course_report table.GrowthSurvytbl tr th {
  padding: 12px 5px;
}
.Course_report table td:before{
  background: #fff;
}
.Course_report table tr {
  box-shadow: -1px 1px 5px 0px #b2b2b2;
  border-radius: 30px;
}
.Course_report table tr:first-child {
  box-shadow: none;
}
.Course_report table tr td:nth-child(2) {
  background-color: #81aaff;
  border-radius: 30px;
  padding: 5px 7px;
  border: 5px solid #fff;
  min-width: 215px;
}
.Course_report table tr th:nth-child(2),.Course_report table tr td:nth-child(2){
  max-width: 200px;
  min-width: 225px;
  width: 225px;
}
.StudentNameed {
  color: #fff;
  white-space: nowrap;
  font-size: 14px;
  /* max-width: 80px;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.Course_report table tr:nth-child(odd) td:nth-child(2) {
  background: #fff;
  border-color: #ddefff;
  display: block;
  /* box-shadow: 0px 0px 2px #aaaaaa; */
}
.Course_report table tr:nth-child(odd) td::before {
  background: #ddefff;
  height: 55px;
}
.Course_report table tr:nth-child(odd){
  background-color: #ddefff
}
.Course_report table tr:nth-child(odd) td:nth-child(2) .StudentNameed{
  color: #000;
}
/* .Course_report table tr:nth-child(odd) td:nth-child(2)::before {
  width: 10px;
  height: 56px;
  right: -14.7px;
  top: -5px;
} */
.Course_report table tr:nth-child(odd) td:last-child:before {
display: none;
}
.Course_report table tr:nth-child(1) {
  background-color: #fff;
}
.Course_report table.GrowthSurvytbl tr:nth-child(1) {
  background-color: #81aaff;
}
.Course_report table tr:nth-child(odd) td:nth-child(2):before {
  content: "";
  position: absolute;
  width: 10px;
  height: 55px;
  background: #ddefff;
  right: -13px;
  top: -4px;
}
.PlanTblCourse_Name span ,.PlanTblModule_Name span,.courseprogres_List .modules_List span.modintials{
  background: #4cb1e5;
  color: #fff;
  padding: initial;
  border-radius: 50%;
  width: 27px;
  font-size: 13px;
  text-align: center;
  height: 27px;
  display: inline-block;
  line-height: 27px;
  margin-right: 5px;
}
.heading h2 .dropdwn_leaner_ img.report_Iocns {
  width: auto;
  /* max-height: 22px; */
}
span.inprogressbatchtbl {
  padding: 1px 15px;
  border-radius: 15px;
  background: #ff9333;
  font-size: 14px;
  margin-left: auto;
  margin-right: 11px;
}
.Course_report .GrowthSurvytbl[aria-expanded="true"] svg.svg-inline--fa.fa-chevron-down {
  transform: rotate(180deg);
  transition: all .3s;
}

.Course_report .GrowthSurvytbl[aria-expanded="false"] svg.svg-inline--fa.fa-chevron-down {
  transform: rotate(0deg);
  transition: all .3s;
}
.StudentIng.studentinitials {
  background: #cce5ff;
  border: 1px solid #81aaff;
}
.PlanTblCourse_Name {
  min-width: 350px;
}
.skill_pnlcircle.Stu_skill_panel_growth:nth-child(2) p.SkillPlanatryprog {
  left: auto !important;
  right: -36px !important;
}
.skill_Connectionsbubl .NHPreogressouter {
  background: #e8f8ff;
}

.Skills_Connection_List {
  min-width: 240px;
  position: absolute;
  padding: 5px 5px 5px 9px;
  border-radius: 6px;
  background: #fffaf3;
  left: -256px;
  top: 50%;
  display: none;
  transform: translateY(-50%);
  border: 1px solid #797979;
}
.NHPreogressouter:hover .Skills_Connection_List{
  display: block;
}
.SchHolistcFlowrthree .Skills_Connection_List,.RightbarPannel .Skills_Connection_List{
  display: block;
  position: static;
  background: #fff;
  transform: none;
  border: 0;
}

.SchHolistcFlowrthree .Skills_Connection_List ul li,.RightbarPannel .Skills_Connection_List ul li {
  font-size: 16px;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}
/* .SchHolistcFlowrthree .Skills_Connection_List ul li:last-child{
  border-bottom: 0;
} */
.SchHolistcFlowrthree .Skills_Connection_List ul li span{
 width: 35px;
 height: 35px;
 line-height: 33px;
}
.Skills_Connection_List ul li {
  font-size: 14px;
  padding: 5px 0;
}
.Skills_Connection_List ul li span sub {
  bottom: 0;
  font-size: 8px;
}
.Skills_Connection_List ul li span {
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
  margin-right: 5px;
  background: #2196F3;
  color: #fff;
  width: 30px;
  border: 1px solid #0040ff;
  height: 30px;
  line-height: 28px;
  text-align: center;
}
.Skills_Connection_List:before {
  content: "";
  height: 0;
  border-top: 8px solid transparent;
  border-left: 8px solid #797979;
  border-bottom: 8px solid transparent;
  position: absolute;
  top: 50%;
  right: -8px;
  transform: translateY(-50%);
}
.SchHolistcFlowrthree .Skills_Connection_List:before, .RightbarPannel .Skills_Connection_List:before{
  display: none;
}
.Hometwowrap .skill_Connectionsbubl.NHProgresslistitem:nth-child(4){
  margin-top: 68px;
}

/* Student profile  */
.Studimensional_progress .Coursecompprcent circle.rc-progress-circle-trail,
.Stu_courseprog .Coursecompprcent circle.rc-progress-circle-trail,
.Stu_courseprog .Coursecompprcent circle.rc-progress-circle-path,
.Studimensional_progress .Coursecompprcent  circle.rc-progress-circle-path {
  stroke-width: 24px;
}
.Studimensional_progress .Coursecompprcent {
  width: 150px;
  margin: auto;
}
.stuOvrallprg .Studimensional_progress .Coursecompprcent {
  max-width: 80px;
}
.Studimensional_progress .Coursecompprcent svg,
.Stu_courseprog .Coursecompprcent svg{
  border-radius: 50%;
}

.Studimensional_progress span.PercentCompleted {
  font-size: 38px;
}
.Studimensional_progress .percenrSign {
  font-size: 20px;
}
.perticulardim_prog .dimName img,.Stuoverallprgdgn .dimName img{
  max-width: 24px;
}
.Studimensional_progress .perticulardim_prog  .Coursecompprcent,
.StuEnroCorseList .Stu_courseprog .Coursecompprcent{
  width: 80px;
  margin: auto;
}
.Studimensional_progress .perticulardim_prog  span.PercentCompleted,
.StuEnroCorseList .Stu_courseprog span.PercentCompleted,
.stuOvrallprg  .Studimensional_progress span.PercentCompleted{
  font-size: 20px;
} 
.Studimensional_progress .perticulardim_prog .percenrSign {
  font-size: 14px;
}
.Studimensional_progress  .PercentCompleted {
  top: 56px !important;
}
.Studimensional_progress .perticulardim_prog .PercentCompleted,
.Stu_courseprog .PercentCompleted,
.stuOvrallprg  .Studimensional_progress span.PercentCompleted {
  top: 35px !important;
}
.perticulardim_prog {
  text-align: center;
  width: 23.5%;
  border-radius: 5px;
  padding: 0px 0 20px;
}
.Studimensional_progress .StuEnroCorseList .Coursecompprcent {
  width: 40px;
}

.Studimensional_progress .StuEnroCorseList .PercentCompleted {
  top: 17px !important;
  font-size: 14px;
}
.Studimensional_progress .StuEnroCorseList .percenrSign {
  font-size: 8px;
}
.Studimensional_progress .StuEnroCorseList .Coursecompprcent circle.rc-progress-circle-path {
  stroke: #3ec093 !important;
}
.Studimensional_progress .StuEnroCorseList .Coursecompprcent circle.rc-progress-circle-path {
  stroke-width: 12px;
}
.StuEnroCorseList ul li {
  width: 100%;
  margin-bottom: 15px;
  justify-content: start;
  background: #ffffff;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgb(0 0 0 / 45%);
}
.StuEnroCorseList ul li .StudentNameed{
  white-space:initial
}

.StugrowthSrvy_bg {
  padding: 0px 60px;
  background: #ffffff;
  border-radius:20px 20px;
  border-top: 0;
}
.StudGrowthservey .AccordianTabl h5 {
  margin-bottom: 5px;
}
ul.stuplansummList li {
    display: inline-block;
    text-align: center;
  }

ul.stuplansummList {
  display: flex;
  justify-content: space-around;
}
ul.stuplansummList li .Coursecompprcent {
  margin: auto;
}
.stuplansummacrdlist {
  border: 1px solid #ccc;
  /* padding: 10px 10px; */
  border-radius: 4px;
}
.stuplanlbl {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px 5px;
}
.Grwtharrow_img img {
  max-width: 220px;
}
.StuEnroCorseList ul li .StudentIng img {
  border-radius: 50%;
  min-width: 36px;
}

/*2nd design  */
.stuOvrallprg {
  /* background: #f1f1f1; */
  padding: 0 0 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.round_progres_.mt-3.Stuoverallprgdgn {
  background: #fff;
  max-width: 200px;
  margin: auto;
  width: 156px;
  border-radius: 5px;
  margin-right: 10px;
  box-shadow:0 0 5px #0000003d
}
.perticulardim_prog{
  background: #fff;
  box-shadow:0 0 5px #0000003d
}
/* .AllnumberWorkstu ul li{
    text-align: center;
    margin-right: 50px;
    min-width: 150px;
    margin-top: 24px;
}
.AllnumberWorkstu ul li img{
  max-width: 36px;
}
.AllnumberWorkstu ul li span{
  display: block;
  font-size: 16px;
  margin-top: 10px;
}
.AllnumberWorkstu ul li span strong{
  font-size: 14px;
  margin-right: 5px;
  color: #9f9f9f;
} */
.StuEnroCorseList .PercentCompleted {
  top: 16px !important;
  font-size: 16px;
}
.stuplansummList .PercentCompleted{
  top: 14px;
}
.StuEnroCorseList  .Coursecompprcent {
  width: 40px;
}
.StuEnroCorseList ul li ul li {
  width: 32%;
  /* display: flex;
  justify-content: space-between; */
  font-size: 16px;
  /* box-shadow: none;
  margin-bottom: 0 !important;
  padding: 0 !important; */
}
.PlanTblCourse_Name img{
  max-width: 50px;
}
.StuEnroCorseList .Stu_courseprog .Coursecompprcent {
  margin: 0;
  width: 115px;
}
.StuEnroCorseList .StudentIng {
  width: 60px;
  height: 60px;
  background: #fff;
  border: 4px;
}
.StuEnroCorseList ul li .StudentIng img{
  border-radius: 0px;
}
.StuEnroCorseList .Stu_courseprog .Coursecompprcent span.PercentCompleted {
  top: 43px !important;
  font-size: 34px;
}

.StuEnroCorseList .Stu_courseprog .Coursecompprcent span.PercentCompleted .percenrSign {
    font-size: 16px;
}
.pre_growth_Servy {
  padding: 20px 0;
}
.plnsummlbl{
  border-bottom: 1px solid #329fff;
}
.modules_List img,.studentClsDetails h4 img ,.uploadStuProf img {
  max-width: 25px;
  max-height: 25px;
}
.pre_growth_Servy .indivisectionprog {
  padding: 60px 10px 20px;
}
.StugrowthSrvy_bg .middleContentMainDiv .moveTopContent2 {
  top: auto;
  bottom: -75px;
}
.StugrowthSrvy_bg .middleContentMainDiv .moveTopContent1 {
top: -47px;
}
.Course_report .inprogressbatcharrow[aria-expanded="true"] svg.svg-inline--fa.fa-chevron-down {
  transform: rotate(180deg);
  transition: all .3s;
}
.reminderCourseContent .progressstyl .Coursecompprcent {
  width: 60px;
  background: transparent;
  box-shadow: none;
  border: 0;
}
.SemicircleProgress circle {
  stroke-width: 25px;
}
.SemicircleProgress .semicircle-container{
  text-align: left;
}
.progressstyl .semicircle-container circle {
  stroke-width: 18px;
}
.StudGrowthservey .SemicircleProgress circle:nth-child(2) {
  stroke:#329fff;
}
.StudGrowthservey  span.semicircle-percent-value {
  font-size: 28px;
  color: #329fff;
}
 .SemicircleProgress circle:nth-child(2) {
  stroke: rgb(0 109 204);
}
.SemicircleProgress span.semicircle-percent-value {
  font-size: 30px;
  color: #232323;
  /* left: 92px !important; */
  /* text-align: left !important; */
  font-weight: 600;
}
.indivisualprogressivecourse .progressstyl .Coursecompprcent {
  width: 200px;
}
.progressstyl span.semicircle-percent-value {
  bottom: -8px !important;
  font-size: 26px;
  color: #232323;
  font-weight: 600;
}
.coursedetailbnr .progressstyl span.PercentCompleted .Coursebanner p {
  padding: 0px 0 5px;
}
.coursedetailbnr .progressstyl span.PercentCompleted{
  left: 0;
}

.studentClsDetails .coursedtlImage img {
  border-radius: 0px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.studentClsDetails .coursedtlImage{
  border: 0;
}
.coursedtlImage.studtlinitials {
  min-height: 200px;
  border: 1px solid #cce5ff;
  border-radius: 10px 10px 0 0;
  background: #cce5ff;
}
.coursedtlImage.studtlinitials p {
  font-size: 60px;
  color: #0066dc;
}
.coursedtlImage.studtlinitials {
  border: 1px solid #cce5ff;
  background: #cce5ff;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  min-height: 180px;
}
.coursedtlImage.studtlinitials p {
  font-size: 60px;
  color: #0066dc;
}
.uploadStuProf {
  background: #90CAF9;
  border-radius: 0 0 10px 10px;
}
.StuPlanSummy .TeacherDashboard .rc-progress-circle {
  border-radius: 50%;
  width: 35%;
}

.StuPlanSummy .TeacherDashboard .PercentCompleted {
  top: 43%;
  font-size: 18px;
  font-weight: 700;
  left: 0px;
}

.studentClsDetails .coursedetailbnr h3{
  font-size: 21px;
}
/*Start Added by alfaiz  */
.StuPlanSummy .PlanTblModule_Name .moduleName{
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 205px;
}

.StuPlanSummy .plansummryheading h3{
    width: 25%;
    margin-left: 15px;
    font-size: 18px !important;
    text-align: center;
}
.StuPlanSummy .plansummryheading h3:first-child{
  margin-left: 0;
}
.StuPlanSummy .plansummryheading h3 img{
  width: 26px;
}
/* .StuPlanSummy .TeacherDashboard  .Coursecompprcent{
    width: 80%;
} */
.studentClsDetails .Courseporogresspopup h4, .studentClsDetails .Courseporogresspopup p{
  color: #fff !important;
}
.studentClsDetails .Courseporogresspopup:after {
  border-bottom: 15px solid #666666;
  top: -11px;
}
.studentClsDetails .reminderCourseContent .emailAddress{
    font-size: 18px;
}
.studentClsDetails .courseHeading{
  border-radius: 20px;
  margin-bottom: 13px;
}
.studentClsDetails .courseCircle{
  height: 30px;
  width: 30px;
  font-size: 15px;
}
.studentClsDetails .StudGrowthservey .SemicircleProgress span.semicircle-percent-value{
  font-size: 28px;
  color: #232323;
  left: 0px !important;
  text-align: center !important;
}
.studentClsDetails .AccordianTabl .GrowthSurvyCollapse[aria-expanded="true"] svg.svg-inline--fa.fa-chevron-down {
  transform: rotate(180deg) ;
  transition: all .3s;
}
.studentClsDetails .inprogressbatcharrow[aria-expanded="true"] svg.svg-inline--fa.fa-chevron-down {
  transform: rotate(180deg) ;
  transition: all .3s;
}
/*End Added by alfaiz  */
.reminderCourseContent .progressstyl .Coursecompprcent p {
  padding: 0;
}
.progressstyl span.proficencyprog p, .progressstyl span.CompltProgress p, .progressstyl span.TimProgress p {
    cursor: pointer;
    padding-bottom: 0;
    position: relative;
}
.Coursebanner  .reminderCourseContent .Courseporogresspopup p,.Coursebanner  .reminderCourseContent .Courseporogresspopup h4{
    text-align: left;
    color: #fff;
}
.StuPlanSummy .TeacherDashboard .percenrSign,.advancementreportsumm h5 .percenrSign {
  font-size: 13px;
}
.StuPlanSummy .TeacherDashboard .Coursecompprcent{
  margin: 0;
  margin-top: 15px;
  margin-left: 15px;
  flex: 1;
}
/* .StuPlanSummy  .AccordianTabl{
  border-radius: 0;
  box-shadow: none;
} */
.coursedetailbnr .reminderCourseContent {
  min-height: 214px;
  border-radius: 10px !important;
}
.home_page_rgt_pnl .tabgrid:before {
  top: 35px;
}
.skill_pnlcircle.Stu_skill_panel_growth:nth-child(2) p.Zeroprogress {
  left: 26px !important;
}
.Stu_skill_panel_growth.skill_pnlcircle:nth-child(2) span.skils_list.skill_progLabel{
  margin-left: 0;
  margin-right: -25px;
}
.skillsReport  table td{
    
}
.skillsReport  circle.rc-progress-circle-path {
  stroke: royalblue !important;
}
.skillsReport {
    width: 100%;
    overflow-x: auto;
    /* overflow-y: hidden; */
}
.skillsReport table tr td,.skillsReport table tr th{
  padding: 3px 15px;
  font-size: 16px;
  text-align: center;
  position: relative;
  min-width: 260px;
  white-space: nowrap; 
}
.NHProgresslistitem.skillsliitem.skill_Connectionsbubl:nth-child(8) {
  margin-top: 68px;
}
.studentClsDetails .StudGrowthservey .disabledDiv {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px 20px;
  position: relative;
  margin-top: 15px;
}
.studentClsDetails .StudGrowthservey .disabledDivChild {
  background: #6e5353;
  bottom: 0;
  left: 0;
  opacity: .5;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
}
.studentClsDetails .StudGrowthservey .anchor-disabled {
  cursor: not-allowed !important;
}
.StudGrowthservey .AccordianTabl .pre_growth_Servy h5{
  justify-content: center;
}
.PlanTblModule_Name.ml-3.pl-3.flex {
  width: 40%;
  justify-content: left;
}
.indivisualprogressivecourse.p-3 {
  overflow-x: unset;
  background-color: #e8f5ff;
  padding-top: 0px !important;
}
.PlanTblModule_Name span.modulecountcir {
  font-size: 17px;
  font-weight: 600;
  height: 38px;
  line-height: 38px;
  width: 38px;
  background-color: #006dcc;
}
.inactive .Course_report .AccordianTabl {
  overflow-x: auto;
}
.studentClsDetails .studentProfileDiv {
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
  background-color: #fff;
}

.studentClsDetails .CoursebannerWrap {
  background: #e8f5ff !important;
  padding-top: 0px;
  margin-top: 15px !important;
  padding-left: 0px;
}
.StuPlanSummy .knowledgeCheckCircleMain .indivisectionprog {
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
  top: 3px;
  margin-left: -15px;
}

.StuPlanSummy .knowledgeCheckCircleMain .graphbatteryprogress {
  height: 63px;
  margin: 0px;
}

.StuPlanSummy .knowledgeCheckCircleMain .rc-progress-circle {
  width: 66px;
}

.StuPlanSummy .knowledgeCheckCircleMain .PercentCompleted {
  top: 40% !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  left: 20.6% !important;
}

.StuPlanSummy .knowledgeCheckCircleMain .titleTxt {
  font-size: 12px;
  font-weight: 600;
}

.StuPlanSummy .knowledgeCheckCircle {
  flex: 1;
}

.StuPlanSummy .knowledgeCheckCircleMain .numberDigit {
  font-size: 16px;
  font-weight: 700;
}

.StuPlanSummy .knowledgeCheckCircleMain .numberDigit small {
  font-size: 12px !important;
  font-weight: bold;
  color: #9e9797;
}
.AccordianTabl.growthServy_tbl {
  overflow: auto;
}
.SemicircleProgress.intellectualcirclwDim circle:nth-child(2){
  stroke: #85b812;
}
.SemicircleProgress.SocialcirclwDim circle:nth-child(2){
  stroke: #ff9900;
}
.SemicircleProgress.EmotionalcirclwDim circle:nth-child(2){
  stroke: #e46666;
}
.studimprog {
  width: 50%;
}

.studimprog 
.studimthread {
  text-align: left;
  padding: 10px;
  min-height: 63px;
  display: flex;
  /* box-shadow: 0px 0px 10px #ccc; */
  align-items: center;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #2196f3;
}

.stuprogskillList {padding: 20px}
 .StuPlanSummy .knowledgeCheckCircleMain .indivisectionprog {
   padding: 0px 0px 0px 0px;
   display: flex;
   flex-direction: column;
   align-items: center;
   flex: 1;
   position: relative;
   top: 3px;
   margin-left: -15px;
 }
 .indivisualprogressivecourse.threeskillsconnections .progressstyl .Coursecompprcent {
  width: 250px;
}
.SemicircleProgress.text-center.SocialcirclwDim {
  margin-top: -62px;
  width: 78%;
  margin-left: auto;
}
.SemicircleProgress.text-center.SocialcirclwDim 
p.Coursecompprcent.position-relative.pointer,.contributionCourselist  p.Coursecompprcent.position-relative.pointer {
  border: 0;
  box-shadow: none;
  background: transparent;
  margin: 20px 0 0;
  padding: 0;
}
.contributionCourselist p.Coursecompprcent.position-relative.pointer {
  margin: 0 0 0 10px;
  width: 59px;
}
.TeacherDashboard .contributionCourselist .PercentCompleted{
  font-size: 14px;
  left: 0;
  top: 35%;
}
.TeacherDashboard .contributionCourselist .PercentCompleted .percenrSign{
  font-size: 10px;
}
p.modulenumprog {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  font-size: 14px;
}
.SemicircleProgress.text-center.SocialcirclwDim .PercentCompleted {
  top: 38%;
}
.advancementreportsumm.AccordianTabl h5 p.Coursecompprcent.position-relative.pointer {
    width: 80px;
}
.advancementreportsumm.AccordianTabl h5 .Coursecompprcent circle.rc-progress-circle-trail {
  stroke-width: 20px;
}
.advancementreportsumm.AccordianTabl h5  span.PercentCompleted {
  font-size: 20px;
  top: 30px;
}
.advancementreportsumm.AccordianTabl h5{
  padding: 3px 10px;
  border-radius: 15px 15px 0 0;
}
.advancementreportsumm .PlanTblCourse_Name span{
  width: 80px;
  font-size: 28px;
  height: 80px;
  line-height: 76px;
}
.advancementreportsumm.AccordianTabl h5 strong{
  font-size: 24px;
  margin-left: 10px;
}
.advancementreportsumm.AccordianTabl h5 svg.svg-inline--fa.fa-chevron-down{
  font-size: 25px;
}
.setup_one .PaceModeSel label.Selcheckbox.m-0.ActiveQQst span.checkmark{
  top: 11px;
}
p.timint_date .courseName {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
  white-space: nowrap;
}
.contributionCourselist .courseName {
  white-space: nowrap;
}
.welcomeAgain_Poup.skipnow {
  position: absolute;
  left: 0px;
}









/* CourseLibrary */
/* design one */
.Course_tagList img ,.courses_liblist h4 img {
    max-width: 25px;
    margin-right: 9px;
}

.Course_lib_Taglist {
    /* background: #fff3e087;
    padding: 13px 17px;
    border: 1px solid #F57C00;
    box-shadow: 6px 6px 11px #00000036; */
    border-radius: 0 10px;
    margin: 10px 0 0;
}
.Course_lib_Taglist li {
  display: inline-block;
  margin: 5px 10px 5px 0px;
  border: 1px solid #329fff;
  padding:2px 10px 2px 35px;
  border-radius: 22px;
  /* box-shadow: 0px 8px 10px #8f8f8f; */
  background: #329fff;
  color: #fff;
  position: relative;
}

.Course_lib_Taglist li a {
    color: #fff;
}
.Course_lib_Taglist li:hover {
  border: 1px solid #1976D2;
}
.Course_lib_Taglist li:hover a {
    color: #fff;
    box-shadow: none;
}

.Course_lib_Taglist li:last-child {
    background: #329fff;
}

.Course_lib_Taglist li:last-child a {
    color: #ffffff;
    box-shadow: none;
}
.taglabel {
  display: block;
  position: relative;
  padding-left: 3px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
}

/* Hide the browser's default checkbox */
.taglabel input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.Course_lib_Taglist .checkmark {
  position: absolute;
  top: 2px;
  left: -23px;
  height: 17px;
  border-radius: 4px;
  width: 17px;
}

/* On mouse-over, add a grey background color */
.taglabel:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.taglabel input:checked ~ .checkmark {
  background-color: #00b050;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.taglabel input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.taglabel .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



/* coursecard */
.lib_courseimg {
  width: 20%;
  background: #e3f2fd;
  border-right: 1px solid #329fff;
  position: relative;
  border-radius: 6px 0 0 6px;
}

.lib_coursul {
  width: 80%;
  padding: 5px  0px 0; 
}
.lib_courseimg img {
  padding: 3px;
  border-radius: 6px;
  background: #faf4ea;
}

.lib_coursecarddtl {
  display: flex;
}

.lib_Course_cardTitle {
  border-bottom: 1px solid #329fff;
  padding: 5px 10px;
}

.lib_course_card {
  padding: 0px;
  border: 1px solid #329fff;
  margin-top: 10px;
  border-radius: 6px;
}

.lib_coursul 
li {
  font-size: 14px;
  margin: 0px 0px 5px;
  position: relative;
  padding: 0px;
}

.courses_liblist {
  margin: 30px 0;
}
.lib_coursul li{
  display: flex;
  align-items: baseline;
}
/* .lib_coursul li:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: #90CAF9;
  top: 6px;
  border-radius: 50%;
  left: 0;
  border: 2px solid #1E88E5;
  box-shadow: inset 0px 0px 4px #1A237E;
} */
.explorebtn a {
  background: #e46666;
  color: #fff;
  padding: 10px 15px;
  margin: 8px 10px 8px 0px;
  border-radius: 4px;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  min-width: 125px;
  text-align: center;
}
.explorebtn a:hover{
  background:#ed8080 !important ;
  color: #fff;
}
.explorebtn a:nth-child(2) {
  background: #329fff;
}
.explorebtn a:nth-child(2):hover{
  background: #0066dc;
  color: #fff;
}

.container {
  max-width: 1140px;
}
.lib_course_items {
  display: flex;
  padding-left: 50px;
  justify-content: space-between;
}


.techrimg {
  max-width: 30px;
  margin-right: 10px;
}

.techrimg img {
  border-radius: 50%;
}

.teaher {
  display: flex;
  align-items: center;
  border-left: 1px solid #329fff;
  padding-left: 39px;
}
.explorebtn {
  /* background: #e3f2fd; */
  text-align: right;
  /* border-top: 1px solid #329fff */
}

.lib_coursul ul {
  padding: 5px 0 0;
  padding-left: 15px !important;
}

.lib_course_items {
  padding-bottom: 0px;
}

.lib_course_items p {
  font-weight: 600;
}
span.dimension_icon {
  position: absolute;
  bottom: 28px;
  left: 15px;
}
.dimension_icon img {
  width: 50px;
  padding: 1px;
  border: 1px solid #727272;
}
.lib_coursul li img {
  max-width: 14px;
}

.SkillDropdwn {
  /*display: none; */
  position: absolute;
  width: 300px;
  background: #ffffff;
  padding: 0px;
  z-index: 9;
  top: 29px;
  left: 0;
  border: 1px solid #FFF3E0;
  box-shadow: 0 0 4px #707070;
  border-radius: 3px;
}

.Course_lib_Taglist .SkillDropdwn li {
  width: 100%;
  border-radius: 0;
  border: 0;
  margin: 0;
  background: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 36px;
  color: #000;
}

.Course_lib_Taglist .SkillDropdwn li label.taglabel {
  color: #000;
}
.Course_lib_Taglist .SkillDropdwn li:hover {
  background: #efefef;
}
.learner_pLan_dropdown ul.dropdown-menu.dropdown-menu-end.lPDropdwnList.search_tag_dimens {
  width: 240px;
}
.newinfo_popupdimension .skills_Progress_Dimen .skilanddimensioncircle {
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: #ccc;
  position: relative;
  margin: auto;
}
.back_next_pos .backpageStrip.flex.pt-0.pb-3 {
  position: absolute;
  top: 44px;
  left: 0;
}
.GrowthSurvytbl .Studimensional_progress .PercentCompleted {
  top: 88px !important;
}
.Course_report.skillsReport table tr th,.Course_report table tr th {
  position: sticky;
  top: -14px;
  z-index: 1;
  background: #fff;
  padding: 10px 0;
  border-radius: 0 !important;
}
.Course_report table tr th{
  top:0
}
.breadCrum li.Brumprofile ul li.Addlearner_bradcrumb {
  position: sticky;
  bottom: -7px;
  background: #fff;
}
.whreyou_left {
  margin-left: auto;
  padding: 0px 7px 3px;
  background: #FFF8E1;
  outline: 2px solid #F57C00;
  border-radius: 5px;
  margin-right: 10px;
}
.whreyou_left img {
  max-width: 18px;
}
.createdcourse_list ul li img,.plan_content_cover h5 img {
  max-width: 20px;
  margin-right: 5px;
}
.createdcourse_list ul li{
  margin-bottom: 5px;
  margin-top: 5px;
  font-size: 14px;
}
span.readmoretxt {
  position: relative;
  color: #006dcc;
}


.plan_content_cover ,.plans_contents_{
  padding-left: 0px;
}

.plan_content_cover ul li,.plans_contents_ ul li  {
  font-size: 16px;
  margin-bottom: 5px;
  position: relative;
  padding-left: 0px;
}
.plans_contents_ ul li{
  margin-bottom: 10px;
}
.plan_content_cover h5 {
  border-top: 1px solid #cccccc;
  padding-bottom: 5px;
  margin-bottom: 5px;
  /* border: 1px solid #ccc; */
  padding-left: 5px;
  border-radius: 4px;
  background: #eee;
  box-shadow: 1px 1px 4px 0px #919191;
}
.plan_content_cover ul li img.green_tickk, .plans_contents_ ul li img.green_tickk {
  max-width: 14px;
  position: absolute;
  left: 0;
  top: 6px;
}
.plan_content_cover ul {
  padding-left: 10px !important;
}
img.mr-2.plan_image_ {
  max-width: 25px;
}
.Plan_card_ {
  border: 1px solid #329fff;
  background: #e8f5ff;
  padding: 5px 5px 1px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.plan_content_cover ul li svg.svg-inline--fa.fa-hand-point-right {
  color: #329fff;
}
.challenge_table th {
  border-right: 1px solid #1976D2 !important;
  border-bottom: 1px solid #1976D2 !important;
  font-size: 20px;
  padding: 8px;
  background-color: #329fff;
  color: #fff;
}
.challenge_table td, .challenge_table th {
  border: 1px solid #329fff !important;
  vertical-align: middle;
  padding: 10px;
  font-size: 16px;
}
.challenge_table td p {
  margin: 0;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 6px;
  width: 40%;
  margin: 10px 0 5px;
  font-size: 15px;
}
.corse_items img{
  max-width: 20px;
  margin-right: 5px;
}
.challenge_table td p:nth-child(2){
  width:58%;
  }
  ul.skill_overview_ li p {
    font-size: 16.5px;
}
.plan_content_cover.w-100.flex.pl-0 h5.mt-3 {
  padding: 6px 10px;
  min-width: 25%;
}
.learner_pLan_dropdown ul.lPDropdwnList {
  width: 270px !important;
}
.challenge_img img{
  min-width: 300px;
  max-width: 300px;
}
.Plan_card_ .helpvideos .helpvideosection img {
  max-width: 100%;
  cursor: pointer;
  border: 1px solid #1d103f;
  border-radius: 5px;
  margin: 0;
  box-shadow:none;
}


div.planprogress_list ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.planprogress_list {
    padding:100px 30px 0px 10px;
}
.planprogress_list ul li{
  width: 25%;
  position: relative;
  display: flex;
  align-items:center;
}
/* .planprogress_list ul li:last-child{
  width: auto;
} */
.planprogress_list ul li .planprog_title_text {
  background: #329fff;
  font-size: 18px;
  min-width: 60px;
  position: relative;
  width: 60px;
  text-align: center;
  border-radius: 6px;
  padding:12px 7px;
  color: #ffff;
  font-weight: 600;
  box-shadow: 0px 0px 0px 1px #283593;
}
.planprogress_list ul {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
  .character {
    position: absolute;
    left: calc(50% - 35px);
    top: -100px;
}

.character  img {
  max-width: 35px;
}
.course_track {
  width: calc(100% - 60px);
  background: #efefef;
  height: 40px;
  border-top: 1px solid #ccc;
  position: relative;
  border-bottom: 1px solid #ccc;
}
.planprogress_list ul li .planprog_title_text svg{
  color: #fff;
}
.track_progress {
  position: absolute;
  left: 0;
  height:48px;
  width: calc(0% - 0px);
  background: #43a047;
}
.track_progressc1 {
width: 50%;
}
span.couprog_ {
  margin: auto;
  font-size: 14px;
  font-weight: 600;
  color: #92e395;
}
ul.dropdown-menu.dropdown-menu-end.course_dropdwn {
  display: none;
}
ul.dropdown-menu.dropdown-menu-end.course_dropdwn.show{
  display: block;
  max-width: 310px;
  min-width: 310px
}
.course_dropdwn li.rewardDrpDwnd img {
  max-width: 24px;
  margin-right: 10px;
}
.course_dropdwn.dropdown-menu::after{
display: none;
}
span.poitbag_score {
  position: absolute;
  top: -33px;
  left: 8px;
  counter-reset: #212121;
}
span.poitbag_score img,.Score_points img {
  max-width: 30px;
}
span.poitbag_score span {
  position: absolute;
  top: -26px;
  left: 4px;
  color: #212121;
  font-size: 18px;
}
span.plan_end_flag {
  position: absolute;
  top: -40px;
  right: 50px;
}
span.Left_plan_end_flag {
  position: absolute;
  top: -40px;
  left: 50px;
}
span.plan_end_flag img,span.Left_plan_end_flag img {
  max-width: 42px;
}
.planprogress_list ul.module_list li.green_bg {
  background: #388E3C;
  color: #fff;
}
.planprogress_list ul.module_list li {
    justify-content: center;
    width: 25%;
    text-align: center;
    border-right: 1px solid #e2e2e2;
    padding: 10px 0px;
    font-size: 12px;
}
.planprogress_list ul.module_list li.green_bg {
    background: #388E3C;
    color: #fff;
}
.module_objective .plans_contents_ ul li svg.circlegreen {
  color: #388e3c !important;
}
.module_objective .plans_contents_ ul li svg {
  font-size: 24px;
  color: #bdbdbd;
  vertical-align: middle;
}.plans_contents_.pl-3 {
  margin: 10px;
  /* border: 1px solid #388e3c;
  border-radius: 8px;
  background: #E3F2FD;
  padding: 10px;
  margin: 10px 30px 10px 10px;
  border-left: 10px solid #388e3c; */
}

.planprogress_list ul.module_list li:before {
  content: "";
  position: absolute;
  width: 47px;
  height: 50px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid #388e3c;
  bottom: -16px;
  display: none;
}
.planprogress_list ul.module_list li.green_bg:before{
  display: block;
}
/* .module_objective .plans_contents_ ul li {
  margin-bottom: 8px;
  border: 1px solid #64B5F6;
  padding: 5px 10px;
  border-radius: 28px;
} */














.staire_map {
  display: flex;
  flex-direction: column-reverse;
  padding: 45px 0;
}
.staire_map .planprogress_list ul li {
    padding: 20px;
}

.staire_map ul {
  max-width: 59%;align-items: flex-start !important;
margin: auto;
}
/* 
.planprogress_list .staire_map ul li {
  border: 1px solid #e9e9e9;
  min-width: 80px;
  justify-content: center;
  padding: 1px;
  font-size: 10px;
  background: #eee;
  max-width: 60px;
}

.planprogress_list .staire_map ul li:nth-child(2) {
    margin-top: -15px;
}

.planprogress_list .staire_map ul li:nth-child(3) {
    margin-top: -30px;
}

.planprogress_list .staire_map ul li:nth-child(5) {
    margin-top: -60px;
}

.planprogress_list .staire_map ul li:nth-child(4) {
    margin-top: -45px;
}
.staire_map ul:nth-child(2),.staire_map ul:nth-child(4){
  align-items: flex-end !important;
}
.planprogress_list .staire_map ul:nth-child(2) li:nth-child(2),.planprogress_list .staire_map ul:nth-child(4) li:nth-child(2) {
  margin-top: 15px;
}

.planprogress_list .staire_map ul:nth-child(2) li:nth-child(3),.planprogress_list .staire_map ul:nth-child(4) li:nth-child(3) {
  margin-top: 30px;
}

.planprogress_list .staire_map ul:nth-child(2) li:nth-child(5),.planprogress_list .staire_map ul:nth-child(4) li:nth-child(5) {
  margin-top: 60px;
}

.planprogress_list .staire_map ul:nth-child(2) li:nth-child(4),.planprogress_list .staire_map ul:nth-child(4) li:nth-child(4) {
  margin-top: 45px;
} 
.planprogress_list .staire_map ul li:first-child {
  background: #329fff;
  border: 1px solid #329fff;
  border-radius: 2px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.staire_map ul li span.poitbag_score{
  left: 0;
  top: -26px;
 }
.staire_map ul:nth-child(2) li:first-child span.poitbag_score, .staire_map ul:nth-child(4) li:first-child span.poitbag_score{
 left: auto;
 right: 0;
}
span.poitbag_score img, .Score_points img {
  max-width: 24px;
}
span.poitbag_score span {
  position: absolute;
  top: -20px;
  left: 0px;
  color: #212121;
  font-size: 15px;
} */
/* 
.planprogress_list .staire_map ul li.green_bg:before {
  content: "";
  position: absolute;
  right: -109px;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  top: -1px;
 
  background-image: url("../images/physicalarrow.png");
  background-size: contain;
}
.planprogress_list .staire_map ul li.green_bg:after {
  content: "";
  width: 100px;
  height: 8px;
  background: #1ec1d9;
  position: absolute;
  right: -102px;
  top: 5px;
} */

.planprogress_list ul li:nth-child(2) {
  margin-top: -15px;
}

.planprogress_list ul li:nth-child(3) {
  margin-top: -30px;
}

.planprogress_list ul li:nth-child(4) {
  margin-top: -45px;
}

.planprogress_list ul li:nth-child(5) {
  margin-top: -60px;
}

div.planprogress_list ul:nth-child(2),div.planprogress_list ul:nth-child(4) {
  flex-direction: row-reverse;

}
.planprogress_list ul li:first-child {
  background: #329fff;
  color: #ffff;
  padding: 8px;
  /* margin-bottom: -9px; */
  max-width: 70px;
  margin-top: 5px;
  font-size: 14px;
  border: 0;
  font-weight: 900;
  position: relative;
  bottom: -5px;
}
.staire_map ul {
  max-width: 80%;
}
.planprogress_list ul li{
  justify-content: center;
  padding: 10px;
  background: #c0dfff;
  border-color: #eee;
  border-radius: 3px;
}
.staire_map ul {
  max-width: 80%;
  margin: 28px 10px;
}
div.planprogress_list ul:nth-child(2), div.planprogress_list ul:nth-child(4){
  margin-left: 78px;
}
.planprogress_list ul:last-child li:first-child{
  margin-bottom: -8px;
  margin-left: -0px;
}
.staire_map {
  padding: 12px 20px;
  /* background: repeating-linear-gradient(42deg, #cae3fd, #E1BEE7); */
  margin: 10px;
  /* border: 3px solid #ffe4e4; */
  border-radius: 8px;
  position: relative;
  /* border-left: 3px solid #ffe4e4; */
  /* box-shadow: 0px 0px 0px 1px #BA68C8; */
  min-height: 66vh;
  /* background-image: url("../images/game_bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
}
.character {
  position: absolute;
  left: calc(74% - 46px);
  top: -66px;
}
.character img{
    max-width: 65px;
    z-index: 10;
    position: relative;
}
span.poitbag_score span {
  position: absolute;
  top: -19px;
  left: 4px;
  color: #212121;
  font-size: 13px;
}
span.poitbag_score img, .Score_points img {
  max-width: 22px;
}
span.poitbag_score {
  position: absolute;
  top: -24px;
  left: 35px;
}
.planprogress_list ul li.rewardDrpDwnd {
  width: 100%;
  max-width: 100%;
  height: auto;
  margin: 0;
  justify-content: left;
}
.green_bg {
  background: #388e3c;
  position: absolute;
  left: 0;
  height: 100%;
  /* width: 81%; */
}
.module_num {
  font-weight: 900;
  color: #000;
}
.module_num span{
  z-index: 9;
  position: relative;
}
.planprogress_list ul li ul.course_dropdwn li span {
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* width: 210px; */
  display: block;
  overflow: hidden;
}
.planprogress_list ul li ul.course_dropdwn li{
  background-color: #fff;
  border-bottom: 1px dashed #ccc;
  margin: 0;
}
.planprogress_list ul li ul.course_dropdwn li:first-child{
  margin: 0;
  color: #212121;
  bottom: 0;
}
.planprogress_list ul li ul.course_dropdwn li:hover,.planprogress_list ul li ul.course_dropdwn li.Active_plan_{
  background:#329fff !important;
  color: #ffff !important;
}
.planprogress_list ul li ul.course_dropdwn li:hover p,.planprogress_list ul li ul.course_dropdwn li.Active_plan_ p{
  color: #ffff !important;
}
.planprogress_list ul li ul.dropdown-menu.dropdown-menu-end.course_dropdwn.show {
  display: block;
  max-width: 250px;
  /* transform: none !important; */
  min-width: 250px;
  margin: 0;
  /* top: 36px !important; */
}
.module_num span svg,.course_indx  svg{
 transform: rotate(-90deg);
 padding: 0 !important;
 margin-left: 2px;
}

.obj_prog_ress {
  min-width: 80px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  /* background: #BBDEFB; */
  margin-right: 15px;
  padding: 7px 1px 0px;
  border-radius: 4px;
  /* border: 2px solid #329fff; */
}

.obj_prog_ress span.percentage_marker {
  display: block;
  font-size: 10px;
  font-weight: 900;
  background: #388e3c;
  max-width: 28px;
  margin: -27px auto;
  padding: 1px 3px;
  position: absolute;
  color: #fff;
  border-radius: 4px;
  left: 50%;
  transform: translateX(-50%);
}
.obj_prog_ress span.Progresslistitem {
  text-align: center;
  height: 10px;
}
.obj_prog_ress span.Progresslistitem .diemensionalProgress{
  height: 8px;
  top: 1px;
}
.obj_prog_ress span.percentage_marker::before{
  content: "";
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 10px solid #388e3c;
  left: 50%;
  top: 16px;
  position: absolute;
  transform: translateX(-50%);
}
.obj_prog_ress span.percentage_marker sub{
  bottom: 0 !important;
}
p.chart_plan_name {
  /* position: absolute;
    right: 0px;
    top: 6px; */
    font-size: 17px;
    font-weight: 600;
}
p.chart_plan_name circle.rc-progress-circle-trail {
  stroke-width: 15px !important;
  stroke: #ffffff !important;
}
p.chart_plan_name svg.rc-progress-circle {
  width: 50px;
  position: absolute;
  right: 6px;
  top: 25px;
  padding: 4px;
  background: #dcc7ed;
  border-radius: 6px;
  box-shadow: 0px 0px 4px 0px #464646;
}
p.chart_plan_name 
span.PercentCompleted {
  right: 19px;
  left: auto;
  top: 38px;
  width: auto;
  font-size: 20px;
}
p.chart_plan_name  circle.rc-progress-circle-path {
  stroke: #388e3c !important;
}
.module_objective .plans_contents_ ul li {
  margin-bottom: 22px;
}
ul.objectibe_module_list {
    display: flex;
    margin-bottom: 14px;
    margin-top: 5px;
}
.module_objective .plans_contents_ ul.objectibe_module_list li {
    margin: 0;
    /* border-right: 1px solid #329fff; */
    width: 20%;
    text-align: center;
    /* background: #f7f0f0;
    border-bottom: 1px solid #329fff; */
    padding: 4px 2px;
    /* border-radius: 5px; */
}
/* 
.module_objective .plans_contents_ ul.objectibe_module_list li.active_module_obj {
    border-bottom: 4px solid #329fff;
}
.module_objective .plans_contents_ ul.objectibe_module_list li:hover {
  border-bottom: 4px solid #329fff;
} */
.planprogress_list .staire_map ul.end_course_ li {
  margin-left: 0px !important;
  margin-bottom: -5px !important;
}
.planprogress_list .staire_map ul.end_course_ li ul li:hover{
  margin: 0 !important;
}
.planprogress_list .staire_map ul.end_course_ li ul li{
  justify-content: space-between;
  color: #212121;
  margin: 0 !important;
}
.planprogress_list ul li .course_indx.start_staire_index ul.dropdown-menu.dropdown-menu-end.course_dropdwn.show {
  min-width: 333px;
}
div.planprogress_list ul:nth-child(2) span.poitbag_score , div.planprogress_list ul:nth-child(4) span.poitbag_score {
    left: 6px;
}
span.Dashboard_i_icon_ {
  position: absolute;
  right: 10px;
  top: 5px;
  z-index: 9;
}
.module_objective .plans_contents_ h3,.module_objective .plans_contents_ h4 {
  font-size: 18px;
  display: flex;
  color: #005fb2;;
  align-items: center;
}
.module_objective .plans_contents_ h4 {
  color: #005fb2;;
}
.module_objective .plans_contents_ h3 img {
  max-width: 32px;
  margin-right: 8px;
}
.module_objective .plans_contents_ h4 span._module_name,.module_objective .plans_contents_ h3 span._module_name{
  border: 1px solid #329fff;
    border-radius: 3px;
    font-size: 14px;
    margin-right: 8px;
    text-align: center;
    background: #c0dfff;
    color: #080808;
    cursor: pointer;
    width: 30px;
    height: 26px;
    display: inline-block;
    line-height: 25px;
}
.module_num span.module_dropdwn_icon,.course_indx .module_dropdwn_icon{
    position: absolute !important;
    left: 0;
    top: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    padding-left: 29px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.course_indx .module_dropdwn_icon.start_staire{
   left: 8px;;
}
.module_challenge_ h4 {
    font-size: 19px;
    margin-right: 35px !important;
}
.module_challenge_ p {
    font-size: 18px !important;
}
.module_challenge_ p em{
  font-size: 18px;
}
.how_challenges_work ul li img {
  max-width: 12px;
  margin-right: 5px;
  margin-top: 5px;
}

.how_challenges_work ul li {
  display: flex;
      align-items: flex-start;
      font-size: 16px !important;
      margin-bottom: 5px;
}



.congratulation_screen {
  position: relative;
  padding: 0 10px;
  text-align: center;
}

.congratulation_screen img.confeti_img {
  position: absolute;
  width: 78%;
  overflow: hidden;
  left: 0;
  right: 0;
  margin: auto;
  top: -49px;
}

.congratulation_screen h3 {
  color: green;
  font-size: 24px;
  margin-top: 28px !important;
}

.varat_point_bg {
  border-radius: 8px;
  background: #ffffff;
  z-index: 9;
  position: relative;
  max-width: 293px;
  margin: auto;
  /* border: 3px solid #ff9900; */
  flex-direction: row-reverse;
  padding: 0 36px;
  margin-bottom: 19px;
}
.varat_point_bg 
span {
  margin-left: 0px;
  display: block;
  font-size: 18px;
  margin-top: 16px;
}

img.shild_image {
  max-width: 99px;
  background: #fff;
  z-index: 9999;
  position: relative;
}

.congratulation_screen h3.mt-3 span {
  font-size: 16px;
  color: #000;
  margin-left: 50px;
}

.congratulation_screen h3.mt-3 span img.point_bag {
  max-width: 33px;
}

img.point_bag {
  max-width: 33px;
}
.challenge_story_vid img {border: 1px solid #1d103f;border-radius: 5px;}
p.chart_plan_name svg {
  font-size: 20px;
  color: #329fff;
}
.intructionsBox.ScenecerelateddQuiz.Analysistxt.seneriotext p{
font-size: 17px !important;
margin-bottom: 0px;
font-weight: 400;
color: #FF9800;
display: inline;
text-align: justify;
}







/* dashboard */

.top_threecard li.topper_card {
  width: 200px;
  background: #ccc;
  border: 1px solid yellow;
  display: inline-block;
  padding: 8px;
  margin: 0 5px;
  border-radius: 8px;
  box-shadow: 0px 0px 5px 0px #170460;
  min-height: 180px;
}
.top_threecard li.topper_card:nth-child(1){
  min-height: 180px;
  max-height: 180px;
  background: silver;
}
.top_threecard li.topper_card:nth-child(2){
  min-height: 200px;
  background: goldenrod;
}
.top_threecard li.topper_card:nth-child(3){
   min-height: 160px;
  max-height: 160px;

  background-color: #CD7F32;
}
.top_threecard ul {
  display: flex;
  align-items:flex-end
}

.top_threecard ul .tottle_diamond img {
  max-width: 50px;
  margin: auto;
}

.tottle_diamond.text-center {
  font-size: 20px;
  font-weight: 600;
}

.Student_Avatar {
  text-align: center;
  margin: 8px 0 0;
}

.Student_Avatar img {
  max-width: 37px;
  margin: auto;
}
.Student_name p {
  text-align: center;
}
.student_leadr_list ul li {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 5px 5px 5px;
  /* padding: 5px 5px 5px 55px; */
  background: #fff;
  border-radius: 5px;
  justify-content: space-between;
  margin: 0px 0 25px;
  border:1px solid #a86adb;
  position: relative;
}


.student_leadr_list ul li 
span.sutdent_diamond img {
  max-width: 30px;
}

.student_leadr_list ul li 
span.tottle_point img {
  max-width: 22px;
}

.student_leadr_list ul li 
span.stud_name {
  min-width: 200px;
  padding: 0 5px;
  font-weight: 600;
  position: relative;
  padding-left: 85px;

}

.student_leadr_list ul li span {
  font-size: 16px;
  padding: 0 5px;
  min-width: 100px;
  text-align: left;
}

.student_leadr_list.w-50 {
    padding: 0px 10px;
    max-height: 400px;
    overflow-y: auto;
}
.student_leadr_list ul li span.student_count{
  min-width: 20px;
  /* position: absolute;
  left: -8px;
  width: 55px;
  height: 55px;
  line-height: 55px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  background: #a86adb;
  color: #fff; */
}
.student_leadr_list ul li span.stud_name img {
  max-height: 36px;
  width: 36px;
  border: 1px solid #ff9900;
  background: #E3F2FD;
  border-radius: 50%;
}
.leader_board_contnr{
    border-top: 1px solid #ccc;
    background: #f5f5f5;
    padding: 20px 0 20px 7px;
}
.student_pro_ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.student_pro_ span:last-child{
  text-align: center;
}
.real_avtar_chat svg{
  color: #00b050;
  font-size: 24px;
}
.student_leadr_list{
  padding: 0px 15px;
}
.student_leadr_list ul li:first-child{
  background: #E3F2FD;
  border-color: #005fb2;
}
.student_leadr_list ul li:first-child span{
  font-weight: 600;
}
.student_leadr_list ul li:first-child span:first-child{
  background: #005fb2;
}
.student_leadr_list ul li.real_user_rank {
  background: #efdbff;
}

.student_leadr_list ul li span.student_count {
  min-width: 20px;
  /* position: absolute;
  left: -13px; */
  width: 50px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  border-radius: 5px;
  background: #f3d4be;
  color: #fff;
  /* transform: rotate(45deg); */
}
.student_leadr_list ul li span.student_count strong {
  /* transform: rotate(-45deg); */
  display: inline-block;
}
.student_leadr_list {
  padding: 0px 26px;
}   
.student_leadr_list ul li span.stud_name 
 strong.Avtar_user_name {
    display: inline-block;
}

.student_leadr_list ul li span.stud_name strong.Avtar_user_name  img {
  width: 60px;
  min-height: 60px;
  position: absolute;
  top: -18px;
    left: 8px;
    border: 3px solid #ffffff;
    outline: 3px solid #a86adb;
    background: #e5c6ff;
    /* border-radius: 5px; */
}
.student_leadr_list ul li span.student_count strong {
  /* transform: rotate(-45deg); */
  display: inline-block;
  line-height: 1;
  border: 1px solid #3a37e5;
  outline: 1px solid #ffff;
  border-radius: 15px;
  padding: 4px;
  background: #fff;
  color: #a86adb;
}
.student_leadr_list ul li:first-child span:first-child strong {
  margin: -8px;
  color: #005fb2;
  font-size: 16px;
  padding: 2px;
}
.student_leadr_list ul li{
  border: 1px solid #ead1ff;
}
.intructionsBox.ScenecerelateddQuiz.Analysistxt.seneriotext {
  background: #FFF3E0;
  border: 1px solid #f57c00;
  border-left: 0;
  padding: 5px 10px;
  border-right: 0;
  margin-top: 4px;
}
.how_challenges_work ul li::before{
  display: none;
}
.ScenecerelateddQuiz .how_challenges_work h4{
  margin-bottom: 10px !important;
}
.planprogress_list ul li ul.course_dropdwn li p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.but_nowbtn button.btn-blue.btn-login.d-block{
  margin-left: 0 !important;
}
.planprogress_list ul li:first-child ul.course_dropdwn li:first-child:hover{
  background: transparent !important;
}
.planprogress_list ul li:first-child ul.course_dropdwn li:first-child:hover p{
  color: #232323 !important;
}
 
.module_challenge_ .modal {
  padding-top: 17px;
  top: 16%;
}
img.avatr_image {
  max-width: 200px;
}

table th .stud_name .Avtar_user_name img,
table td.stud_name .Avtar_user_name img {
  max-width: 50px;
}

table.studentleaderboardtbl span.student_count {
  border: 3px solid #a86ada;
  padding: 5px 4px 5px;
  border-radius: 24px;
  background: #fff;
  color: #4169e1;
}

table td .stud_name .Avtar_user_name img,
table th .stud_name .Avtar_user_name img {
  max-width: 50px;
}

table.studentleaderboardtbl th {
  background: #232323;
  color: #fff;
  padding: 6px;
  min-width: 100px;
  font-size: 14px;
}

table.studentleaderboardtbl td {
  padding: 5px;
  /* background: #e7fcff;*/ 
  font-size: 14px;
  transition-delay: 1s;
  transition: all .5s;
}

table.studentleaderboardtbl th:first-child,
table.studentleaderboardtbl td:first-child {
  max-width: 60px;
  min-width: 60px;
  text-align: center;
}

table.studentleaderboardtbl td:first-child strong {
  border: 2px solid #a86ada;
  background: #fff;
  padding: 6px;
  border-radius: 11px;
}

table.studentleaderboardtbl td:nth-child(2),
table.studentleaderboardtbl th:nth-child(2),
table.studentleaderboardtbl td:nth-child(11),
table.studentleaderboardtbl th:nth-child(11),
table.studentleaderboardtbl td:nth-child(9),
table.studentleaderboardtbl th:nth-child(9),
table.studentleaderboardtbl td:nth-child(10),
table.studentleaderboardtbl th:nth-child(10) {
  min-width: 230px;
}

table.studentleaderboardtbl tr {
  border: 5px solid #fff;
  position: relative;
  transition: all .5s;
  background-color: #fff7eb;
}

table.studentleaderboardtbl tr:nth-child(even) {
  background-color: #e7fcff;
}

table.studentleaderboardtbl td:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fe9800;
}

table.studentleaderboardtbl td:after {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 0;
  background: #fe9800;
}

table.studentleaderboardtbl tr:nth-child(even) td:before,
table.studentleaderboardtbl tr:nth-child(even) td::after {
  background: #1cc0d9;
}
.badges_earned {
  background: #fff;
  z-index: 9;
  position: relative;
}
tr.my_position_list {
  background-color: #eed9ff !important;
}

table.studentleaderboardtbl tr.my_position_list td:after,
table.studentleaderboardtbl tr.my_position_list td::before {
  background-color: #a86ada !important;
}

table.studentleaderboardtbl tr::before {
  width: 0;
  height: 0;
  border-left: 25px solid transparent;
  border-right: 25px solid transparent;
  border-top: 7px solid #fe9800;
  position: absolute;
  left: -28px;
  top: 21px;
  transform: rotate(90deg);
  content: "";
}

table.studentleaderboardtbl tr:nth-child(even)::before {
  border-top: 7px solid #1cc0d9;
}

table.studentleaderboardtbl tr:nth-child(1)::before {
  border-top: 7px solid #232323;
}

table.studentleaderboardtbl tr.my_position_list::before {
  border-top: 7px solid #a86ada;
}

table.leade_bord_detailcard {
  position: fixed;
  min-width: 450px;
  max-width: 450px;
  display: none;
  left: 0;
  transition: all .5s;
  transition-delay: 1s;
  background-color: #fff;
  z-index: 55;
  border: 1px solid red;
  top: 52%;
  left: 55%;
  transform: translate(-50%, -50%);
}

table.leade_bord_detailcard ul {
  text-align: left;
  margin-bottom: 10px;
}

table.studentleaderboardtbl table.leade_bord_detailcard tr {
  background: #fff;
  border: 5px solid #329fff;
}

table.studentleaderboardtbl table.leade_bord_detailcard tr::before,
table.studentleaderboardtbl table.leade_bord_detailcard tr::after {
  display: none;
}
table.studentleaderboardtbl tr td:last-child,table.studentleaderboardtbl tr th:last-child{
  min-width: 10px;
  max-width: 10px;
}
.lbuserimage {
  margin-right: 5px;
}

.lbuserimage img {
  max-width: 80px;
}
.lbuserName i {
  font-size: 18px;
}
.lbuserName {
  text-align: left;
}

.lbuserdtl.flexone {
  background: #329fff;
}

.clbcarddtl ul li {
  width: 48%;
  margin-right: 1%;
  text-align: left;
  display: inline-block;
  padding: 5px 6px;
  /* border-bottom: 2px solid #ffa813;*/ /* background: #fff3d3;*/ 
  border-radius: 21px;
  margin: 10px 1% 0px;
}

.clbcarddtl ul li span {
  font-weight: 600;
  margin-right: 10px;
  min-width: 65px;
  display: inline-block;
}

table.studentleaderboardtbl tr:hover  table.leade_bord_detailcard {
  display: block;
  transition: all .5s;
  transition-delay: 1s;
} 
table.studentleaderboardtbl .leade_bord_detailcard th:first-child,
table.studentleaderboardtbl .leade_bord_detailcard td:first-child {
  max-width: 100%;
  min-width: 100% !important;
  text-align: center;
}

table.studentleaderboardtbl tr td:last-child {
  padding: 0 !important;
}

.userprofiledtl .clbcarddtl ul li {
  font-size: 18px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 5px;
}

.lbuserdtl.flexone {
  background: transparent;
  border-bottom: 1px solid #329fff;
  padding: 10px 10px 0;
}

.userprofiledtl {
  background: #fff;
  padding: 0px 27px 0 10px;}

.messgae_avtar {
  background: #cbe9ff;
  padding: 5px 5px 5px;
  border-radius: 41px;
  border-bottom: 1px solid #550696;
  text-align: center;
}

.userprofiledtl .clbcarddtl ul li img {
  max-width: 21px;
  margin-right: 6px;
}
span.days_left {
  position: absolute;
  right: 6px;
  bottom: 0px;
  font-weight: 400;
  font-size: 8px;
  z-index: 99;
}
span.locked_module svg {
  color: #818181 !important;
  transform: none;
  margin-right: 5px;
  margin-left: -5px;
}
.myBadgeslist .mybadges_items{
  width: 23%;
  margin: 1%;
  padding: 5px;
  border: 1px solid #329fff;
  background: hwb(0deg 0% 100% / 5%);
}
.module_objective.w-50.p-0 {
  /* background: #f5faff; */
  border-left: 1px solid #bfdfff;
}
.date_tooptip p {
    color: #fff;
}
.date_tooptip{
    display: none;
    width: 120px;
    box-shadow:2px -2px 0px #000;
    border: 1px solid #000;
    background: #5e5e5e;
    padding:7px 9px;
    position: absolute;
    border-radius: 5px;
    text-align: center;
    bottom: 20px;
    transform: translateX(50%);
    right: 50%;
}
.date_tooptip::before{
  width: 0;
  content: "";
  position: absolute;
  bottom: -8px;
  right: 50%;
  transform: translateX(50%);
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 8px solid #000000;
}
.days_left:hover .date_tooptip{
  display: block;
}
span.point_congrate {
  position: absolute;
  right: 10px;
}
.badges_earned img {
  max-width: 250px;
}
.congratemsg.flexone {
  border: 1px solid #000;
  border-radius: 82px;
  background: #FFE9C8;
  margin: 10px 0;
  padding: 2px;
}

.congratemsg .avatr_image {
  max-width: 79px;
}
.congratemsg h4 {
  text-align: left;
}
.badges_earned h4 {
  color: #a86adb;
}