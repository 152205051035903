.slider {
    position: relative;
    width: 200px;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
    position: absolute;
  }
  
  .slider__track {
    background-color: #329fff;
    width: 100%;
    z-index: 1;
    margin: auto;
    height: 7px;
    top: 3px;
  }
  
  .slider__range {
    background-color: #9fe5e1;
    z-index: 2;
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0px;
    padding-left: 0;
    padding-right: 0;
    min-height: 0;
    width: 200px;
    outline: none;
    background: transparent;
  }
  
  .thumb--zindex-3 {
    z-index: 3;
  }
  
  .thumb--zindex-4 {
    z-index: 4;
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  .slider__left-value,
  .slider__right-value {
    color: #525353;
    font-size: 14px;
    font-weight: 600;
    top: 20px;
    position: absolute;
    padding: 3px 15px;
    border: 1px solid #ccc;
  }
  
  .slider__left-value {
    left: 0px;
  }
  .thumb::-webkit-slider-thumb {
    width: 12px;
    height: 12px;
} 
  .slider__right-value {
    right: 0px;
  }  
  .RangeShorting{
    padding-bottom: 36px;
  }