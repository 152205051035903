
#LearnerModulePlanDetails .learner_pLan_dropdown ul.lPDropdwnList{
    width: 154px;
    min-width: 0;
    max-height: 169px !important;
    overflow-y: auto;
}
#LearnerModulePlanDetails .learner_pLan_dropdown{
    margin-top: -17px;
}
#LearnerModulePlanDetails .learner_pLan_dropdown h5, .startdateLabel{
    font-size: 12px;
    padding: 0px 0px 12px;

}
#LearnerModulePlanDetails .learner_pLan_dropdown .dropdownLabel{
    font-size: 14px;
}
#LearnerModulePlanDetails .learner_pLan_dropdown .dropdownDiv{
    border: 1px solid #ccc;
    padding: 11px;
    padding-left: 15px;
    padding-right: 15px;
}
#LearnerModulePlanDetails .learner_pLan_dropdown .dropdownLabel{
    font-size: 14px;
}
#LearnerModulePlanDetails .learner_pLan_dropdown li{
    padding-left: 10px;
    color: black;
}
#LearnerModulePlanDetails .table-responsive{
    width: 100%;
    overflow-x: scroll;
}
#LearnerModulePlanDetails table {
    border-color: #877676;
    width: 100%;
}
#LearnerModulePlanDetails table tbody>tr>td:first-child {
    background: #ffffff;
    position: sticky;
    width: 380px !important;
    max-width: 380px !important;
    left: 0;    
  }
  
#LearnerModulePlanDetails table th {
    font-size: 16px;
    background-color: #329fff;
    color: white;
    text-align: center;
}
#LearnerModulePlanDetails table .subheading {
    background-color: rgb(231, 231, 231);
    font-size: 14px;
    font-weight: 600;
}
#LearnerModulePlanDetails table .subheading .courseModule{
    background-color: rgb(231, 231, 231) !important;
}
#LearnerModulePlanDetails table td {
    color: #232323;
    font-size: 16px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#LearnerModulePlanDetails table td span {
    font-size: 13px;
}
#LearnerModulePlanDetails .table-responsive::-webkit-scrollbar {
    width: 3px;
    height: 10px;
}
 
/* Handle */
#LearnerModulePlanDetails .table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
    scrollbar-width: thin;
}

#LearnerModulePlanDetails .react-calendar__tile {
    max-width: 100%;
    padding: 8px 6.6667px;
    /* background: none; */
    text-align: center;
    line-height: 15px;
    font-size: 14px;
}
#LearnerModulePlanDetails .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 0.5rem;
}
