.Blogbanner,
.SlideItems {
  background: url("../images/blogBannner.jpg") center no-repeat;
  background-size: cover;
  min-height: 260px;
  display: flex;
  align-items: center;
}
.guidebanner {
  background: url("../images/guideBannner.png") center no-repeat;
  background-size: cover;
  min-height: 220px;
  display: flex;
  align-items: center;
  margin-top: 75px;
}
.Blogbanner h5,
.guidebanner h5 {
  font-size: 20px;
  font-weight: 600;
  color: #6d6d6d;
  margin-bottom: 10px;
}
.blogdetailsstyl .blogdesc {
  max-width: 80%;
  margin: 0 auto;
  box-shadow: none;
  border: none;
}
.Blogbanner h3,
.guidebanner h3 {
  font-size: 50px;
  color: #3a3a3a;
  font-weight: 600;
  margin-bottom: 5px;
  letter-spacing: 2px;
  text-shadow: 0px 3px 4px #bab7b7;
  padding: 0px 20px;
}
.Blogbanner h3 span,
.guidebanner h3 span {
  color: #e46666;
}
.Blogbanner p {
  font-size: 22px;
  color: #464646;
  padding: 0px 20px;
  line-height: 1.2;
  font-style: oblique;
  padding-top: 5px;
  text-align: left;
}
.BlogSlider {
  width: 75%;

  overflow: hidden;
}
.SlideItems {
  background: url("../images/bligSliderimage.jpg") center no-repeat;
  padding: 40px 30px;
  display: inline-block;
  background-size: contain;
  color: #fff;
}
.BlogTiming {
  position: absolute;
  left: 40px;
  bottom: 40px;
  color: #fff;
}

.BlogCarddesc {
  padding: 10px;
  height: 100%;
  min-height: 220px;
  border: 1px solid #cecece;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  border-radius: 0 0 20px 20px;
}

.HeighLigtBlog {
  width: calc(25% - 10px);
  margin-left: 10px;
}
.blogcardImg img {
  max-width: 100%;
  width: 100%;
  height: 256px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
  border: 1px solid #cecece;
}
.BlogListings,
.Bloglist {
  padding-top: 50px;
}
.SlideItems h4 {
  font-size: 21px;
  margin-bottom: 30px;
}
.SlideItems p {
  color: #fff;
  font-size: 16px;
}
.SlideItems .BlogTiming p {
  font-size: 14px;
}
.BlogCarddesc h5 {
  font-size: 13px;
  margin-bottom: 10px;
  color: #666;
  text-transform: uppercase;
}
.BlogCarddesc h4 {
  font-size: 24px;
  line-height: 1.3;
  margin-bottom: 5px;
  color: #424141;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.BlogCarddesc p {
  font-size: 18px;
  color: #666;
  font-weight: 400;
  text-align: left;
  padding-top: 10px;
  line-height: 1.3;
}
.BlogTime {
  font-size: 12px;
}
.BlogTime p {
  font-size: 12px;
  color: #ccc;
  margin-top: 6px;
  font-weight: 600;
}
.Bloglist .BLogCard {
  width: calc(25% - 10px);
}
.Bloglist .BLogCard {
  width: calc(33.3% - 0px);
  margin-bottom: 30px;
  padding: 0px 20px 10px 20px;
  cursor: pointer;
}
.BlogTime p.readmore_style {
  color: #1e5a9a;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
button.loadmore_btn {
  background: #e46666;
  padding: 7px 15px;
  color: #fff;
  font-size: 21px;
  border-radius: 10px;
  text-align: center;
}
.bloglisting {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.blogdetailsstyl {
  margin-top: 5.5rem;
}

.blogdesc h2 {
  font-size: 48px;
  color: #011b38;
  padding: 10px 0px;
  line-height: 1.2;
}

.blogdesc h4 {
  font-size: 32px;
  color: #011b38;
  padding: 10px 0px;
  line-height: 1.3;
}
.blogdesc h3 {
  font-size: 26px;
  color: #011b38;
  padding: 10px 0px;
  line-height: 1.3;
}

.blogdesc p {
  font-size: 18px;
  line-height: 1.4;
  padding-bottom: 20px;
}
.blogdesc p.centercstyl {
  padding: 10px 10% 20px 10%;
  text-align: center;
  line-height: 1.6;
  font-size: 21px;
  font-weight: bold;
  font-style: italic;
}
.blogdesc p.centercstylleft {
  padding: 10px 10% 20px 0%;
  line-height: 1.6;
  font-size: 21px;
  font-weight: bold;
  font-style: italic;
}
.blogdesc img {
  padding: 10px 0px;
  max-width: 100%;
}
.SELbannerstyl {
  background: url("../images/SELbanner.png") center no-repeat;
  background-size: cover;
  min-height: 390px;
  display: flex;
  align-items: center;
}
.SELbannerstyl h3 {
  font-size: 46px;
  color: #011b38;
  font-weight: 600;
  margin-bottom: 5px;
  text-shadow: 0px 3px 4px #bab7b7;
  padding-top: 20px;
  text-align: left;
}
.BlogSELbannerstylbanner h3 span {
  color: #e46666;
}
.blogdesc p.centercstyl strong {
  cursor: pointer;
  color: #e46666;
  text-decoration: underline;
}
.blogdesc p a {
  color: #e46666;
  line-height: 1.6;
  font-size: 21px;
}

.blogpagination {
  width: 100%;
  padding: 20px 0px;
}

.blogpaginationTitle img {
  min-width: 50px;
  max-height: 50px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.blogpaginationTitle span h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  color: #515151;
  padding: 10px 6px 10px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.blogpaginationTitle span.backblogclr {
  background: #2f73bc;
  border: 1px solid #2f73bc;
  padding: 5px 10px;
  color: #fff;
}
.blogpaginationTitle span.backblogclr:hover {
  background: #2b468b;
  border: 1px solid #2b468b;
  transition: all ease 0.5s;
}
.blogpagination_style {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  cursor: pointer;
}

.blogpaginationTitle {
  max-width: 45%;
  display: flex;
  align-items: baseline;
  flex-direction: column;
}

.Contact_us h3 {
  padding: 10px 0;
}

.Contact_us p,
.Contact_us p a {
  font-size: 24px;
  padding: 14px 0;
}
.Contact_us {
  padding: 70px 0;
  min-height: 51vh;
}
.Contact_us h2.text-center {
  font-size: 48px;
}
.Contact_us h3 {
  padding: 10px 0;
}

ul.contactuslist {
  padding-top: 30px;
}
.Contact_us ul.contactuslist p strong {
  color: #e46666;
}
/* .deletYrAccount .basicdetailsform .wrapper.mx-auto {
    background: #fafafa;
    margin: 20px;
    border: 1px solid #ccc;
    max-width: 70%;
    border-radius: 5px;
    padding: 20px 20px;
} */
.deletYrAccount .basicdetailsform .wrapper.mx-auto textarea {
  height: 100px;
}
.deletYrAccount .basicdetailsform .wrapper.mx-auto textarea::placeholder {
  opacity: 0.6;
}
.deletYrAccount {
  max-width: 800px;
  margin: 20px auto;
  border-radius: 4px;
  /* border: 1px solid #dee2e6; */
}
.w-98 {
  width: 98% !important;
}
.deleteAccountpage .menuList {
  display: none;
}
.selectYourAction .checkmark {
  top: 0;
}
.selectYourAction label {
  font-weight: 400 !important;
}
.deletetab {
  width: 100%;
  margin-bottom: 20px;
}

.deletetab li {
  width: 100%;
  display: inline-block;
  background: #f5faff;
  border-radius: 7px;
  padding: 10px;
  box-shadow: 0px 1px 5px #ccc;
  margin: 6px 0;
}
.deletetab.selectYourAction .checkmark {
  top: 9px;
  /* right: 0; */
  left: 0;
  width: 30px;
  height: 30px;
}
.deletetab li img {
  max-width: 30px;
}
.deletetab ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.deletetab li p {
  width: 100%;
  padding-left: 0px;
}
/* .deletetab.selectYourAction .checkmark {
    top: 0;
    right: 0;
    left: auto;
} */
.selectactionlabl {
  font-size: 18px !important;
}
.deletetab li:hover {
  background: #3c96ff;
  color: #fff;
  border-color: #3c96ff;
}

.deletetab li:hover p,
.deletetab li:hover h4 {
  color: #fff;
}

.deletetab li h4 {
  font-size: 20px;
}
.deletetab li label {
  padding-left: 40px !important;
}
.deletetab .Selcheckbox .checkmark:after {
  left: 8px;
  top: 4px;
  width: 12px;
  height: 20px;
  border-width: 0 5px 5px 0;
}
.delatedeactUserRes {
  padding: 16px 0px;
  min-height: 93.4vh;
}
.delatedeactUserRes p span {
  text-transform: lowercase;
}
.delatedeactUserRes p {
  font-size: 28px;
  padding: 14px 230px !important;
  text-align: center;
}
.deleteAccountpage.Successpage h3 span {
  text-transform: lowercase;
  color: #000;
}
.Contact_us h3 {
  padding: 10px 0;
}

.Contact_us p,
.Contact_us p a {
  font-size: 24px;
  padding: 14px 0;
}
.Contact_us {
  padding: 70px 0 0;
  min-height: 48vh;
}
.Contact_us h2.text-center {
  font-size: 48px;
}
.Contact_us h3 {
  padding: 10px 0;
}

ul.contactuslist {
  padding-top: 30px;
}
.Contact_us ul.contactuslist p strong {
  color: #e46666;
}
/* .Contact_us 
 ul.contactuslist p ,.Contact_us p a{
    padding: 5px 0;
    font-size: 16px;
} */
.delatedeactUserRes button.btn-blue.btn-login.d-block {
  margin: 20px auto !important;
}
/* redirectionpage */
.redirectioncontainer {
  background:linear-gradient(90deg, #1A237E 0%, #0d1351 100%);
  min-height: 100vh;
}
.redirectiomPage {
  padding: 50px 0 50px;
  min-height: 93.4vh;
}
.redirectiomPage h3 {
  /* max-width: 300px; */
  text-align: center;
  font-size: 42px;
  color: #ff9900;
  text-shadow: 1px 2px #363636;
}

.redirectiomPage h3 img {
  max-width: 250px;
  margin-right: 25px;
  width: 250px;
}

.redirectiomPage h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 72px;
  color: #fff;
  text-shadow: 2px 2px #464646;
}
.newbloomsterlogo {
  text-align: center;
  padding: 30px 0 0;
}

.newbloomsterlogo img {
  max-width: 550px;
  margin: auto;
}

.redirectiomPage ul {
  text-align: center;
}

.redirectiomPage li {
  display: inline-block;
  font-size: 24px;
  color: #fff;
  letter-spacing: 10px;
  padding: 15px 0 29px;
}
.redirectionbantxt p {
  font-size: 15px;
  text-align: center;
  color: #fff;
  letter-spacing: 2px;
}
.bllombtm button.btn-blue.btn-login.d-block.mb-5.w-auto,
.gotohomebtn button.btn-blue.btn-login.d-block.mb-5.mt-3.w-auto.m-auto {
  margin-bottom: 0 !important;
  padding: 12px 35px;
  font-size: 20px;
  background: #e46666;
  font-weight: 600;
  margin: 0px auto 20px !important;
  box-shadow: 1px 1px 4px #f00;
}
.gotohomebtn button.btn-blue.btn-login.d-block.mb-5.mt-3.w-auto.m-auto {
  background: #329fff;
  box-shadow: none;
}
.contentonpage {
  padding: 40px 0;
}
.contentonpage li {
  padding: 10px 10px;
  border-bottom: 1px solid #ececec;
}
.contentonpage li:last-child {
  border-bottom: 0;
}
.contentonpage li strong {
  min-width: 45px;
  display: inline-block;
}
.contentonpage li a {
  padding: 10px 0px 10px 105px;
}
.section_title {
  padding-left: 103px;
}
