.calenderViewpHaifScreenPopUpforTeacher {
  width: 100% !important;
  max-width: 75% !important;
}
.calenderViewpHaifScreenPopUp1 {
  width: 100% !important;
  max-width: 85% !important;
}

.calenderViewpHaifScreenPopUpConsumer {
  width: 100% !important;
  max-width: 85% !important;
}

#planCalendarView table {
  width: 98%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-collapse: collapse;
  table-layout: fixed;
  border: 1px solid #329fff;
  border-radius: 10px;
  border-collapse: separate;
  border-spacing: 8px;
}

#planCalendarView table th,
#planCalendarView table td {
  padding: 8px;
  text-align: left;
  vertical-align: top;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
position: relative;
}

#planCalendarView table th {
  background-color: #4cb1e5;
  color: white;
  font-size: 20px;
  text-align: center;
  border-radius: 8px 8px 0px 0px;
}

#planCalendarView table td:nth-child(1) {
  background-color: #d4e7f9;
}

#planCalendarView table td {
  width: 100px;
  height: 110px;
  border-bottom: 3px solid #4cb1e5;
  border-radius: 10px;
}

/* #planCalendarView .bg-color1 {
  background-color: #f9f9f9;
}

#planCalendarView .bg-color2 {
  background-color: #DBDBDC;
} */

#planCalendarView .holidays {
  /* background-color: #f8f8a1;
  border-bottom: 3px solid #d0d065; */
  background-color: #c7c2c2;
  border-bottom: 3px solid #8e8e8e;
}
#planCalendarView .current {
  background-color: #acd1f5;
  border-bottom: 3px solid #739cc3;
}
#planCalendarView .planDates {
  font-weight: 900;
  font-size: 14px !important;
  padding-bottom: 5px;
}

#planCalendarView .courseName {
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
  text-overflow: ellipsis;
  font-weight: 500;
  color: #55555b;
  padding-bottom: 8px;
  align-items: center;
  padding-bottom: 0px;
}

#planCalendarView .moduleName {
  /* font-size: 12px;
  font-weight: 800; */
  /* background-color: #4cb1e5; */
  /* padding: 2px;
  padding-left: 5px;
  border-radius: 5px;
  color: #fff; */
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  text-overflow: ellipsis;
  font-weight: 500;
  color: #55555b;
  padding-bottom: 8px;
  align-items: center;
  padding-bottom: 0px;
}

#planCalendarView .week {
  padding-top: 6px;
  font-size: 16px;
  font-weight: 600;
}
#planCalendarView .calenderIcon {
  color: #919198f7;
  padding-right: 7px;
  font-size: 15px;
}
#planCalendarView .upcoming{
  background: #ff9333;
  border-radius: 11px;
  color: #000;
  display: inline-block;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.3;
  margin-left: 3px;
  padding: 0 5px 1px;
}
#planCalendarView .completed{
  background: #3ec093;
  border-radius: 11px;
  color: #000;
  display: inline-block;
  font-size: 11px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.3;
  margin-left: 3px;
  padding: 0 5px 1px;
}
/* #planCalendarView .moduleMinutes {
  font-size: 10px;
  padding-left: 4px;
  font-weight: 600;
  background-color: #4cb1e5;
  padding: 3px 6px 0px 6px;
  border-radius: 50px;
  color: #fff;
  margin-left: 9px;
} */

#planCalendarView .courseCircle {
  height: 22px;
  width: 22px;
  background-color: #4cb1e5;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
  color: white;
}
#planCalendarView .moduleCircle {
  height: 22px;
  width: 22px;
  background-color: #006dcc;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
  color: white;
}
.halfPagePOpup table td.bg-color1{
  position: relative;
}
.halfPagePOpup table td .__react_component_tooltip {
  top: -24px !important;
  left: 5px !important;
  position: absolute;
  min-width: 200px;
}
.halfPagePOpup table td .Module_Name_cal .__react_component_tooltip {
  margin-top: 0 !important;
  top:10px !important;
}
#planCalendarView .statusImg {
  height: 18px;
  width: 18px;
}
.newenrollepopup #planCalendarView table th,
.newenrollepopup #planCalendarView table td {
  padding:5px;
}
.newenrollepopup #planCalendarView .courseName {
font-size: 14px;
}