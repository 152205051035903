#emailVerificationParentDiv,.userverifymopup{
    background-color: #cccccc47;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 93.4vh;
}
.email-verification {
    width: 900px;
    background-color: #fff;
    padding: 30px 20px 30px 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 10px;
}

.email-verification .logo {
    width: 22%;
}

.email-verification .main-div {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding-top: 20px;
}

.email-verification .imgDiv {
    width: 40%;
    padding-right: 20px;
}

.email-verification .imgDiv img {
    width: 100%;
    display: block;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    /* margin-top: 50px; */
}

.email-verification .contentDiv {
    width: 60%;
    text-align: center;
}

.email-verification .contentDiv h1 ,.email-verification .contentDiv h2 {
    font-size: 40px;
    font-weight: 700;
    color: #a86ada;
    margin-top: 0px;
    padding-bottom: 30px;
}

.email-verification .contentDiv h3 {
    font-size: 32px;
    padding-bottom: 30px;
}
.userverifymopup .email-verification .contentDiv h4 {
    font-size: 24px;
    padding-bottom: 30px;
    line-height: 1.5;
}

.email-verification .contentDiv p {
    font-size: 30px;
    font-size: 18px;
    line-height: 1.5;
    padding: 0px 20px 0px 20px;
    text-align: left;
}

.email-verification .contentDiv a {
    color: #329fff;
    text-decoration: none;
    padding-left: 5px;
    font-size: 17px;
}

.email-verification .contentDiv button {
    padding: 6px 20px 6px 20px;
    letter-spacing: 1px;
    background-color: #329fff;
    margin: 27px 0px 20px 0px;
    color: white;
    cursor: pointer;
    font-size: 20px;
    border-radius: 5px;
    border: none;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    font-weight: 400;
    width: auto;
}

.email-verification .contentDiv button:hover {
    background-color: #005fb2;
    color: #fff;
}
.appLInked.flex {
    max-width: 400px;
}
.appLInked.flex img{
    max-width: 120px;
}