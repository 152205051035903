body,
html {
	 font-family: 'Lato', sans-serif !important;
	/* font-family: 'Avenir_LT _Std' !important; */
	font-weight: 400;
	line-height: 1.5;
	margin: 0;
	color: #000;
	font-size: 0.875rem;
	background: #ffffff !important;
	/* scrollbar-width: thin;
	scrollbar-color: #c9cdd1 #f9f9f9; */
}
@font-face {
	font-family: 'dapulse';
	src: url('../fonts/dapulse_9ba2778de1e612fbdbcafce07154e8cd.eot');
	src: url('../fonts/dapulse_9ba2778de1e612fbdbcafce07154e8cd.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/dapulse_9ba2778de1e612fbdbcafce07154e8cd.woff')
			format('woff'),
		url('../fonts/dapulse_9ba2778de1e612fbdbcafce07154e8cd.ttf')
			format('truetype'),
		url('../fonts/dapulse_9ba2778de1e612fbdbcafce07154e8cd.svg#dapulse')
			format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Avenir_LT _Std';
    src: url('../fonts/AvenirLTStd-Light.eot');
    src: url('../fonts/AvenirLTStd-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTStd-Light.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Light.woff') format('woff'),
        url('../fonts/AvenirLTStd-Light.ttf') format('truetype'),
        url('../fonts/AvenirLTStd-Light.svg#AvenirLTStd-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir_bold';
    src: url('../fonts/AvenirLTStd-Roman.eot');
    src: url('../fonts/AvenirLTStd-Roman.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTStd-Roman.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Roman.woff') format('woff'),
        url('../fonts/AvenirLTStd-Roman.ttf') format('truetype'),
        url('../fonts/AvenirLTStd-Roman.svg#AvenirLTStd-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Avenir_LT_Regular';
    src: url('../fonts/AvenirLTStd-Book.eot');
    src: url('../fonts/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/AvenirLTStd-Book.woff2') format('woff2'),
        url('../fonts/AvenirLTStd-Book.woff') format('woff'),
        url('../fonts/AvenirLTStd-Book.ttf') format('truetype'),
        url('../fonts/AvenirLTStd-Book.svg#AvenirLTStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 12, 2022 */



@font-face {
    font-family: 'eurostilebold';
    src: url('../fonts/eurostilebold-webfont.eot');
    src: url('../fonts/eurostilebold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/eurostilebold-webfont.woff2') format('woff2'),
         url('../fonts/eurostilebold-webfont.woff') format('woff'),
         url('../fonts/eurostilebold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'eurostylenormal';
    src: url('../fonts/eurostyle_normal-webfont.eot');
    src: url('../fonts/eurostyle_normal-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/eurostyle_normal-webfont.woff2') format('woff2'),
         url('../fonts/eurostyle_normal-webfont.woff') format('woff'),
         url('../fonts/eurostyle_normal-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}
body,
html {
	font-family: 'Lato', sans-serif !important;
	/* font-family: 'Avenir_LT _Std'; */
	font-weight: 400;
	line-height: 1.5;
	margin: 0;
	color: #000;
	font-size: 0.875rem;
	background: #ffffff !important;
	/* scrollbar-width: thin;
	scrollbar-color: #c9cdd1 #f9f9f9; */
}
body,
article,
aside,
footer,
header,
hgroup,
main,
nav,
section,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div input,
button,
select,
textarea {
	/* font-family: 'calibri'; */
	font-family: 'Lato', sans-serif !important;
	/* font-family: 'Avenir_LT _Std'; */
}

body .panelHeight .Inner-panelHeight,
.panelHeight {
	border-radius: 10px;
	/* scrollbar-width: thin;
	scrollbar-color: #c9cdd1 #f9f9f9; */
}

b {
	font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
	line-height: 1.2;
}
.h3,
h3 {
	font-size: 1.75rem;
}
.h4,
h4 {
	font-size: 1.25rem;
	line-height: 26px;
}
h5,
.h5 {
	line-height: 24px;
	font-size: 1.15rem;
}
ul {
	padding-left: 0 !important;
}
ul li {
	list-style: none;
}
.h1,
.h2,
.h3,
h1,
h2,
h3 {
	font-weight: 500;
}
.font-weight-bold {
	font-weight: 600 !important;
}
.font-weight-light {
	font-weight: Normal !important;
}
p,
.para {
	font-family: 'Lato', sans-serif !important;
	font-size: 14px;
}

.input-group
	> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
	margin-left: 0;
	border-top-left-radius: initial;
	border-bottom-left-radius: initial;
}



::after,
::before {
	box-sizing: border-box;
}
a,
a:hover,
a:focus {
	color: #006dcc;
	text-decoration: none;
	outline: none;
	font-size: 14px;
}
a:hover .sec-row,
.sec-row:hover {
	background-color: #f6f6fb;
	cursor: pointer;
}
small,
.small {
	line-height: 0.8rem;
	font-size: 80%;
	font-weight: 400;
	font-size: 14px !important;
}
p {
	margin-top: 0px;
	margin-bottom: 0px;
}

.para-lg,
.font_12 {
	font-size: 12px !important;
}
.icon-small,
.icon-small::before {
	font-size: 14px !important;
}
img {
	max-width: 100%;
	height: auto;
}
/* .overflow-hidden {
	overflow: hidden;
} */
.text-right {
	text-align: right;
}
.text-center {
	text-align: center;
}
.float-right {
	float: right;
}
.text-muted {
	color: #6c757d !important;
}
.text-error {
	color: #f75717 !important;
}
.text-white,
.text-white:focus,
.text-white:hover {
	color: #fff !important;
}
.text-blue {
	color: #006dcc;
}
.bg-white {
	background-color: #fff;
}
.bg-darks {
	background-color: #1d103f !important;
}
.bg-success {
	background-color: #28a745 !important;
}
.bg-primary {
	background-color: #007bff !important;
}
.bg-lite {
	background-color: #f4f4f4 !important;
}
.bg-light-blue {
	background-color: #f0f8ff !important;
}
.btn-paid {
	min-width: 79px;
}
.btn {
	border-radius: 0.3rem;
}
.btn-neutral {
	background-color: #ffffff;
	border: 1px solid #d8dde6;
	color: #0070d2 !important;
}
.btn-neutral:hover,
.btn-neutral:focus {
	background-color: #f4f6f9;
}
.btn-blue {
	background-color: #329fff;
	color: #fff;
}
/* .btn-blue:hover,
.btn-blue:focus {
	background-color: #005fb2;
	color: #fff;
} */
.btn-success {
	background-color: rgb(39, 194, 41) !important;
	-webkit-background-color: rgb(39, 194, 41) !important;
	color: #fff !important;
	font-weight: 400;
}
.btn-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}
.redborder {
	border: 1px solid rgb(194, 57, 52);
}
.btn-lg {
	font-size: 1.25rem;
	line-height: 1.5;
}
.btn {
	border-radius: 0.3rem;
	display: inline-block;
	text-align: center;
}
.btn-xs {
	font-size: 0.675rem;
	line-height: 1.5;
	min-width: 85px;
}
.btn-danger,
.btn-danger:hover {
	background-color: #ff5161;
	border-color: #ff5161;
	color: #fff !important;
}
.btn-secondary {
	color: #fff;
	background-color: #6c757d;
	border-color: #6c757d;
}
.badge-light {
	color: #212529;
	background-color: #f8f9fa;
	padding: 0.25rem !important;
	border: 1px solid #dee2e6 !important;
	border-radius: 10rem;
	font-size: 75%;
	text-align: center;
	font-weight: 600 !important;
	line-height: 1;
	white-space: nowrap;
	vertical-align: baseline;
	display: inline-block;
}
.border {
	border: 1px solid #dee2e6 !important;
}
.float-right {
	float: right;
}
.rounded {
	border-radius: 0.25rem !important;
}
.rounded-lg {
	border-radius: 0.4rem !important;
	border: 1px solid #dcdcdc;
}
.border-bottom-dark_dashed {
	border-bottom: 1px dashed #006dcc !important;
}
.border-bottom {
	border-bottom: 1px solid #dee2e6 !important;
}
.border-top {
	border-top: 1px solid #dee2e6 !important;
}
.position-relative {
	position: relative !important;
}
.d-none {
	display: none !important;
}
.d-block {
	display: block;
}

.form-control,
.form-control:focus {
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
input,
select {
	padding: 0.375rem 0.75rem;
	min-height: 32px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: #404040 !important;
}
.box select {
	width: 100%;
	background-color: #fff;
	padding: 0.375rem 0.75rem;
	border-radius: 0.25rem;
	border: 1px solid #ced4da;
	height: calc(1.5em + 0.75rem + 2px);
	/* -moz-appearance: none;
	-webkit-appearance: none; */
}
.box {
	position: relative;
}
.box::before {
	font-family: 'Line Awesome Free';
	content: '\f107';
	font-weight: 900;
	position: absolute;
	height: 100%;
	padding: 5px 10px;
	top: 0;
	right: 0;
	font-size: 15px;
	text-align: center;
	color: #fff;
	border-radius: 0px 0.25rem 0.25rem 0px;
	background-color: rgb(50, 159, 255);
	pointer-events: none;
}
/*Spacing*/
.p-3 {
	padding: 1rem !important;
}
.p-4 {
	padding: 1.5rem !important;
}
.mr-1,
.mx-1 {
	margin-right: 0.25rem !important;
}
.mr-2,
.mx-2 {
	margin-right: 0.5rem !important;
}
.ml-2,
.mx-2 {
	margin-left: 0.5rem !important;
}
.mr-3,
.mx-3 {
	margin-right: 1rem !important;
}
.ml-3,
.mx-3 {
	margin-left: 1rem !important;
}
.mt-1,
.my-1 {
	margin-top: 0.25rem !important;
}
.mt-3,
.my-3 {
	margin-top: 1rem !important;
}
.mr-2,
.mx-2 {
	margin-right: 0.5rem !important;
}
.m-2 {
	margin: 0.5rem !important;
}
.m-3 {
	margin: 1rem !important;
}
.mb-3,
.my-3 {
	margin-bottom: 1rem !important;
}
.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important;
}
.mt-2,
.my-2 {
	margin-top: 0.5rem !important;
}
.mb-1,
.my-1 {
	margin-bottom: 0.25rem !important;
}
.pt-2,
.py-2 {
	padding-top: 0.5rem !important;
}
.pt-3,
.py-3 {
	padding-top: 1rem !important;
}
.pt-4,
.py-4 {
	padding-top: 1.5rem !important;
}
.pb-4,
.py-4 {
	padding-bottom: 1.5rem !important;
}
.pb-3,
.py-3 {
	padding-bottom: 1rem !important;
}
.p-2 {
	padding: 0.5rem !important;
}
.pr-10 {
	padding-right: 10px;
}
.pl-2,
.px-2 {
	padding-left: 0.5rem !important;
}
.pr-2,
.px-2 {
	padding-right: 0.5rem !important;
}
.pr-3,
.px-3 {
	padding-right: 1rem !important;
}
.pl-3,
.px-3 {
	padding-left: 1rem !important;
}
.pb-2,
.py-2 {
	padding-bottom: 0.5rem !important;
}
.pb-1,
.py-1 {
	padding-bottom: 0.25rem !important;
}
.mt-49 {
	margin-top: 49px;
}
.mt-5 {
	margin-top: 2rem;
}
.pt-5 {
	padding-top: 2rem;
}
.p-10 {
	padding: 10px 10px 0px;
}
.mt-10 {
	margin-top: 10px;
}
.ml-1,
.mx-1 {
	margin-left: 0.25rem !important;
}
.ml-auto,
.mx-auto {
	margin-left: auto !important;
}
.mr-auto,
.mx-auto {
	margin-right: auto !important;
	overflow-y: hidden !important;
}
.mt-auto {
	margin-top: auto;
}
.m-minu-15 {
	margin-bottom: -15px;
}

.checkboccuuently {
	width: 20px;
	height: 20px;
	margin-top: 3px;
}
span.check,
.exclamatry {
	width: 14px;
	height: 14px;
	background: #198754;
	border: 1px solid #198754;
	display: inline-block;
	margin-right: 10px;
	vertical-align: middle;
	border-radius: 50%;
	position: relative;
	color: #fff;
}
span.check::before {
	content: '';
	position: absolute;
	width: 8px;
	height: 5px;
	border: 2px solid #fff;
	left: 2px;
	top: 2.5px;
	border-top: 0;
	border-right: 0;
	transform: rotate(-45deg);
}
span.check .fa-check {
	font-size: 10px;
	padding: 1px;
	margin-top: 0px;
	display: block;
}

.txtred {
	color: #dd3333;
}
/*CSS FLEX*/
.d-flex {
	display: -ms-flexbox !important;
	display: flex !important;
}
.justify-content-between {
	-ms-flex-pack: justify !important;
	justify-content: space-between !important;
}
.justify-content-end {
	justify-content: end;
}
.flex-fill {
	-ms-flex: 1 1 auto !important;
	flex: 1 1 auto !important;
}
.flex-column {
	flex-direction: column;
}
.align-self-center {
	align-self: center;
	margin: 6px 0px;
}

/*CSS GRID*/
/*body div {box-sizing: content-box;}
.item2 {grid-row-end: span 2;}
.item3 {grid-row-end: span 3;}*/
/* .container {
	max-width: 80%;
	margin: 0 auto;
} */
.grid-container {
	display: grid;
	grid-template-columns: 69.3% 30%;
	grid-gap: 10px;
	padding: 10px;
	max-width: 100%;
}

.height-serviceIndex,
.index-smallboxes {
	height: 232px;
	overflow-y: auto;
}
.highlightedPanel {
	padding: 10px 10px 0;
}
.grade-primary {
	padding: 0px 8px;
	border: 1px solid #385a95 !important;
	width: 25px;
	height: 24px;
	display: inline-block;
}
.alert-success {
	color: #155724;
	background-color: #d4edda;
	border-color: #c3e6cb;
}
h4
.h4 {
	font-size: 16px;
	font-weight: 600;
	color: #313131;
}
h3{
	font-size: 17px;
	font-weight: 600;
	color: #313131;
}
p{
	font-size: 14px;
    color: #232323;
}
label{
	color: #8b8b8b;
    font-size: 14px;
}
/* span{
	font-size: 14px;
    color: #3b3b3b;
} */