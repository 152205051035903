.kc-report-modal{
    width: 600px;
    max-width: 600px;
}
.kcReportParentDiv {
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.kc-report{
    background-color: white;
    padding: 20px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    border-radius: 8px;
    text-align: center;
    width: 500px;
    padding-top: 0px;
}

.kc-report h2 {
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.kc-report .chart {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 183px;
}

.kc-report .attempt {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-items: baseline;
    position: relative;
    right: 17px;
}
.kc-report .attemptCheckboxTwoProgess {
    right: 34px !important;
}

.kc-report .attemptCheckboxTwoProgess {
    right: 34px !important;
}

.kc-report .bar {
    width: 30px;
    margin: 5px 5px 0;
}

.kc-report .option1 {
    background-color: #67bf6d;
}

.kc-report .option2 {
    background-color: #FDC72E;
}

.kc-report .option3 {
    background-color: #ED524E;
}

.kc-report .red-color{
    background-color: #ED524E !important;
}

.kc-report .label {
    margin-top: 10px;
    font-size: 14px;
}

.kc-report .legend {
    margin-top: 30px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    gap: 20px;
}

.kc-report .legend b{
    font-size:25px;
    color: #ED524E;
}

.kc-report .legend-color {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
}
.kc-report .line-div{
    position: relative;
    left: 26px;
}
.kc-report .line1{
    position: relative;
    top: 18px;
    width: 100%;
    opacity: .4;
    left: 20px;
}
.kc-report .line2{
    position: relative;
    top: 81px;
    width: 100%;
    opacity: .4;
    left: 20px;
}
.kc-report .line3{
    position: relative;
    top: 142px;
    width: 100%;
    opacity: .4;
    left: 20px;
}
.kc-report .line4{
    position: relative;
    top: 203px;
    width: 100%;
    opacity: .4;
    left: 20px;
}
.kc-report .number-count{
    line-height: 83px;
    position: relative;
    top: 40px;
    right: 10px;
}
.kc-report .attempt-div{
    display: flex;justify-content: space-around;padding-left: 16px;
}

.kcReportParentDiv .tooltip-main-div{
    position: absolute;
    background: white;
    width: auto;
    margin: 0;
    padding: 0px;
    margin: auto;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    padding: 0px 0px 0x 0px;
    border-radius: 3px;
    line-height: 2;
    margin-top: 20px;
    display: none;
    text-align: justify;
    left: 27px;
}
.kcReportParentDiv .tooltip-child-div{
    min-height: auto;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 130px;
    padding: 10px !important;
}

.kcReportParentDiv .tooltip-contents{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
}

.kcReportParentDiv .tooltip-main-div:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -4%;
    top: 10%;
    box-sizing: border-box;
    border: 0.4em solid black;
    border-color: transparent transparent #fff #fff;
    transform-origin: 50% 50%;
    transform: rotate(46deg);
    box-shadow: -5px 5px 10px 0 rgba(0, 0, 0, 0.1);
}

.tooltip-main-div-left{
    left: -23px !important;
    z-index: 999;
}

.kcReportParentDiv .tooltip-main-div-left:after {
    content: "";
    left: 95% !important;
    top: 14% !important;
}

.option1:hover .tooltip-main-div, 
.option2:hover .tooltip-main-div, 
.option3:hover .tooltip-main-div{
    display: block;
}
.option2:hover .tooltip-main-div {
    left: 65px !important;
    z-index: 999;
}
.option3:hover .tooltip-main-div {
    left: 103px !important;
    z-index: 999;
}
.kcReportParentDiv ::-webkit-scrollbar {
    width: 5px;
}
.kcReportParentDiv ::-webkit-scrollbar-track {
    background: #f1f1f1; 
}


/* Target the scrollbar thumb */
.kcReportParentDiv ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 5px;
}

/* Change the scrollbar thumb when hovered */
.kcReportParentDiv ::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
