#whyPlanPopup .modal-body {
    padding: 10px 10px 10px 10px !important;
}

#whyPlanPopup .flex-child {
    flex: 1;
}

#whyPlanPopup .infopopup_txt p {
    font-size: 20px !important;
}

#whyPlanPopup .Seocialaaproch.MindfulnessApproch {
    background: #a769d9;
    position: absolute;
    top: 116px;
    left: 232px;
}

#whyPlanPopup img.mindfulnessarrow {
    position: relative;
    top: 101px;
    left: 192px;
    transform: rotate(-130deg);
    max-width: 20px;
}

#whyPlanPopup .Seocialaaproch.intellectualApproch {
    background: #83b612;
    position: absolute;
    top: -13px;
    left: 272px;
}

#whyPlanPopup img.intellectualarrow {
    position: relative;
    top: 34px;
    left: 168px;
    transform: rotate(150deg);
    max-width: 20px;
}

#whyPlanPopup .Seocialaaproch.EmotioanlApproch {
    background: #e46666;
    position: absolute;
    top: -14px;
    left: 36px;
}

#whyPlanPopup .Straightarrow {
    max-width: 60px;
    height: 50px;
}

#whyPlanPopup .downArrow img {
    max-width: 35px;
    transform: rotate(0deg);
    position: absolute;
    left: 71%;
    top: 44%;
}

#whyPlanPopup .holisticnewappoch {
    padding: 25px 0px 0px 10px;
}

#whyPlanPopup .holisticflowr {
    text-align: center;
    padding: 0px 0px 10px;
    display: flex;
    position: relative;
    bottom: 18px;
    left: -10px;
}

#whyPlanPopup .skilanddimensioncircle.Holisticgtflr {
    border: 0;
    background: none;
    width: 165px;
    height: 150px;
}

#whyPlanPopup .Holosticcircle {
    width: 130px;
    height: 130px;
    top: 59%;
}

#whyPlanPopup .dimensionapproch {
    position: relative;
    top: -20px;
}

#whyPlanPopup .centeralapproch {
    position: relative;
    top: 118px;
}

#whyPlanPopup .newEnrollCoursPopup .modal-content {
    max-height: 97vh;
    overflow-y: auto;
}

#whyPlanPopup .Seocialaaproch {
    width: 80px;
    height: 80px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
#whyPlanPopup .centeralapproch {
    width: 100px;
    height: 100px;
    background: #4169e1;
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    left: 136px;
    top: 93px;
}
#whyPlanPopup .Seocialaaproch.PhysicalApproch {
    background: #1ec1d9;
    position: absolute;
    left: 71px;
    top: 116px;
}
#whyPlanPopup img.socialarrow {
    position: relative;
    top: -14px;
    left: 164px;
    transform: rotate(90deg);
    max-width: 20px;
}
#whyPlanPopup img.physicalarrow {
    position: relative;
    top: 107px;
    left: 83px;
    transform: rotate(-45deg);
    max-width: 20px;
}
#whyPlanPopup img.emotionalarrow {
    position: relative;
    left: 122px;
    max-width: 20px;
    top: 33px;
    transform: rotate(30deg);
}
#whyPlanPopup .Seocialaaproch p {
    font-size: 13px;
    color: #fff;
    font-weight: 600;
}