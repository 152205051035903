/**
 * /* / New Front Page /
 *
 * @format
 */

body,
html {
  font-family: "Lato", sans-serif !important;
  /* font-family: 'Avenir_LT _Std'; */
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
  color: #000;
  font-size: 0.875rem;
  background: #ffffff !important;
  /* scrollbar-width: thin;
  scrollbar-color: #c9cdd1 #f9f9f9; */
}

.blockcontent {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  padding-right: 0px !important;
  overflow: auto !important;
}

article,
aside,
footer,
header,
hgroup,
main,
nav,
section,
h1,
h2,
h3,
h4,
h5,
h6,
p,
div input,
button,
select,
textarea {
  /* font-family: 'calibri'; */
  font-family: "Lato", sans-serif !important;
  /* font-family: 'Avenir_LT _Std'; */
}

body .panelHeight .Inner-panelHeight,
.panelHeight {
  border-radius: 10px;
  /* scrollbar-width: thin;
  scrollbar-color: #c9cdd1 #f9f9f9; */
}

b {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  line-height: 1.2;
}

.h4,
h4 {
  font-size: 1.25rem;
  line-height: 26px;
}

h5,
.h5 {
  line-height: 24px;
  font-size: 1.15rem;
}

ul {
  padding-left: 0 !important;
}

ul li {
  list-style: none;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-light {
  font-weight: Normal !important;
}

p,
.para {
  font-family: "Lato", sans-serif !important;
}

.input-group
  > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(
    .valid-feedback
  ):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: 0;
  border-top-left-radius: initial;
  border-bottom-left-radius: initial;
}

::placeholder {
  color: #404040 !important;
}

:-ms-input-placeholder {
  color: #404040 !important;
}

::-ms-input-placeholder {
  color: #404040 !important;
}

@media (max-width: 568px) {
  h4,
  .h4 {
    font-size: 1rem;
  }
}

*,
::after,
::before {
  box-sizing: border-box;
}

a,
a:hover,
a:focus {
  color: #006dcc;
  text-decoration: none;
  outline: none;
}

a:hover .sec-row,
.sec-row:hover {
  background-color: #f6f6fb;
  cursor: pointer;
}

small,
.small {
  line-height: 0.8rem;
  font-size: 80%;
  font-weight: 400;
  font-size: 14px !important;
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

.pointer {
  cursor: pointer !important;
}

.para-lg,
.font_12 {
  font-size: 12px !important;
}

.icon-small,
.icon-small::before {
  font-size: 14px !important;
}

img {
  max-width: 100%;
  height: auto;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}
.font-italic {
  font-style: italic;
}
.float-right {
  float: right;
}

.text-muted {
  color: #6c757d !important;
}

.text-left {
  text-align: left;
}

.text-error {
  color: #f75717 !important;
}

.text-white,
.text-white:focus,
.text-white:hover {
  color: #fff !important;
}

.text-blue {
  color: #006dcc;
}

.bg-white {
  background-color: #fff;
}

.bg-darks {
  background-color: #1d103f !important;
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-primary {
  background-color: #007bff !important;
}

.bg-lite {
  background-color: #f4f4f4 !important;
}

.bg-light-blue {
  background-color: #f0f8ff !important;
}

.btn-paid {
  min-width: 79px;
}

.btn {
  border-radius: 0.3rem;
}

.br-none {
  border-radius: 0 !important;
}

.btn-neutral {
  background-color: #ffffff;
  border: 1px solid #d8dde6;
  color: #0070d2 !important;
}

.btn-neutral:hover,
.btn-neutral:focus {
  background-color: #f4f6f9;
}

.btn-blue {
  background-color: #329fff;
  color: #fff;
}

.btn-blue:hover,
.PublishBtn button:hover .btn-blue:focus {
  background-color: #005fb2;
  color: #fff;
}

.btn-success {
  background-color: rgb(39, 194, 41) !important;
  -webkit-background-color: rgb(39, 194, 41) !important;
  color: #fff !important;
  font-weight: 400;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.redborder {
  border: 1px solid rgb(194, 57, 52);
}

.btn-lg {
  font-size: 1.25rem;
  line-height: 1.5;
}

.btn {
  border-radius: 0.3rem;
  display: inline-block;
  text-align: center;
}

.btn-xs {
  font-size: 0.675rem;
  line-height: 1.5;
  min-width: 85px;
}

.btn-danger,
.btn-danger:hover {
  background-color: #ff5161;
  border-color: #ff5161;
  color: #fff !important;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
  padding: 0.25rem !important;
  border: 1px solid #dee2e6 !important;
  border-radius: 10rem;
  font-size: 75%;
  text-align: center;
  font-weight: 600 !important;
  line-height: 1;
  white-space: nowrap;
  vertical-align: baseline;
  display: inline-block;
}

.border {
  border: 1px solid #dee2e6 !important;
}

p + p {
  margin-top: 0;
}

.float-right {
  float: right;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.4rem !important;
  border: 1px solid #dcdcdc;
}

.border-bottom-dark_dashed {
  border-bottom: 1px dashed #006dcc !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.position-relative {
  position: relative !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block;
}

.input-group .is-invalid {
  border: 1px solid;
  border-color: #dc3545 !important;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }
}

.form-control,
.form-control:focus {
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input,
select {
  padding: 0.375rem 0.75rem;
  min-height: 32px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #404040 !important;
}

.box select {
  width: 100%;
  background-color: #fff;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  height: calc(1.5em + 0.75rem + 2px);
}

.box {
  position: relative;
}

.box::before {
  font-family: "Line Awesome Free";
  content: "\f107";
  font-weight: 900;
  position: absolute;
  height: 100%;
  padding: 5px 10px;
  top: 0;
  right: 0;
  font-size: 15px;
  text-align: center;
  color: #fff;
  border-radius: 0px 0.25rem 0.25rem 0px;
  background-color: rgb(50, 159, 255);
  pointer-events: none;
}

/*Spacing*/
.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pr-10 {
  padding-right: 10px;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.mt-49 {
  margin-top: 49px;
}

.mt-5 {
  margin-top: 2rem;
}

.pt-5 {
  padding-top: 2rem;
}

.p-10 {
  padding: 10px 10px 0px;
}

.mt-10 {
  margin-top: 10px;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
  overflow-y: hidden !important;
}

.mt-auto {
  margin-top: auto;
}

.m-minu-15 {
  margin-bottom: -15px;
}

.checkboccuuently {
  width: 20px;
  height: 20px;
  margin-top: 3px;
}

span.check,
.exclamatry {
  width: 14px;
  height: 14px;
  background: #198754;
  border: 1px solid #198754;
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  color: #fff;
  margin-top: 3px;
}

span.check::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 5px;
  border: 2px solid #fff;
  left: 2px;
  top: 2.5px;
  border-top: 0;
  border-right: 0;
  transform: rotate(-45deg);
}

span.check .fa-check {
  font-size: 10px;
  padding: 1px;
  margin-top: 0px;
  display: block;
}

.txtred {
  color: #dd3333;
}

/*CSS FLEX*/
.d-flex {
  display: -ms-flexbox !important;
  display: flex !important;
}

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
}

.justify-content-end {
  justify-content: end;
}

.flex-fill {
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.flex-column {
  flex-direction: column;
}

.align-self-center {
  align-self: center;
  margin: 6px 0px;
}

/* .container {
  max-width: 80%;
  margin: 0 auto;
} */
.grid-container {
  display: grid;
  grid-template-columns: 69.3% 30%;
  grid-gap: 10px;
  padding: 10px;
  max-width: 100%;
}

.height-serviceIndex,
.index-smallboxes {
  height: 232px;
  overflow-y: auto;
}

.highlightedPanel {
  padding: 10px 10px 0;
}

.grade-primary {
  padding: 0px 8px;
  border: 1px solid #385a95 !important;
  width: 25px;
  height: 24px;
  display: inline-block;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.profile-banner {
  background: #307ad5;
  color: #fff;
}

input.b_radius {
  border-radius: 10px;
}

.form-control,
.react-datepicker__input-container input {
  position: relative;
  padding: 0.375rem 0.75rem;
  flex: 1 1;
  min-width: 0;
  margin-bottom: 0;
  height: 38px;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0px;
}

/*Dropdown*/
.border-dashed {
  border: 1px dashed #6e6d6d;
  margin-top: -15px !important;
}

h2 span.bg-white {
  font-size: 27px;
  padding: 0px 15px;
}

.dropdown {
  position: relative;
}

.dropdown-menu {
  min-width: 410px;
  position: absolute;
  background-color: #fff;
  right: -5px;
  z-index: 1000;
  padding: 0 0 0rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  margin-top: 6px;
}

.dropdown-round-icon {
  border-radius: 50%;
  padding: 0.6rem;
}

.dropdown-menu::after {
  content: "";
  position: absolute;
  top: -21px;
  right: 3px;
  border: 13px solid transparent;
  border-bottom-color: transparent;
  border-bottom-color: #292f4c !important;
}

/*Topbar*/
@media (min-width: 991px) {
  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }

  .fixed-top.highlightedPanel {
    padding: 0px;
    left: 50px;
  }

  .fixed-top .rounded-lg {
    border-radius: 0 !important;
  }
}

.nav-top {
  background-color: #fff;
  padding: 0px 10px;
}

.nav-item {
  margin-right: 2rem;
  display: inline-block;
}

.nav-link {
  display: inline-block;
}

.navTop_right {
  padding: 3px 0px;
}

.navTop_right .icon {
  position: relative;
  display: inline-block;
  width: 18px;
  height: 15px;
  color: #404040;
}

.navTop_right .icon::before {
  font-size: 20px;
  line-height: 0px;
  position: absolute;
  top: 11px;
  left: 0px;
}

.navTop_right .icon-v2-surface-broadcast::before {
  font-size: 22px;
  top: 14px;
}

.pulse {
  display: block;
  position: absolute;
  top: 2px;
  right: -2px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #22c03c;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
}

.search-bar .form-control {
  display: inline-block;
  width: 350px;
  height: 34px;
  border-radius: 5px;
  background: #fff;
  color: #303030;
  border: 1px solid #bfbfbf;
}

.search-bar .btn {
  position: absolute;
  top: -1px;
  right: 0;
  background-color: transparent;
  height: 40px;
  color: #303030;
  transition: none;
  font-size: 16px;
  padding-right: 13px;
  border: 0px;
}

.rounded-circle {
  border-radius: 50% !important;
}

.navTop_right.nav-list .nav-item:last-child {
  margin-right: 0px;
}

/*Sidebar*/
.sidebar {
  position: fixed;
  left: 0px !important;
  top: 0;
  bottom: 0;
  max-height: 100%;
  z-index: 9999;
  background: #1d103f;
  width: 200px;
  box-shadow: 0px 8px 14.72px 1.28px rgba(229, 228, 230, 0.5);
  border-bottom: 1px solid #eae8f1;
  border-right: 1px solid #eae8f1;
  white-space: nowrap;
  visibility: visible;
  display: flex;
  flex-direction: column;
  -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.sidebar_top {
  display: block;
  padding: 0px 20px;
}

.list-subItem {
  display: block;
  color: #a8b1c7;
  font-size: 10px;
  margin: 0px 15px 0px 25px;
  text-transform: uppercase;
  padding: 6px 0px;
  padding-left: 0px;
  border-bottom: 1px dashed #352759;
}

.navbar-brand {
  width: 85px;
  margin: 0px;
}

#btn_toggler {
  line-height: 12px;
}

.sidebar_list .icon-v2-graduation-hat {
  font-size: 1.9rem !important;
  height: 25px;
}

.sidebar_list {
  padding: 0px 0px 10px;
  border-top: 1px solid #383f5d;
  overflow: auto;
  position: relative;
  width: auto;
  height: 90%;
}

.sidebar_list i {
  color: #fff !important;
  margin: 0 8px 0 4px;
}

.sidebar_list a.list-item {
  color: #fff;
  text-transform: uppercase;
  display: flex;
  padding: 13px 20px;
  border-right: 3px solid #1d103f;
}

.sidebar_list a.list-item.active {
  border-color: #3ba9df !important;
}

.sidebar_list a.list-item span {
  align-self: center;
}

.smallSidebar .sidebar a.list-item {
  padding: 13px 10px;
}

.smallSidebar #btn_toggler {
  padding: 5px 0px;
}

.smallSidebar .sidebar .list-item span,
.smallSidebar .list-subItem span {
  display: none;
}

.smallSidebar .sidebar_top {
  padding: 11px 15px 11px 16px;
}

.smallSidebar .list-subItem {
  display: none;
}

.smallSidebar .logo_collapsemenu {
  display: inline-block !important;
}

@media only screen and (min-width: 992px) {
  .smallSidebar .sidebar {
    width: 50px;
  }

  .smallSidebar .section_right {
    padding-left: 50px;
  }

  .section_right {
    padding-left: 200px;
    transition: padding 0.3s cubic-bezier(0, 0, 0.2, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .smallSidebar .sidebar_top img {
    display: none;
  }

  .nav-top.fixed-top {
    left: 50px;
    min-height: 49px;
  }
}

@media only screen and (max-width: 992px) {
  .smallSidebar .sidebar {
    left: -200px !important;
  }

  .vh-100 {
    height: auto !important;
  }

  .nav-top #btn_toggler {
    top: 17px;
    position: absolute;
  }

  .nav-top .logo_collapsemenu {
    margin-left: 25px !important;
  }
}

.header {
  height: 60px;
  padding: 12px 0;
  width: 100%;
  top: 0;
}

.LargeVh {
  height: 100vh;
}
.w-34 {
  margin-right: 60px;
}
.login-left {
  height: 97%;
  border-radius: 5px;
}

.marg_tr {
  padding: 4rem 4rem 5rem 13rem;
}

.login-right {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 5px 0px 5px 20px;
  overflow-wrap: normal;
  margin-right: 0rem;
  margin-top: 0px;
  border: 1px solid #858585;
  min-height: 370px;
}

p.learn_more {
  font-size: 14px;
  text-align: center;
  padding-bottom: 10px;
}

p.learn_more a {
  text-decoration: underline;
}

h4.title_style {
  font-weight: 400;
  font-size: 21px;
  text-align: center;
  margin: 0px;
}

.mt_10 {
  margin-top: 10px;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: -55%;
  z-index: 1030;
}

.wrapper {
  max-width: 100%;
  height: 100%;
}

.border-danger {
  border: 1px solid #dc3545 !important;
}

span.pipe {
  font-size: 23px;
  color: #d3d3d3;
}

.UploadProimagepopup .modal-body {
  padding: 0rem 0rem 0rem 0rem;
  background: #1d2226;
}

.btn-login {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding: 9px 10px 9px 10px;
  border: 0;
  border-radius: 6px !important;
  background-color: #329fff;
  text-align: center;
  width: 100%;
  line-height: 1
}

.cancelbutton svg.svg-inline--fa.fa-xmark {
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 0 2px;
  font-size: 14px;
  vertical-align: text-bottom;
}

button.btn-blue.btn-login.d-block.mb-5.cancelbutton,
.sharecertificate button.btn-blue.btn-login.d-block.cancelbutton {
  background: #6c757d;
}

.input-group {
  display: inline-block;
}

.basicdetailsform .input-group {
  width: 48%;
}

.fullinput_width {
  width: calc(100% - 200px);
}

.basicdetailsform form.bg-white.content .input-group.full-Width-group {
  width: 100%;
  justify-content: space-between;
}

.form-group.BDsubmitbutton {
  width: 100%;
}

.input-group.employmentType {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-group.employmentType .form-group.mt-4 {
  width: 100%;
}

.basicdetailsform .input-group.full-Width-group {
  width: 100%;
  margin-bottom: 10px;
}

.basicdetailsform .HalfWidth-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Activityined > div textarea {
  height: 100px;
}

.basicdetailsform .HalfWidth-group .form-group {
  width: 48%;
}

.basicdetailsform .HalfWidth-group .form-group.mt-4 {
  margin-top: 0 !important;
}

.w-48 {
  width: 48% !important;
}

form.bg-white.content .input-group.full-Width-group label {
  margin-bottom: 0.5rem;
}

.input-group .userInput {
  margin-bottom: 20px !important;
  padding: 6px 10px;
  height: 40px;
  border-radius: 10px !important;
  font-size: 16px;
}

p strong.font_styl {
  font-size: 14px;
}

.btn-chat {
  width: 36px;
  height: 36px;
  line-height: 2 !important;
}

/*Datapanel*/
.section-title {
  font-weight: bold;
  line-height: 17px;
  font-size: 1.15rem;
  border-bottom: 1px solid #dee2e6;
}

.section-title > .bg-light {
  padding: 6px 8px;
  border-radius: 0.4rem 0.4rem 0 0;
  background-color: #f8f9fa !important;
}

.sec-row {
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-bottom: 1px solid #dee2e6;
}

#accordion .card-link,
.accordion .card-link {
  display: block;
  position: relative;
  padding-right: 30px;
}

#accordion .card-link::after,
.accordion .card-link::after {
  font-family: "Line Awesome Free";
  font-weight: 900;
  position: absolute;
  right: -1px;
  top: 1.5px;
  font-size: 16px;
  content: "\f056";
  color: #2e9ee1;
}

.accordion-assessment#accordion .card-link::after {
  top: -2px;
}

#accordion .card-link.collapsed::after,
.accordion .card-link.collapsed::after {
  content: "\f055";
}

.rounded-circle {
  border-radius: 50% !important;
}

.box-chat {
  border-radius: 5px 5px 5px 0px;
  border: 1px solid #ccc;
  padding: 10px;
  background-color: #fbfbfb;
  margin-bottom: 20px;
}

.reply {
  height: 41px !important;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
  padding: 0px 0px;
}

.w-75 {
  width: 75%;
  padding: 40px 50px 40px 0px;
}

.Assemptionrightbar.w-50 {
  padding-left: 30px;
}

.box-chat-right {
  border-radius: 5px 5px 0px 5px;
  background-color: #fffcf2;
}

.border-left-dashed {
  border-left: 1px dashed rgba(0, 0, 0, 0.3);
}

.img-enroll {
  display: inline-block;
  height: 29px;
  width: 29px;
  font-size: 13px;
  padding: 5px;
}

.img-submiter-warning {
  background-color: #ffe699;
  border: 1px solid #cfae47;
}

.img-submiter {
  width: 42px;
  height: 42px;
  font-size: 20px;
  line-height: 30px;
  padding: 5px;
}

.img-submiter-primary {
  background-color: #b4c7e7;
  border: 1px solid #375994;
}

.rounded-circle {
  border-radius: 50% !important;
  width: 100%;
  height: 100%;
}

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff;
}

.error {
  color: rgb(194, 57, 52);
  padding-top: 5px;
  display: flex;
  width: 100%;
}

.errorfield {
  border-color: rgb(194, 57, 52) !important;
  box-shadow: 0 0 1px rgb(194, 57, 52) !important;
}

@media (min-width: 992px) {
  .highlightedPanel + .grid-container .panelHeight {
    min-height: calc(100vh - 150px);
  }

  .highlightedPanel + .grid-container .panelHeight .Inner-panelHeight {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
  }
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-bottom {
  box-shadow: 0 8px 6px -6px rgba(0, 0, 0, 0.15) !important;
}

.section_right > .grid-container .panelHeight {
  height: 254px;
  overflow: auto;
}

.modal {
  position: fixed;
  z-index: 1;
  padding-top: 10px;
  width: 95%;
  height: 100%;
  overflow: auto;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

/* / Modal Content / */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  max-width: 60%;
}

.close {
  position: absolute;
  right: 10px;
  font-size: 20px;
  top: -2px;
  cursor: pointer;
}

.box {
  position: relative;
}

.form-control-payment[readonly] {
  background-color: #fff;
  opacity: 1;
  padding-left: 0px;
  border-color: transparent;
  border-bottom-color: transparent;
  border-bottom-color: #ced4da !important;
  border-radius: 0px;
  box-shadow: 0 0 0 0.2rem #fff;
}

.form-control-payment:focus {
  outline: none;
  border-bottom: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.form-control-payment {
  position: relative;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  min-width: 0;
  margin-bottom: 0;
  height: 2.5rem;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.gird50 {
  grid-template-columns: 1fr 1fr;
  padding: 0px;
}

.dropdown-menu-small {
  min-width: 150px;
}

.section-subtitle {
  font-size: 1.035rem;
}

.min-80 {
  min-width: 80px;
}

.LeftbarPannel::-webkit-scrollbar,
.RightbarPannel::-webkit-scrollbar,
.courseInformation .heading h2 ul::-webkit-scrollbar,
.SelectCourseLIst::-webkit-scrollbar,
#scrollableDiv::-webkit-scrollbar,
.prolistedvidoitem::-webkit-scrollbar,
.educationList::-webkit-scrollbar,
.AllSubgradetable::-webkit-scrollbar,
.modal-dialog.modal-lg::-webkit-scrollbar,
ul.dropdown-menu.dropdown-menu-end::-webkit-scrollbar,
.EvaluationScaletab::-webkit-scrollbar,
.signinandSignup form[name="usersignup"]::-webkit-scrollbar,
.HalfScActF::-webkit-scrollbar,
.menuList .dropdown-menu::-webkit-scrollbar,
.OtherChPPLstitm::-webkit-scrollbar,
.halfPagePOpup .modal::-webkit-scrollbar,
.halfPagePOpup.SchoolActivityPopup.modal::-webkit-scrollbar,
.RightbarPannel .ScenecerelateddQuiz::-webkit-scrollbar,
.DimensionDashboadcard::-webkit-scrollbar,
.NuggetPopupDesc::-webkit-scrollbar,
.panneloverflow::-webkit-scrollbar,
.RightbarPannel .intructionsBox.LessionDtlOverview::-webkit-scrollbar,
.signinandSignup form[name="selfsignup"]::-webkit-scrollbar,
.SpecialLeftpanel
  .RightbarPannel.p-0.rightpannelSticky
  .personalityListing::-webkit-scrollbar,
.signupType.rangetypeQuestion.textAreatypeQuestion::-webkit-scrollbar,
.LessionDtlOverview::-webkit-scrollbar,
.lSidebarcontentwrap::-webkit-scrollbar,
.SignupPopup::-webkit-scrollbar,
.OtherCProfile::-webkit-scrollbar,
.coursebody::-webkit-scrollbar,
.Rpprogresbar::-webkit-scrollbar,
.popularcourse::-webkit-scrollbar,
.progressHomes::-webkit-scrollbar,
.pagescrollauto::-webkit-scrollbar,
.parentQuizsanalysis .welcomscreenContent .keyconceptListing::-webkit-scrollbar,
.rghtpnl_Skill::-webkit-scrollbar,
.newEnrollCoursPopup .modal-body::-webkit-scrollbar,
.popularcourse ul::-webkit-scrollbar,
.parents_Support .welcomscreenContent ul::-webkit-scrollbar,
.newEnrollCoursPopup .modal-content::-webkit-scrollbar,
.PProfilePoup::-webkit-scrollbar,
div#ProfileMenuPopup::-webkit-scrollbar,
.nav-item.dropdown.clModal::-webkit-scrollbar,
.learner_Planrighpnl::-webkit-scrollbar,
.selectecPaceWeek::-webkit-scrollbar,
.halfPagePOpup .parents_Support .selectecPaceWeek::-webkit-scrollbar,
#AreasFocus::-webkit-scrollbar,
.Plandetal_collapse::-webkit-scrollbar,
.planheighCollaspe::-webkit-scrollbar,
.indivisualprogressivecourse::-webkit-scrollbar,
.CourseCardWrapper::-webkit-scrollbar,
.skillsReport::-webkit-scrollbar,
.student_leadr_list ul::-webkit-scrollbar,
.my_lockerlist::-webkit-scrollbar,
.personalityrightpannel .personalityListing::-webkit-scrollbar,
.halfPagePOpup .parents_Support .selectecPaceWeek::-webkit-scrollbar,
.insceptionFilter .dropdown-menu::-webkit-scrollbar {
  width:4px;
  scrollbar-width: thin;
  scrollbar-color: #c9cdd1; 
}
/* .LeftbarPannel,
.RightbarPannel,
.courseInformation .heading h2 ul,
.SelectCourseLIst,
#scrollableDiv,
.prolistedvidoitem,
.educationList,
.AllSubgradetable,
.modal-dialog.modal-lg,
ul.dropdown-menu.dropdown-menu-end,
.EvaluationScaletab::-webkit-scrollbar,
.signinandSignup form[name="usersignup"] .HalfScActF,
.menuList .dropdown-menu,
.OtherChPPLstitm,
.halfPagePOpup .modal,
.halfPagePOpup.SchoolActivityPopup.modal,
.RightbarPannel .ScenecerelateddQuiz,
.DimensionDashboadcard,
.NuggetPopupDesc,
.panneloverflow,
.RightbarPannel .intructionsBox.LessionDtlOverview,
.signinandSignup form[name="selfsignup"],
.SpecialLeftpanel
  .RightbarPannel.p-0.rightpannelSticky
  .personalityListing::-webkit-scrollbar,
.signupType.rangetypeQuestion.textAreatypeQuestion,
.LessionDtlOverview,
.lSidebarcontentwrap,
.SignupPopup,
.OtherCProfile,
.parents_Support .selectecPaceWeek,
.insceptionFilter .dropdown-menu {
  /* scrollbar-width: thin !important ; */
  /* scrollbar-color: #c9cdd1 #f9f9f9 !important;  
} */

.SignupPopup::-webkit-scrollbar, .studentClsDetails .progressstyl::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: #f1f1f1;
}

.psListing::-webkit-scrollbar {
  width: 6px;
}

.psListing::-webkit-scrollbar-thumb {
  background-color: #6986cd;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  border-radius: 5px;
}

.LeftbarPannel::-webkit-scrollbar-thumb,
.educationList::-webkit-scrollbar-thumb,
.SelectCourseLIst::-webkit-scrollbar-thumb,
.courseInformation .heading h2 ul::-webkit-scrollbar-thumb,
.AllSubgradetable::-webkit-scrollbar-thumb,
.RightbarPannel::-webkit-scrollbar-thumb,
#scrollableDiv::-webkit-scrollbar-thumb,
.OtherChPPLstitm::-webkit-scrollbar-thumb,
.modal-dialog.modal-lg::-webkit-scrollbar-thumb,
.prolistedvidoitem::-webkit-scrollbar-thumb,
.menuList .dropdown-menu::-webkit-scrollbar-thumb,
.EvaluationScaletab::-webkit-scrollbar-thumb,
ul.dropdown-menu.dropdown-menu-end::-webkit-scrollbar-thumb,
form[name="usersignup"]::-webkit-scrollbar-thumb,
.RightbarPannel .ScenecerelateddQuiz::-webkit-scrollbar-thumb,
.HalfScActF::-webkit-scrollbar-thumb,
.DimensionDashboadcard::-webkit-scrollbar-thumb,
.halfPagePOpup .modal::-webkit-scrollbar-thumb,
.halfPagePOpup.SchoolActivityPopup.modal::-webkit-scrollbar-thumb,
.NuggetPopupDesc::-webkit-scrollbar-thumb,
.SpecialLeftpanel
  .RightbarPannel.p-0.rightpannelSticky
  .personalityListing::-webkit-scrollbar-thumb,
.panneloverflow::-webkit-scrollbar-thumb,
.RightbarPannel .intructionsBox.LessionDtlOverview::-webkit-scrollbar-thumb,
.LessionDtlOverview::-webkit-scrollbar-thumb,
.signupType.rangetypeQuestion.textAreatypeQuestion::-webkit-scrollbar-thumb,
.OtherCProfile::-webkit-scrollbar-thumb,
.lSidebarcontentwrap::-webkit-scrollbar-thumb,
.personalityListing::-webkit-scrollbar-thumb,
.Rpprogresbar::-webkit-scrollbar-thumb,
.progressHomes::-webkit-scrollbar-thumb,
.popularcourse::-webkit-scrollbar-thumb,
.rghtpnl_Skill::-webkit-scrollbar-thumb,
.pagescrollauto::-webkit-scrollbar-thumb,
.popularcourse ul::-webkit-scrollbar-thumb,
.parentQuizsanalysis .welcomscreenContent.lockContentPOpups::-webkit-scrollbar-thumb,
.newEnrollCoursPopup .modal-body::-webkit-scrollbar-thumb,
.newEnrollCoursPopup .modal-content::-webkit-scrollbar-thumb,
.SignupPopup::-webkit-scrollbar-thumb,
.PProfilePoup::-webkit-scrollbar-thumb,
div#ProfileMenuPopup::-webkit-scrollbar-thumb,
.learner_Planrighpnl::-webkit-scrollbar-thumb,
.selectecPaceWeek::-webkit-scrollbar-thumb,
#AreasFocus::-webkit-scrollbar-thumb,
.Plandetal_collapse::-webkit-scrollbar-thumb,
.planheighCollaspe::-webkit-scrollbar-thumb,
.halfPagePOpup .parents_Support .selectecPaceWeek::-webkit-scrollbar-thumb,
.indivisualprogressivecourse::-webkit-scrollbar-thumb,
.parents_Support .welcomscreenContent ul::-webkit-scrollbar-thumb,
.personalityrightpannel .personalityListing::-webkit-scrollbar-thumb,
.halfPagePOpup .parents_Support .selectecPaceWeek::-webkit-scrollbar-thumb,
form[name="selfsignup"]::-webkit-scrollbar-thumb, 
.student_leadr_list ul::-webkit-scrollbar-thumb, 
.my_lockerlist::-webkit-scrollbar-thumb, 
.skillsReport::-webkit-scrollbar-thumb, 
.CourseCardWrapper::-webkit-scrollbar-thumb,
.studentClsDetails .progressstyl::-webkit-scrollbar-thumb {
   background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07); 
   scrollbar-width: thin;
    scrollbar-color: #c9cdd1; 
}

.SignupPopup::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.6);
}
.insceptionFilter .dropdown-menu::-webkit-scrollbar {
  background-color: #c1c1c1;
}

.LessionDtlOverview::-webkit-scrollbar-thumb {
  background-color: #ffcb5b;
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1),
    inset 0 -1px 0 rgba(0, 0, 0, 0.07);
}

components-service-request-form textarea {
  min-height: 6rem;
}

.popover {
  position: relative;
}

.popover:hover::after {
  position: absolute;
  content: "";
  border: 12px solid transparent;
  border-top-color: #ffedb5;
  top: -10px;
  left: 5px;
}

.popover-content {
  font-weight: normal;
  position: absolute;
  bottom: 28px;
  background-color: #ffedb5;
  border: 1px solid #ffe7a4;
  padding: 5px;
  border-radius: 3px;
  color: #404040 !important;
  width: 11rem;
  display: none;
}

.popover:hover .popover-content {
  display: block;
}

.w-20 {
  width: 20%;
}

.w-14 {
  width: 14%;
}

a[data-name="invoice"] picture {
  font-size: 1.6rem !important;
  line-height: 1.9rem !important;
  margin-left: -2px;
}

.card-header {
  cursor: pointer;
}

components-calendar .item3 > div {
  min-height: 776px;
  max-height: 776px;
  overflow-y: auto;
}

components-current-and-upcoming-sessions .height-serviceIndex {
  height: 230px !important;
}

ul {
  margin: 0;
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}

.badge-warning:hover {
  color: black;
}

.badge-pill {
  padding-right: 0.6rem !important;
  padding-left: 0.6rem !important;
  border-radius: 10rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
}

.bg-visited {
  background-color: gray !important;
}

.grey {
  color: grey;
}

.title-section {
  background-color: rgba(161, 206, 245, 0.3);
  border-radius: 3px;
  padding: 0.3rem 0.5rem;
}

.section_right .panelHeight .dropdown-header {
  display: none !important;
}

.calendar.quickview .panelHeight {
  margin-bottom: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.Inner-panelHeight .section-title {
  border-bottom: 0 !important;
}

.Inner-panelHeight .section-title > .bg-light {
  background-color: transparent !important;
  padding: 0 1rem 1rem !important;
}

.item3 .panelHeight {
  box-shadow: none !important;
  border-radius: 0 !important;
  margin: 0 !important;
  height: auto !important;
  border: 0 !important;
}

.text-general,
.text-general:hover {
  color: #000 !important;
  font-size: 14px;
  font-weight: 400;
}

span.head_style {
  border: 1px solid #e1dede;
  padding: 7px 0px 7px 7px;
  color: #eb7d2e !important;
}

input[type="checkbox"],
input[type="radio"] {
  min-height: auto !important;
}

/*Css for date picker*/
.custom-div {
  width: 200px;
  position: relative;
}

.custom-img {
  font-size: 24px;
  position: absolute;
  top: 5px;
  right: 6px;
  background: white;
  color: #9a9a9a;
  cursor: pointer;
}

.fc-button-group .custom-datepicker-input {
  border-radius: 0 !important;
}

.modal {
  position: fixed;
  z-index: 999999;
  padding-top: 0px;
  width: 100%;
  overflow: auto;
  top: 0px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  align-items: center;
  justify-content: center;
}

.SelectYourProfile .modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -43%);
  top: 43%;
}

.modal-content {
  position: relative;
  background-color: #fff;
  margin: auto;
  border: 0;
  padding: 0;
  /* border: 1px solid #888; */
  max-width: 100%;
  border-radius: 4px !important;
}

.halfPagePOpup .modal-content {
  border-radius: 15px 15px 15px 15px;
}

.close {
  position: absolute;
  right: 10px;
  border: 1px solid #1e79c9;
  font-size: 20px;
  color: #1e79c9;
  top: 5px;
  border-radius: 50%;
  cursor: pointer;
  padding: 0 6px;
  background: #f5faff;
}

.modal-body {
  min-height: 160px;
  /* padding: 0; */
  padding: 10px;
}

.form-control[readonly] {
  border-radius: 0 !important;
  border-width: 0 0 1px 0 !important;
  padding: 0.375rem 0 !important;
}

/* #signin {
  min-width: 500px;
} */

.title {
  font-size: 56px;
  letter-spacing: 7px;
  font-weight: 400;
  color: #eb7d2e;
  padding: 10px 0px 10px 0px;
}

.title span {
  color: #262626;
}

.position-relative .la {
  position: absolute;
  right: 7px;
  top: 1.5rem;
  z-index: 10;
}

.position-relative .la1 {
  position: absolute;
  right: 7px;
  top: 1.3rem;
  z-index: 10;
}

.position-relative .la2 {
  position: absolute;
  right: 7px;
  top: 1.3rem;
  z-index: 100;
}

.btn-google {
  background-color: transparent;
  border-radius: 20px;
  border: 1px solid #e4e0e0 !important;
  color: #434242;
  padding: 10px 24px 10px 24px;
  border-radius: 20px !important;
  font-size: 16px;
  width: 100%;
}

.btn-gmail {
  background-color: #f1efef;
  border-radius: 20px;
  border: 1px solid #ccc;
  color: #706f6f;
  padding: 12px 24px 12px 24px;
}

.title2 {
  font-size: 30px;
  font-weight: 400;
}

ul.nav-tabs {
  display: flex;
  flex-direction: row;
  padding: 0px !important;
}

.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
  width: 50%;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus {
  font-size: 21px;
  text-transform: capitalize;
  border-bottom: 2px solid #eb7d2e !important;
  color: #eb7d2e !important;
  border-right: none !important;
  border-top: none !important;
}

.nav-tabs > li > a:hover {
  border: none !important;
}

.nav-tabs > li > a {
  font-size: 21px;
  text-transform: capitalize;
  background: #fff !important;
  color: #3e3e3e;
  border-right: 1px solid #eee !important;
  font-weight: 400;
  text-align: center;
  border-left: none !important;
}

.tab-content > .active {
  display: block;
  padding-top: 3rem !important;
}

.tab {
  padding: 0px 20px 30px 0px;
  text-align: center;
}

.signinandSignup .login-right .tab {
  padding: 0px 20px 20px 0px;
}

/* Style the buttons inside the tab */
.tab a {
  float: initial;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 8px 20px 8px;
  transition: 0.3s;
  font-size: 21px;
  text-align: center;
  color: #000;
  border-bottom: 3px solid transparent;
}

.tab a:hover {
  color: #000;
}

/* .landingpageMenu ul li:hover {
  color: #329fff;
} */

#defaultOpen {
  background: transparent;
  color: #e46666;

  float: initial;
  border: none;
  outline: none;

  padding: 8px 20px 8px;
  transition: 0.3s;
  font-size: 21px;
  text-align: center;

  border-bottom: 3px solid transparent;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 2rem 0rem;
  border-top: none;
}

.panel-title a i.fas.fa-user {
  font-size: 16px;
  padding-right: 5px;
  color: #ccc;
}

.panel-title i.fa.fa-chevron-up.icon-show {
  color: #ccc;
}

img.g_icon {
  vertical-align: middle;
}

.btn-large {
  width: 100%;
}

.hrline {
  width: 100%;
}

.navSidebar {
  width: 190px;
  height: 100vh;
  position: fixed;
  background: #1d103f;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  border-radius: 0px 0.4rem 0.4rem 0px;
  top: 0px;
  z-index: 10000;
  left: 0;
  -webkit-transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
  transition: all 0.3s cubic-bezier(0, 0, 0.2, 1);
  -webkit-transform: translate3d(0, 0, 0);
}

.navSidebar.inactive {
  width: 50px;
}

.navSidebar .top-section {
  position: relative;
  text-align: center;
  border-bottom: 1px solid #2e3d4c;
  height: 52px;
  border-bottom: 1px solid #ccc;
  background: #1d103f;
  padding: 0 10px;
  border-radius: 0 10px 0 0;
  line-height: 60px;
}

.navSidebar .top-section .logo-section {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
}

.navSidebar .top-section .logo-section img {
  max-width: 100%;
  max-height: 100%;
}

.navSidebar .top-section .back-arrow {
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PannelContent.basicdetailsform.px-5
  .input-group.searchInputs.searchinrest
  input.form-control {
  max-width: 100%;
  padding: 11px 38px 14px 18px;
  font-size: 16px !important;
}

.searchinrest .search {
}

.searchinrest .searchInputs input.form-control {
  padding: 0 30px;
  color: #d8d8d8;
  font-size: 14px !important;
  background: #fff;
  height: 42px;
}

span.closeicon {
  position: absolute;
  right: 10px;
  top: 48px;
  cursor: pointer;
  z-index: 1;
  color: #8b8b8b;
}

span.shortLogo {
  line-height: 1;
  font-size: 14px;
  padding: 6px 8.24px;
  border: 2px solid #fff;
  border-radius: 50%;
  font-weight: bold;
  display: inline-block;
  background: #ff7600;
  color: #ffffff;
}

.navSidebar svg.svg-inline--fa.fa-xmark {
  color: #d5d5d5;
  font-size: 15px;
}

.sidebarmenutxt {
  margin-right: auto;
  padding-left: 10px;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
}

.navSidebar .top-section .bars {
  color: #fff;
  font-size: 18px;
}

.uploadedPhoto img {
  border-radius: 50%;
  width: 100%;
  cursor: pointer;
  border: 4px solid #8d8d8d;
}

.navSidebar li {
  list-style: none;
  color: #fff;
  display: flex;
  align-items: center;
  transition: all 0.3s;
  margin-bottom: 0;
  text-align: left;
  justify-content: left;
  padding: 11px 10px;
  margin: 0px 0;
  flex-wrap: nowrap;
  border-bottom: 1px solid #402b78;
}

.navSidebar li.sidebar-items {
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.navSidebar.inactive li.sidebar-item .dp-submenu {
  background: #1d103f;
}

.sidebardropdown.dropdown-toggle.sidebar-link[aria-expanded="true"] {
  background: #1d103f;
}

.sidebardropdown.dropdown-toggle.sidebar-link[aria-expanded="true"] a {
  background: #1d103f;
  padding: 10px;
  border-radius: 0 5px;
}

.sidebardropdown.dropdown-toggle.sidebar-link[aria-expanded="false"] a:hover {
  background: #1d103f;
}

.sidebardropdown.dropdown-toggle.sidebar-link[aria-expanded="false"] a {
  background: #1d103f;
  padding: 10px;
}

.sidebardropdown.dropdown-toggle.sidebar-link .dp-submenu a {
  background: #1d103f;
  padding: 5px 0px 5px 0px;
}

.navSidebar.inactive
  li
  .sidebardropdown.dropdown-toggle.sidebar-link
  .dp-submenu
  a {
  border-left: 1px solid #fff;
  border-top: 1px solid #fff;
  border-radius: 0 0 5px 0px;
}

.sidebardropdown.dropdown-toggle.sidebar-link {
  width: 100%;
}

.navSidebar.inactive li.sidebar-items {
  padding: 0;
}

.sidebardropdown.dropdown-toggle.sidebar-link.collapsed {
  background: #1d103f;
}

.navSidebar.inactive li.sidebar-items .dp-submenu span {
  display: inline-block;
  width: 104px;
  padding: 5px 0;
}

.navSidebar.inactive li.sidebar-items ul.dp-submenu li a::before {
  border: 1px solid white;
  left: 10px;
  top: 17px;
}

.navSidebar.inactive li.sidebar-items ul.dp-submenu li {
  margin: 0px 0 0 50px;
  transition: all 0s !important;
  padding: 0;
  border-radius: 0 0 5px 0px !important;
}

.navSidebar.inactive
  li.sidebar-items
  .dropdown-toggle.sidebar-link.collapsed
  span.hide-menu {
  display: none;
}

.navSidebar.inactive
  li.sidebar-items
  .dropdown-toggle.sidebar-link[aria-expanded="true"]
  span.hide-menu {
  display: inline-block;
  padding-left: 20px;
}

.sidebardropdown.dropdown-toggle.sidebar-link a {
  padding: 10px;
  position: relative;
}

.navSidebar.inactive
  li
  .sidebardropdown.dropdown-toggle.sidebar-link[aria-expanded="true"] {
  background: #1d103f;
  min-width: 344px;
  padding-left: 147px;
  height: 50px;
  border-radius: 5px;
  transition: all 0.3s;
}

.navSidebar.inactive
  li.sidebar-items
  .dropdown-toggle.sidebar-link
  ul.dp-submenu.collapse.show
  span.hide-menu {
  display: inline-block;
  background: #1d103f;
  width: 146px;
  text-align: left;
  padding-left: 10px;
  padding-bottom: 5px;
  padding-top: 5px;
}

.navSidebar li:hover,
.sidebardropdown.dropdown-toggle.sidebar-link:hover {
  background: #1d103f;
  color: lightgrey;
}

.navSidebar li a {
  display: flex;
  align-items: center;
  padding: 0;
}

.navSidebar li i,
.navSidebar li svg {
  font-size: 21px !important;
  width: 30px;
  display: inline-block;
  transition: all 0.3s;
  text-align: center;
  color: #fff;
}

.navSidebar
  li
  .sidebardropdown.dropdown-toggle.sidebar-link
  .hide-menu
  svg.svg-inline--fa.fa-user {
  position: relative;
  transform: rotate(0deg) !important;
  top: 0;
  left: 0;
  width: auto;
}

.navSidebar li .sidebardropdown.dropdown-toggle.sidebar-link .hide-menu SVG {
  font-size: 11px !important;
  color: #fff;
  position: absolute;
  top: 19px;
  transition: all 0.3s;
  right: 0;
}

.ProCoursecard svg.svg-inline--fa.fa-user {
  margin-right: 0;
}

.ProCoursecard.Gridcard .GridTeacher svg.star-svg {
  margin-right: 0;
}

.PannelContent .ProCoursecard h3.ActivyTitleName.pointer {
  padding-bottom: 0px;
}

.navSidebar
  li
  .sidebardropdown.dropdown-toggle.sidebar-link[aria-expanded="true"]
  .hide-menu
  SVG,
.Nestedtable th .DownArrow[aria-expanded="true"] svg,
.settingtable td table td:last-child[aria-expanded="true"] svg,
.newenrolledpopup h3[aria-expanded="false"] span svg,
.sectionscore li[aria-expanded="true"] span svg,
.dimensionshorting h3 span[aria-expanded="true"] svg {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.Nestedtable th .DownArrow[aria-expanded="false"] svg,
.newenrolledpopup h3[aria-expanded="true"] span svg,
.sectionscore li[aria-expanded="false"] span svg,
.settingtable td table td:last-child[aria-expanded="false"] svg,
.dimensionshorting h3 span[aria-expanded="false"] svg {
  transform: rotate(0deg);
  transition: all 0.3s;
}

.navSidebar li ul.dp-submenu li {
  padding: 3px 0 5px 50px;
  text-align: inherit;
  margin: 5px 0 0;
  list-style: circle !important;
  border-bottom: none;
  border-top: 1px solid #402b78;
  border-left: none;
}

ul.dp-submenu {
  top: -3pxpx;
  position: relative;
}

.dlogo {
  border-radius: 50%;
  width: 14px;
  position: relative;
  height: 14px;
  border: 5px solid #fff;
}

span.dimen-1 {
  width: 4px;
  height: 10px;
  background: #fff;
  display: block;
  position: absolute;
  top: -14px;
  left: 0px;
}

span.dimen-1.dimen-2 {
  transform: rotate(72deg);
  left: 11px;
  top: -7px;
}

span.dimen-1.dimen-3 {
  transform: rotate(-72deg);
  left: -11px;
  top: -7px;
}

span.dimen-1.dimen-4 {
  transform: rotate(144deg);
  left: 7px;
  top: 6px;
}

span.dimen-1.dimen-5 {
  transform: rotate(-144deg);
  left: -7px;
  top: 6px;
}

span.dimen-1::before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  border-radius: 50%;
  top: -9px;
  left: -3px;
}

.lodaingBackgropund {
  top: 0;
  position: fixed;
  background: #00000066;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
}

.VickeyLoder {
  animation-name: animationloder;
  animation-duration: 2s;
  animation-timing-function: linear;
  padding: 24px;
  border-radius: 50%;
  animation-iteration-count: infinite;
}

@keyframes animationloder {
}

@-ms-keyframes animationloder {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes animationloder {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes animationloder {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes animationloder {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.navSidebar li span {
  font-size: 16px;
  color: #fff;
  font-weight: 300;
  transition: all 0.3s;
  text-transform: capitalize;
  padding-left: 10px;
}

.top-section i.fa.fa-bars {
  color: #fff;
  font-size: 24px;
}

.back-arrow .fa-arrow-left {
  font-size: 15px;
  color: #ffffff;
  border: 2px solid #ffffff;
  margin-right: -20px;
  border-radius: 50%;
  padding: 2px 3px;
  background: #329fff;
}

.navSidebar.inactive li {
  padding: 11px 0;
  transition: all 0.3s;
  justify-content: center;
}

.navSidebar.inactive li i {
  text-align: center;
  transition: all 0.3s;
}

.navSidebar.inactive li:hover,
.navSidebar.inactive .sidebardropdown.dropdown-toggle.sidebar-link:hover {
  background: #0f1a34;
}

.navSidebar ul li #open-sidebar {
  width: 70px;
}

.navSidebar ul {
  padding-left: 0 !important;
  text-align: center;
  width: 100%;
}

.navSidebar.inactive ul {
  transition: all 0s !important;
}

.navSidebar li ul.dp-submenu li a {
  position: relative;
}

.MainContent {
  padding-left: 190px;
  display: flex;
  padding-top: 60px;
}

.LeftbarPannel.inactive {
  width: calc(65% - 10px);
  margin: 0px 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 30%);
  border: 1px solid #e2e2e2;
  height: 100vw;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
}

.MainContent {
  padding-left: 50px;
  display: flex;
  padding-top: 38px;
  padding-bottom: 10px;
}

.teachergroup.basicdetailsform .input-group {
  width: 100%;
  margin: auto;
  margin-bottom: auto;
}

.teachergroup.basicdetailsform .wrapper.mx-auto .form-control {
  max-width: 100%;
}

.Main {
  background: #f4f4f4;
  padding-top: 10px;
}

.heding-title span.profilecategory {
  font-size: 15px;
  font-weight: normal;
}

.header {
  height: 48px;
  padding: 0px 0 0;
  position: fixed;
  width: 100%;
  background: #fff;
  top: 0;
  z-index: 99999;
  /* background: #1d103f; */
}

.login-right.my-2 .tab {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 25px;
}

span.pipe {
  font-size: 30px;
  color: #d3d3d3;
  padding: 0px 30px 0px 30px;
}

.signupType {
  display: flex;
  align-items: center;
  color: #212529;
}

.signupType label {
  padding-left: 28px;
  font-size: 16px;
  /* padding-right: 44px; */
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

div#Educations .signupType label {
  padding-left: 25px;
  font-size: 14px;
  padding-right: 44px;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #8b8b8b;
}

.tabgrid.Educationtab li.tabs {
  width: auto;
  padding: 7px 20px;
}

div#Educations .signupType.mt-4.publicprivateToggle label.Selcheckbox {
  font-weight: normal;
}

div#Educations .checkmark {
  height: 18px;
  width: 18px;
}

div#Educations .Selcheckbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 6px;
  border-radius: 1px;
  height: 10px;
}

.input-group label {
  margin-top: 10px;
}

.SignupRegistration .input-group label {
  margin-top: 7px;
  color: #606060 !important;
}
.SignupRegistration .input-group label a {
  padding: 0 0px;
  color: #329fff;
}

.input-group .checkboxes label {
  margin-top: 0;
}

.panel-title a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.rangeString {
  width: 9.09%;
  text-align: center;
}

output#output {
  width: 100%;
  margin-top: 0 !important;
  display: block !important;
  text-align: center;
}

.QstrangeSlider > div {
  width: 100% !important;
}

.learnerprofilelist li {
  font-size: 16px;
  position: relative;
  padding-left: 20px;
  margin-bottom: 15px;
}

.PopupConclusion p {
  font-size: 16px;
}

.PopupConclusion p span {
  font-weight: 600;
}

.PopupConclusion button.btn-blue.btn-login.d-block {
  width: auto;
}

.Assemptionleftbar .QstrangeSlider > div {
  width: 90% !important;
  margin: 10px 0;
}

.defaultScore {
  font-size: 14px;
  font-weight: bold;
}

.Assemptionleftbar .QuestionList.d-flex {
  margin-bottom: 10px;
}

.learnerAssumtion {
  background: #fff;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 20px;
  min-height: 100%;
}

.halfPagePOpup.AssumptionPopup .modal.fade.show .modal-dialog.modal-lg {
  right: 0;
  border-radius: 6px;
}

.AssemptionWrap {
  padding: 15px;
  background: rgb(172 211 232 / 10%);
  border-radius: 10px;
  border: 1px solid #ccc;
  min-height: calc(100vh - 140px);
}

.learnerprofilelist.mt-3 li:before {
  content: "";
  width: 12px;
  height: 12px;
  background: #ffffff;
  left: 0;
  border-radius: 50%;
  border: 4px solid rgb(117 154 255);
  position: absolute;
  top: 9px;
}

.AlearnerProname span {
  font-weight: normal;
  margin-right: 10px;
  display: inline-block;
  min-width: 70px;
}

.AlearnerProname h3 span {
  margin-left: -2px;
}

.AlearnerProname p {
  font-weight: 600;
}

.Asslearnerimage {
  font-size: 20px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  text-align: center;
  line-height: 64px;
  background: #329fff;
  color: #fff;
}

.QstrangeSlider .text-range {
  padding-top: 5px;
  position: relative;
}

.halfPagePOpup.AssumptionPopup .modal-content {
  border-radius: 6px;
}

.rangeString span {
  display: block;
  font-size: 10px;
  color: #717171;
  margin-top: 0px;
  position: absolute;
  left: 0px;
  top: -15px;
}

.w-100.PopupConclusion.flex {
  margin-top: 10px;
}

.rangeString span.verymuch {
  right: 0px;
  left: auto;
}

output#output {
  margin-top: 0 !important;
}

.next-btn {
  text-align: right;
  margin-top: 20px;
  margin-right: 22px;
}

.panel-body {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  padding: 10px 15px 10px 20px !important;
}

input#rememberMe {
  vertical-align: middle;
}

.landing-page {
  padding-top: 0px;
  width: 100%;
}

/* pankaj */
.next-btn a {
  width: 130px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  color: white;
  background-color: #0073ea;
  font-size: 18px;
}

.next-btn a {
  width: 114px;
  height: 34px;
  border-radius: 5px;
  border: 0;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  line-height: 30px;
  color: white;
  background-color: #1ec1d9;
  font-size: 16px;
}

.next-btn {
  text-align: right;
  margin-top: 36px;
  padding-right: 0;
  width: 83%;
  margin: auto;
  padding-top: 10px;
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  padding-bottom: 12px;
  border: 0;
}

.LeftbarPannel {
  width: calc(70% - 10px);
  margin: 0px 10px;
  border: 1px solid #dcdcdc;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: #fff;
  padding: 20px;
  /* scrollbar-width: thin;
  scrollbar-color: #c9cdd1 #f9f9f9; */
  /* min-height: 100vh; */
  border-radius: 0.4rem !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  
}

.LPwidth .LeftbarPannel.p-0 {
  justify-content: space-between;
}

.SpecialLeftpanel .LeftbarPannel {
  /* box-shadow: none !important; */
  border: 0;
  /* min-height: 86vh; */
}

.CourseCardWrapper {
  box-shadow: none;
    background: #fff;
    border-radius: 4px;
    min-height: 70vh;
    max-height: 70vh;
    overflow: auto;
    order: 2;
    flex: 1 1 0;
}

.CourseCardWrapper.fullHeight100 {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 0;
}
.LeftPanelFooter{
  order: 3;
}
.RightbarPannel {
  width: 30%;
  border: 1px solid #dcdcdc;
  margin: 0;
  padding: 0px !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  background: #ffffff;
  /* scrollbar-width: thin;
  scrollbar-color: #c9cdd1 #f9f9f9; */
  border-radius: 0.4rem !important;
  /* min-height: 86vh; */
  z-index: 99;
  overflow: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.form-title.heading {
  order: 1;
  height: 37px;
}
.RightbarPannel.fullrightpannelheight {
  min-height: 100vh;
}

.RightbarPannel.p-0.rightpannelSticky {
  /* min-height: 84vh;
  max-height: 84vh; */
  margin-top: 0;
  /* position: sticky;
  top: 108px; */
  width: calc(30% + 0px);
  margin-right: 0px !important;
  /* border: 0 !important; */
  /* box-shadow: none !important; */
  overflow: auto;
}
/* .RightbarPannel.p-0.rightpannelSticky.newcoursecardpanel.home_page_rgt_pnl {
    min-height: 84vh;
    max-height: 84vh;
} */
/* .RightbarPannel.p-0.rightpannelSticky.newcoursecardpanel.home_page_rgt_pnl.setuptwo_rPnl {
  overflow: hidden;
} */
.SpecialLeftpanel .RightbarPannel.p-0.rightpannelSticky {
  overflow: initial;
}
.SpecialLeftpanel
  .RightbarPannel.p-0.rightpannelSticky.enrolledCourseAssetsstr {
  overflow: inherit;
}
.SpecialLeftpanel .RightbarPannel.HomepgeRightpannel.rightpannelSticky {
  max-height: calc(100vh - 5px);
}

.HomepgeRightpannel .heading {
  position: sticky;
  top: 0;
}

.selectedintrestlist .profile_division .panel-collapse {
  border: 0;
}

.selectedintrestlist .Profileheading {
  border-bottom: 1px solid #ccc;
}

.RightbarPannel.p-0 .PannelContent:last-child {
  margin-bottom: 0;
  box-shadow: none;
}

.BDsubmitbutton.Addchieldbtn span {
  font-size: 15px;
  border: 1px solid #fff;
  line-height: 19px;
  display: inline-flex;
  border-radius: 50%;
  padding: 0px;
  margin-left: 5px;
  width: 19px;
  height: 19px;
  align-items: center;
  justify-content: center;
}

.BDsubmitbutton.Addchieldbtn {
  text-align: right;
  display: flex;
  justify-content: right;
  margin-right: 0px;
}
.heading {
  border-bottom: 1px solid #ccc;
  padding: 5px 8px;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 91;
  background: #f5faff;
  border-radius: 5px 5px 0 0;
}

.AllguidePoups2 .RightbarPannel .heading {
  z-index: 100001;
}

.heading h2 {
  padding: 0 !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  font-family: "Poppins", sans-serif !important;
  line-height: 20px;
  display: flex;
  align-items: center;
  font-size: 1.25rem;
}
.modal .heading h2 {
  font-size: 18px !important;
}
li.notificationbar {
  position: relative;
  margin: 0 0 0 -10px !important;
}

li.notificationbar .fa-bell {
  font-size: 24px;
  color: #32436a;
}

.basicdetailsform .wrapper.mx-auto .form-group {
  display: block;
}

.basicdetailsform .wrapper.mx-auto .form-control {
  border-radius: 0px !important;
}

.css-yk16xz-control,
.css-yk16xz-control {
  border-radius: 0 !important;
}

.form-control::placeholder {
  color: rgb(129, 129, 129) !important;
}

.title-input::placeholder {
  color: rgb(129, 129, 129) !important;
}

.title-input:focus {
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.title-input {
  color: rgba(0, 0, 0, 0.54);
  left: 0px;
  top: 0px;
  width: 100%;
  border-style: solid;
  border-width: 0.727273px;
  font-size: 14px;
  font-weight: 400;
  height: 38px;
  line-height: 21px;
  margin: 0px;
  padding: 5.25px 10.5px;
  border: 1px solid #ced4da;
}

.basicdetailsform .wrapper.mx-auto {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.heading h2 i {
  vertical-align: middle;
  padding-right: 5px;
}

.curdOperation.editcard {
  padding: 10px;
  text-align: right;
}

.Questions {
  padding: 0px 0;
  position: relative;
  padding-left: 0px;
  width: 95%;
  margin: auto;
}

.QuestionList.d-flex p.fw-bold {
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.QuestionText {
  display: flex;
}

div#create_login .basic_details_form .form-group.BDsubmitbutton.d-flex {
  width: 100%;
}

.buttonDistribotion {
  display: flex;
  justify-content: right;
  width: 100%;
}

.login-right .selectedField:before {
  position: absolute;
  top: 0px;
}

.form-group.selectedField {
  position: relative;
}

.panel-title a svg.rc-progress-line {
  width: 195px;
  margin: 0 10px;
  border-radius: 16px;
}

.panel-body p i {
  padding-right: 5px;
}

h4.panel-title {
  cursor: pointer;
}

.PannelContent.ProviderProfile h4.panel-title {
  cursor: auto;
}

.Questions i {
  position: absolute;
  left: -32px;
  top: 23px;
}

.panel-title a i {
  font-size: 16px;
  padding-right: 5px;
  color: #ccc;
}

.panel-title a i.icon-show {
  color: #ccc;
}

.panel-body a {
  color: #3b3b3b;
}

.panel-body p i {
  padding-right: 5px;
  width: 20px;
}

.panel-body i {
  color: #a9a9a9;
}

.LeftbarPannel.genralDetails .PannelContent {
  padding: 10px;
  width: 83%;
  margin: auto;
}

.next-btn {
  text-align: right;
}

.next-btn a {
  width: auto;
  padding: 0px 20px;
  height: 40px;
  border-radius: 5px;
  border: 0;
  display: inline-block;
  text-transform: capitalize;
  text-align: center;
  line-height: 40px;
  color: white;
  background-color: #1ec1d9;
  font-size: 16px;
}

.Coursebanner .RatingPopupdiv h3 {
  font-size: 15px;
  color: #888888;
  font-weight: bold;
  padding-bottom: 0px;
}

.rangeslider.rangeslider-horizontal {
  margin: 10px 0 20px;
  height: 10px;
}

.QstrangeSlider .rangeslider-horizontal .rangeslider__handle {
  width: 25px;
  height: 25px;
}

.QstrangeSlider .rangeslider-horizontal .rangeslider__handle:after {
  width: 11px;
  height: 11px;
}

.PannelContent.basicdetailsform.px-5
  .input-group.searchInputs.searchinrest
  label {
  font-size: 16px;
  color: #565454;
  font-weight: bold;
  margin-bottom: 2px !important;
}

.dimensionHeader {
  border-bottom: 1px dashed #ccc;
}

.dimensionHeader h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
}

.dimensions {
  margin-bottom: 10px;
}

.dimensionHeader h3 img {
  width: 40px;
  margin-right: 10px;
}

.pulse-component-wrapper {
  display: flex;
  position: relative;
}

.grid-floating-cells {
  display: flex;
  position: sticky;
  left: 8px;
  padding-left: 24px;
  z-index: 1;
}

.grid-floating-cells .grid-cell {
  background: #f1f1f1;
}

.componentheader .grid-floating-cells .grid-cell {
  background: #fff;
}

.grid-cell {
  border-right: 2.8px solid #fff;
  display: flex;
  justify-content: left;
  position: relative;
  min-width: 200px;
  height: 40px;
  align-items: center;
  border-top: 2.8px solid #fff;
  padding: 0 11px;
}

.grid-cell p {
  font-size: 14px;
}

.grid-cells-row {
  background: #f1f1f1;
  display: flex;
  margin-left: 8px;
}

.AddSublect .grid-cell p span {
  font-size: 20px;
  font-weight: bold;
}

button.EditBUtton {
  border: 0;
  opacity: 0;
  padding-left: 10px;
  margin-right: auto;
}

.grid-floating-cells .grid-cell:hover .name-cell-text p {
  color: #00aaff;
}

.grid-floating-cells .grid-cell:hover .EditBUtton {
  opacity: 1;
}

.grid-cell-wrap {
  display: flex;

  margin-left: 0px;
}

.plusleftindicator {
  width: 8px;
  position: absolute;
  left: -8px;
  top: 0;
  bottom: -1px;
  background-color: rgb(87, 155, 252);
  color: rgb(87, 155, 252);
}

.board-content-items {
  margin: 10px 0;
  position: relative;
  overflow-x: scroll;
  padding-bottom: 20px;
}

.AddSublect {
  width: 200px;
  border: 1px solid #a9a9a9;
  border-top: 1px solid #fff;
  position: sticky;
  left: 30px;
}

.AddPlus .grid-cell {
  min-width: 44px;
  font-size: 20px;
  font-weight: 700;
  line-height: 50px;
  justify-content: center;
  align-items: center;
}

.AddSublect .grid-cell {
  border: 1px solid #ccc;
  border-right: 0;
  border-bottom: 0;
}

.AddSublect .grid-cell p {
  color: #000;
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.componentheader .grid-floating-cells .grid-cell,
.componentheader .grid-cells-row .grid-cell {
  color: #000;
  border: 0;
}

.componentheader .grid-floating-cells,
.componentheader .grid-cells-row {
  background: #fff;
}

.side-menu {
  position: fixed;
  background-color: #1d103f;
  width: 300px;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 20px;
  transition: width 0.2s ease-in;
}

.side-menu.inactive {
  width: 80px;
}

.side-menu .top-section {
  position: relative;
}

.side-menu .top-section .logo-section {
  width: 40px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
}

.side-menu .top-section .logo-section img {
  max-width: 100%;
  max-height: 100%;
}

.side-menu .top-section .back-arrow {
  color: #fff;
  font-size: 20px;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.MainContent.inactive {
  padding-left: 190px;
}

.MainContent.inactive .panel-title a svg.rc-progress-line {
  width: 240px;
}

.MainContent .panel-title a svg.rc-progress-line {
  width: 145px;
}

.checkboxes label {
  font-size: 14px;
}

.Selcheckbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
}

.Selcheckbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark,
.WrongQuizAns,
.circularicon {
  position: absolute;
  top: 3px;
  left: 0;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  background-color: #eee;
}
.halfPagePOpup .checkmark,
.halfPagePOpup .WrongQuizAns {
  top: 50%;
  transform: translateY(-50%);
}
.WrongQuizAns {
  background-color: #fff;
  z-index: 1;
  text-align: center;
  font-size: 16px;
  line-height: 8px;
  color: red;
  border: 1px solid #f00;
}

.forChildren .error {
  border: 1px solid red;
}

.ScenecerelateddQuiz .Selcheckbox.WrongQuzAnswer {
  color: red;
}

.ScenecerelateddQuiz .Selcheckbox.RightQuzAnswer {
  color: #00b050;
}

.Selcheckbox:hover input ~ .checkmark {
  background-color: #ccc;
}

.Selcheckbox input:checked ~ .checkmark,
.RightQuzAnswer .checkmark,
.input-group label input:checked ~ .feedbackcheck {
  background-color: #00b050;
}

.Selcheckbox input:checked ~ .checkmark1 {
  background-color: #ccc;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.Selcheckbox input:checked ~ .checkmark:after,
.RightQuzAnswer .checkmark:after {
  display: block;
}

.pt-4.childdetailtitle h4 {
  font-size: 16px;
}

#signin .btn-login svg.svg-inline--fa.fa-paper-plane {
  padding-right: 0px;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
  -webkit-appearance: none;
  position: relative;
  z-index: 2;
}

.mandatoryField {
  color: #dd3333;
  font-size: 16px;
  line-height: 1;
}

div#Educations .BDsubmitbutton button.btn-blue.btn-login.d-block.mb-5,
#Experince_form .BDsubmitbutton button.btn-blue.btn-login.d-block.mb-5 {
  margin-left: auto;
}

#Experince_form
  .BDsubmitbutton
  .buttonDistribotion
  button.btn-blue.btn-login.d-block.mb-5 {
  margin-left: 0;
}

span.PasswordeyeIcon {
  position: absolute;
  right: 10px;
  top: 44px;
  z-index: 1;
  cursor: pointer;
  color: #c1c1c1;
}
.SignupRegistration span.PasswordeyeIcon {
  top: 44px;
}
span.PasswordeyeIcon:hover {
  color: #646464;
}

.form-group.BDsubmitbutton {
  margin-top: 20px;
}

.fcardInfolist li img {
  height: 25px;
}

.input-group.calender .clenderIcon {
  position: absolute;
  right: 6px;
  width: 28px;
  z-index: 9;
  cursor: pointer;
  pointer-events: none;
  top: 38px;
  color: #bf9fd9;
}

.paginationButton .next-btn a button {
  border: 0;
  outline: none;
  background: #329fff;
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
  text-align: center;
  line-height: 40px;
  color: white;
  font-size: 16px;
  line-height: 1;
  font-weight: 699;
}

.login-right .selectedField:after {
  top: 8px;
}

.courseresultDesc img {
  width: 44px;
}

.Selcheckbox .checkmark:after,
.RightQuzAnswer .checkmark:after {
  left: 6px;
  top: 2px;
  width: 6px;
  height: 11px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.lendin_gpage {
  display: flex;
}

p.sidebarActive.active a.active {
  font-weight: bold;
  display: flex;
  color: black;
  align-items: center;
}

p.sidebarActive.active a.active i.fa.fa-caret-right {
  font-size: 24px;
  color: #000;
}

.name-cell-text {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.name-cell-text img,
.grid-Activity img {
  width: 24px;
}

.grid-Activity {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

h2.h1.text-center.m-minus-15.orbutton {
  margin: 20px 0px;
  border-bottom: 1px dashed #ccc;
  position: relative;
}

button.btn-google.btn-login.d-block.mb-5 {
  margin-top: 25px !important;
  border: 1px solid #e4e0e0 !important;
  display: block;
  border-radius: 4px !important;
  box-shadow: none !important;
  font-size: 16px !important;
}

button.btn-google.btn-login.d-block.mb-5 div {
  display: inline-block;
  padding: 7px !important;
}

h2 span.bg-white {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 18px;
  top: -12px;
  padding: 0px 3px;
  color: #c3c3c3;
  font-weight: normal;
  font-family: "Figtree", sans-serif !important;
  background: #ffffff;
}

h2.orbutton span.bg-grey {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  top: -10px;
  padding: 0 10px;
  color: #c1c1c1;
}

.basicdetailsform .input-group.uploadimgwidth {
  width: 200px;
}

.uploadimagealign {
  text-align: center;
}

.uploadcorsimgalign {
  margin-left: 29.3rem !important;
}

.RightbarPannel.p-0 .PannelContent {
  height: auto;
  margin-bottom: 0px;
  overflow-y: hidden;
  padding: 0 !important;
  box-shadow: 0 0px 3px #ccc;
  border-bottom: 1px solid #ccc;
}

.arrow_icon .panel-collapse {
  display: none;
  transition: all 0.5s;
}

body .child_profiles *,
body .lernerparents * {
  transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  -ms-transition: 0.5s ease-in-out;
}

.Profileheading {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

div#scrollableDiv {
  max-height: 329px !important;
  overflow: auto !important;
}

.SelectMenu {
  width: 18px;
  border-radius: 4px;
  height: 18px;
  opacity: 0;
  position: absolute;
  left: -30px;
  top: 11px;
  line-height: 18px;
  background: #e6e9ef;
  text-align: center;
}

.pulse-component-wrapper:hover .SelectMenu {
  opacity: 1;
}

.menuOption {
  position: absolute;
  width: 123px;
  flex-wrap: wrap;
  background: #fff;
  z-index: 9;
  padding: 10px;
  top: 34px;
  left: 1px;
  border-radius: 5px;
  display: flex;
  display: none;
  justify-content: space-between;
  box-shadow: 0px 2px 5px #a0a0a0;
}

.menu {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

.menu i {
  padding-right: 10px;
  color: #8d8d8d;
  font-size: 15px;
}

.PageTitle {
  font-size: 14px;
}

.showsmenu .SelectMenu {
  display: block;
}

.pulse-component-wrapper.componentheader p {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
}

.checkboxes {
  display: flex;
  align-items: center;
}

.checkboxes.mt-4 input {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.QuestionList p {
  font-size: 14px;
}

.QuestionList p span {
  font-weight: 600;
}

.text-range span {
  font-size: 12px;
}

.AssemptionWrap h4 {
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 13px !important;
}

.AssemptionWrap h3 {
  font-size: 16px;
}

.AssemptionWrap .learnerAssumtion .position-relative h3 {
  cursor: pointer;
}

.AlearnerProname h3 {
  font-size: 18px;
}

span.SharetpeAct {
  font-size: 12px;
}

.panel-collapse p,
.panel-collapse a {
  font-size: 14px;
}
.profile_division .panel-collapse a,
.profile_division .panel-collapse p {
  font-size: 15px;
}
.heding-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px 8px;
  border-radius: 0px 0px 0 0;
  color: #212121;
  background: #fff;
  border-bottom: 0;
}

.profile_division .panel-collapse {
  border-top: 1px solid #ccc;
}

.profile_division .Profileheading span.Username {
  color: #616161;
}

.rembrme {
  line-height: 1;
}

.uploadedPhoto span.cameraiconImg img,
.uplodedCImage img {
  border-radius: 0;
  cursor: pointer;
  border: 0;
  width: auto;
  height: 30px;
}

.child_profiles .heding-title {
  background: #fff;
}

.profile_division {
  border-radius: 0px;
  background: #fff;
  margin: 0px;
}

.Progress-bar svg {
  width: 100%;
  border-radius: 10px !important;
}

.AddReviewBtn {
  background: #38a2ff;
  color: #fff;
  padding: 10px 0;
}

.RatingPopupdiv
  span.style-module_simpleStarRating__nWUxf.react-simple-star-rating {
  margin-top: -10px;
  margin-right: 5px;
}

/* .ratingProgressbar path.rc-progress-line-path {
  stroke: #c5c5c5;
} */
.panel-collapse .panel-body:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

.Progress-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  background: #fff;
  padding-left: 31px;
  padding: 7px 32px;
  border-top: 1px solid #eee;
}

.heding-title span i {
  padding-right: 5px;
}

.heding-title span i img,
.dropdown span i img {
  width: 25px;
}

.heding-title span {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.heding-title span {
  font-size: 16px;
  color: #616161;
  display: flex;
  font-weight: bold;
}

.submoduleList.heding-title span {
  color: #232323;
  line-height: 1.2;
}

.child_profiles .heding-title .fa-user,
.dropdown- span .fa-user {
  border: 1px solid #ccc;
  padding: 3.5px 4.45px;
  color: #ccc;
  border-radius: 50%;
  margin-right: 7px;
}

.form-group label,
.input-group label {
  color: #606060;
  font-size: 14px;
  margin-bottom: 0.4rem !important;
  font-weight: 600;
}

.form-group.BDsubmitbutton {
  justify-content: space-between !important;
}

.BDsubmitbutton button.btn-blue.btn-login.d-block.mb-5 {
  display: flex !important;
  margin: 0;
  align-items: center;
  margin-bottom: 0 !important;
  width: auto;
  font-size: 14px !important;
}
/* button.btn-blue.btn-login.d-block.mb-5.cancelbutton,
.sharecertificate button.btn-blue.btn-login.d-block.cancelbutton {
  margin-right: 10px !important;
} */
/* .coursedetailpopuppage button.btn-blue.btn-login.d-block.mb-5.cancelbutton,
.closebtnreset button.btn-blue.btn-login.d-block.mb-5.cancelbutton{
  margin-right: 0 !important;
} */

/* .NuggetPopupFooter button.btn-blue.btn-login.d-block.mb-5.cancelbutton {
  margin-right: 10px;
} */
button.btn-blue.btn-login.d-block {
  margin-left: 10px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
button.btn-blue.btn-login.d-block.mb-5.cancelbutton,
.fsignupBtn button.btn-blue.btn-login.d-block,
.beginAssessmentbtn button.btn-blue.btn-login.d-block,
.LeftPanelFooter button.btn-blue.btn-login.d-block,
.subscriptionwrapcard
  button.btn-blue.btn-login.d-block.mb-5.cancelbutton.left_auto,
.priceWrap button.btn-blue.btn-login.d-block,
button.btn-blue.btn-login.d-block.left_auto {
  margin-left: auto !important;
}
.signin_box_styl button.btn-blue.btn-login.d-block,
button.btn-blue.btn-login.d-block.w-auto.m-0.ml-3.procedecourse,
button.btn-blue.btn-login.d-block.mb-5.back_button,
.GridCardTitle button.btn-blue.btn-login.d-block.w-100.back_button,
button.btn-blue.btn-login.d-block.mb-5.cancelbutton.ml-0,
button.btn-blue.btn-login.d-block.mb-5.ml-auto.back_button {
  margin-left: 0 !important;
}
.NuggetPopupFooter button.btn-blue.btn-login.d-block.mb-5.cancelbutton {
  margin-right: 10px !important;
}
.basicdetailsform form.bg-white.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.fcardInfolist button {
  margin-bottom: 0 !important;
}

.basicdetailsform .form-group.mt-4 {
  margin-top: 0 !important;
}

.basicdetailsform .form-group.mt-4 {
  margin-top: 0 !important;
}

.basicdetailsform .checkboxes.mt-4 {
  display: flex;
  align-items: center;
  margin: 38px 0 0 !important;
}

.basicdetailsform
  form.bg-white.content
  .input-group.Activityined.full-Width-group {
  margin-top: 0;
}

.wrapper.changepasword .basic_details_form .form-group.BDsubmitbutton.d-flex {
  width: 100%;
  margin-top: 20px;
}

.basicdetailsform .checkboxes.mt-4 label {
  margin-bottom: 0 !important;
}

.erroe404 {
  padding: 50px;
}

.ErrorImage {
  width: 500px;
}

.ErrorDescription.w-100 h2 {
  font-size: 50px;
  color: #4e4e4e;
  text-align: center;
  font-weight: 600;
}

.ErrorDescription.w-100 {
  text-align: center;
  margin-top: 10px;
}

.ErrorDescriptionbtn a:Hover {
  color: #fff !important;
}

.ErrorDescription.w-100 p {
  font-size: 16px;
  color: #aaaaaa;
  text-align: center;
}

.ErrorDescription.w-100 a {
  padding: 8px 30px;
  display: inline-block;
  background: #329fff;
  font-size: 15px;
  margin: 20px 0 0;
  color: #fff;
  border-radius: 3px;
}
.ErrorDescription.w-100 a:hover{
  background: #006dcc;
}
.card_image {
  height: 90px;
  margin-right: 10px;
  width: 90px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.card_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.choseintrest {
  width: 100%;
  text-align: center;
  margin: 30px 0 50px;
  border-bottom: 1px dashed #000;
  position: relative;
  font-size: 14px;
}

.interestedTitle h3 {
  font-size: 17px;
  color: #212121;
}

.GradeAndleve h4.flex {
  color: #afafaf;
  font-size: 14px;
}

.instlistitem,
.probleumListitem {
  align-items: center;
  border-radius: 0px;
  background: #f9f8f8;
  margin: 5px 5px 5px 5px;
  border: 1px solid #8e8d8d;
  border-radius: 17px;
  display: flex;
  position: relative;
  padding: 4px 0;
}

.probleumListitem {
  font-size: 18px;
  vertical-align: middle;
  padding-right: 5px;
  color: #505050;
}

.ProbvidListItem {
  width: 100%;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 0px;
}

span.rightcheck img,
.tabgrid ul li img.alreadysceneed {
  max-width: 20px;
}

.searchVideoList li {
  border: 1px solid #cccc;
  padding: 5px;
  justify-content: space-between;
}

.AddedvideoList li.d-flex.mb-3.position-relative {
  border: 0;
  box-shadow: 0 0 10px 2px #f2eeee;
}

/* .instlistitem:nth-child(3n + 3),
.probleumListitem:nth-child(3n + 3) {
  margin-right: 0;
} */
.BDsubmitbutton.Addchieldbtn.addEnrichbtn.pr-10.m-0.p-1.mr-2 {
  text-align: right;
  display: flex;
  margin-top: 20px;
  justify-content: right;
  margin-right: 10px;
  padding: 8px 0px 9px 12px !important;
  border-left: 1px solid #ccc;
}

.carditem {
  position: relative;
  padding: 0px 7px 0px;
  cursor: pointer;
}

.SelfGenratedTags .instlistitem:nth-child(3n + 3) {
  margin-right: 10px;
}

.instlistitem:hover,
.RightbarPannel .instlistitem:hover,
.probleumListitem:hover {
  border: 1px solid #c6c6c6;
}

.teachername p {
  font-weight: 600;
}

.addEnrichbtn .fa-trash-can {
  color: #ccc;
}

.editcard span .fa-trash-can:hover,
.editcard span:hover,
.addEnrichbtn .fa-trash-can:hover {
  color: #ff1100;
}

.courseInformation .heading h2 ul li span.SubjFullname {
  width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.editcard span .fa-pencil:hover {
  color: #28a745;
}

.GridCardFullDetails p.CoursecardTextDesc {
  font-size: 14px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.Gridcard .CoursecardTextDesc + span .readmoretxt,
.jrny_box .CoursecardTextDesc + span .readmoretxt,
.CourseDetails .CoursecardTextDesc + span .readmoretxt {
  position: absolute;
  right: 0px;
  top: 28px;
  background: #fff;
}

.jrny_box .CoursecardTextDesc + span .readmoretxt,
.CourseDetails .CoursecardTextDesc + span .readmoretxt {
  top: 52px;
  right: 10px;
}

.jrny_box .text,
.CourseDetails .text {
  position: relative;
}

.CourseDetails p.CoursecardTextDesc {
  padding: 0 8px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.AllCourseList {
  padding: 10px;
}
.enrolledCoursePagewrap.AllCourseList {
  padding: 0px 10px 10px;
}
.removepic:hover {
  background: #dd3333;
}

.BDsubmitbutton.Addchieldbtn.addEnrichbtn strong {
  color: #329fff;
  font-size: 16px;
  cursor: pointer;
  font-weight: 400;
}

.BDsubmitbutton.Addchieldbtn.addEnrichbtn strong svg {
  color: #48cfae;
  font-size: 14px;
}

.BDsubmitbutton.Addchieldbtn.addEnrichbtn strong span {
  border: 1px solid #48cfae;
  text-align: center;
  margin-right: 5px;
  color: white;
  background: #48cfae;
}

.BDsubmitbutton.Addchieldbtn.addEnrichbtn strong:hover {
  text-decoration: underline;
}

.teachername span.teachernametxt {
  color: #006dcc;
  text-transform: capitalize;
  cursor: pointer;
  font-size: 14px;
}

.GridCardTitleDesc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px 0px 10px;
  flex-direction: row;
}

.CourseardDesc {
  padding: 5px 10px 0;
  margin-left: 0px;
  position: relative;
  margin-right: 0px;
}

.jrny_box p.CoursecardTextDesc {
  padding: 0px 12px 5px 0;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.GridTeacher {
  display: flex;
}

.gridSchool.w-50.p-0 {
  padding-left: 7px;
}

.teachername {
  padding-left: 0;
  font-size: 14px;
  color: #006dcc;
  font-weight: 600;
  text-transform: capitalize;
}

.ActivitycrdTitle .flex.ratepopupp {
  margin-left: -2px;
  margin-bottom: 2px;
}

.teachername p span.react-simple-star-rating {
  margin-top: -12px;
  margin-left: -2px;
}

span.threeDots {
  font-size: 20px;
  line-height: 1;
  cursor: pointer;
}

.Topicspeclistitem svg.star-svg,
.videoDescription svg.star-svg,
.coursedetailpopuppage svg.star-svg,
.spaclistcontactlist svg.star-svg,
.Resorctitledtl svg.star-svg {
  height: 16px;
  width: 16px;
  margin-right: 0;
}

.EnrollCourseConfimation svg.star-svg,
.Gridcard svg.star-svg {
  width: 17px;
  height: 17px;
  margin-right: 0;
}

/* svg.svg-inline--fa.fa-arrow-left:hover {
  padding: 2px 5px;
  transition: all 0.2s;
} */
.gridSchool img {
  width: 32px;
  margin-right: 7px;
}

.gradeiconimage img {
  max-width: 28px;
  margin-right: 5px;
}

.Gridsocialsec ul {
  padding-left: 0 !important;
  padding-top: 20px;
}

.Gridsocialsec ul li {
  list-style: none;
  margin: 7px 0;
  font-size: 14px;
  color: #000000;
}

.Gridsocialsec ul li svg {
  font-size: 14px;
  width: 20px;
  color: #329fff;
  padding-right: 5px;
}

.Gridsocialsec ul li img {
  width: 28px;
  margin-right: 5px;
}

.Activity ul {
  margin-top: 10px;
}

.Activity ul li svg {
  padding-right: 7px;
  color: #329fff;
  min-width: 18px;
}

.tabgrid ul li svg {
  font-size: 18px;
  vertical-align: middle;
  color: #8a8a8a;
  margin-right: 0;
  padding-right: 5px;
}

.tabgrid ul li img {
  padding-right: 0;
  width: 25px;
  margin-right: 5px;
  max-height: 25px;
}

.react-datepicker-popper {
  z-index: 9;
  padding-top: 10px;
  transform: translate(0px, -115px) !important;
}

.addChildProf .react-datepicker-popper {
  transform: initial !important;
  padding-bottom: 33px;
}

.react-datepicker__input-container {
  display: block !important;
}

.mobileDobWidth {
  width: 68% !important;
}

.react-datepicker-wrapper {
  display: flex !important;
}

.LoadMoreIntrstitem {
  cursor: pointer;
  color: #329fff;
}

.css-1pahdxg-control svg.css-tj5bde-Svg {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.css-yk16xz-control svg.css-tj5bde-Svg {
  transition: all 0.3s;
}

.SignupRegistration .react-datepicker-popper {
  transform: translate(0%, 64px) !important;
  z-index: 99;
}

.SignupRegistration .LeanerDtl .react-datepicker-popper {
  transform: translate(0%, -23px) !important;
}

h4.panel-title.heding-title.justify-content-between.p-2 {
  padding: 8px !important;
}

.intrestlisting {
  width: 83%;
  margin: auto;
}

.intrestlisting {
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.SearchnotFound {
  padding: 5px;
  background: #f3f3f3;
  color: #e36565;
  font-size: 13px;
}

.intrestAddhere {
  padding: 7px;
  text-align: center;
  background: #329fff;
  margin-top: 6px;
  color: #fff;
  cursor: pointer;
}

.intrestAddhere span {
  width: 17px;
  height: 17px;
  background: #329fff;
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  border-radius: 50%;
  border: 1px solid #ffff;
  margin-left: 4px;
  text-align: center;
  line-height: 12px;
}

.intresttile {
  margin-top: 20px;
  padding-top: 13px;
  border-top: 1px dashed #ccc;
}

.intresttile h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.additemicon {
  width: 30px;
  height: 30px;
  background: #7ead86;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  border-radius: 50%;
  padding: 0;
  line-height: 28px;
  color: #fff;
}

.cardDescription p {
  font-size: 16px;
  line-height: 1.4;
  color: #6c6c6c;
  overflow: hidden;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagslist {
  display: flex;
  border-top: 1px solid #e3e1e1;
  background: #f5f4f4;
  border-radius: 0px 0px 0px 25px;
}

.tagtitle {
  background: #f1ebeb;
  padding: 8px 12px;
  border-right: 1px solid #ccc;
  border-radius: 0px 0px 0px 25px;
}

.tagslist ul li {
  padding: 0 5px;
  cursor: pointer;
  color: #838282;
  display: inline-block;
  font-size: 14px;
}

.tagslist ul {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-left: 5px !important;
}

.startsEnrolling h5,
.StartComapring h5 {
  font-size: 18px;
  color: #303f9f;
  font-weight: 600;
  background: #eeeeee;
  padding: 7px 0;
  width: 100%;
  box-shadow: inset 0px 0px 12px 0px #c3c1c1;
  border-radius: 30px;
}

.startsEnrolling.pointer {
  width: 100%;
}

.tagtitle img,
.startsEnrolling h5 img,
.StartComapring h5 img {
  margin-right: 4px;
  width: 24px;
}

.instlistitem h4,
.probleumListitem h4 {
  display: flex;
  text-transform: capitalize;
  font-size: 14px;
  justify-content: space-between;
  color: #434343;
  line-height: 1;
  z-index: 1;
  position: relative;
}

.stuchalagelist {
  margin-top: 20px;
}

.probleumListitem.selectedProbleum {
  background: #329fff;
  border: 1px solid #329fff;
}

.probleumListitem.selectedProbleum h4 {
  color: #fff;
}

span.uncheckbox {
  width: 21px;
  height: 21px;
  border: 2px solid #808080;
  background: white;
  display: block;
  margin: auto;
  border-radius: 4px;
  box-sizing: border-box;
}

.ProbvidListItem span.rightcheck img {
  max-width: 25px;
}

.stuchalagelist h3 {
  font-size: 16px;
  color: #717171;
  font-weight: 400;
}

.stuchalagelist h3 span {
  font-weight: 600;
  color: #48cfae;
}

.probleumrelatedvideolist.w-100 {
  border: 1px solid #ccc;
}

.prolistedvidoitem {
  max-height: 397px;
  overflow: auto;
  padding: 20px;
}

.probleumrelatedvideolist.w-100 h4 {
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0px;
  width: 100%;
  color: #616161;
  background: #f1f1f1;
  padding: 5px 15px;
}

.input-group.full-width {
  width: 100%;
}

.Selvidoption {
  width: 15%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #e3e3e3;
}

.tabgrid:before {
  content: "";
  width: 100%;
  height: 1px;
  position: absolute;
  top: 35px;
  left: 0;
  border-bottom: 1px dashed #ccc;
  z-index: 0;
}

.hideIcon {
  display: none !important;
}

.showIcon {
  display: inline-block !important;
}

p:active .hideIcon {
  display: inline-block !important;
}

p:active .showIcon {
  display: none !important;
}

.rightIcon {
  display: none !important;
}

.circleIcon {
  display: inline-block !important;
}

.circlegreen {
  color: #48cfae !important;
}

.active .rightIcon {
  display: inline-block !important;
}

.active .circleIcon {
  display: none !important;
}

.landing-page .vickyLogo {
  padding: 5px 0px 5px 0px;
  min-height: 67px;
}

/* .topbarlandindwidth {
  max-width: 1370px;
} */

.menuList li {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  color: #808080;
  font-size: 16px;
}

.menuList li:last-child {
  border: 1px solid #cce5ff;
  background: #cce5ff;
  cursor: pointer;
  height: 40px;
}

.menuList li ul.dropdown-menu.show li:last-child {
  border: 0;
  background: #fff;
  height: auto;
  width: 100%;
}

.menuList li ul.dropdown-menu.show li:last-child:hover {
  background: #dcdfec;
}

.heading h2 img,
.learner_pLan_dropdown ul.dropdown-menu li svg,
.heading h3 svg,
.Enrollcoursetitle h3 span img {
  width: 26px;
  max-height: 26px;
}
.heading h2 img.report_Iocns{
  width: auto;
}
.learner_pLan_dropdown .Summary p img{
  max-width: 20px;
}
.carditem button {
  background: #fff;
  padding: 0;
}
.NuggetVickyImage .Pointcounting img {
  min-width: auto;
  max-width: 36px;
}

span#navbardrop {
  color: #1d2083;
  font-size: 14px;
  padding: 0;
  font-weight: bold;
  display: block;
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.panel-collapse .panel-body:nth-child(1) {
  margin-top: 0px;
}

.VKprofile .vkprofilename {
  font-size: 14px;
  color: #212529 !important;
  margin-right: 0;
  text-transform: capitalize;
}

.AlearnerProname h3,
.Menuprofile_name,
.MenuProfile_name {
  text-transform: capitalize !important;
}

.RightbarPannel .VKprofile .vkprofilename {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 111px;
  overflow: hidden;
  text-align: right;
}

.VKprofile .vkprofilename i {
  font-size: 16px;
  margin-right: 10px;
}

.profile_fild .form-control,
.profile_fild .react-datepicker__input-container input {
  line-height: 1;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 0px !important;
  padding: 10px;
}

.input-group:hover .UserEditForm {
  display: block;
}

.intlikes {
  color: #329fff;
  font-weight: 400;
  position: absolute;
  top: 6px;
  display: flex;
  right: 6px;
  padding: 0px;
}

.VKprofile .vkprofilename a {
  color: #6a6a6a;
  text-transform: capitalize;
}

.menuList li i {
  font-size: 20px;
  color: #000;
}

.forgetpassword {
  width: 100%;
  padding-top: 31px;
}

.forgetpassword h2 {
  font-size: 21px;
  margin-bottom: 30px;
  font-weight: 600;
}

.forgetpassword button.btn-blue.btn-login.d-block.mb-5 {
  font-size: 14px;
  text-transform: capitalize;
  border: 0;
  padding: 6px 17px !important;
  height: 35px;
  border-radius: 6px !important;
  margin: 0 5px;
}

.form-group.BDsubmitbutton.justify-content-center.d-flex {
  justify-content: left !important;
  margin-top: 0;
}

.pt-4.childdetailtitle {
  margin-left: -22px;
}

.forgot_password {
  color: #006dcc;
  font-size: 14px;
  font-family: "Figtree", sans-serif;
}

.forgot-pass a:hover {
  text-decoration: underline;
}

.panel-body a svg,
.panel-body p svg.svg-inline--fa.fa-minus-circle.circleIcon,
.Accountrelated
  .PasswordIntruction
  ul.list-group
  li.list-group-item.bg-light
  .circleIcon {
  font-size: 15px;
  color: #ccc;
  vertical-align: middle;
  padding-right: 10px;
}

.SignupPopup .PasswordIntruction ul li .circleIcon {
  color: #ccc;
}

.Accountrelated
  .PasswordIntruction
  ul.list-group
  li.list-group-item.bg-light
  .circleIcon {
  margin: 0;
}

p.pb-4.mb-0.forgot-pass.flex {
  padding: 11px 0px 0;
}

.panel-body a svg.svg-inline--fa.fa-caret-right.rightIcon {
  color: #000;
  height: 15px;
  width: 20px;
}

.input-group.photoUploded.p-0.border-0 .form-group::before {
  content: "Upload Video";
  display: inline-block;
  background: transparent;
  border-radius: 0px;
  width: 26%;
  padding: 8px 8px;
  border: 1px solid #1ec1d9;
  color: #ffffff;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: none;
  position: absolute;
  background: #1ec1d9;
  font-size: 14px;
}

.border-0 {
  border: 0;
}

.photoUploded img {
  max-width: 100px;
}

.photoUploded input[type="file"] {
  background: none;
  color: #1ec1d9 !important;
}
input[type="file"]::file-selector-button {
  border: 0;
  border-radius: 0.2em;
  background-color: #ccc;
  height: 42px;
  color: #fff;
  padding: 6px 0px !important;
  transition: 1s;
  width: 0%;
}

.form-control.uploadphoto.form-input.field-answers.font-weight-lighter {
  width: 229px;
  padding-left: 10px;
}

input[type="file"]::file-selector-button:hover {
  background-color: #81ecec;
  border: 2px solid #00cec9;
}

.uploaded_photo {
  width: 48%;
  text-align: center;
}

.uploadphoto {
  padding: 0;
}

.form-title {
  width: 100%;
  margin: 0px 0 15px;
  margin-top: 0px;
}
.SignupPopup .form-title {
  margin: 0px 0 0px;
}
.margintitle {
  margin-top: 15px;
}

.RemovePhoto {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
}

.basicdetailsform .selectedField:before {
  top: 0;
}

.basicdetailsform .selectedField::after {
  top: 7px;
}

.Editprofilepic input {
  width: 100%;
}

.searchVideoList .videoDescription {
  padding-right: 20px;
  width: calc(100% - 185px);
}

.form-title h3 {
  font-size: 18px;
  font-weight: 600;
  display: flex;
  border-bottom: 1px dashed #ccc;
  align-items: center;
  justify-content: left;
  color: #3a3a3a;
  margin-top: 20px;
  /* font-family: BicycletteLight !important; */
  background: #fff;
  padding: 10px 0 !important;
}

.form-group.currentlyWorking {
  display: flex;
  align-items: center;
  margin-top: 27px;
}

.form-group.currentlyWorking label {
  padding-left: 5px;
}

.PannelContent.basicdetailsform.px-5 .signupType {
  padding-left: 35%;
  margin-bottom: 0px;
  margin-top: 10px;
}

.form-control.addTags {
  padding: 10px 10px;
  height: auto;
}

.tagaddedlist li {
  padding: 1px 5px;
  border: 1px solid #ccc;
  display: inline-block;
  text-transform: capitalize;
  color: #000;
  font-size: 14px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 18px;
  background: #e3e3e3;
}

.tagaddedlist ul {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.tagaddedlist li span {
  margin-left: 9px;
  color: #00cec9;
  font-size: 16px;
  vertical-align: middle;
  border-radius: 50%;
  cursor: pointer;
}

.tagaddedlist li span svg {
  margin-right: 0;
}

.form-control.addTags input.text-rows {
  width: 100%;
  border: 0;
  margin: 10px 0;
  outline: 0;
  background: transparent;
  padding: 0;
}

.videoDescription {
  padding-right: 20px;
}

.videoDescription.ml-3 .closeicon {
  top: 0;
  right: 0;
  font-size: 16px;
  transform: rotate(90deg);
  cursor: pointer;
}

.Selvidoption li {
  cursor: pointer;
}

span.deletelink:hover {
  color: red;
}

span.deletelink {
  display: block;
  color: #adadad;
  font-size: 16px;
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 10px;
}

.Selvidoption li strong svg {
  color: #48cfae;
  font-size: 20px;
  padding-left: 5px;
}

.videoDescription.ml-3 ul,
.Selvidoption ul {
  background: #fefefe;
  padding: 8px 15px !important;
  text-align: center;
  min-width: 80px;
  width: 127px;
  box-shadow: 0 0 4px #dfdfdf;
}

.Activity .dropdownlistmodl.show ul li,
.intrestlisting.dimpagetags ul li,
.learner_pLan_dropdown  ul.dropdown-menu  li ,
.coursecardnav.dropdownlistmodl ul li ul.dropdown-menu.dropdown-menu-end li {
  display: block;
  margin: 0px 0px !important;
  text-align: left;
  padding: 10px 10px;
  border-bottom: 1px dashed #ccc;
  cursor: pointer;
  color: #747474;
  width: 100%;
  display: flex;
  align-items: center;
}

.Activity .dropdownlistmodl.show ul li:last-child {
  border-bottom: 0;
}

.Activity .dropdownlistmodl.show ul li svg {
  color: #747474;
}

.dropdownlistmodl .dropdown-menu::after,
.SlecteSub.dropdown-menu::after,
.learner_pLan_dropdown  ul.lPDropdwnList::after ,
.intrestlisting.dimpagetags .dropdown-menu::after,
.ListIcon ul::after,
.GridCardTitleDesc .ListIcon ul::after {
  display: none;
}

.dropdownlistmodl ul.dropdown-menu.dropdown-menu-end {
  min-width: 0;
  width: 180px;
  text-align: left;
  padding: 5px !important;
}

.ActivityTitle .videoDescription ul,
.GridCardTitleDesc .ListIcon ul,
.courseInformation .heading h2 ul,
.courseInformation .ListIcon ul,
.menuList li ul.dropdown-menu,
.learner_pLan_dropdown  ul.lPDropdwnList,
.intrestlisting.dimpagetags ul.dropdown-menu,
.insceptionFilter .dropdown-menu,
.ProgressDetails ul {
  width: 210px;
  min-width: 0;
  padding: 5px !important;
  max-height: 315px;
}

.ActivityTitle .videoDescription ul li,
.GridCardTitleDesc .ListIcon ul li .courseInformation .heading h2 ul li,
.menuList li ul.dropdown-menu li,
.ListIcon ul li,
.ProgressDetails ul li,
.intrestlisting.dimpagetags ul.dropdown-menu li,
.insceptionFilter .dropdown-menu ul li {
  color: #747474;
  border-bottom: 1px dashed #ccc;
  cursor: pointer;
  padding: 10px 10px;
  display: flex;
  align-items: center;
}
.insceptionFilter .dropdown-menu ul li {
  width: 100%;
  border-right: none;
}

.courseInformation .heading h2 ul li span.SubFName {
  font-size: 10px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #329fff;
  margin-right: 5px;
  text-align: center;
  line-height: 16px;
  color: #747474;
  border-radius: 50%;
}

.ListIcon ul li svg {
  font-size: 16px;
  width: 24px;
  margin-right: 5px !important;
}

.input-group.submitgrade button {
  width: auto;
  padding: 8px 12px;
}

.videoDescription ul li:hover,
.Activity .dropdownlistmodl.show ul li:hover,
.courseInformation .heading h2 ul li:hover,
li ul.dropdown-menu,
.menuList li ul.dropdown-menu li:hover,
.intrestlisting.dimpagetags ul.dropdown-menu li:hover,
.coursecardnav.dropdownlistmodl
  ul
  li
  ul.dropdown-menu.dropdown-menu-end
  li:hover,
.ListIcon ul li:hover,
.insceptionFilter .dropdown-menu ul li:hover,
.learner_pLan_dropdown ul.dropdown-menu li:hover,
.ProgressDetails ul li:hover {
  background: #dcdfec;
  padding: 10px 15px;
  transition: all 0.3s;
}

.insceptionFilter .dropdown-menu ul li:hover {
  padding: 10px 15px;
}

.ActivityTitle .videoDescription ul li:last-child,
.insceptionFilter ul.dropdown-menu li:last-child,
.courseInformation .heading h2 ul li:last-child,
.ListIcon ul li:last-child,
.menuList li .dropdown-menu li:last-child,
.ProgressDetails ul li:last-child {
  border: 0;
}

.ProgressDetails ul li.SubjActive {
  background: #dcdfec;
}

.videoDescription.ml-3 ul li strong {
  display: inline;
  vertical-align: middle;
  padding: 0px 4.5px 1px;
  color: #fff;
  margin-left: 5px;
  background: #48cfae;
  border-radius: 50%;
}

.VideoThumbimg {
  width: 145px;
}

.AViewListitem .VideoThumbimg {
  width: 190px;
  object-fit: cover;
}

.VideoThumbimg img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

.videoDescription {
  padding-right: 20px;
  width: calc(100% - 293px);
}

.halfPagePOpup .videoActivityPopup .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
}

.ratepopupp .RatingPopupdiv.dropdown-menu.d-block {
  padding: 20px;
  box-shadow: 2px 2px 6px #a0a0a0;
  top: 24px;
  left: -22px;
  /* right: -22px; */
  font-weight: 600;
  text-transform: initial;
}

.resourseCatdTitle .ratepopupp .RatingPopupdiv.dropdown-menu.d-block {
  left: auto;
  right: 22px;
}

.GridCardTitleDesc .ratepopupp .RatingPopupdiv.dropdown-menu.d-block {
  left: auto;
  top: 0;
}

.GridCardTitleDesc .RatingPopupdiv.dropdown-menu::after {
  right: 4%;
}

.Gridsocialsec .RatingPopupdiv {
  transform: none !important;
}

.bannerright video {
  width: 100%;
  height: 344px;
  object-fit: cover;
}

.Gridcard .Gridsocialsec svg.star-svg {
  margin-right: 0;
  /* margin-top: -5px; */
}

.halfPagePOpup .videoActivityPopup .modal-dialog {
  max-width: 730px;
}

.halfPagePOpup .videoActivityPopup .modal-content {
  height: 470px;
}

.modal-body.RatetousPopup.videoActivityPopup {
  padding: 10px 15px 20px;
}

.AddedvideoList .searchVideoList.mt-3 {
  margin-top: 26px !important;
}

.AddedvideoList .dropdown-menu::after,
.form_left .dropdown-menu::after,
.ActivityTitle .dropdown-menu::after,
.menuList li ul.dropdown-menu::after,
.insceptionFilter .dropdown-menu::after,
.allYtypeactivityList .addActivity.CatItemList::after,
.courseInformation .heading h2 ul.dropdown-menu::after,
ul.dropdown-menu.dropdown-menu-end.enrollassetsval::after {
  display: none;
}

.searchInputs input.form-control.is-invalid,
.searchInputs input.form-control:disabled[name="address1"] {
  padding: 0 10px;
}

.AddedvideoList .videoDescription.ml-3 ul li:hover {
  color: #bd2222;
}

.videoDescription.ml-3 ul li {
  color: #ccc;
  cursor: pointer;
}

.input-group.AttributrTitle .form-control.is-invalid {
  background: #fff;
}

.sweet-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #0000008f;
}

.heding-title span svg.svg-inline--fa.fa-circle-check.circlegreen {
  font-size: 16px;
}

.navSidebar .top-section svg.svg-inline--fa.fa-bars {
  font-size: 24px;
  color: #fff;
}

.vkprofilename svg.svg-inline--fa.fa-user {
  padding-right: 5px;
}
/* 
.gridSection {
  padding: 10px;
} */

.tabgrid {
  margin-bottom: 10px;
  position: relative;
  min-height: 36px;
  background:#f5faff;
}

.basicdetailsform .tabgrid.Educationtab {
  margin-top: 20px;
}

.Gridcard.subjectCard .Activity {
  width: calc(33.33% - 10px);
}

.tabgrid ul {
  padding-left: 0 !important;
  position: relative;
  display: flex;
  align-items:center;
}

.tabgrid ul li {
  display:flex;
  align-items: center;
  width: 20%;
  text-align: left;
  border-right: 1px solid #cccccc;
  font-size: 17px;
  font-weight: 600;
  min-height: 37px;
  max-height: 36px;
  border-bottom: 3px solid transparent;
  color: #616161;
  padding: 5px 10px;
  cursor: pointer;
  font-family: "Poppins", sans-serif !important;
}
.schoolstutabingthree.tabgrid ul li {
  width: 33.33%;
}
.schoolstutabingFour.tabgrid ul li {
  width: 25%;
}
.tabgrid ul li button {
  background: none;
}
.tabgrid.shorting_courses ul li {
  padding: 9px 10px 6px;
  border-bottom: 3px solid transparent;
}
.tabgrid ul li::before {
  content: "";
}
.gridSection .tabgrid ul li:nth-child(5):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(5):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(5).active-tabs,
.gridSection .tabgrid ul li:nth-child(5).active-tabs {
    border-bottom: 3px solid #1ec1d9 !important;
} 

.gridSection .tabgrid ul li:nth-child(4):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(4):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(4).active-tabs,
.gridSection .tabgrid ul li:nth-child(4).active-tabs {
  border-bottom: 3px solid #a86adb !important;  
}

.gridSection .tabgrid ul li:nth-child(1):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(1):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(1).active-tabs,
.gridSection .tabgrid ul li:nth-child(1).active-tabs {
  border-bottom: 3px solid #ff9900 !important;
}

.gridSection .tabgrid ul li:nth-child(2):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(2):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(2).active-tabs,
.gridSection .tabgrid ul li:nth-child(2).active-tabs {
  border-bottom: 3px solid #e46666 !important;
}

.gridSection .tabgrid ul li:nth-child(3):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(3):hover,
.Home_two.gridSection .tabgrid ul li:nth-child(3).active-tabs,
.gridSection .tabgrid ul li:nth-child(3).active-tabs {
  border-bottom: 3px solid #85b812 !important;  
}

.tabgrid ul li:nth-child(1).active-tabs,
.tabgrid ul li:nth-child(1):hover,
.tabgrid ul li:nth-child(2).active-tabs,
.tabgrid ul li:nth-child(2):hover {
  border-bottom: 3px solid #1ec1d9;
  background: #f5faff;
}

.tabgrid.AddActivityTab ul li:nth-child(1):hover,
.tabgrid.AddActivityTab ul li:nth-child(1).active-tabs,
.tabgrid ul li:nth-child(3):hover,
.tabgrid ul li:nth-child(3).active-tabs,
.tabgrid ul li:nth-child(4):hover,
.tabgrid ul li:nth-child(4).active-tabs {
  border-bottom: 3px solid #1ec1d9;
  background: #f5faff;
}

.tabgrid ul li svg.fa-play {
  font-size: 18px;
  color: #404040;
}

.tabgrid ul li:last-child {
border-right: 0;
}

.tabgrid ul li a {
}

.VKprofile {
  display: flex;
  align-items: center;
  justify-content: end;
}

.ShowProgres {
  margin-right: 20px;
  font-size: 14px;
  font-weight: 600;
}

.tabgrid ul li i {
  padding-right: 10px;
  font-size: 24px;
}

.SubjTitle h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.SubjTitle p {
  font-size: 14px;
  color: #737373;
  font-weight: 600;
  margin-bottom: 12px;
}

.SubDescription p {
  font-size: 14px;
  color: #686868;
}

.headfilters {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.headfilters label.Selcheckbox {
  margin-bottom: 0;
  font-size: 16px;
  margin-right: 19px;
}

.headfilters span {
  font-size: 16px;
  padding-right: 17px;
}

.Gridimage {
  width: 160px;
  height: 160px;
  border: 0;
  border-radius: 12px;
}

.AllCoursesDimensionlist .Gridimage {
  width: 115px;
  height: 115px;
}

.Gridimage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.courseCardImg{
  width: 150px;
  height: 150px;
  border: 0;
  border-radius: 12px;
}
.shorSchedule p img,
.shorSchedule p svg {
  width: 16px;
  margin-right: 10px;
  font-size: 16px;
  color: #5b5b5b;
}

.Gridsocialsec {
  background: #ededed;
  padding: 10px;
  display: flex;
  width: 180px;
  flex-wrap: wrap;
  align-content: space-between;
}

.AllCoursesDimensionlist .Gridsocialsec {
  width: 135px;
}

/* .GridCardTitle h3 span {
  font-size: 13px;
  font-weight: normal;
  color: #000;
  font-weight: 600;
  z-index: 11;
} */

.GridCardTitle h3 img {
  max-width: 28px;
  margin-left: -7px;
  margin-top: 0px;
  cursor: auto;
}

span.gradeiconimage {
  font-size: 14px;
}

.GridCardTitle h3 span.PCtotlePoints {
  z-index: 11;
  position: relative;
}

.SocialCoursecardWrap h4.KATitle svg.fa-pencil {
  font-size: 16px;
  color: #6c6c6c;
}

.Gridcard {
  display: flex;
  margin-top: 10px;
  border: 1px solid #9c9a9a;
}

.GridDetails {
  width: calc(100% - 180px);
  padding: 0px 0px 0px 0px;
}

.AllCoursesDimensionlist .GridDetails {
  width: calc(100% - 135px);
}

/* .GridCardTitle h3 span svg {
  padding-right: 10px;
  color: #8a7b7b;
} */
.cardRegardInfo {
  padding: 7px 15px;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.cardRegardInfo .form-group.BDsubmitbutton.d-flex {
  margin-top: 0;
  flex-direction: row-reverse;
  width: auto;
  align-content: center !important;
}

.GridCardTitle .cardRegardInfo p {
  padding: 7px 0;
  font-size: 15px;
  color: #000;
}

.cardRegardInfo .form-group.BDsubmitbutton span {
  padding: 7px 10px;
  font-size: 14px;
  color: #000;
  font-weight: 600;
}

.halfPagePOpup .modal-header svg.svg-inline--fa.fa-circle-chevron-down {
  color: #7d7d7d;
}

.GridCardTitle h3 {
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  background: #ededed;
  cursor: auto;
  color: #006dcc;
  font-weight: 600;
  padding: 7px 15px;
  width: 100%;
  align-items: center;
}

.GridCardFullDetails {
  padding: 5px 0px 0px;
}

.ProCoursecard .GridCardFullDetails {
  padding: 10px 15px 10px;
  border-bottom: 1px solid #ccc;
}

.AddCourseformWrap .Gridsocialsec .RatingPopupdiv,
.ProCoursecard .Gridsocialsec .RatingPopupdiv {
  left: 0;
  padding: 20px;
}

.CourseProvider.mt-3 span {
  font-size: 14px;
}

/* .TeacherratingCount {
  margin-top: -5px;
} */
.ProblmsoladdAct svg {
  font-size: 18px;
  color: #48cfae;
  padding-left: 5px;
}

.loadmoreActivity svg,
.ProblmsoladdAct svg {
  font-size: 13px;
  color: #1675e0;
  padding-left: 5px;
}

.ProblmsoladdAct {
  font-size: 14px;
  color: #616161;
}

.addActivity svg.svg-inline--fa.fa-circle-plus {
  font-size: 21px;
  color: #48cfae;
  padding-right: 5px;
}

/* .GridCardTitle h3 span svg {
  padding-right: 10px;
  color: #8a7b7b;
} */
.CourseProvider {
  padding: 0 15px;
  font-size: 14px;
}

.CourseProvider svg {
  font-size: 16px;
  color: #329fff;
  padding-right: 5px;
}

.CourseProvider p {
  padding-bottom: 0;
  font-size: 14px;
}

.ActivityTitle {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

html {
  scroll-behavior: smooth;
}

.ActivityTitle h4,
.addActivity h4 {
  font-size: 16px;

  color: #5e5e5e;
  padding-right: 0;
}

.PannelContent .AddCourseformWrap .Activity h3 {
  padding-bottom: 0;
}

.AddCourseformWrap .ActivitycrdTitle {
  width: auto;
}

.AddCourseformWrap .addActivity h4 svg {
  padding-right: 0 !important;
}

.instlistitem h4 .fa-circle-plus {
  font-size: 18px;
  color: #48cfae;
  background: #fff;
  border-radius: 50%;
}

.instlistitem h4 .svg-inline--fa.fa-heart {
  color: #329fff;
  display: none;
  font-size: 16px;
}

.HurtCount {
  font-size: 12px;
  color: #329fff;
  font-weight: 400;
}

.loadmoreActivity {
  font-size: 14px;
  color: #006dcc;
  text-align: right;
  width: 130px;
  flex-grow: 1;
  cursor: pointer;
  display: flex;
  justify-content: right;
  align-items: center;
}

.loadmoreAddActivity {
  margin-top: -5px;
}

.loadmoreActivity.AddoreCol {
  position: absolute;
  left: -29px;
  top: 0px;
  display: block;
  text-align: center;
  color: #48cfae;
  width: 63px;
  line-height: 41px;
  padding-left: 6px;
  height: 41px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: -3px 0px 0px #a0a0a0;
}

.loadmoreActivity span {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1px solid;
  text-align: center;
  font-size: 17px;
  line-height: 17px;
  border-radius: 50%;
  margin-left: 5px;
}

.basicdetailsform .bodyimagetitle.flex svg,
.CourseardDesc .svg-inline--fa.fa-file-lines {
  font-size: 20px;
}

.Selectcalenderyr .form-group.selectedField {
  width: calc(50% - 10px);
}

.Selectcalenderyr {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.gridActivity {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 10px;
  border-top: 1px dashed #9d9d9d;
}

.Activity {
  width: calc(50% - 5px);
  display: flex;
  margin-bottom: 5px;
  align-items: baseline;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding: 0px 5px;
  border: 1px solid #9c9a9a;
  font-size: 14px;
}

.GridTeacher svg.fa-bullseye-arrow {
  font-size: 18px;
  color: #747474;
}

.ActivitycrdTitle {
  font-size: 15px;
}

.ActivitycrdTitle a {
  font-size: 14px;
  font-weight: 600;
}

.activityCap h3 {
  text-transform: none;
}

.Activity ul li {
  display: inline-block;
  margin-left: 15px;
}

.Activity ul {
  width: 100%;
  text-align: right;
}

.ActivityList,
.ActivitycrdTitle {
  width: calc(100%);
  background: transparent !important;
}

.Activity.AssessmentCards .ActivitycrdTitle {
  width: auto;
}

.Gridsocialsec ul {
  padding-left: 0 !important;
  padding-top: 0;
  padding-bottom: 0px;
}

.Gridsocialsec ul li {
  list-style: none;
  margin: 5px 0 0px;
  font-size: 14px;
  color: #404040;
  cursor: pointer;
}

.SocialGridcard .GridTeacher .TeacherActivityimg img {
  padding: 0;
}

.shorSchedule {
  padding: 0 5px 10px;
}

.Gridsocialsec ul li svg {
  font-size: 16px;
  width: 15px;
  color: #5b5b5b;
  padding-left: 5px;
}

.Gridsocialsec ul li svg.svg-inline--fa.fa-calendar-days {
  font-size: 16px;
}

.publicprivateToggle {
  width: 48%;
}

.priceWrap {
  padding: 10px 20px 0 20px;
  width: 100%;
  border-radius: 4px;
}

.priceWrap h3 {
  font-size: 18px;
  text-align: center;
  color: #424242;
  font-weight: 600;
  margin-bottom: 0;
  padding-bottom: 3px;
}

.priceWrap button {
  margin-top: 10px;
  padding: 9px 10px;
  font-size: 14px;
}

.priceWrap h3 span {
  font-size: 12px;
}

.AddActivity .Activity {
  margin-bottom: 0;
  border-color: #8577ff;
}

.headfilters .checkmark {
  height: 20px;
  width: 20px;
}

.headfilters .Selcheckbox .checkmark:after {
  left: 7px;
  top: 4px;
  width: 6px;
  height: 11px;
}

.activityListitems {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.GridCardTitle p {
  font-size: 16px;
  padding-bottom: 10px;
  color: #232323;
}

.classdetailsDesc {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}

.infolist ul {
  padding-left: 0 !important;
}

.infolist ul li {
  list-style: none;
  font-size: 14px;
  margin: 0px 0 9px;
}

.infolist ul li:last-child {
  margin-bottom: 0;
}

.infolist ul li svg {
  color: #454545;
  margin-right: 10px;
}

.PerclassCharge span {
  font-size: 16px;
  font-weight: 600;
  vertical-align: bottom;
  margin-right: 10px;
}

.PerclassCharge button {
  width: 120px;
  border: 0;
  outline: none;
  border-radius: 4px;
  color: #fff;
  background: #00aaff;
  padding: 8px 7px;
  font-size: 14px;
}

.ActivityTeacherList {
  display: flex;
  width: 100%;
}

#Teacherdetailpage .MenuProfile_img {
  width: 120px;
  display: flex;
  margin-right: 0px;
  height: 120px;
  font-size: 40px;
  color: #70777a;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #bfbfbf;
  font-weight: 600;
}

.MenuProfile_img img {
  border-radius: 50%;
}

.listMedia {
  display: flex;
  align-items: center;
  background: #292f4c;
}

.MenuProfile_name h4 {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.MenuProfile_name p {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  margin-top: 0px;
}

.dropdown- {
  display: flex;
  align-items: center;
  width: 100%;
  color: #329fff;
  font-size: 16px;
  background: #f9f9f9;
  min-height: 50px;
  padding: 10px;
  border-bottom: 1px solid #ededed;
}

.ActivityCategory h4 {
  font-size: 15px;
  color: #000;
  font-weight: 600;
  padding: 10px;
  border-bottom: 1px dashed #ccc;
  text-align: left;
  align-items: center;
  display: block !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 185px;
  cursor: auto;
}

.insceptionFilter p {
  font-size: 14px;
  font-weight: 600;
  color: #212529 !important;
}

.CatItemList li,
.breadCrum .CatItemList li {
  font-size: 14px;
  display: flex;
  color: #505050;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  border-bottom: 1px dashed #ccc;
  position: relative;
  cursor: pointer;
}

.breadCrum.breadsticky.providerBreadcrumb {
  top: 48px;
  border-top: 1px solid #e2e2e2;
}

.breadCrum .insceptionFilter .CatItemList li {
  justify-content: left;
  font-weight: 400;
  padding-left: 19px;
}

.breadCrum .insceptionFilter .CatItemList li svg {
  position: absolute;
  left: 0;
  color: #006dcc;
  top: 16px;
}

.breadCrum .VKprofile .insceptionFilter .CatItemList li svg {
  top: 10px;
}

.insceptionFilter .CatItemList li:last-child {
  border-bottom: 0;
}

.ActivityCategory h4 img {
  padding-right: 5px;
  vertical-align: inherit;
  height: 18px;
}

.SocialCoursecardWrap h4.flex.KATitle.skillandcours.p-0.border-0 {
  color: #5e5e5e;
  cursor: auto;
  font-size: 17px;
  font-weight: 600;
}

.totleCourse svg.svg-inline--fa.fa-dash {
  font-size: 8px;
}

.buttonDistribotion svg {
  margin-right: 6px;
}

.ActivitycrdTitle img {
  margin-right: 4px;
  width: 20px;
}
.PMSkillList .ActivitycrdTitle img {
  margin-right: 6px;
  width: 20px;
}
.Addschoolcard {
  justify-content: right;
}

.categorryName {
  position: absolute;
  left: 200px;
  width: 205px;
  height: 150px;
  overflow: auto;
  top: -6px;
  display: none;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0 0 10px #ccc;
}

.ActivityCategory {
  width: 203px;
  top: 4px;
  z-index: 99;
  background: #fff;
  box-shadow: 0 0 10px #ccc;
}

.allYtypeactivityList {
  font-size: 14px;
  cursor: pointer;
  color: #006dcc;
  position: relative;
  transform: none !important;
}

.allYtypeactivityList svg {
  color: #48cfae;
  font-size: 18px;
}

.allYtypeactivityList .addActivity.CatItemList {
  border: 0;
  background: none;
}

.allYtypeactivityList .addActivity.CatItemList ul {
  padding: 0 !important;
}

.AddActivity:hover .ActivityCategory {
  display: block;
}

.CatItemList li:hover {
  background: whitesmoke;
}

.CatItemList li:hover .categorryName {
  display: block;
}

.AddActivity span,
.CatItemList span {
  font-size: 15px;
  border: 1px solid #329fff;
  line-height: 1;
  display: inline-block;
  border-radius: 50%;
  color: #329fff;
  padding: 0px;
  margin-left: 5px;
  cursor: pointer;
  width: 19px;
  text-align: center;
  height: 19px;
}

.CatItemList span {
  color: #fff;
  background: #48cfae;
  border-color: #48cfae;
}

a.dropdown-.ProfileDetails:hover {
  background: #292f4c;
}

a.dropdown-.ProfileDetails {
  background: #292f4c;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.MenuEdit_profile {
  color: #ffc107 !important;
}

.ProfileChild {
  width: 40px;
  height: 40px;
  display: inline-block;
  color: #fff;
  font-weight: 600;
  text-align: center;
  display: flex;
  margin-right: 10px;
  font-size: 16px;
  align-items: center;
  justify-content: center;
  letter-spacing: -1px;
  border: 1px solid #ccc;
  border-radius: 50%;
  background: #1675e0;
}

.ProfileChild svg.svg-inline--fa.fa-bullseye-arrow {
  font-size: 20px;
}

.ChatName label.Selcheckbox.pl-0 {
  padding-right: 0;
}

.lowerProfile .MenuEdit_profile {
  color: #28a745 !important;
}

.dropdown- svg {
  margin-right: 8px;
  font-size: 20px;
  width: 30px;
  text-align: center;
}

span.Menuprofile_name {
  margin-right: auto;
}

span.UserEditForm {
  cursor: pointer;
  color: #ccc;
  position: absolute;
  top: 36px;
  right: 6px;
  font-size: 14px;
}
.billingAddress span.UserEditForm {
  padding: 7px 10px;
  top: 38px;
  right: 0px;
  z-index: 9;
}
.billingAddress .cardnumberedit span.UserEditForm {
  top: 56px;
}
.basicdetailsform svg {
  margin-right: 6px;
  font-size: 16px;
}

.ScenecerelateddQuiz h4 svg {
  font-size: 20px;
  color: #282529;
  vertical-align: middle;
}

.form-title.margintitle.w-100 span.checkmark {
  border-radius: 0;
}

.basicdetailsform span.UserEditForm svg {
  margin-top: 10px;
}

.navSidebar.inactive li span {
  display: none;
}

.flipIcon:hover {
  background: #355bb7;
}

div#Experince_form .Activityined > div textarea {
  height: calc(1.5em + 0.75rem + 12px);
}

svg.svg-inline--fa.fa-magnifying-glass {
  position: absolute;
  top: 48px;
  right: 12px;
  font-size: 14px !important;
  z-index: 1;
  color: #d8d8d8;
}

.placeholderseach svg.svg-inline--fa.fa-magnifying-glass,
.placeholderseach span.closeicon {
  top: 12px;
}

.searchInputs input.form-control {
  padding: 0 33px 0 10px;
  color: #d8d8d8;
  font-size: 14px !important;
}

div#Educations
  .BDsubmitbutton
  .buttonDistribotion
  button.btn-blue.btn-login.d-block.mb-5 {
  margin-left: 0;
}

.input-group.teachergroup > div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-group.teachergroup > div .form-group {
  display: block;
  width: 48%;
  margin-bottom: 20px;
}

.teachergroup .bg-white.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.basic_details_form .form-group.BDsubmitbutton.d-flex {
  width: 100%;
  margin-left: auto;
}

div#intrest_form .input-group input#name {
  max-width: 100%;
}

.choseintrest span {
  position: absolute;
  margin: auto;
  background: #fff;
  top: -12px;
  padding: 0 10px;
  font-size: 16px;
  left: 50%;
  transform: translate(-50%);
  color: #000000;
  font-weight: bold;
  text-transform: capitalize;
}

.homepageimage {
  min-width: 30%;
}

.heding-title[aria-expanded="false"] .fa-chevron-down,
.EnrollCourseAccord[aria-expanded="false"] .fa-chevron-down {
  transform: rotate(0deg);
  transition: 0.3s all;
}

.heding-title[aria-expanded="true"] .fa-chevron-down,
.EnrollCourseAccord[aria-expanded="false"] .fa-chevron-down {
  transform: rotate(180deg);
  transition: 0.3s all;
}

.genralstate .fa-chevron-down {
  transform: rotate(180deg) !important;
  transition: 0.3s all;
}

h4.panel-title.heding-title.justify-content-between.p-2.genralstate.collapsed
  .fa-chevron-down {
  transform: rotate(0deg) !important;
  transition: 0.3s all;
}

.selectedField:after {
  position: absolute;
  right: 10px;
  width: 10px;
  height: 10px;
  pointer-events: none;
  border: 1px solid #8b8b8b;
  border-bottom: 0;
  border-right: 0;
  content: "";
  transform: rotate(-135deg);
  top: 29px;
  transition: all 0.3s;
  z-index: 9;
}

.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--scroll {
  width: 100px;
  width: 80px;
  background: #fff;
  text-align: left;
  padding: 3px 10px;
}

.login-right .selectedField:after,
.basicdetailsform .selectedField::after {
  position: absolute;
  right: 12px;
  top: 11px;
}

.basicdetailsform .form-group.selectedField.collapsed::after {
  transform: rotate(45deg);
  top: 17px;
  transition: all 0.3s;
}

.selectedField .collapse:not(.show) {
  display: block !important;
}

.form-group.selectedField.accordion-button {
  flex-wrap: wrap !important;
  padding: 0;
  background: none;
  box-shadow: none;
}

span.react-datepicker__month-read-view--selected-month {
  width: 100px;
}

.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
  width: 80px;
  background: #fff;
  text-align: left;
  padding: 3px 10px;
}

.selectedField.selectedfield_icon:after {
}

.react-datepicker__month-dropdown-container.react-datepicker__month-dropdown-container--scroll {
  width: 100px;
  width: 80px;
  background: #fff;
  text-align: left;
  padding: 3px 10px;
}

span.react-datepicker__month-read-view--selected-month {
  width: 100px;
}

.react-datepicker__year-dropdown-container.react-datepicker__year-dropdown-container--scroll {
  width: 80px;
  background: #fff;
  text-align: left;
  padding: 3px 10px;
}

.selectedField.selectecfield:after {
  transform: rotate(45deg);
  top: 12px;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  transform: rotate(135deg);
  right: 0 !important;
  top: 2px !important;
}

.datespl .HalfWidth-group {
  margin-top: -18px;
}

button.btn-blue.btn-login.d-block.mb-5.cancelbutton:hover {
  background: #51575c;
}

div#School_information label.Selcheckbox {
  font-size: 14px;
  padding-left: 28px;
}

div#School_information label.Selcheckbox span.checkmark {
  height: 20px;
  width: 20px;
}

div#School_information .Selcheckbox .checkmark:after {
  left: 6px;
  top: 3px;
  width: 7px;
  height: 12px;
}

.FieldinfoCard {
  box-shadow: 2px 2px 4px #d9d5d5;
  width: 100%;
  padding: 0px 0 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 21px;
  background: #ffffff;
}

.backicon {
  right: 48px;
  z-index: 100;
  background: #0f1a34;
  color: #fff;
  position: fixed;
  cursor: pointer;
  padding: 1px 8px;
  top: 46px;
  border-radius: 0 0 10px 10px;
  display: none;
  z-index: 99991;
}

.Profile_details {
  margin-bottom: 0;
}

.Profile_details {
  width: calc(100% - 150px);
  margin-top: -15px;
  padding-left: 20px;
}

.backicon:hover {
  background: #355bb7;
}

.fcardTitle .headingTitle {
  margin-bottom: 5px;
  border-bottom: 1px solid #b1b1b1;
  display: flex;
  font-size: 16px;
  align-items: center;
  padding: 5px 10px 5px 10px;
  background: #efefef;
}

.fcardTitle .headingTitle h4 {
  font-size: 16px;
  color: #212121;
  font-weight: bold;
  text-transform: capitalize;
  line-height: 18px;
  align-items: start;
}

.fcardTitle .headingTitle p {
  color: #212121;
  font-size: 14px;
}

.fcardTitle .headingTitle h5 {
  color: #828181;
  font-size: 14px;
}

.fcardTitle .headingTitle img {
  width: 42px;
}

.fcardInfolist ul {
  padding: 20px 20px 25px 25px !important;
  display: flex;
  flex-wrap: wrap;
}

.fcardInfolist li {
  list-style: none;
  display: flex;
  width: 45%;
  font-size: 11px;
  margin: 10px;
  padding: 0px 10px;
  background: #f5faff;
  align-items: center;
  border-radius: 50px;
  border: 2px solid #c1ceeb;
}

.fcardInfolist li img {
  padding-right: 10px;
  height: 32px;
}

.fcardInfolist li span,
.teacherListitm .cardfield span {
  font-weight: normal;
  display: block;
  width: 120px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fcardInfolist li h4 {
  font-weight: 600;
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #343434;
}

#Experince_form .basicdetailsform .fcardTitle::before,
#Educations .basicdetailsform .fcardTitle::before {
  content: "";
  border-bottom: 20px solid transparent;
  border-left: 310px solid transparent;
  border-right: 15px solid transparent;
  background-image: url("./resources/images/arrow_top_view.png");
  background-position: center center;
  background-repeat: no-repeat;
  width: 80px;
  height: 10px;
  border-top: 30px solid transparent;
  margin: 0px auto;
  justify-content: center;
}

#Educations .basicdetailsform .fcardTitle:nth-child(1),
#Educations .basicdetailsform .fcardTitle:nth-child(1):before {
  background-image: none;
}

#Experince_form .basicdetailsform .fcardTitle:nth-child(1),
#Experince_form .basicdetailsform .fcardTitle:nth-child(1):before {
  background-image: none;
}

.cart_style {
  box-shadow: none;
  border: none;
  padding: 10px 10px 20px;
}

.fcardInfolist .experience {
  width: 160px;
  height: 150px;
  position: relative;
  background: #1a2d5a;
  padding: 20px 10px;
  border-radius: 6px;
  align-items: center;
  left: 15px;
  text-align: center;
  position: absolute;
  top: -17px;
}

.fcardInfolist .experience h4 {
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  padding: 5px 0;
}

.fcardInfolist .experience p {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.fcardInfolist .comp_details {
  background: #fff;
  padding: 10px 20px 10px 200px;
  width: 100%;
  box-shadow: 1px 1px 6px #a1a1a1;
  border-radius: 5px;
  margin: 22px 0px 0px 0px;
  min-height: 115px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.fcardInfolist .comp_details h4 {
  font-size: 21px;
  font-weight: 600;
  color: #1a2d5a;
  text-transform: capitalize;
  width: 100%;
  margin-bottom: 5px;
  flex: 0 0 100%;
}

.fcardInfolist .comp_details p {
  text-overflow: ellipsis;
  font-size: 16px;
  overflow: hidden;
  font-weight: 400;
  width: 100%;
  flex: 0 0 100%;
  color: #6c6c6c;
  padding-bottom: 0px;
  white-space: nowrap;
}

.fcardInfolist .comp_details span {
  font-size: 16px;
  font-weight: 400;
  color: #6c6c6c;
}

.fcardInfolist {
  position: relative;
}

#Educations
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .experience {
  left: auto;
  right: 15px;
  background: #e36565;
}

#Educations
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details
  .editcard {
  background: #e36565;
  left: 20px;
  margin-left: 0;
  position: absolute;
}

#Educations
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details {
  padding: 15px 220px 20px 20px;
}

#Experince_form
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .experience {
  left: auto;
  right: 15px;
  background: #e36565;
}

#Experince_form
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details
  .editcard {
  padding-left: 7px;
  background: #e36565;
  left: 20px;
  margin-left: 0;
  position: absolute;
}

#Experince_form
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details {
  padding: 15px 220px 20px 20px;
}

.FieldinfoCard.cart_style .fcardInfolist svg.svg-inline--fa.fa-calendar-days {
  font-size: 28px;
  color: #fff;
  padding: 5px;
}

.FieldinfoCard.cart_style:nth-child(2n + 2) .fcardInfolist .comp_details h4 {
  color: #e36565;
}

#Educations
  .basicdetailsform
  .fcardTitle
  .FieldinfoCard.cart_style
  .FieldinfoCard.cart_style:nth-child(2n + 2)
  .fcardInfolist
  .experience:before {
  content: "";
  position: absolute;
  left: -11px;
  bottom: -1px;

  border-top: 0px solid transparent;
  border-right: 14px solid #e36565;
  border-bottom: 14px solid transparent;
}

#Educations
  .basicdetailsform
  .fcardTitle
  .FieldinfoCard.cart_style
  .FieldinfoCard.cart_style:nth-child(2n + 2)
  .fcardInfolist
  .experience:after {
  content: "";
  position: absolute;
  left: -11px;
  top: 0px;
  border-top: 14px solid transparent;
  border-right: 14px solid #e36565;
  border-bottom: 0px solid transparent;
}

.cart_style .editcard .tooltip_style:hover .tooltiptext {
  visibility: visible;
}

.cart_style .editcard span.tooltip_style {
  position: relative;
  display: inline-block;
}

.cart_style .editcard span.tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #1a2d5a;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 100;
  top: -49px;
  right: -90px;
  font-size: 14px;
  font-weight: 400;
}

#Educations
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details
  .editcard
  .tooltip_style:hover
  .tooltiptext {
  visibility: visible;
}

#Educations
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details
  .editcard
  span.tooltip_style {
  position: relative;
  display: inline-block;
}

#Educations
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details
  .editcard
  span.tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #e36565;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 100;
  top: -49px;
  right: -130px;
}

#Experince_form
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details
  .editcard
  .tooltip_style:hover
  .tooltiptext {
  visibility: visible;
}

#Experince_form
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details
  .editcard
  span.tooltip_style {
  position: relative;
  display: inline-block;
}

#Experince_form
  .basicdetailsform
  .fcardTitle:nth-child(2n + 2)
  .FieldinfoCard.cart_style
  .fcardInfolist
  .comp_details
  .editcard
  span.tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: #e36565;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 100;
  top: -49px;
  right: -130px;
}

.fcardInfolist .comp_details .type_style {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-top: 5px;
}

.teacherListitm {
  display: flex;
}

.UploadPhoto_style {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  background: #ebebeb;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 9px;
}

.uploadedPhoto {
  width: 150px;
  border: 0;
  height: 150px;
  background: #fff;
  text-align: center;
  display: flex;
  border-radius: 50%;
  position: relative;
  padding: 5px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 6px #ccc !important;
}

.UploadPhoto {
  padding: 0px 0 7px;
}

.reactEasyCrop_Container {
  margin: auto !important;
}

.uploadedPhoto span {
  font-size: 50px;
  padding: 8px;
  width: 140px;
  color: #000000;
  margin: auto;
  border: 4px solid #7c7c7c;
  background: #f5faff;
  height: 139px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 400;
}

.holistic_growth .img_style img {
  padding: 60px 120px 60px 0px;
}

.center_img {
  margin: 15px auto;
}

.upload_photo_st {
  width: 200px;
  border: 0;
  height: 200px;
  background: #efefef;
  text-align: center;
  display: flex;
  border-radius: 50%;
  position: relative;
  padding: 5px;
  border: 1px solid #ccc;
  position: absolute;
  top: auto;
  left: auto;
  margin: 70px 30px;
}

.upload_photo_st span {
  font-size: 90px;
  padding: 8px;
  width: 180px;
  display: flex;
  color: #fff;
  margin: auto;
  border: 2px solid #fff;
  background: #f9c0c0;
  height: 180px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.form-title h4 {
  font-size: 17px;
  color: #2f2f2f;
  font-weight: 600;
}

.SecondryActivityform {
  margin-top: 20px;
}

.upload_photo_stl {
  width: 200px;
  border: 0;
  height: 200px;
  background: #efefef;
  text-align: center;
  display: flex;
  border-radius: 50%;
  position: relative;
  padding: 5px;
  border: 1px solid #ccc;
  position: absolute;
  top: auto;
  left: auto;
  margin: 70px 30px;
}

.upload_photo_stl span {
  font-size: 90px;
  padding: 8px;
  width: 180px;
  display: flex;
  color: #fff;
  margin: auto;
  border: 2px solid #fff;
  background: #f9c0c0;
  height: 180px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.RemovePhoto button {
  margin: 0 10px 0 0;
}

.RemovePhoto button .fa.fa-trash {
  margin-right: 5px;
}

.photoUploded {
  padding: 10px 15px;
  border-top: 1px solid #787878;
}

.QuestionList.d-flex {
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.QstrangeSlider {
  width: 100%;
}

div#Experince_form .Activityined > div textarea {
  height: 100px;
  max-width: 100%;
}

.Accountrelated {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.wrapper.changepasword {
  max-width: 100%;
  overflow: auto;
}

.Accountrelated form.bg-white.content {
  width: 50%;
}

.Accountrelated form.bg-white.content .input-group {
  width: 100%;
}

.PasswordIntruction {
  padding-top: 6px;
  width: 45%;
}

.rangeslider-horizontal .rangeslider__fill {
  background-color: #329fff !important;
}

.parrell_filed {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.PasswordIntruction h4 {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;
  color: #606060;
}

.Accountrelated .PasswordIntruction ul.list-group li.list-group-item.bg-light {
  padding: 13px 10px;
  font-size: 13px;
}

.PasswordIntruction ul.list-group {
  padding-left: 0 !important;
  border-radius: 0;
}

.QuestionList .fa-asterisk {
  padding-right: 10px;
  color: #329fff;
  font-size: 14px;
}

.paginationButton {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-left: auto;
  flex-direction: row-reverse;
  margin-bottom: 30px;
}

.paginationButton .next-btn {
  width: auto;
  margin: 0 50px;
}

.paginationButton .next-btn a {
  background: #329fff;
}

.paginationButton .next-btn a:hover,
.paginationButton .next-btn button:hover,
.paginationButton .next-btn a:hover button {
  background-color: #005fb2;
  color: #fff;
}

.paginationButton .next-btn a svg {
  color: #747474;
  padding-right: 0 !important;
  padding: 4px 5px !important;
  font-size: 16px;
  vertical-align: bottom;
}

.fa-user {
  color: #747474;
}

.fa-school {
  color: #747474;
  font-size: 16px;
}

.AddChildPopup .modal-title.h4 {
  font-size: 18px;
  font-weight: 600;
  color: #747474;
}

.AddChildPopup button.btn.btn-primary,
#signin button.btn,
.Custom_balanceprofile button.btn.btn-primary,
.halfPagePOpup .modal-header button.btn.btn-primary,
.congratulationPopup button.btn.btn-primary,
.SubscriptionModlPopup button.btn.btn-primary,
.AreyousurePopup button.btn.btn-primary,
#schoolactivity39 button.btn.btn-primary,
.newinfo_popup button.btn.btn-primary,
.ljpopups button.btn.btn-primary,
.newinfo_popupdimension button.btn.btn-primary,
.certificatePopup button.btn.btn-primary,
.serveyPoup button.btn.btn-primary,
.downgradepopup button.btn.btn-primary,
.MIscoreDescription button.btn.btn-primary,
.popupTitle span {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  color: #329fff;
  padding: 0;
  text-align: center;
  border: 1px solid #329fff;
  font-size: 20px;
  display: flex;
  background-color: transparent;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.SignupRegistration svg.svg-inline--fa.fa-xmark.m-0 {
  font-size: 20px;
}

.AddChildPopup .modal-dialog .modal-content {
  max-width: 77%;
}
.serveyPoup .AddChildPopup .modal-dialog .schoolcourse {
  max-width: 100%;
}
.RatingsModelPopup .modal-dialog .modal-content {
  max-width: 100%;
}

.AddChildPopup .modal-dialog {
  max-width: 750px;
}

.whereyouleft.AddChildPopup .modal-dialog {
  width: 450px;
}

.whereyouleft.AddChildPopup .modal-dialog .modal-content {
  max-width: 100%;
}
.whereyouleft.AddChildPopup
  .modal-dialog
  .AddChildPopup
  .AreyousurePopup
  .modal-dialog {
  max-width: 650px;
  height: 93vh;
  align-items: center;
  display: flex;
}

.AllCoursesDimensionlist .GridCardTitle h3 {
  cursor: pointer;
}
.parentdob {
  margin-top: 38px;
}
.parentdob span.checkmark {
  border-radius: 0;
  border: 1px solid #cccccc;
  top: 0px;
}
.tacbox.parentdob span.checkmark {
  top: 4px;
}
/* .tacbox a{
  font-size: 16px;
  padding: 0 5px;
} */
.modal.fade .modal-dialog,
.ljpopups .modal .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50%);
  top: 50%;
  margin: 0 auto;
}
.Addrewardform.ljpopups .modal-dialog.modal-lg.certificatePopup {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  width: 100%;
}
.SignupPopup.AddChildPopup.modal .modal-dialog {
  transform: none;
  top: 0;
  transition: none !important;
}

.BDsubmitbutton.childpopup button.btn-blue.btn-login.d-block.mb-5 {
  margin-left: auto;
}

.BDsubmitbutton.childpopup button svg {
  padding-right: 10px;
}

.AddChildPopup .modal-content .selectedField:before {
  top: 0;
}

.AddChildPopup .modal-content .selectedField::after,
.AddNewInterest .modal-content .selectedField::after {
  top: 10px;
}

.sweet-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: fixed;
  height: 100%;
}

.expCrad .BDsubmitbutton button.btn-blue.btn-login.d-block.mb-5 {
  width: 150px;
}

.editcard {
  margin-left: auto;
}

.FieldinfoCard.cart_style .editcard {
  margin-left: auto;
  width: 40px;
  background: #1a2d5a;
  position: absolute;
  transition: all 0.3s;
  top: -33px;
  right: 20px;
  padding: 4px 0px 4px;
  text-align: center;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
}

.FieldinfoCard.cart_style:nth-child(2n + 2) .editcard {
  margin-left: auto;
  left: 20px;
  right: auto;
  background: #e36565;
}

.cart_style .editcard span {
  color: #fff;
  margin: 0px;
}

.cart_style .editcard span svg {
  margin-right: 0;
}

.editcard span,
.editcard span a {
  color: #ccc;
  margin-left: 10px;
  cursor: pointer;
}

.MenuProfile_img img {
  width: 100%;
  height: 100%;
}

.search ul {
  padding-left: 0 !important;
  padding: 0px 0px !important;
  background: #fff;
  position: absolute;
  border: 1px solid #ccc;
  z-index: 10;
  width: 100%;
  border-top: 0;
  max-height: 270px;
  overflow: auto;
  box-shadow: 0px 4px 7px 0px #a9a9a6;
}

.LessionDtlOverview p,
.LessionDtlOverview p span,
.LessionDtlOverview li {
  /* margin: 5px 10px 5px 0px; */
  /* margin: 5px 10px 5px 0px; */
  font-size: 16px !important;
  text-align: justify;
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  line-height: 1.5;
  color: #232323;
  padding-bottom: 0px;
}
p.text-right.pr-3.justhintsofpage {
  color: #a1a1a1;
  width: auto;
  margin-top: -10px;
}
.Thanksmessage .LessionDtlOverview p {
  font-size: 18px !important;
  text-align: left;
}

.SignupRegistration .LessionDtlOverview p {
  margin-right: 0;
}

.intructionsBox.LessionDtlOverview a,
.LessionDtlOverview a {
  font-size: 16px !important;
  color: #329fff !important;
  word-break: break-all;
}

.LessionDtlOverview p span {
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  color: #000;
}

.NuggetPopupDesc p {
  font-size: 17px;
  line-height: 1.6;
  font-weight: 600;
  /* display: flex; */
  text-align: justify;
  /* width: 492px; */
}

.search ul li {
  list-style: none;
  padding: 8px 12px;
  cursor: pointer;
  border-bottom: 1px solid #c9c9c9;
  color: #212121;
  font-size: 14px;
}

.search ul li:hover {
  background: #cce5ff;
}

.search ul li:last-child {
  border-bottom: 0;
}

.diemensionCard .Card {
  background: #ffffff;
  width: 20%;
  height: auto;
  padding: 5px 5px;
  position: relative;
  border: 1px solid #fff;
  flex-wrap: wrap;
  margin: 0px 5px 0px;
  transition: all 0.3s;
  box-shadow: 10px 7px 11px #b1b1b1;
  justify-content: center;
  align-content: space-between;
  flex-wrap: wrap;
  flex: 1 1 0;
}

/* .diemensionCard .Card span {
    font-size: 14px;
    position: absolute;
    right: 5px;
    bottom: 5px;
} */


.diemensionCard .Card span img {
  width: 35px;
}

.cardDimension img {
  width: 24px;
}
.diemensionCard .earnnoCoin {
  font-size: 20px;
  margin-right: -1px;
}

.diemensionCard .Card:nth-child(1) {
  border: 1px solid #ff9900;
  border-top: 8px solid #ff9900;
  margin-left: 10px;
}

.diemensionCard .Card:nth-child(1) {
  border: 1px solid #ff9900;
  border-top: 8px solid #ff9900;
  margin-left: 10px;
}

.diemensionCard .Card:nth-child(1) .PercentCompleted {
  color: #fff;
}
.diemensionCard .Card:nth-child(1) .position-relative.round_progress {
  background: #ff9900;
  border-radius: 50%;
}
.diemensionCard .Card:nth-child(2) .position-relative.round_progress {
  background: #e46666;
  border-radius: 50%;
}
.diemensionCard .Card:nth-child(3) .position-relative.round_progress {
  background: #a86adb;
  border-radius: 50%;
}
.diemensionCard .Card:nth-child(4) .position-relative.round_progress {
  background: #1ec1d9;
  border-radius: 50%;
}
.diemensionCard .Card:nth-child(5) .position-relative.round_progress {
  background: #85b812;
  border-radius: 50%;
}
.diemensionCard .cardrightpannel .Card:nth-child(1) .skillprogrs span {
  color: #fff;
}
.diemensionCard .cardrightpannel .Card:nth-child(1) .skillprogrs span {
  background: royalblue;
  border-radius: 50%;
}
.diemensionCard .Card:nth-child(2) .PercentCompleted {
  color: #fff;
}
.diemensionCard .Card:nth-child(2) .skillprogrs span {
  background: #e46666;
  border-radius: 50%;
}
.diemensionCard .Card:nth-child(3) .PercentCompleted {
  color: #fff;
}
.diemensionCard .Card:nth-child(4) .skillprogrs span {
  background: #a86adb;
  border-radius: 50%;
}
.diemensionCard .Card:nth-child(4) .PercentCompleted {
  color: #fff;
}
.diemensionCard .Card:nth-child(5) .skillprogrs span {
  background: #1ec1d9;
  border-radius: 50%;
}
.diemensionCard .Card:nth-child(5) .PercentCompleted {
  color: #fff;
}
.diemensionCard .Card:nth-child(3) .skillprogrs span {
  background: #85b812;
}
.diemensionCard
  .Card:nth-child(1)
  svg.rc-progress-circle
  circle.rc-progress-circle-path {
  stroke: #ff9900 !important;
}

.diemensionCard
  .Card:nth-child(2)
  svg.rc-progress-circle
  circle.rc-progress-circle-path {
  stroke: #e46666 !important;
}

.diemensionCard
  .Card:nth-child(3)
  svg.rc-progress-circle
  circle.rc-progress-circle-path {
  stroke: #a86adb !important;
}

.diemensionCard
  .Card:nth-child(4)
  svg.rc-progress-circle
  circle.rc-progress-circle-path {
  stroke: #1ec1d9 !important;
}

.diemensionCard
  .Card:nth-child(5)
  svg.rc-progress-circle
  circle.rc-progress-circle-path {
  stroke: #85b812 !important;
}

.diemensionCard
  .cardrightpannel
  .Card:nth-child(1)
  svg.rc-progress-circle
  circle.rc-progress-circle-path {
  stroke: #5a5a5a !important;
}

.diemensionCard .Card:nth-child(2) {
  border: 1px solid #e46666;
  border-top: 8px solid #e46666;
}

.diemensionCard .Card:nth-child(3) {
  border: 1px solid #85b812;
  border-top: 8px solid #85b812;
}

.diemensionCard .Card:nth-child(4) {
  border: 1px solid #a86adb;
  border-top: 8px solid #a86adb;
}

.diemensionCard .Card:nth-child(5) {
  border: 1px solid #1ec1d9;
  border-top: 8px solid #1ec1d9;
}



.diemensionCard {
  margin-right: -5px;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  margin-left: -10px;
  width: calc(100% + 20px);
  z-index: 999;
  background: #f4f4f4;
  transition: 0.5s linear;
  -moz-transition: 0.5s linear;
  /* Firefox 4 */
  -webkit-transition: 0.5s linear;
  /* Safari and Chrome */
  -o-transition: 0.5s linear;
  /* Opera */
  -ms-transition: 0.5s linear;
  /* Explorer 10 */
}

.diemensionCard .Card svg.rc-progress-circle {
  border-radius: 50%;
  margin-bottom: 0px;
  max-width: 32px;
  min-width: 32px;
}

.ribbonDimenson {
  width: 100%;
  position: relative;
  padding-top: 0px;
  align-items: center;
}

.totlecout {
  display: flex;
  flex-wrap: wrap;
  padding-right: 7px;
  font-size: 12px;
  max-width: 100%;
  min-width: 100%;
}

.cardrightpannel .totlecout {
  max-width: 73%;
  min-width: 85%;
  margin-right: auto;
}

.diemensionCard .Card svg.rc-progress-circle circle.rc-progress-circle-trail {
  stroke-width: 10 !important;
}

.diemensionCard .Card svg.rc-progress-circle circle.rc-progress-circle-path {
  stroke-width: 10 !important;
}

.diemensionCard.sticky {
  position: sticky;
  z-index: 999;
  width: calc(100% + 20px);
  transition: all 0.3s !important;
  top: 47px;
}

.sidebarmenuwrap {
  height: 93%;
  align-content: space-between;
}

.ribbonDimenson span.PercentCompleted {
  width: auto;
  font-size: 14px;
  left: 50%;
  top: 50%;
  color: #85b812;
  transform: translate(-50%, -50%);
}

.whereustand {
  position: absolute;
  top: 10px;
  top: 50%;
  right: 6px;
  max-width: 65%;
  transform: translateY(-50%);
}

.menuList li.HolisticView {
  margin-right: 0;
}

.form-control.is-invalid {
  background: none !important;
}

.whereustand img {
  max-width: 30px;
}

.tcCount {
  position: absolute;
  left: 27px;
  top: 17px;
}

.tcCount span:first-child {
  background: #fff;
  color: #232323;
}

.tcCount span {
  display: block;
  font-size: 12px;
  padding: 3px;
  border-radius: 50%;
  background: #d9d9d9;
  color: #fff;
  line-height: 1;
  border: 1px solid #ccc;
  margin-bottom: 1px;
}

.totleCourse {
  padding: 3px 0px;
  /* border: 1px solid #006dcc; */
  /* background: #0ba4f0; */
  border-radius: 15px;
  margin: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.EnrollTitle .LessionDtlOverview {
  max-height: initial;
  min-height: 0;
  padding: 6px 10px;
  margin-top: 0;
  position: relative;
  border-radius: 5px 5px 5px;
  margin: 0px 0px 0px 15px;
  overflow: inherit;
  top: -5px;
}

.specialcoursefilter label img {
  max-width: 20px;
}

.EnrollTitle .LessionDtlOverview::before,
.commingSoonPage .LessionDtlOverview::before {
  content: "";
  position: absolute;
  left: -30px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-right: 30px solid #ffcb5b;
  border-bottom: 0px solid transparent;
  border-radius: 0px 0px 0px 0px;
  top: 0px;
  display: none;
}

.courseInformation .EnrollTitle .LessionDtlOverview::before {
  top: 0px;
}

.courseInformation.enrolledpopups .EnrollTitle .LessionDtlOverview::before {
  top: 0px;
}

/* .commingSoonPage .LessionDtlOverview::before {
  border-right: 30px solid #ffcb5b;
  top: 100px;
  border-bottom: 30px solid transparent;
  border-top: 0px solid transparent;
  left: -31px;
} */

.MainContent.inactive .totleCourse {
  font-size: 11px;
}

/* .totleCourse:first-child {
  background: #b4b4b4;
  border-color: #7c7c7c;
}

.totleCourse:nth-child(2) {
  background: #3ec093;
  border-color: #238723;
}

.totleCourse:nth-child(3) {
  background: #5d9bfc;
  border-color: #006dcc;
} */

.cardrightpannel .Card.flex {
  border-color: royalblue;
  margin-left: 5px;
  margin-right: 0;
}

.shorSchedule p {
  margin-bottom: 5px;
}

.diemensionCard::after {
  width: 100%;
  height: 1px;
  background: #acacac;
  position: absolute;
  bottom: 0px;
  left: 0;
  content: "";
}

.diemensionCard.collapse:not(.show)::after {
  bottom: 0;
}

.flipIcon.collapsed .fa-angles-down {
  transform: rotate(-180deg);
  transition: all 1000ms ease-in-out;
  padding: 3px 6px;
  font-size: 16px;
}

.flipIcon .fa-angles-down {
  transition: all 1000ms ease-in-out;
}

.MainContent {
  padding-left: 40px;
  display: block;
  transition: all 0.3s;
}

li.notificationbar a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swal2-actions {
  flex-direction: row-reverse;
}

.Card h3 {
  text-align: center;
  font-size: 45px;
  font-weight: bold;
  color: #a7a5a5;
}

.cardleftpannel {
  width: calc(80% - 5px);
}

.cardrightpannel .Card {
  width: 100%;
  margin: 0 5px 0 0px;
}

.cardrightpannel {
  width: 30%;
}

.Card p .fa-brain,
.Card p .fa-person-running,
.Card p .fa-circle-nodes,
.Card p .fa-person-running,
.Card p .fa-react {
  font-size: 28px;
}

.Card p.percent {
  margin-top: 5px;
}

.child_profiles .Progress-bar {
  background: #fff;
}

.Card p span {
  font-size: 16px;
  display: block;
  color: #939393;
  position: absolute;
  top: 14px;
}

.Card p img {
  width: 28px;
}

.onCardnotify {
  width: 6px;
  display: block;
  height: 6px;
  background: #dd3333;
  position: absolute;
  border-radius: 50%;
  right: 8px;
  top: 12px;
  animation-name: notificationani;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

.Card p.percent img {
  width: 20px;
  margin-top: 39px;
}

@keyframes notificationani {
  0% {
    background-color: #dd3333;
  }

  25% {
    background-color: yellow;
  }

  50% {
    background-color: green;
  }

  75% {
    background-color: yellow;
  }

  100% {
    background-color: #dd3333;
  }
}

.Card p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img.diemensionicon {
  width: 32px;
}

.flipIcon {
  width: 25px;
  height: 20px;
  cursor: pointer;
  background: #0f1a34;
  text-align: center;
  border-radius: 0 0 8px 8px;
  color: #fff;
  position: fixed;
  margin-right: -9px;
  right: 23px;
  top: 46px;
  z-index: 99991;
  line-height: 1;
}

.Card p.percent {
  justify-content: right;
}

.teacherlist ul {
  padding-left: 0 !important;
}

.teacherlist ul li {
  list-style: none;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #0f1a34;
  border-bottom: 1px dashed #329fff;
  margin: 0px 0;
  padding: 5px 0;
}

.teacherlist ul li svg {
  color: #ccc;
}

div#School_information .fcardTitle .headingTitle img {
  width: 32px;
}

.teacherlist ul li img {
  width: 32px;
  margin-right: 5px;
}

.teacherlist ul li .teacherListitm {
  width: 22.5%;
  align-items: flex-start;
  margin-right: 10px;
}

.teacherlist {
  padding: 0 10px;
}

.cardfield {
  font-size: 16px;
  color: #444;
  padding: 0px 0px;
  font-weight: 600;
}

.teacherlist ul li .teacherListitm:last-child {
  border: 0;
  margin-right: 0;
  width: 10%;
  justify-content: flex-end;
  padding-right: 0;
}

.basicdetailsform .wrapper .react-tel-input {
  width: 32%;
  padding-right: 8px;
}

.basicdetailsform .wrapper .react-tel-input input.form-control {
  width: 100%;
  height: 38px;
  border-radius: 0;
}

.basicdetailsform
  .wrapper.mx-auto
  .mobileManage
  .react-tel-input
  input.form-control {
  width: 90%;
  height: 38px;
  border: 0;
  padding-left: 32px;
  border: 1px solid #ccc;
}

.mobileManage {
  display: flex;
}

.basicdetailsform .wrapper.mx-auto .mobileManage .react-tel-input {
  padding-right: 0;
}

.mobileManage .react-tel-input .flag-dropdown {
  border: 0;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px 6px;
}

.mobileManage .react-tel-input .selected-flag {
  padding-left: 0 !important;
  width: 28px;
}

.basicdetailsform
  .wrapper.mx-auto
  .mobileManage
  input.form-control.hideCountry {
  padding-left: 48px;
}

.AViewListitem.linkViewItem .videoDescription {
  padding-right: 0px;
  width: calc(100% - 0px);
  padding: 0px 0 0;
  position: relative;
}

.circularActIcon {
  border: 1px solid #ccc;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
}

.AViewListitem.linkViewItem .videoDescription h3 {
  margin-bottom: 5px;
}

.Selvidoption span.closeicon {
  top: 2px;
  transform: rotate(90deg);
  font-size: 20px;
}

.AddActivity .Activity {
  width: 100%;
}

.AddActivity {
  width: calc(26% - 10px);
  position: relative;
}

.flipIcon .fa-angles-down {
  padding: 4px 5px;
  transition: all 1000ms ease-in-out;
}

.diemensionCard.collapse:not(.show) {
  display: flex;
  overflow: hidden;
  height: 8px !important;
}

.diemensionCard.collespe.hide.collapse {
  margin-bottom: 15px;
}

.diemensionCard.collespe.hide.collapse.show {
  margin-bottom: 25px;
}

.diemensionCard.collapsing {
  height: 10px;
}

.flipIcon.collapsed ~ .diemensionCard {
  transition: all 1000ms ease-in-out;
}

.addActivity.CatItemList span {
  margin-left: 0;
  margin-right: 5px;
  font-size: 18px;
  line-height: 0.9;
  width: 18px;
  font-weight: bold;
  padding: 0px 3px;
  height: 18px;
}

.addActivity:hover .ActivityCategory {
  display: block;
}

@media (max-width: 992px) {
  .grid-container {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 992px) {
  .minheight-463 {
    min-height: 463px;
  }
}

@media (max-width: 767px) {
  .d-flex {
    flex-wrap: wrap;
  }

  .marg_tr {
    padding: 0rem !important;
  }

  h1.title {
    font-size: 27px !important;
    line-height: 2;
  }

  .login-right {
    width: 100% !important;
    margin: 0rem 2rem !important;
  }

  .w-50 {
    width: 100% !important;
  }

  .container {
    max-width: 100% !important;
  }

  .title2 {
    text-align: left !important;
    font-size: 28px !important;
  }

  h4.title_style {
    margin: 20px 0 0;
  }

  .landing-page {
    width: 100%;
  }

  p.learn_more {
    padding-bottom: 20px;
  }

  .marg_tr .d-flex.w-100.justify-content-between {
    justify-content: center !important;
  }

  .d-flex.mx-2.my-4.p-5 {
    padding: 0 !important;
  }

  .d-flex.bg-white.mx-2.mt-2 .d-flex.w-50 {
    padding: 0 0 0px !important;
  }

  .d-flex.w-100.justify-content-between {
    justify-content: center !important;
  }

  .title2 {
    font-size: 20px !important;
    text-align: center !important;
  }

  .marg_tr .d-flex.w-100.justify-content-between {
    justify-content: center !important;
  }

  .MainContent.inactive {
    padding-left: 0;
    flex-direction: column-reverse;
  }

  .MainContent {
    flex-wrap: wrap;
    padding-left: 0;
  }

  .LeftbarPannel {
    width: 100%;
    height: auto;
    box-shadow: none !important;
    margin: 0;
    margin-bottom: 20px;
    max-height: none;
  }

  .RightbarPannel {
    margin-bottom: 20px;
    width: 100%;
    box-shadow: none !important;
  }

  .react-datepicker__input-container input,
  .rs-picker-default .rs-picker-toggle.rs-btn {
    max-width: 100%;
    border-radius: 10px;
    width: 100%;
    flex: 0 0 100%;
  }

  .basicdetailsform.px-5 {
    padding: 30px 0 !important;
  }

  .basicdetailsform .HalfWidth-group {
    width: 100%;
  }

  .basicdetailsform .checkboxes.mt-4 {
    padding-left: 0%;
  }

  .menuList .dropdown-menu {
    min-width: 0;
    width: 200px;
    margin-top: 13px;
  }
}

.ProfileQuote .invalid-feedback {
  padding-left: 20px;
}

.MobileBars.bars {
  display: none !important;
}

.PannelContent.basicdetailsform.px-5 .signupType {
  padding-left: 0px;
}

.contents {
  display: none !important;
}

.active-content {
  display: block !important;
}

.dataResult {
  margin-top: 5px;
  width: 300px;
  height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow: hidden;
  overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
  display: none;
}

.dataResult .dataItems {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.dataItems a {
  margin-left: 10px;
}

.halfPagePOpup .modal.fade .modal-dialog.modal-lg {
  right: -50%;
  transition: all 0.5s;
}

.halfPagePOpup .modal-content {
  max-width: 100%;
  border: 0;
  border-radius: 0;
}

.halfPagePOpup #Holisticgrowthchart .modal-content {
  min-height: 100%;
}

.halfPagePOpup .modal-header {
  padding: 8px 10px;
  border: 0;
  display: block;
  background: #f5faff;
  border-bottom: 1px solid #ccc;
  position: sticky;
  width: 100%;
  top: 0px;
  z-index: 99;
}

.AddChildPopup .modal-footer,
.newinfo_popup .modal-footer,
.newinfo_popupdimension .modal-footer,
.ljpopups .modal-footer,
.Whatadmcndo .modal-footer,
.deletYrAccount .modal-footer,
#schoolactivity39 .modal-footer .certificatePopup .modal-footer {
  background: #e7e7e7;
  padding: 8px 10px;
  border-radius: 0 0 4px 4px !important;
}
.halfPagePOpup.parentQuizsanalysis .modal-footer {
  position: sticky;
}
.SelectYourProfile .modal-footer,
.SubscriptionModlPopup .modal-footer,
div#signin .modal-footer,
.congratulationPopup .modal-footer,
.RatingsModelPopup .modal-footer,
.AreyousurePopup .modal-footer,
.downgradepopup .modal-footer,
.unsubscribe_styl .modal-footer {
  background: #e7e7e7;
  padding: 8px 10px;
}

.modal-header {
  background: #f5faff !important;
  padding: 8px 10px;
}

.AddChildPopup form.bg-white.p.content {
  border-radius: 0 0 4px 4px !important;
}

.halfPagePOpup .modal-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  background: #e7e7e7;
  padding: 8px 10px;
  z-index: 1;
}
.Skill_popup_half div#schoolactivity39 .modal-footer {
  position: relative;
}
.halfPagePOpup {
 /* scrollbar-width: thin;
  scrollbar-color: #c9cdd1 #f9f9f9;
   position:relative;
  z-index: 99999; */
}

.ActivityOption .Selcheckbox .checkmark:after {
  left: 6px;
  top: 2px;
  width: 7px;
  height: 11px;
}

.notification-container {
  top: 0%;
  left: 50%;
  max-height: calc(100% - 30px);
  transform: translate(-50%, 0%);
}

span.radiobutton,
.checkboxQ {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 50%;
}

.checkboxQ,
.checkboxQst .checkmark {
  border-radius: 0;
}

.Questionlisting {
  width: 80%;
  padding-left: 30px;
}

.askquestionimg {
  position: absolute;
  top: 40px;
  left: 0;
}

.askquestionimg svg {
  font-size: 25px;
  color: #838282;
}

.qzQuestioncontent {
  min-height: 150px;
}

.Addquestion.radiobuttonQst.mb-3 {
  padding: 15px;
  border: 1px solid #ccc;
  margin: 40px 0 15px;
  position: relative;
}

.input-group.Questionlisting .editcard.deletemodl,
.EvalutionEditor .editcard.deletemodl {
  position: absolute;
  top: 13px;
  right: 0;
  margin-bottom: 0;
  padding-right: 0;
}

.EvalutionEditor .editcard.deletemodl {
  top: -23px;
}

.qzQuestioncontent .BDsubmitbutton.Addchieldbtn {
  justify-content: left;
}

.input-group.AttributrTitle {
  width: calc(100% - 179px);
  margin-bottom: 10px;
  position: absolute;
  top: -21px;
  left: 16px;
  right: 0;
}

.EvalutionEditor .wrapperClassName {
  border: 1px solid #ccc;
  margin-bottom: 15px;
}

.EvalutionEditor .input-group {
  width: 100%;
  margin-top: 25px;
}

.PreviewTitle {
  margin-bottom: 10px;
}

.PreviewTitle h3 {
  font-size: 18px;
  color: #4c4c4c;
  margin-bottom: 5px;
}

.categorryName {
  position: absolute;
  left: 200px;
  width: 171px;
  height: 150px;
  overflow: auto;
  top: -87px;
}

.EvalutionEditor .rdw-editor-toolbar.toolbarClassName {
  border: 0;
  border-bottom: 1px solid #ccc;
}

.editorClassName.rdw-editor-main {
  padding: 10px;
  font-size: 14px;
  min-height: 200px;
  border: 1px solid #eee;
  border-top: 0;
}

.rdw-editor-toolbar {
  margin-bottom: 0;
}

.bodyTitle h4 .fa-ellipsis {
  font-size: 24px;
}

.schoolcourse .AllSubgradetable th input.AddScoreform,
.schoolcourse .AllSubgradetable td input.AddScoreform {
  padding: 0 5px;
  border: 1px solid #ccc;
}

.rescrdVidContent .LinkImage.pointer {
  width: 100%;
  margin-right: 10px;
  height: 100%;
}

.schoolcourse input::placeholder {
  color: #c2c2c2 !important;
}

.halfPagePOpup .modal-body {
  min-height: calc(100vh - 92px);
}

.halfPagePOpup div#resourceInnerModal .modal-body {
  min-height: calc(100vh - 44px);
}

.halfPagePOpup .RatetousPopup,
.halfPagePOpup div#resourceInnerModal .RatingsModelPopup .modal-body {
  min-height: 160px;
}

.Addquestopn {
  font-size: 16px;
  color: #8b8b8b;
}

.ActivityOption label {
  font-size: 15px;
  color: #000;
  font-weight: 600;
}

.ActivityOption .Selcheckbox .checkmark:after {
  border: solid #00b050;
  border-width: 0 3px 3px 0;
}

.ActivityOption .Selcheckbox input:checked ~ .checkmark {
  border: 1px solid #00b050;
  background: #fff;
}

.input-group.ActivityOption span.checkmark {
  border-radius: 0;
}

.subheading h3 {
  font-size: 15px;
  color: #212121;
  text-align: right;
  font-weight: bold;
  padding: 7px 15px 0px;
}

.Courseimage {
  width: 154px;
  height: 154px;
  border: 2px solid #ccc;
  border-radius: 0px;
}

.UPCourseAndimg h4 {
  font-size: 21px;
  color: #747474;
  text-transform: capitalize;
}

.imageuplodedbtn {
  width: 30%;
}

.ClosereportCard {
  text-align: right;
  line-height: 1 !important;
  justify-content: right;
  display: flex;
  margin-left: auto;
}

/* .form-check-input:checked {
  background-image: none;
  background-color: #0d6efd;
  border-color: #0d6efd;
} */

.form-group.courseAttributes span.checkmark {
  border-radius: 0;
}

.uploadCOrseimg input.form-control {
  z-index: 1;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.uploadCOrseimg .reactEasyCrop_Container {
  width: 150px !important;
  height: 150px !important;
}

.uploadCOrseimg {
  z-index: 1;
  position: relative;
  margin-left: 26.3rem;
}

.subheading h4 {
  font-size: 15px;
  color: #404040;
  font-weight: 600;
}

.radiobtn {
  width: 20px;
  height: 20px;
}

.d-flex.align-items-center.hearticon {
  background: #e36565;
  padding: 3px 8px;
  color: #fff;
  font-size: 16px;
  border-radius: 0px 15px 0px 15px;
}

.VKprofile .dropdown-menu {
  min-width: 200px;
}

.dropdown-toggle::after {
  display: none;
}

.VKprofile .vkprofilename svg {
  margin-left: 5px;
  color: #747474;
}

.VKprofile .vkprofilename svg.svg-inline--fa.fa-chevron-down {
  cursor: pointer;
}

.d-flex.align-items-center.hearticon img {
  width: 20px;
  padding-right: 3px;
}

.ActivityDeftxt p {
  font-size: 14px;
  color: #404040;
}

.intlikes.ml-auto .BDsubmitbutton.Addchieldbtn {
  background: #48cfae;
  color: #fff;
  padding: 2px 5px 5px !important;
}

.intlikes .BDsubmitbutton.Addchieldbtn.addEnrichbtn strong span {
  border: 1px solid #ffffff;
  text-align: center;
  margin-right: 3px;
  color: #48cfae;
  background: #ffffff;
}

.intlikes.ml-auto .BDsubmitbutton.Addchieldbtn strong {
  color: #fff;
}

.halfPagePOpup .form-group .form-check label {
  margin-bottom: 0 !important;
}

.qzQuestioncontent .form-check-input:checked {
  background-color: #36bb9a;
  border-color: #36bb9a;
}

.rangeFieldmsg .input-group {
  width: 25%;
}

.courseInformation .heading h2 {
  font-size: 18px !important;
}

.courseInformation .bodyTitle h4 {
  font-size: 18px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}

.courseInformation .bodyTitle .bodyimagetitle img {
  width: auto;
  margin-right: 0 !important;
  padding: 0px;
  box-sizing: border-box;
}

.courseInformation .bodyTitle .bodyimagetitle span {
  display: inline-block;
  margin-right: 5px;
  border-radius: 50%;
  text-align: center;
  width: 48px;
  height: 48px;
}

.courseInformation.schoolcourse .bodyTitle .bodyimagetitle span {
  border: 1px solid #ccc;
  justify-content: center;
  margin-right: 10px;
}

.halfPagePOpup .selectedField:after {
  top: 7px;
}

.halfPagePOpup .modal-footer.p-0.mb-3 {
  border: 0;
}

.form-group.courseAttributes label.Selcheckbox,
.form-group.forChildren label.Selcheckbox {
  font-size: 14px;
}

span.uplodedCImage {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.form-group.Upload {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 150px;
}

.halfPagePOpup .modal-dialog.modal-lg {
  transform: none;
  width: 100%;
  max-width: 65%;
  position: absolute;
  background: #fff;
  right: 0;
  top: 0;
  box-shadow: 0 0 10px #b2b2b2;
  margin: 0 !important;
  bottom: 0;
  height: 100%;
  z-index: 9999;
  transition: all 3s;
}

.ModuleTitle {
  padding: 15px 15px;
  background: #ececec;
}

.BrifeInfomation {
  width: 265px;
  position: absolute;
  border-radius: 5px;
  left: 29px;
  background: #ffffff;
  top: 30px;
  border: 1px solid #ccc;
  z-index: 1;
}

.BrifeInfomation ul {
  padding: 5px !important;
}

.Gridsocialsec ul li .BrifeInfomation svg {
  color: #838383;
  font-size: 17px;
  width: 31px;
  text-align: center;
}

.Gridsocialsec ul li .BrifeInfomation span {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}

.BrifeInfomation::after {
  content: "";
  position: absolute;
  top: -5px;
  left: 20px;
  width: 10px;
  height: 10px;
  background: #fff;
  border: 1px solid #ccc;
  transform: rotate(45deg);
  border-bottom: 0;
  border-right: 0;
}

.submoduleList ul {
  padding: 10px !important;
}

.submoduleList h4 ul {
  padding: 0px 0px 0 0px !important;
}

.Gridsocialsec ul li:hover .BrifeInfomation {
  display: block !important;
}

.BrifeInfomation ul li {
  font-size: 13px;
  background: #fff;
  padding: 0;
}

span.ortag {
  font-size: 14px;
  padding-top: 22px;
}

.inte span.ortag {
  padding-top: 32px;
}

.cutQuote {
  position: absolute;
  right: 5px;
  cursor: pointer;
  color: #808080;
  top: 10px;
}

.submoduleList li span.complete {
  color: #48cfae;
}

.input-group.searchInputs.searchinrest {
  width: 100%;
}

.geninterstsplcls .input-group.searchInputs.searchinrest {
  width: 46%;
}

.submoduleList li span.Progress {
  color: #faaf3b;
}

.form-group.BDsubmitbutton.d-flex.mt-2.justify-content-end {
  justify-content: end !important;
}

.submoduleList li span.incomplete {
  color: #dd3333;
}

.ProbvidListItem .Selvidoption {
  width: 15%;
}

.submoduleList li {
  margin-bottom: 10px;
  color: #232323;
  font-size: 16px;
  border-bottom: 1px solid #ebe8e8;
  padding: 0 76px 10px 0;
}

.submoduleList h4 li {
  padding-right: 49px;
}

.ModuleTitle h4 {
  font-size: 16px;
  color: #2f2f2f;
  font-weight: 600;
}

.clearallFilter .instlistitem {
  background: #d9d9d9;
}

.clearallFilter .instlistitem .crossmark {
  background: transparent;
  color: #434343;
}

.submoduleList li span:nth-child(2) span.checkmark {
  width: 20px;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 0;
  margin: auto;
}

.submoduleList .Selcheckbox .checkmark:after {
  left: 6px;
  top: 3px;
}

.halfPagePOpup .modal.fade.show .modal-dialog.modal-lg {
  right: 0;
  border-radius: 0;
}

/* .Activity button {
  border: 0;
  background: transparent;
} */

.halfPagePOpup .modal-body .form_left,
.halfPagePOpup .modal-body .form_right {
  width: 48%;
}

.bodyTitle h4 {
  font-size: 14px;
  color: #404040;
  font-weight: 600;
}

.halfPagePOpup .bodyTitle h4 {
  font-size: 17px;
  color: #2f2f2f;
  font-weight: 600;
}

.bodyTitle img {
  width: 24px;
}

.marksorgrade {
  width: 40%;
}

.input-group.submitgrade {
  width: 12%;
}

.tabletr .react-datepicker-popper {
  z-index: 9;
  padding-top: 10px;
  transform: translate(0px, 28px) !important;
}

.basicdetailsform .gradeDivsion .input-group.datespl.calender {
  width: 40%;
}

.basicdetailsform .input-group.datespl.calender {
  width: 48%;
}

.ProbvidListItem .videoDescription {
  width: calc(100% - 230px);
}

.basicdetailsform .AddCourseformWrap .input-group.datespl.calender {
  width: 48%;
}

.Assessment h4 {
  font-size: 16px;
  font-weight: 600;
}

.basicdetailsform .marksorgrade .input-group {
  width: 40%;
}

.searchVideoList ul li:hover .AddvidActivityWrap {
  display: flex;
}

.AddvidActivityWrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #cccc;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
}

.AddvidActivityWrap strong span {
  border: 1px solid #fff;
  text-align: center;
  margin-right: 3px;
  color: white;
  border-radius: 50%;
  margin-left: 5px;
  width: 15px;
  height: 15px;
  line-height: 1;
  display: inline-block;
}

.AddvidActivityWrap strong {
  padding: 8px 20px;
  color: #fff;
  font-size: 14px;
  background: #237461;
  border-radius: 4px;
  cursor: pointer;
}

.TeachAssessmentPopup .input-group .form-group label {
  font-size: 14px;
  color: #262626;
}

.TeachAssessmentPopup .input-group label {
  margin-top: 10px;
  font-size: 14px;
  color: #383838;
  line-height: 1.2;
}

.videoDescription h3 {
  font-size: 17px;
  font-weight: 600;
  color: #404040;
}

.videoDescription h5,
.videoDescription p {
  font-size: 14px;
  color: #3b3b3b;
}

.halfPagePOpup .Activityined > div textarea,
.AddNewInterest .Activityined > div textarea {
  height: 50px;
}

.menuList li img.mr-2 {
  width: 30px;
}

.EnrollCourseConfimation .modal-content {
  max-width: 100%;
}

.modal-header.popupTitle h3 {
  font-size: 16px;
  font-weight: 600;
}

.halfPagePOpup .modal-header .heading button.close,
.hoosrSubModlPopup .modal-header .heading button.close,
.centerPagePopup .modal-header .heading button.close,
#GradeActivitychart .modal-header .heading button.close {
  border: 1px solid #1e79c9;
  font-size: 20px;
  color: #1e79c9;
  border-radius: 50%;
  width: 24px;
  line-height: 19px;
  height: 24px;
  background: #f5faff;
  position: absolute;
  top: 5px;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.modal-backdrop.show {
  opacity: 0;
  z-index: 0;
  width: 0;
  height: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.reactEasyCrop_CropAreaRound {
  width: 200px !important;
  height: 200px !important;
}

.reactEasyCrop_Contain {
  position: relative !important;
}

.reactEasyCrop_Container {
  position: relative !important;
  width: 200px !important;
  height: 200px !important;
}

.general-btn {
  cursor: pointer;
}

.VKprofile .vkprofilename .dropdown-toggle img.rounded-circle {
  width: 30px;
  height: 30px;
  border: 1px solid;
  margin-right: 5px;
}

.VKprofile .vkprofilename span {
  padding: 0;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.cardDescription.p-2 {
  width: calc(100% - 90px);
}

/* ------------------Landing page css added by @nkit--------------- */

/* Start Header part css added */
.bg-grey {
  background: #fafafa;
}

.landing_page_style {
  padding: 20px 50px;
  width: 100%;
  background: #fcfcfc;
  overflow: hidden;
}

.landing-page {
  padding: 0px;
}

.logostyle {
  width: 60%;
  display: flex;
}

.menustyle ul {
  display: flex;
  flex-direction: row;
}

.RightbarPannel .PannelContent.ProviderProfile {
  border: 0;
  box-shadow: none;
}

.BDsubmitbutton.Addchieldbtn.addEnrichbtn strong span.switchdesc,
.BDsubmitbutton.Addchieldbtn.addEnrichbtn strong span.switchdescminus {
  border: 0;
  width: 55px;
  background: transparent;
  color: #48cfae;
}

.menustyle ul li {
  display: flex;
}

.menustyle ul li a {
  font-size: 18px;
  color: #6e6c6c;
  font-weight: lighter;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 4px;
  padding: 10px 20px;
}

/* End Header part css added */

/* Start Banner part css added */

.banner_back_style {
  width: 100%;
  height: 740px;
  background: #ffffff;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 75px 40px 15px 70px;
  border-bottom: 1px solid #eeee;
}

.banner_back_style .form_style {
  width: 36%;
  padding: 0px 0px;
  z-index: 2;
  position: relative;
  transition: all ease 2s;
}

.banner_back_style .form_style .head_style h2 {
  font-size: 42px;
  letter-spacing: 15px;
  color: #2d324c;
  font-weight: initial;
  /* font-family: "Avenir_bold" !important; */
  line-height: 70px;
}

.container-fluid.head_style {
  padding-top: 20px;
}

.w-48.vickyimgsec {
  padding: 20px 00 0;
  text-align: center;
  border: 2px solid #ffc107;
  border-radius: 0px;
  background: #ecf7ff;
  width: 48% !important;
  margin-top: 0px;
}

.vickyimgsec img {
  max-width: 260px;
}

.feedback-form .vickyimgsec img {
  max-width: 185px;
}

.banner_back_style .form_style .head_style h4 {
  font-size: 64px;
  letter-spacing: 18px;
  color: #e46666;
  font-weight: normal;
  line-height: 1;
  margin-bottom: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-family: "eurostylenormal" !important;
}

.banner_back_style .form_style .head_style h4 span {
  background: #fff;
  position: relative;
  padding-left: 10px;
}

.slider.custom-labels.w-100.flex {
  background: #1d2226;
  padding: 0px 20px;
  margin-bottom: 10px;
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  width: calc(100% + 57px) !important;
}

.photoUploded .BDsubmitbutton .Editbuttons button.remove_btn {
  color: #ccc;
}

.photoUploded .BDsubmitbutton .Editbuttons button.remove_btn:hover {
  color: red;
}

.banner_back_style .form_style .head_style h4 span::after {
  content: "";
  width: 97%;
  height: 65px;
  position: absolute;
  bottom: 0;
  left: 0;
  top: -5px;
  border: 1px solid #b5b4b4;
  border-radius: 4px;
}

.spinner {
  position: absolute;
  width: 22px;
  height: 22px;
  border: 0.5px solid #218cea;
  border-radius: 18px;
  background: #ffffff;
  animation: infinite-spinning 1.4s linear infinite;
  right: 10px;
  top: 45px;
}

.UploadPhoto .css-kud8pv {
  position: absolute;
  font-size: 0;
  top: 20px;
  left: 20px;
  width: 60px;
  height: 60px;
  left: 0;
  right: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 10px;
  width: 5px;
  height: 16px;
  border-radius: 0 5px;
  margin-left: -2px;
  background: #218cea;
}

.spinner:before {
  content: "";
  width: 3px;
  height: 3px;
  background: #fafafa;
  position: absolute;
  top: 9px;
  left: 9px;
  z-index: 9;
  border-radius: 50%;
}

.signinandSignup .btn-login:hover {
  background: #005fb2;
}

.landingpageBannerBg {
  background: url("./resources/images/banner_img.png") no-repeat bottom right;
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 0;
}

.banner_back_style .banner_style {
  width: 66%;
  padding: 20px 10px;
  position: relative;
}

.signinandSignup span.PasswordeyeIcon {
  position: absolute;
  right: 20px;
  top: 15px;
}

.banner_back_style .banner_style .dimension_style1,
.banner_back_style .banner_style .dimension_style2 {
  text-align: center;
  justify-content: right;
  z-index: 9;
  position: relative;
  padding-top: 124px;
}

.banner_back_style .banner_style .dimension_style1 .physical {
  background: #fff;
  padding: 15px;
  border: 4px solid #1ec1d9;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  position: absolute;
  right: 484px;
  top: 70px;
}

.banner_back_style .banner_style .dimension_style1 .physical h4 {
  color: #1ec1d9;
  /* font-family: "Avenir_bold" !important; */
}

.banner_back_style .banner_style .dimension_style1 .intelectual {
  background: #ffffff;
  padding: 25px;
  border: 4px solid #85b812;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: absolute;
  right: 291px;
  top: -14px;
}

.banner_back_style .banner_style .dimension_style1 .intelectual h4 {
  color: #85b812;
  /* font-family: "Avenir_bold" !important; */
}

.banner_back_style .banner_style .dimension_style1 .social {
  background: #fff;
  padding: 15px;
  border: 4px solid #ff9900;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  position: absolute;
  top: 70px;
  right: 115px;
}

.banner_back_style .banner_style .dimension_style1 .social h4 {
  color: #ff9900;
  /* font-family: "Avenir_bold" !important; */
}

.banner_back_style .banner_style .dimension_style2 .emotional {
  background: #ffffff;
  padding: 25px;
  border: 4px solid #e46666;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: absolute;
  right: 586px;
}

.banner_back_style .banner_style .dimension_style2 .emotional h4 {
  color: #e46666;
  /* font-family: "Avenir_bold" !important; */
}

.banner_back_style .banner_style .dimension_style2 .spiritual {
  background: #ffff;
  padding: 25px;
  border: 4px solid #a86adb;
  border-radius: 50%;
  width: 140px;
  height: 140px;
  position: absolute;
  right: 0;
}

.signinandSignup input.form-control,
.signinandSignup select {
  height: 42px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.signinandSignup .input-group label {
  color: #8b8b8b;
  font-size: 16px;
}

.signinandSignup form[name="usersignup"],
.signinandSignup form[name="selfsignup"] {
  max-height: 100%;
  height: 100%;
  padding-right: 20px;
  scrollbar-width: thin;
  scrollbar-color: #c9cdd1 #f9f9f9;
  position: relative;
  z-index: 1;
  transition: all ease 2s;
}

.signinandSignup form[name="freesignin"] {
  padding-right: 20px;
}

.signinandSignup .selectedField:after,
.basicdetailsform .signinandSignup .selectedField::after {
  position: absolute;
  right: 18px;
  top: 13px;
}

.signinandSignup button.btn-google.btn-login.d-block.mb-5 {
  width: 100%;
  height: 42px !important;
  margin-bottom: 20px !important;
  font-size: 16px !important;
  box-shadow: none !important;
}

.signinandSignup .btn-login {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  padding: 8px 20px 8px 20px;
  border: 0;
  border-radius: 10px !important;
  background-color: #329fff;
  text-align: center;
  width: 100%;
  height: 42px;
  border: 1px solid #1ec1d9;
  text-transform: capitalize;
  margin: 0 auto;
}

.signinandSignup .input-group {
  padding: 0 5px;
}

button.btn-blue.btn-login.d-block.mb-5.removepic {
  background: #e36565;
}

.signinandSignup .signupType {
  padding-bottom: 20px;
}

.signinandSignup .input-group.calender .clenderIcon {
  right: 15px;
  top: 7px;
}

.invalid-feedback {
  margin-top: 0.3rem !important;
  margin-bottom: 15px;
  font-size: 12px;
  display: block;
  color: #dc3545;
  text-align: left;
}

.input-group .form-control.is-invalid {
  margin-bottom: 0px !important;
}

.banner_back_style .banner_style .dimension_style2 .spiritual h4 {
  color: #a86adb;
  /* font-family: "Avenir_bold" !important; */
}

/* End Banner part css added */

/* Start Holistic Growth part css added */
.holistic_growth {
  padding: 20px 20px;
  align-items: center;
  padding: 20px 20px;
  align-items: center;
  background: #fff;
  position: relative;
  z-index: 1;
}

.navSidebar li ul li span.hide-menu {
  color: #c9cfff;
  font-size: 14px;
  padding: 0;
  margin: 0;
}

.holistic_growth .img_style {
  width: 50%;
  padding: 0px 40px;
}

.holistic_growth .img_style {
  margin: 10px;
}

.holistic_growth .text_style {
  width: 50%;
  padding: 0px 10px;
}

.holistic_growth .text_style h4 {
  font-size: 30px;
  font-weight: initial;
  color: #555454;
  line-height: 40px;
  padding: 0px 0px 0px 0px;
  letter-spacing: 3px;
  position: relative;
  z-index: 10;
  /* font-family: "Avenir_bold" !important; */
  margin-left: -140px;
}

.signinandSignup {
  /* font-family: "Avenir_bold" !important; */
  padding: 5px 10px;
}

.holistic_growth .text_style h4 span {
  color: #e46666;
  font-weight: 600;
  font-size: 45px;
}

.learning_growth {
  padding: 0px 50px;
  background: #0e3652;
  background-image: url(./resources/images/learning_back.png);
  background-position: left top;
  background-repeat: no-repeat;
}

.learning_growth .img_style1 {
  width: 45%;
  padding: 0px 40px;
}

.learning_growth .text_style1 {
  width: 55%;
  padding: 0px 10px;
}

.learning_growth .text_style1 h4 {
  font-size: 30px;
  font-weight: initial;
  color: #555454;
  line-height: 40px;
  padding: 190px 0px 0px 15px;
  letter-spacing: 3px;
}

.learning_growth .text_style1 h4 span {
  color: #e46666;
  font-weight: 600;
  font-size: 45px;
}

.personal_coach {
  padding: 60px 20px;
}

.personal_coach .img_style2 {
  width: 50%;
  padding: 10px 88px;
  background-image: url(./resources/images/youtube_back.jpg);
  background-position: left center;
  background-repeat: no-repeat;
  height: 100%;
  min-height: 450px;
}

.personal_coach .text_style2 {
  width: 50%;
  position: relative;
  padding: 0px 10px;
}

.personal_coach .text_style2 h4 {
  font-size: 30px;
  font-weight: initial;
  color: #555454;
  line-height: 40px;
  z-index: 1;
  position: relative;
  padding: 135px 0px 0px 50px;
  letter-spacing: 3px;
}

.personal_coach .text_style2 h4 span {
  color: #e46666;
  font-weight: 600;
  font-size: 45px;
}

span.css-q3o1l2 {
  position: absolute;
  right: 31px;
  top: 62px;
  width: 19px;
  border-right: 3px solid #329fff;
  border-top: 3px solid #329fff;
  height: 20px;
  border-left: 3px solid #329fff;
}

.uploadCOrseimg.d-flex.align-items-center .reactEasyCrop_Container {
  position: relative !important;
  width: 137px !important;
  height: 137px !important;
}

.explore {
  padding: 10px 40px;
  background: #fde7ac;
  background-image: url(./resources/images/explor_back_pattern.png);
  background-position: left center;
  background-position: left center;
  background-size: cover;
}

.HolisticgrothContanr {
  background: #fff;
  z-index: 9;
  position: relative;
  overflow: hidden;
}

.borderlendingtop {
  overflow: hidden;
}

.explore .img_style3 {
  width: 45%;
  padding: 0px 40px;
}

.explore .text_style3 {
  width: 55%;
  padding: 0px 10px;
}

.explore .text_style3 h4 {
  font-size: 30px;
  font-weight: initial;
  color: #555454;
  line-height: 40px;
  padding: 190px 0px 0px 60px;
  letter-spacing: 3px;
}

.explore .text_style3 h4 span {
  color: #e46666;
  font-weight: 600;
  font-size: 45px;
}

.footer {
  padding: 10px 40px;
  background: #e46666;
  text-align: center;
}

.footer h4 {
  color: #fff;
  font-size: 16px;
  text-align: center;
}

.footer h4 a {
  color: #fff;
  font-size: 16px;
}

.footer h4 a:hover {
  border-bottom: 1px solid;
}

.fontawesome-i2svg-active .Intellectual,
.fontawesome-i2svg-active .physical,
.fontawesome-i2svg-active .social,
.fontawesome-i2svg-active .emotional,
.fontawesome-i2svg-active .spiritual {
  opacity: 1;
}

.modal-backdrop.show {
  opacity: 0;
  z-index: 0;
  width: 0;
  height: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.reactEasyCrop_CropAreaRound {
  width: 200px !important;
  height: 200px !important;
}

.reactEasyCrop_Contain {
  position: relative !important;
}

.reactEasyCrop_Container {
  position: relative !important;
  width: 200px !important;
  height: 200px !important;
}

.general-btn {
  cursor: pointer;
}

.RoundAnimation {
  text-align: center;
  border-radius: 50%;
  padding: 50px;
  text-align: center;
  position: relative;
  width: 450px;
  height: 450px;
  background: linear-gradient(
    90deg,
    rgb(250 250 255) 0%,
    rgb(255 255 255) 100%
  );
  margin: auto;
  position: absolute;
  right: 140px;
  left: 0;
  margin: auto;
  top: -180px;
}

.RoundAnimation::before {
  content: "";
  position: absolute;
  width: 450px;
  height: 450px;
  background: #52a1fa;
  top: 0;
  right: -110px;
  box-shadow: inset 0 -8px 50px #3d3d3d;
  border-radius: 50%;
}

.Round1 img {
  width: 100%;
}

.Round1 {
  width: 40px;
  height: 40px;
  border: 1px solid #ccc;
  transform: rotate(45deg);
  margin: auto;
  padding: 5px;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 20px;
  border: 2px solid #85b812;
  background: #fff;
}

.circle11 {
  position: absolute;
  width: 50px;
  height: 50px;
  left: calc(50% - 25px);
  top: calc(50% - 25px);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  animation-name: dimCircularAni;
  animation-duration: 8s;
  animation-timing-function: linear;
  animation-delay: 0s;
  border: 1px solid #a7d6ff;
  animation-iteration-count: infinite;
  background: linear-gradient(
    90deg,
    rgb(250 250 255) 0%,
    rgb(179 208 242) 100%
  );
}

.circle11::after {
  top: 10px;
  left: auto;
  right: 0;
}

.circle11.circle12 {
  width: 150px;
  height: 150px;
  left: calc(50% - 75px);
  animation-duration: 8s;
  top: calc(50% - 75px);
  z-index: 7;
  background: #fff;
  box-shadow: 0 0 20px #000;
}

.circle11.circle12 .Round1 {
  bottom: -16px;
  top: auto;
  left: 64px;
  border: 2px solid #1ec1d9;
}

.circle11.circle13 {
  width: 250px;
  height: 250px;
  left: calc(50% - 125px);
  animation-duration: 7.5s;
  z-index: 6;
  top: calc(50% - 125px);
  background: #0551b0;
}

.circle11.circle13 .Round1 {
  top: -15px;
  left: 70px;
  border-color: #ff9900;
}

.circle11.circle14 {
  width: 350px;
  height: 350px;
  left: calc(50% - 175px);
  animation-duration: 7s;
  z-index: 5;
  background: linear-gradient(
    90deg,
    rgb(250 250 255) 0%,
    rgb(179 208 242) 100%
  );
  top: calc(50% - 175px);
}

.circle11.basiccircle {
  z-index: 8;
  box-shadow: 0 0 25px #05172e;
}

.circle11.circle14 .Round1 {
  top: auto;
  bottom: -15px;
  left: 120px;
  border-color: #e46666;
}

.circle11.circle15 {
  width: 450px;
  height: 450px;
  left: calc(50% - 225px);
  animation-duration: 8s;
  z-index: 4;
  background: linear-gradient(
    90deg,
    rgb(250 250 255) 0%,
    rgb(179 208 242) 100%
  );
  top: calc(50% - 225px);
}

.circle11.circle15 .Round1 {
  top: -10px;
  border-color: #a86adb;
  left: 133px;
}

@keyframes dimCircularAni {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.MovetoTop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: #2b468b;
  color: white;
  cursor: pointer;
  padding: 5px 15px;
  border-radius: 4px;
}

.MovetoTop:hover {
  background-color: #e46666;
  color: #fff;
  box-shadow: 0 0 10px #999;
}

.input-group.Questionlisting .ActivityCategory {
  width: 190px;
  position: absolute;
  left: 20px;
  border: 1px solid #ccc;
  top: -131px;
}

.RatetousPopup .AsssesQListItem h4 {
  font-size: 15px;
  color: #4e4e4e;
  line-height: 1.3;
  font-weight: 600;
  margin-bottom: 10px;
}

.RatetousPopup h3 {
  font-size: 18px;
}

.markDivision {
  position: absolute;
  top: 69px;
  left: 50%;
  font-size: 20px;
  width: 50px;
  text-align: center;
  color: #ff6a4c;
  height: 30px;
  background: #fff;
  transform: translate(-50%);
}

.AssessViewGrade span {
  width: 48px;
  height: 48px;
  line-height: 44px;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 21px;
  border-radius: 50%;
  background: #ff6a4c;
  box-shadow: 0 0 4px #ccc;
  box-shadow: 0 0px 5px #00000033, 0 0 0 transparent;
  border: 2px solid;
  margin-left: 10px;
}

.AsssesQListItem p span {
  font-weight: 600;
  color: #000;
}

.MainContent .upload_box {
  width: 100%;
  margin: 0;
  box-shadow: none !important;
  height: 100%;
  max-height: 150px;
  overflow: visible;
  border-radius: 0 !important;
  padding-bottom: 30px !important;
  box-shadow: 0 6px 10px #ccc !important;
  padding: 15px;
  margin-bottom: 5px;
  background-image: url("./resources/images/pattern.png");
  background-size: cover;
  background-repeat: no-repeat;
  min-height: auto;
  background-position: bottom;
  border-top: 0;
}

.content.imgFrm {
  margin-top: 14px;
}

.Profile_details h4 {
  font-size: 25px;
  color: #747474;
  text-transform: capitalize;
}

.wrapper .imgFrm .Profile_details p {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  padding-bottom: 20px;
}

.ProfileQuote input#addBio {
  height: 30px;
}

.UploadProimagepopup .modal-header {
  background: #1d2226;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px 4px 0px 0px;
}

.RemovePhoto .btn-blue svg.svg-inline--fa {
  padding: 0px 5px 0px 0px;
}

.imgFrm .Profile_details .userEmailAndNumbr.mt-2 p svg,.studentClsDetails .PageTitle svg.fa-envelope-dot {
  vertical-align: middle;
  color: #ff9900;
}

.photoUploded .BDsubmitbutton .edit_btn {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  line-height: 21px;
}

.photoUploded .BDsubmitbutton .add_btn {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  line-height: 21px;
}

.photoUploded .BDsubmitbutton .save_btn {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  line-height: 21px;
}

.photoUploded .BDsubmitbutton .remove_btn {
  background: transparent;
  border: none;
  font-size: 16px;
  color: #fff;
  line-height: 21px;
}

.common_search .spinner {
  right: 10px;
  top: 43px;
}

.placeholderseach .common_search .spinner {
  top: 9px;
}

.halfPagePOpup.AssumptionPopup .modal.fade.show .modal-dialog.modal-lg,
.halfPagePOpup.HolisticProgress .modal.fade.show .modal-dialog.modal-lg,
.halfPagePOpup.AllDiemensionChart .modal.fade.show .modal-dialog.modal-lg {
  width: 100%;
  max-width: 90%;
  margin: auto !important;
  left: 0;
  max-height: 93%;
}

.halfPagePOpup.HolisticProgress .modal.fade.show .modal-dialog.modal-lg {
  max-width: 70%;
  max-height: 90%;
}

.halfPagePOpup.AllDiemensionChart .modal.fade.show .modal-dialog.modal-lg {
  max-width: 72%;
  max-height: 80%;
}

.halfPagePOpup.AssumptionPopup .modal-body,
.halfPagePOpup.HolisticProgress .modal-body {
  min-height: 74vh;
}

.newsignup {
  width: 30%;
  height: auto;
  max-height: 100%;
}

.newsignup .Accountrelated form.signupform {
  width: 100%;
}

.i_style {
  font-style: italic;
  color: #329fff;
  padding: 1px 4px;
}

.BDsubmitbutton button.btn-blue.btn-signup {
  width: 100%;
  padding: 7px;
  font-size: 16px;
  border: 1px solid #329fff;
}

.SchoolActivityPopup.halfPagePOpup .bodyTitle h4 {
  font-size: 17px;
  color: #2f2f2f;
  font-weight: 600;
  border: 0;
  padding: 0px 0 0px;
}

.courseInformation .heading h2 svg.svg-inline--fa.fa-chevron-down {
  font-size: 14px;
  color: #000000;
}

.courseInformation .heading h2 svg.svg-inline--fa.fa-list-radio {
  color: #232323;
}

.Subject_Curriculam .bodyTitle .Subject_Curriculam svg {
  font-size: 24px;
}

.tabgrid.intresttab ul li {
  width: 27%;
  padding: 4px 10px;
}

.tabgrid.intresttab {
  margin-bottom: 0;
}

.RightbarPannel .instlistitem {
  background: #329fff;
}

.RightbarPannel .instlistitem .HurtCount {
  display: none;
}

.RightbarPannel .instlistitem h4 {
  color: #fff;
}

.ListIcon svg {
  font-size: 18px;
}

.RightbarPannel .instlistitem h4 .fa-circle-plus {
  margin-right: 5px;
}

img.landingpagelogo {
  display: none;
}

.landing-page img.landingpagelogo {
  display: block;
  max-width: 260px;
  padding-top: 0px;
}

.landing-page img.internallogo {
  display: none;
}

.navSidebar.inactive li.tabactive:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-right: 8px solid #f4f4f4;
  border-bottom: 5px solid transparent;
  right: -1px;
}

.navSidebar ul.dp-submenu li.tabactive:before {
  border: none;
}

.banner_style:before {
  content: "";
  position: absolute;
  width: 1020px;
  border-radius: 50%;
  background: radial-gradient(
    circle,
    rgba(37, 88, 133, 1) 0%,
    rgba(14, 51, 87, 1) 100%
  );
  right: -259px;
  top: -175px;
  height: 1100px;
  z-index: 1;
}

.landing-page .header {
  height: 67px;
  transition: all 0.2s;
  background: transparent;
  z-index: 999;
}

.landing-page header.is-sticky {
  box-shadow: 0 0 4px #ccc;
  background: #fff !important;
  height: 65px;
  z-index: 999;
}

span.crossmark {
  height: 17px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  color: #b8b8b8;
  border: 1px solid #ccc;
  width: 17px;
  padding: 0px 0px;
  line-height: 1;
  background: #fff;
}

span.crossmark svg {
  font-size: 14px !important;
}

.RightbarPannel span.crossmark {
  color: #fff;
  border: 1px solid #fff;
}

.CourseSearchFilter .instlistitem {
  background: #fff;
  margin: 0px 5px 0px;
}

.profile_division .intrestlisting button.crossmark:hover {
  color: #ff1100;
  border: 1px solid #ff1100;
}
.profile_division .intrestlisting button.crossmark {
  background: none;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 1px 3px;
}
.menuList.landingpageMenu li {
  width: auto;
  height: auto;
  color: #fff;
  cursor: pointer;
}

.menuList.landingpageMenu li.signup_styl {
  background: #e46666 !important;
  color: #fff;
  font-weight: 400;
  border-radius: 10px !important;
  padding: 0px 7px;
}

header.is-sticky .menuList.landingpageMenu li {
  color: #212529;
}

header.is-sticky .menuList.landingpageMenu li.signup_styl {
  color: #fff;
}

header.is-sticky .menuList.landingpageMenu li a {
  color: #212529;
}

.landing-page header.is-sticky img.landingpagelogo {
  display: block;
  max-height: 60px;
}

.menuList.landingpageMenu li a {
  color: #fff;
  font-size: 16px;
  padding: 4px 7px !important;
}

.landingpageMenu .menuList li a.active {
  color: #e46666 !important;
  border: 1px solid #e46666;
  padding: 4px 0px;
  border-radius: 10px;
}

/* .menuList.landingpageMenu li a:hover,
.menuList.landingpageMenu li:hover {
  color: #329fff !important;
} */

.menuList.landingpageMenu li:last-child {
  background: transparent;
  border: 0;
}

.fontawsomeicon {
  color: #747474;
  font-size: 16px;
  margin-right: 5px;
}

.AddReviewBtn.text-center.mt-3.pt-3 span.pointer {
  color: #424242;
}

.ProCoursecard .RatingPopupdiv.dropdown-menu.d-block.dropdown-menu-end,
#coursedetailpage
  .teachername
  .RatingPopupdiv.dropdown-menu.d-block.dropdown-menu-end {
  left: auto;
  top: 0px;
  padding: 20px;
  max-width: 400px;
}

#coursedetailpage .RatingPopupdiv.dropdown-menu.d-block.dropdown-menu-end {
  left: 0;
  top: 29px;
}

.teacherdetailhalfsc
  #coursedetailpage
  .RatingPopupdiv.dropdown-menu.d-block.dropdown-menu-end {
  left: 0;
  top: 0;
  min-width: 410px;
  max-width: 410px;
}

.teacherdetailhalfsc #coursedetailpage .RatingPopupdiv.dropdown-menu::after,
#coursedetailpage .RatingPopupdiv.dropdown-menu::after {
  right: 78%;
}

#coursedetailpage .teachername .RatingPopupdiv.dropdown-menu::after {
  right: 5.5%;
}

.Coursebanner .ratingProgressbar p {
  margin: 0;
}

.lernerparents .profile_division .panel-collapse .panel-body:last-child {
  justify-content: right !important;
}

.TeachAssessmentPopup .form-group label span {
  color: #b7b7b7;
  padding-left: 5px;
}

.courseInformation .heading h2 ul {
  border-radius: 0px 0 5px 5px;
  border-top: 0;
  padding: 5px !important;
  background: #f5faff;
  top: 1px !important;
  max-height: 285px;
  overflow: auto;
}
div#schoolactivity4 .courseInformation .heading h2 ul {
  border: 0 !important;
}
.courseInformation .heading h2 ul li {
  font-weight: normal;
  border-bottom: 1px dotted #ccc;
  padding: 10px 5px;
  cursor: pointer;
}

.HolisticView svg {
  font-size: 18px;
  color: #424242;
}

.HolisticView {
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: #006dcc;
}

.HolisticView img {
  max-width: 24px;
  margin-right: 10px;
}

.ChartHistory span {
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) -100%,
    #c1c1c1 100%
  );
}

/* Course Details Page srart*/
.Coursebanner {
}

.Coursebanner h3,
.Aboutmedesc h3 {
  font-size: 18px;
  color: #232323;
  font-weight: 600;
  /* font-family: Avenir_bold !important; */
}

.Aboutmedesc h3 {
  color: #555656;
}

.Coursebanner p,
.Aboutmedesc p {
  color: #232323;
  font-size: 16px;
  margin: 0px 0;
  padding: 5px 0;
}

.thanksyoupage p.tytxtmessage {
  font-size: 22px;
  margin-top: 15px;
}

.CourseJourney .jrny_box p {
  padding-right: 10px;
  font-size: 16px;
  color: #232323;
}

.Coursebanner .bannerleft {
  width: 24%;
  padding-right: 15px;
  /* font-family: Avenir_bold !important; */
}

.Coursebanner .bannerright {
  width: 76%;
}

.border_radius50 {
  border-radius: 50%;
}

.coursedtlImage {
  /* width: 200px;
  height: 200px; */
  border: 1px solid #aaa;
}

.ProCourseNameDes h4 {
  font-size: 16px;
  color: #232323;
  /* font-family: "Avenir_bold" !important; */
  line-height: 1;
}

/* .EnrolledCourse button {
  padding: 8px 30px !important;
} */

.Rating span {
  line-height: 1;
}

.CoursebannerWrap {
  padding: 20px 10px 20px 10px;
  background: #ffffff;
  margin-left: -10px;
  margin-right: -10px;
  box-shadow: 0px 4px 6px #dedede;
}

.Procourselevel {
  color: #3b3b3b;
  padding: 20px 0 0px;
}

.ProCoursename {
  margin: 0px 0 15px;
}

.Coursebanner.cpTeacherBanner:after {
  display: none;
}

.CourseProTeacher::after {
  background: url("./resources/images/curvepattern2.png");
  bottom: auto;
  height: 36px;
  top: 0;
  content: "";
}

.ProCoursenameImage img {
  width: 40px;
  height: 40px;
  border: 1px solid #adadad;
  border-radius: 50%;
}

.CoursedetailList {
  padding: 20px 0;
}

.Coursebanner .bannerright.coursedetailbnr {
  min-height: auto;
}

.CoursedetailList li {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  color: #484848;
  width: 50%;
  margin: 5px 0;
}

.sharebutton button.btn-blue.btn-login.d-block {
  width: auto;
  font-size: 16px;
}

.CoursedetailList li span {
  font-size: 14px;
  padding-left: 5px;
  color: #808080;
}

.CoursedetailList li span.Questionmark {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #329fff;
  color: #329fff;
  border-radius: 50%;
  margin-left: 5px;
}

.CoursedetailList li svg {
  font-size: 16px !important;
  margin-right: 10px;
  padding: 7px !important;
  border: 0;
  width: 30px;
  height: 30px;
  color: #fff;
  padding: 10px;
  box-sizing: border-box !important;
  border-radius: 50%;
  background: #0d6efd;
}

.CoursedetailList ul {
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px !important;
  background: #f3f7fd;
  border-radius: 10px;
  border: 1px solid #0d6efd;
}

.CoursePriceDetail {
  width: 40%;
  text-align: right;
}

.CoursePriceDetail a {
  font-size: 26px;
  display: inline-block;
  padding: 20px 20px 21px;
  border-radius: 9px;
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
  /* font-family: "Avenir_bold" !important; */
  border: 1px solid #005fb2;
  color: #005fb2;
}

.GridCardFullDetails span.read-or-hide.pointer .readmoretxt,
.CourseDetails span.read-or-hide.pointer .readmoretxt,
.jrny_box span.read-or-hide.pointer .readmoretxt {
  color: #329fff;
  background: #fff;
  white-space: nowrap;
  font-size: 14px !important;
}

/* .CourseDetails span.read-or-hide.pointer .readmoretxt {
  top: 42px;
}
.jrny_box span.read-or-hide.pointer .readmoretxt {
  top: 28px;
} */
.GridCardFullDetails span.read-or-hide.pointer .readmoretxt.leasstext,
.CourseDetails span.read-or-hide.pointer .readmoretxt.leasstext,
.jrny_box span.read-or-hide.pointer .readmoretxt.leasstext {
  position: static;
  white-space: nowrap;
  padding-left: 3px;
}

.leasstext::before {
  display: none;
}

.CoursePriceDetail span {
  display: block;
  font-weight: normal;
  font-size: 16px;
  color: #434343;
}

.text.textdescription .textdesc {
  max-height: 100%;
  overflow: auto;
}
.CourseDetails .text.textdescription .textdesc {
  max-height: 100%;
  overflow: auto;
  font-size: 17px;
}
.text.textdescription.coursetextheght .textdesc {
  max-height: 100%;
  overflow: auto;
  max-height: 46px;
  overflow: hidden;
  line-height: 1.5;
}

.CourseDetails .text.textdescription.coursetextheght .textdesc {
  max-height: 60px;
  font-size: 17px;
}

.jrny_box .text.textdescription.coursetextheght .textdesc {
  max-height: 46px;
}

.CourseProTeacher {
  background: #f9f9f9;
  padding: 20px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.CourseBrifeDesc h3 {
  font-size: 18px;
  color: #404040;
  margin-bottom: 5px;
  /* font-family: "Avenir_bold"; */
}

.CourseProTaecrImgae {
  text-align: right;
}

.CourseProTaecrImgae img,
.fusionchartimg {
  width: 200px;
  max-width: 200px;
  height: 200px;
  border: 5px solid #fff;
}

.CourseBrifeDesc h5 {
  font-size: 16px;
  color: #329fff;
  /* font-family: "Avenir_bold"; */
}

.Certifiedby h4 {
  font-size: 16px;
  /* font-family: "Avenir_bold"; */
  color: #5c5c5c;
}

.Certifiedby p {
  margin-bottom: 15px;
}

.Certifiedby span {
  font-weight: bold;
}

.TeacherAboutus h4 {
  font-size: 18px;
  margin-bottom: 5px;
}

footer {
  padding: 20px 0;
  text-align: center;
  color: #fff;
  background: #061545;
}

footer p {
  color: #fff;
  /* font-family: "Avenir_bold"; */
  font-size: 12px;
}

.cpTeacherBanner .ProCoursename {
  margin: 0px 0 20px;
}

section.CPaboutmeSec {
  padding: 20px 0;
}

.Aboutmedesc,
.CourseBrifeDesc {
  width: 60%;
}

.Aboutmeimagsec,
.CourseProTaecrImgae {
  width: 40%;
  text-align: right;
}

h3 {
  font-size: 21px;
}

.ClassCalendr li {
  display: inline-block;
  font-size: 14px;
  color: #808080;
}

.ClassCalendr ul {
  display: flex;
  justify-content: space-between;
  padding-right: 138px;
  padding-top: 20px;
}

.ClassCalendr li svg {
  color: #329fff;
  margin-right: 5px;
}

.Aboutmedesc,
.personalskillgraphics {
  width: 50%;
}

.Aboutmeimagsec {
  width: 50%;
  text-align: right;
}

h3 {
  font-size: 21px;
}

.ClassCalendr li {
  display: inline-block;
  font-size: 14px;
  color: #808080;
}

.ClassCalendr ul {
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
  padding-top: 10px;
}

.ClassCalendr li svg {
  color: #329fff;
  margin-right: 5px;
}

.personalskillgraphics {
  width: 60%;
}

.psListing {
  border-radius: 8px;
  width: 40%;
  background: transparent;
  padding: 10px;
  max-height: 408px;
  overflow: auto;
  height: 320px;
}

section.PersonalskillsListSec {
  padding: 40px 0;
  background: #f3f7fd;
}

.pslistitems {
  display: flex;
  border: 1px solid #329fff;
  padding: 3px 10px 3px 25px;
  background: #b5d8f7;
  position: relative;
  margin: 10px 0;
}

.psCourseDetails h4 {
  color: #252525;
  font-size: 17px;
  text-transform: capitalize;
  line-height: 1.3;
  font-weight: 600;
}

.pslistitems:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 20px solid #329fff;
  border-bottom: 25px solid transparent;
  position: absolute;
  left: 0;
  top: 0;
}

.psCourseDetails p {
  color: #2385dc;
  font-weight: 400;
}

section.serviceprovidersec {
  padding: 40;
  padding: 40px 0;
}

.provideServicetitle h3 {
  text-align: center;
}

.provideServicetitle h3 {
  position: relative;
  display: inline-block;
}

.servicelistitem {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
  margin-bottom: 10px;
}

.servicelistitem span {
  font-size: 20px;
  width: 50px;
  height: 50px;
  display: inline-block;
  border: 1px solid #1a76ff;
  display: block;
  line-height: 50px;
  border-radius: 50%;
  color: #329fff;
  margin: auto;
  margin-bottom: 10px;
}

.wrapper .imgFrm .Profile_details .userEmailAndNumbr.mt-2 p {
  font-size: 14px;
}

.servicelist {
  padding-top: 15px;
}

.servicelistitem p {
  color: #808080;
}

.coursedetailpopuppage .container {
  max-width: 100%;
}

/* Course Details Page End*/
.stuchalagelist .accordion-collapse {
  padding: 5px 15px 15px;
}

.probleumrelatedvideolist.w-100 h4 strong {
  color: #48cfae;
  font-weight: 600;
}

.stuchalagelist .accordion-button:not(.collapsed) {
  color: #616161;
}

.Gridcard .Gridsocialsec svg.star-svg {
  margin-right: 0;
}

.addTags .searchInputs input.form-control {
  outline: none;
  margin: 5px 0 10px;
  padding: 0 10px;
  border: 1px solid #ccc !important;
}

.addTags .form-control:focus {
  border: 0 !important;
  box-shadow: none;
}

.ProbvidListItem .videoDescription h3 {
  font-size: 16px;
}

.ProbvidListItem .videoDescription h5,
.ProbvidListItem .videoDescription p {
  font-size: 14px;
  color: #6f6f6f;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
}

.taglistsearch input.form-control {
  border: 0 !important;
}

.ActivityTitle .videoDescription ul li span.SubFName,
.GridCardTitleDesc .ListIcon ul li span.SubFName {
  display: flex;
  align-items: center;
}

.speclistimage {
  width: 117px;
  height: 115px;
  z-index: 9;
  margin-right: 10px;
  position: absolute;
  left: 10px;
  top: 65px;
  transform: translateY(-50%);
}

.spacelistDesc {
  width: 100%;
  box-shadow: 5px 3px 4px 0px rgb(183 183 183 / 50%);
  overflow: hidden;
}

.speclistimage img {
  border-radius: 5px;
  background: #fff;
  border: 2px solid #1ec1d9;
}

.Topicspeclistitem {
  width: 100%;
  background: #fff;
  margin-bottom: 15px;
  position: relative;
  border: 1px solid #ccc;
  min-height: 160px;
}

.spacelistDesc ul li {
  font-size: 14px;
}

.spacelistDesc .speclistdesctext {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

/* .spacelistDesc ul li img {
  width: 24px;
} */
.Coursevidtitle {
  width: 70%;
  position: relative;
}

.Coursevidtitle h5 {
  border: 1px solid #ccc;
  padding: 5px;
  font-size: 14px;
}

.coverlesson {
  padding: 5px !important;
  border: 1px solid #ccc;
  border-top: 0;
  max-height: 166px;
  background: #fff;
  z-index: 9;
  overflow: auto;
  position: absolute;
  width: 100%;
}

.dropdown- svg.fa-circle-plus {
  margin-right: 10px;
  color: #48cfae;
}

.coverlesson li {
  padding: 10px 10px;
  margin-bottom: 5px;
  font-size: 14px;
  color: #575757;
  border-bottom: 1px solid #eee;
}

.spacelistDesc ul li svg {
  font-size: 16px;
  width: 24px;
}

.spacelistDesc ul.spaclistcontactlist li .RatingWrap1 svg {
  width: auto;
}

.specialistTitle {
  padding: 10px 10px 10px 145px;
  display: flex;
  align-items: end;
  justify-content: space-between;
}

ul.spaclistcontactlist {
  padding: 10px 10px 15px 145px !important;
  background: #f5faff;
  border-top: 2px solid #1ec1d9;
}

.submoduleList li img {
  max-width: 24px;
}

.PublishBtn button {
  background: #329fff;
  color: #fff;
  border: 0;
}

.PublishBtn.w-100 {
  text-align: right;
  padding: 0 15px;
}

.CurriMainTitle li {
  font-size: 17px;
  color: #1d103f;
  font-weight: 600;
}

.AssessmentScore .gradeDivsion .react-datepicker-popper {
  transform: translate(0px, 64px) !important;
}

.submoduleList li span:nth-child(1),
.CurriMainTitle li:nth-child(1) {
  width: 70%;
}

.submoduleList li span:nth-child(3),
.CurriMainTitle li:nth-child(3) {
  width: 20%;
  text-align: center;
}

input.lessionStatuscheck {
  width: 16px;
  height: 16px;
}

ul.CurriMainTitle {
  padding: 15px 50px 10px 15px !important;
  margin-right: 0;
  margin-top: 20px;
}

.submoduleList li:last-child {
  margin-bottom: 0;
  border: 0;
  padding-bottom: 0;
}

.css-1hwfws3 {
  height: 38px;
}

.input-group .form-control.is-invalid {
  z-index: 0;
  background: none;
}

.invalid-feedback {
  margin-bottom: 0;
}

.text-danger {
  color: #dc3545 !important;
  font-size: 12px;
}

.selectedProbleum span.crossmark svg,
.probleumListitem span.crossmark svg {
  margin-right: 0;
}

.PannelContent [name="basicdetailform"] .react-datepicker-popper {
  transform: translate(0px, 65px) !important;
}

.SignupPopup .modal-dialog {
  max-width: 100%;
  margin: 10px;
}

/* .fade.SignupPopup.AddChildPopup.modal.show {
  padding: 0;
} */
.AddChildPopup.SignupPopup .modal-content {
  max-width: 100%;
  background: transparent;
  background-size: cover;
  background-repeat: no-repeat;
}

.AddChildPopup.SignupPopup .MainContent {
  padding: 10px 0 10px;
  min-height: 115vh;
  display: flex;
  align-items: center;
}

.AddChildPopup.SignupPopup .Main {
  padding-top: 0;
  background: transparent;
}

.SignupPopup .PasswordIntruction {
  padding-top: 33px;
  position: absolute;
  width: 48%;
  left: 0;
  top: -151px;
  /* display: none; */
}
li.skillcount_txt span.skillCountlegend {
  position: absolute;
  right: -1px;
  top: 6px;
  background: #fff;
}
.SignupPopup .PasswordIntruction ul li {
  padding: 4px 10px;
  font-size: 13px;
  background: #f5faff !important;
}

.SignupPopup .modal-dialog .SignupRegistration {
  width: 60%;
  margin: 0px auto;
  background: #fff;
  border-radius: 10px;
  border: 0px solid #005fb2;
}
.input-group.signupType.p-0.createlernerlogin {
  margin-top: 25px;
}
span.time_zomemsg {
  font-size: 12px;
}
.SignupPopup .modal-dialog .SignupRegistration div#signin .signupType {
  position: relative;
  padding: 0px 10px 0px;
}

.SignupPopup .modal-dialog .SignupRegistration .input-group {
  width: calc(33.33% - 10px);
  margin-right: 15px;
}
.Sign_Up_Form {
  padding: 0px 10px 0px;
}

.input-group.fsignupBtn .btn-login.d-block {
  width: auto;
  margin-left: auto;
  text-transform: capitalize;
}

.AddChildPopup .modal-title.h4 img {
  max-width: 20px;
}

.teachergroup .Teacherphonenumber .invalid-feedback {
  padding-left: 150px;
}

/* .SignupPopup .modal-header {
  padding: 0;
  border: 0;
} */
p.text-danger {
  margin-top: 5px;
}

.SignupPopup .header {
  width: calc(100% - 1px);
}

span.Tooltiphelp {
  color: #979797;
  font-size: 14px;
  position: relative;
}

/* report Card Start */
.ReportcardName,
.RepoTeacherInfo {
  width: 35%;
  background: #3e77b9;
  border-right: 1px solid #488bd8;
  min-height: 112px;
}

.RepoVickylogo {
  width: 30%;
  min-height: 112px;
  background: #0d51a0;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}

.ReportcardName span,
.RepoTeacherInfo span {
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  display: block;
}

.ReportcardName h4,
.RepoTeacherInfo h4 {
  padding: 0 10px 10px 10px;
  color: #ffffff;
  font-size: 16px;
}

.ReportcardName h3,
.RepoTeacherInfo h3 {
  border-bottom: 1px solid #488bd8;
  padding: 10px;
  color: #fff;
  margin-bottom: 10px;
}

.EvaluationScaletab {
  margin-top: 20px;
  overflow-x: auto;
  white-space: nowrap;
}

.EvaluationScaletab table {
  width: 100%;
  text-align: center;
  border: 1px solid #ccc;
}

.EvaluationScaletab table td {
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  min-width: 200px;
}

.QuestionList table td {
  min-width: 50px;
  font-size: 14px;
}

.QuestionList table th {
  font-size: 16px;
  padding: 10px;
  background: #efefef;
  text-align: left;
  color: #3e77b9;
}

.QuestionList table td:first-child {
  max-width: 500px;
  min-width: 500px;
  text-align: left;
  white-space: normal;
}

.EvaluationScaletab h4 {
  font-size: 16px;
  font-weight: 600;
}

.SelfGenratedTags .form-title h3 {
  font-size: 16px;
}

.AViewListitem {
  width: 100%;
  max-height: 120px;
  min-height: 120px;
  display: flex;
  padding: 0;
}

.LinkImage.pointer {
  width: 20%;
  margin-right: 10px;
}

.LinkImage.pointer img {
  width: 100%;
  border: 2px solid #ffcb5b;
  border-radius: 3px;
  cursor: auto;
  padding: 0px;
  height: auto;
}

.TeacherActivityimg {
  width: 40px;
}

.Courseskilledlist .panel-body li span img {
  max-width: 24px;
}

.Courseskilledlist .panel-body li span {
  font-size: 15px;
  color: #3b3b3b;
}
.panel-body:hover {
  padding: 10px 8px !important;
  background: #eee;
  transition: all 0.3s;
}
.TeacherActivityimg img,
.TeacherActivityimg svg.ActivityIcon {
  max-height: 40px;
  border-radius: 50%;
  width: 40px;
  /* padding: 4px; */
  height: 40px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

.Activity.selfmadeActivity .ActivitycrdTitle {
  padding-left: 45px; /*50px; */
  min-height: 40px;
}

.HalfScreenAct .Activity.selfmadeActivity .ActivitycrdTitle {
  min-height: 40px;
}

.Activity.selfmadeActivity h3 span.ThumnailTeacher,
.AssessmentCards h3.ActivyTitleName span.ThumnailTeacher {
  position: absolute;
  width: 35px;
  height: 35px;
  text-align: center;
  left: 5px;
  line-height: 40px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProCoursecard h3.ActivyTitleName.pointer {
  padding: 10px 10px 10px 50px !important;
  color: #329efd;
}

.TeacherActivityimg svg.ActivityIcon {
  padding: 9px;
}

.selectVideoviewList .VideoThumbimg {
  width: 25%;
}

.searchVideoList .videoDescription {
  padding-right: 20px;
  width: calc(100% - 25%);
}

.courseInformation.searchVideoList li.d-flex.mb-3.position-relative {
  max-height: 148px;
}

.zIndexClass {
  z-index: -1;
  position: relative;
}

.css-1wa3eu0-placeholder {
  font-size: 14px;
}

.react-tel-input {
  height: fit-content;
}

.datatable {
  white-space: nowrap;
  z-index: 1;
  position: relative;
  border: 1px solid #ccc;
  text-align: right;
}

.assessmentWrap {
  overflow: auto;
}

.AllSubgradetable .tabitem {
  font-weight: 600;
  font-size: 15px;
}

.AllSubgradetable .tabitem,
.AllSubgradetable .tabitem {
  border: 0;
  padding: 5px;
  font-size: 14px;
  text-align: left;
  height: 48px;
  line-height: 2.6;
  width: 60px;
  margin: auto;
}

.tablebody .tabletr:first-child .tabitem {
  margin-left: 0;
}

.dateitems {
  padding: 5px;
  width: 135px;
}

.basicdetailsform .tabitem .input-group {
  width: 100%;
}

.tabitem span.ortag {
  font-size: 12px;
  padding: 5px;
  color: #ababab;
}

.tablebody {
  padding: 0px 0 0px;
}

.AllSubgradetable th {
  background: #4074a9;
  color: #fff;
}

.AllSubgradetable .tableheader .tabitem {
  background: #ababab;
  color: #fff;
  border-bottom: 1px solid;
  height: 38px;
  line-height: 17px;
  padding: 10px;
  text-align: center;
  width: 135px;
  border-right: 1px solid white;
}

.tabletr {
  align-items: center;
  max-width: 135px;
}

.tableheader .tabitem.blanktab {
  width: 150px;
}

th.DieminTitle {
  border-right: 0;
}

.SpecialCatCard .spacelistDesc.Topicspeclistitem {
  margin-bottom: 0;
  border: 0;
  box-shadow: none;
}

.SpecialCatCard .spacelistDesc ul li {
  font-size: 15px;
  width: 32.5%;
  color: #10adc4;
  display: inline-block;
  padding: 7px;
  cursor: pointer;
  border: 1px solid #10adc4;
  text-align: center;
  background: #f5faff;
}

.addfncCard img {
  max-width: 100px;
}

.addfncCard {
  height: 82vh;
}

.SpecialCatCard .spacelistDesc ul li:hover {
  box-shadow: 3px 3px 5px #b9b7b7;
  background: #1ec1d9;
  color: #fff;
}

.SpecialCatCard .specialistTitle,
.SpecialCatCard .spaclistcontactlist {
  height: 100px;
  min-height: 100px;
}

.ReCoursListWrap ul li {
  width: 25%;
}

.ReCoursListWrap .GridiamgeCard ul li {
  width: 100%;
}

.SignupPopup .menuList.landingpageMenu li a,
.SignupPopup .menuList.landingpageMenu li {
  color: #212529;
  font-size: 16px;
  /* font-family: "Avenir_bold"; */
}

/* Guide Poup */
.guidepopups .modal-content {
  height: 100%;
  max-width: none;
  background: transparent;
}

.guidepopups .GuidePopupWrap {
  padding: 20px;
  position: absolute;
  background: rgb(50 159 255 / 100%);
  width: 300px;
  top: 11px;
  border-radius: 10px;
  right: 64px;
}
.GuidePopupWrap button.btn.btn-primary {
  background: #0d6efd;
  width: auto;
  height: auto;
  color: #fff;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.3rem;
}
.guidepopups .GuidePopupWrap.first-place {
  top: 33px;
  left: 48px;
}

.guidepopups .GuidePopupWrap.eighth-place {
  top: 81px;
  left: 48px;
}

.guidepopups .GuidePopupWrap.second-place {
  top: 52px;
  left: auto;
  right: 0;
}

.guidepopups .GuidePopupWrap.third-place {
  top: 190px;
  left: 48px;
}

.guidepopups .GuidePopupWrap.fourth-place {
  bottom: 5px;
  left: 50px;
  top: auto;
}

.guidepopups .GuidePopupWrap.fivth-place {
  top: auto;
  bottom: 0;
  left: 49px;
}

.guidepopups .GuidePopupWrap.sixth-place {
  top: auto;
  bottom: 0;
  left: 48px;
}
.guidepopups .GuidePopupWrap.ninth-place {
  top: auto;
  bottom: 20px;
  left: 49px;
}
.guidepopups .GuidePopupWrap.tenth-place {
  top: 107px;
  right: 12px;
  left: auto;
}
.guidepopups .GuidePopupWrap.seventh-place {
  top: 123px;
  left: 48px;
}
/* .guidepopups .GuidePopupWrap.eighth-place {
  top: 130px;
  left: 48px;
} */
.guidepopups .GuidePopupWrap.WelcomePOpScreen {
  right: 50px;
  top: 121px;
  left: auto;
}

/* .guidepopups .GuidePopupWrap.WelcomePOpScreen::before {
  display: none;
} */

.guidepopups .GuidePopupWrap.first-place::before {
  top: 38px;
  right: 98%;
}

.guidepopups .GuidePopupWrap.second-place::before {
  top: 0px;
  right: 25%;
}

.guidepopups .GuidePopupWrap.third-place::before {
  top: 39px;
  right: 98%;
}

.guidepopups .GuidePopupWrap.fourth-place::before {
  top: 41px;
  right: 98%;
}
.guidepopups .GuidePopupWrap.ninth-place::before {
  top: 45%;
  right: 98%;
}
.guidepopups .GuidePopupWrap.fivth-place::before {
  top: 52%;
  right: 98%;
}

.guidepopups .GuidePopupWrap.sixth-place::before {
  top: 31%;
  right: 98%;
}
.guidepopups .GuidePopupWrap.seventh-place::before {
  top: 54px;
  right: 98%;
}
.guidepopups .GuidePopupWrap.eighth-place::before {
  top: 39px;
  right: 98%;
}
.guidepopups .GuidePopupWrap.tenth-place::before {
  right: 20%;
  top: 0;
}
.eighth-place .tabgrid {
  pointer-events: none;
}

.fourth-place .PMSkillList {
  pointer-events: none;
}
.AllguidePoups3 .guidepopups .GuidePopupWrap {
  left: calc(70% + 30px);
  top: 361px;
}

.AllguidePoups3 .guidepopups .GuidePopupWrap::before {
  content: "";
  position: absolute;
  top: 79px;
  right: 99%;
}

.guidedots span {
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  display: inline-block;
  margin: 0 5px;
  border-radius: 50%;
}

.AllguidePoups1 .LeftbarPannel .heading {
  z-index: 100001;
}

.AllguidePoups1 .guidedots span:nth-child(1) {
  background: #fff;
}

.AllguidePoups2 .guidedots span:nth-child(1) {
  background: transparent;
}

.AllguidePoups2 .guidedots span:nth-child(2) {
  background: #fff;
}

.AllguidePoups3 .guidedots span:nth-child(2),
.AllguidePoups3 .guidedots span:nth-child(1) {
  background: transparent;
}

.AllguidePoups3
  .content.active-content
  div:first-child
  .GridCardList
  .activityListitems
  .Activity:nth-child(2) {
  z-index: 10001;
  background: #fff;
}

.AllguidePoups3 .guidedots span:nth-child(3) {
  background: #fff;
}

.GuidePopupWrap h3,
.GuidePopupWrap p {
  color: #fff;
}

.guidepopups .modal-dialog {
  max-width: inherit;
  height: 100%;
  margin: 0;
}

.guidepopups {
  padding-top: 0;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
}

.AllguidePoups2 .guidepopups .GuidePopupWrap {
  left: calc(70% + 62px);
}

.closelist .moduleList .submoduleList.panel-collapse {
  display: none;
  transition: all 0.3s;
}

.closelist .moduleList .submoduleList.panel-collapse.collapse.show {
  display: block;
}

.openlist .moduleList .submoduleList.panel-collapse {
  display: block;
  transition: all 0.3s;
}

.guidepopups .GuidePopupWrap::before {
  content: "";
  position: absolute;
  top: 13px;
  right: 0%;
  width: 15px;
  transform: rotate(45deg) translateY(-50%);
  height: 15px;
  background: #329fff;
}

.form-check.form-switch {
  text-align: right;
}

.CuriculmContnr {
  position: relative;
}

.PatahwaysSel {
  background: #f2f2f2;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  margin-top: 15px;
}

.Selectpathways h3 {
  font-size: 15px;
  cursor: pointer;
  background: #ffffff;
  padding: 10px;
  font-weight: 600;
  color: #313131;
  border-bottom: 1px solid #fff;
}

.Pathwayslist .checkmark {
  background-color: #a1a1a1;
}

.pathwaysListitem label.Selcheckbox {
  font-size: 15px;
  color: #666666;
  width: 48%;
  padding: 10px 36px;
  background: #fff;
  align-items: center;
  border: 1px solid #ccc;
  display: flex;
  border-radius: 29px;
  box-shadow: 2px 5px 5px #c5c5c5;
}

.pathwaysListitem .checkmark {
  position: absolute;
  top: 11px;
  left: 10px;
}

.pathwaysListitem,
.Submitpathways {
  padding: 10px;
  align-items: baseline;
}

.EnrollcConfimation .close {
  position: absolute;
  right: -9px;
  font-size: 16px;
  top: -13px;
  cursor: pointer;
  border: 0;
  background: #fff;
  padding: 0 7px;
}

.Pathwayslist.dropdown-menu::after {
  display: none;
}

.Pathwayslist.dropdown-menu.show {
  transform: none !important;
  top: 0;
  border: 0;
  background: #2c86d6;
  position: relative !important;
  margin-top: 0px;
  border-radius: 0px;
}

.SelCourseimg {
  width: 65px;
  height: 65px;
}

.Submitpathways button {
  width: auto;
}

.Selectpathways h3 .fa-angle-down {
  transition: all 0.35s;
}

.Selectpathways[aria-expanded="true"] h3 .fa-angle-down {
  transform: rotate(180deg);
}
.Multi_learner_plan .LP_chevron[aria-expanded="true"] svg.svg-inline--fa.fa-chevron-down{
  transform: rotate(180deg);
  transition: all .3s;
}
.Multi_learner_plan .LP_chevron[aria-expanded="false"] svg.svg-inline--fa.fa-chevron-down{
  transform: rotate(0deg);
  transition: all .3s;
}
.Forbiddanerror {
  background: #6badc3;
  height: 100vh;
}

.sharetypeact span {
  font-weight: 600;
}

.SelCourseimg img {
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  width: 100%;
}

.halfPagePOpup.EnrollCourseConfimation .modal .modal-dialog.modal-lg,
.halfPagePOpup.EnrollcConfimation .modal .modal-dialog.modal-lg {
  max-width: 40%;
  max-height: none;
  height: auto;
  top: 50%;
  bottom: auto;
  bottom: auto;
  right: 0;
  margin: auto !important;
  transform: translateY(-50%);
}

.halfPagePOpup.EnrollcConfimation .modal.fade.show .modal-dialog.modal-lg {
  max-width: 50%;
  min-height: 350px;
}

.Poupleftimage {
  padding: 0px;
  width: 38%;
}

.popuprightmsg {
  padding: 10px 0 30px;
  width: 62%;
  align-content: center;
  min-height: 0px;
}

.popuprightmsg h3 {
  font-size: 33px;
}

.popuprightmsg h3.secondchildtxt {
  font-size: 26px;
  font-weight: 400;
  line-height: 1.3;
}

.popuprightmsg h3.secondchildtxt span {
  color: #1a7e7e;
}

.popuprightmsg p {
  font-size: 16px;
}

.SelectCourseLIst {
  padding: 20px;
  max-height: 296px;
  overflow: auto;
}

.QuestionList table td svg {
  color: #329fff;
  margin-right: 5px;
}

.selCourseDesc h3 {
  font-size: 16px;
}

.selCourseDesc h4 {
  font-size: 14px;
  display: flex;
  align-items: center;
}

.selectcourseItem {
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  background: #fff;
  box-shadow: 3px 3px 6px #ccc;
}

.selicoursetemwrap {
  width: calc(100% - 100px);
}

.SelectCourseLIst .Selvidoption {
  border-left: 0;
  width: 95px;
}

.SelectCourseLIst span.uncheckbox {
  width: 19px;
  height: 19px;
}

.selCourseDesc.pl-2 p {
  font-size: 12px;
  padding-top: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 245px;
  display: flex;
}

.AllSubgradetable table {
  background: #eee;
}

.selectcourseItem:last-child {
  margin-bottom: 0;
}

/* Thanks Page */
.Successpage {
  padding-top: 50px;
  min-height: 93.8vh;
}
.Successpage.text-center.deleteAccountpage img {
  max-width: 350px;
  margin: auto;
  margin-bottom: 40px;
}
.Successpage.text-center.deleteAccountpage {
  max-width: 1100px;
  margin: auto;
}
.Successpage h2 {
  font-size: 36px;
  color: #329fff;
  margin-bottom: 20px;
}
.Successpage.text-center.deleteAccountpage h2 {
  font-size: 50px;
}
.Successpage h3 {
  font-size: 26px;
  line-height: 1.5;
  color: #484848;
}
.Successpage.text-center.deleteAccountpage h3 {
  font-size: 30px;
}

.Successimsge.flex.w-50.m-auto,
.Successimsge.flex.w-60.m-auto {
  border: 2px solid #ff9900;
  background: #ececec;
  width: 45% !important;
  margin: 30px auto !important;
}

.Successimsge.flex.w-60.m-auto {
  width: 60% !important;
}

.Successpage h3 span {
  color: red;
}

.Successimsge img {
  max-width: 100%;
}

.Successimsge .robovicky {
  max-width: 85%;
}

.Successpage h3 span.greencolo {
  color: #85b812;
}

.logoerror img {
  max-width: 150x;
}

.AllSubgradetable .input-group.calender .clenderIcon {
  top: 4px;
}

.TeachersLive .ActivitycrdTitle {
  width: calc(100% - 50px);
}

.Activity.ActiveActivity {
  background: #cae6ff;
}

/* Dropdown menu */
ul.dropdown-menu.dropdown-menu-end.show {
  background: #fff;
  box-shadow: 0 0 10px #ccc;
  border: 1px solid #ccc;
  padding: 0 0px 0 !important;
  overflow: unset;
  scrollbar-width: thin;
  scrollbar-color: #c9cdd1 #f9f9f9;
}
.gridActivity ul.dropdown-menu.dropdown-menu-end,
.intrestlisting.dimpagetags ul.dropdown-menu.dropdown-menu-end {
  width: 200px;
}
.ListIcon .ActivityCategory,
.ProgressDot .ActivityCategory,
.menuList .ActivityCategory {
  box-shadow: none;
  background: transparent;
}

.menuList li ul.dropdown-menu li {
  width: auto;
  border-radius: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1;
}

.menuList li ul.dropdown-menu {
  min-width: 200px !important;
  right: 0;
}

.Activity.selfmadeActivity .RatingPopupdiv h3 span {
  width: auto;
  height: auto;
  position: relative;
  border: 0;
  left: 0;
  border-radius: 0;
  line-height: 1;
}

/* Course page */
.CourseSearchFilter {
  background: #f3f3f3;
  padding: 12px 15px 12px 15px;
  margin-top: 15px;
  margin-bottom: 0px;
}

.AllFilterdList h4 {
  font-size: 16px;
  line-height: 1;
  color: #3b3b3b;
}

div#filterAccordian {
  background: rgb(255, 255, 255);
}

.SearchCourse input.form-control {
  border-radius: 53px;
  padding-right: 50px;
  padding-left: 15px;
  height: 48px;
  border-color: #e2dede;
  font-size: 15px;
}

.GradeAndleve {
  padding: 10px;
  border: 1px solid #ccc;
}

#filterAccordian1 ul {
  padding: 10px !important;
  background: #f1f8ff;
  border-top: 0 !important;
}

.AllFilterdList ul::after {
  display: none;
}

.AllFilterdList ul li label.w-100 {
  font-size: 16px;
  color: #5a5a5a;
}

.AllFilterdList ul li label.w-100 input[type="checkbox"] {
  margin-right: 10px;
  width: 15px;
  height: 15px;
}

.AllFilterdList ul li:last-child {
  border: 0;
}

.AllFilterdList ul li {
  padding: 5px 0px;
  text-align: left;
  width: 25%;
}

#filterAccordian1 ul {
  border-top: 0;
}

div#School_information .AllFilterdList label.Selcheckbox {
  width: 33.33%;
  font-size: 16px;
}

.basicdetailsform .SearchCourse .input-group {
  width: 100%;
  position: relative;
}

.SearchCourse {
  width: 78%;
  margin-bottom: 12px;
}

.input-group.searchInputs.searchinrest .form-control {
  border-radius: 20px !important;
  height: 42px;
}

.input-group.searchInputs.searchinrest svg.svg-inline--fa.fa-magnifying-glass {
  top: 49px;
  left: auto;
  right: 12px;
}

.filterYourCourse {
  padding: 14px 15px;
  background: #fff;
  border-radius: 50px;
  width: 20%;
  margin-bottom: 12px;
  height: 48px;
  border: 1px solid #e2dede;
}

.AllFilterdList .signupType .checkmark {
  background-color: #dbdbdb;
}

.filterYourCourse h3 {
  color: #636363;
  font-size: 16px;
}

.filterreset {
  height: 48px;
  background: white;
  margin-left: auto;
  line-height: 48px;
  border: 1px solid #e2dede;
  width: 8%;
  padding: 0 20px;
  margin-bottom: 12px;
  color: #636363;
  text-align: center;
  border-radius: 24px;
  margin-right: 10px;
}

.basicdetailsform .SearchCourse .input-group .searchCourseicon svg {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 0;
  padding: 15px 15px;
  cursor: pointer;
  left: auto;
  font-size: 18px !important;
}

.RounAnimation {
  width: 13px;
  height: 13px;
  border: 2px solid #fff;
  border-radius: 50%;
  border-left: 0;
  animation-name: rounAnimate;
  text-transform: capitalize !important;
  animation-duration: 0.6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  display: inline-block;
}

.css-1wa3eu0-placeholder::before {
  position: absolute;
  content: "Search...";
  left: 0px;
  font-size: 14px;
  top: 0px;
  color: #818181;
}

.lowerProfile img.rounded-circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid #ccc;
  margin-right: 5px;
}

@keyframes rounAnimate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.halfPagePOpup .modal-body .form_right {
  padding-top: 8px;
}

.is-invalid .rs-picker-toggle {
  border: none;
}

.swal2-container {
  z-index: 999999 !important;
}

.HalfScreenAct .Activity {
  width: 100%;
  background: #fff;
  margin: 5px 5px;
  box-shadow: 0 0 5px #d5d5d5;
}

.HalfScActF {
  max-width: 50%;
  background: #fff;
  max-height: 300px;
  overflow: auto;
  border: 1px solid #ccc;
  min-width: 50%;
}

span.style-module_fillIcons__6---A.filled-icons {
  color: #ffa41c !important;
}

span.Coordinate {
  font-size: 14px;
  color: #0f1a34;
  left: 8px;
  display: none;
  width: 0;
  top: -16px;
  transition: all 0.3s;
  height: 0;
  transform: rotate(0deg);
}

.ListIcon ul li .SubFName {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 200px;
  white-space: nowrap;
}

.ProgressDot:hover span.Coordinate {
  display: block;
  transition: all 0.3s;
}

.SeriesstoryTitle h4 {
  font-size: 15px;
}

.flex.commingSoonPage .LessionDtlOverview {
  min-height: auto;
  padding: 14px;
  border-radius: 5px 5px 5px;
  margin-left: 50px;
  overflow: initial;
  position: relative;
}

.flex.commingSoonPage {
  padding: 30px;
  align-items: center;
}

.resourcessc .flex.commingSoonPage {
  align-items: flex-start;
  padding: 30px 0;
}

.thanksmsg img {
  max-width: 600px;
}

.commingSoonPage h2 {
  color: #09b18a;
  font-size: 60px;
}

.commingSoonPage .LessionDtlOverview p {
  font-size: 28px !important;
}

.vickyrobot img {
  min-width: 220px;
  max-width: 220px;
}

.heading span.earn_points {
  display: flex;
  flex-direction: row-reverse;
}

.dicardrightside {
  display: flex;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.cardrightpannel .totleCourse {
  width: 52%;
}

span.earnnoCoin,
.GridCardTitle h3 span.earnnoCoin {
  display: inline-block;
  color: #141414;
  width: 35px;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
  text-align: end;
  border-radius: 50%;
  height: 20px;
  font-weight: 600;
  box-sizing: border-box;
  line-height: 15px;
  z-index: 1;
  transition: all 1s;
  margin-right: -5px;
  position: relative;
  top: -5px;
}

.BedgesLeft span.earnnoCoin {
  width: auto;
}

.GridCardTitle h3 span.earnnoCoin {
  margin-right: -1px;
  cursor: auto;
  top: -4px;
}

.Thanksmessage .EnrollTitle .NuggetVickyImage img {
  min-width: auto;
  max-width: 200px;
  height: auto;
  margin-bottom: -10px;
}

.Thanksmessage .LessionDtlOverview h3 {
  color: #3ec093;
}
.LessionDtlOverview {
  /* background: #eee; */
  padding: 0px 0px 10px;
  /* border: 2px solid #ffcb5b; */
  margin: 10px 0px 0px;
  border-radius: 5px;
  /* min-height: 71vh; */
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 600;
  /* max-height: 71vh; */
  /* overflow: auto; */
}

.personalitylistitem.p-3.yourpersonalitytyped {
  background: #e3f2fd;
  border: 2px solid #ffcb5b;
  margin: 10px 10px 0;
}

.RightbarPannel .intructionsBox.LessionDtlOverview {
  min-height: 0;
  overflow: auto;
  padding: 0px;
  border: 0;
  background: transparent;
  font-style: italic;
}

/* .LessionDtlOverview span,
.StoSceneDesc span {
  float: left;
} */

.SmilyExp .smillybx {
  margin-right: 7px;
  color: #000;
  margin-top: 3px;
}

.RightbarPannel .ScenecerelateddQuiz .fa-clipboard-question {
  color: #4b4b4b;
  font-size: 20px;
  padding-left: 2px;
}

.SmilyExp {
  font-size: 15px;
  color: #000;
  border-bottom: 1px dashed #ccc;
  background: #ffd884;
  margin-top: -1px;
  border-radius: 10px 10px 0px 0;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
}

.LessionDtlOverview span svg,
.StoSceneDesc span svg {
  font-size: 20px;
  margin-top: 3px !important;
}

.teachername span.skilledlist {
  color: #747474;
  font-weight: 400;
  font-size: 13px;
  display: block;
  line-height: 1;
}

.lessonDtl {
  padding: 0px 0px;
  display: flex;
  flex-direction: column;
  order: 1;
  flex: 1 1 0;
}

.StoSceneDesc p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.StoSceneDesc {
  background: #eeeeee;
  padding: 10px;
  border: 2px solid #ffcb5b;
  border-radius: 8px;
}

.ConversionStory {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 0px;
  flex: 1 1 0;
}

.pagebuttonStripEx {
  position: absolute;
  bottom: 0px;
  order: 2;
  background: #eee;
  padding: 8px 10px;
}

.textcontentSidebar {
  padding-right: 42px;
}

.pagebuttonStrip {
  order: 3;
  background: #eee;
  padding: 8px 10px;
  min-height: 48px;
}

.StoryScene img {
  /* max-width: 500px; */
  margin: auto;
  width: 100%;
  /* max-height: 600px; */
}

.StoryScene {
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  overflow: hidden;
  flex: 1 1 0;
}

.breadCrum li.Brumprofile {
  position: absolute;
  right: 0;
  top: 0;
}

.ScenecerelateddQuiz {
  padding: 10px 10px;
}

.congratesiocn img {
  max-width: 63px;
}

.congconfeti {
  height: 150px;
  overflow: hidden;
  position: absolute;
  top: -50px;
  z-index: 1;
  width: 100%;
  z-index: 12;
  left: 0;
  /* left: 50%; */ 
  text-align: center;
}

.congratesiocn {
  position: absolute;
  left: 91%;
  transform: translateX(-50%);
  top: -70px;
}

.RightbarPannel .ScenecerelateddQuiz.allcourselj {
  /* min-height: 72vh;
  overflow: auto;
  padding-bottom: 26px;
  max-height: 72vh; */
}
.RightbarPannel .pagescrollauto .ScenecerelateddQuiz.allcourselj {
  min-height: 0;
  overflow: inherit;
  padding-bottom: 26px;
  max-height: fit-content;
}

.SeriesstoryTitle svg {
  font-size: 15px;
}

.SeriesstoryTitle {
  padding: 0 5px;
}

.ScenecerelateddQuiz .signupType label {
  margin-bottom: 6px;
  font-size: 14px;
  flex-grow: 1;
  padding-right: 0;
}

.ScenecerelateddQuiz .signupType {
  flex-direction: column;
  align-items: baseline;
}

.ScenecerelateddQuiz h4 {
  position: relative;
  margin-bottom: 15px !important;
  font-size: 16px;
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
}

.ScenecerelateddQuiz h4 p,
.ScenecerelateddQuiz h4 span {
  position: relative;
  margin-bottom: 0px !important;
  font-size: 16px !important;
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 600;
  color: #232323;
}
.ScenecerelateddQuiz .courseskillbox.Homethree h4 .__react_component_tooltip p {
  color: #fff !important;
}
.ScenecerelateddQuiz h4 span {
  color: #232323;
  padding-right: 10px;
  /* font-family: Comic Neue !important; */
  font-family: "Lato", sans-serif !important;
}

.ScenecerelateddQuiz .signupType label,
.ScenecerelateddQuiz .signupType label p {
  margin-bottom: 15px;
  font-size: 16px;
  flex-grow: 1;
  padding-right: 0;
  color: #232323;
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  padding-left: 32px;
  width: 100%;
}
.signupType label span math {
  /* font-family: Comic Neue, cursive !important; */
  font-family: "Lato", sans-serif !important;
}
.signupType label span math mi,
.ScenecerelateddQuiz h4 math mi {
  font-size: 16px;
  line-height: 1.3;
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  display: initial;
}
.pl-0 {
  padding-left: 0 !important;
}
.ScenecerelateddQuiz h4 math mi {
  font-weight: 600;
}
.QuizContent .ScenecerelateddQuiz .signupType label .QQtitle p {
  padding-left: 0;
  margin-bottom: 0;
}
.ScenecerelateddQuiz .signupType label p {
  padding-left: 0;
}
.ScenecerelateddQuiz svg.svg-inline--fa.fa-thought-bubble {
  position: absolute;
  top: -11px;
  left: 10px;
  color: #747474;
}
.SocialGridcard .Activity.selfmadeActivity h3 span {
  background: #1675e0;
  color: #fff;
  font-size: 12px;
  border: 2px solid #34c3ff;
}

.SocialGridcard .Activity.selfmadeActivity h3 .TeacherratingCount span {
  background: none;
  border: 0;
  font-size: 14px;
  color: #808080;
}

.SelectYourProfile .signupType {
  flex-direction: column;
  align-items: stretch;
}

.SelectYourProfile .signupType label.Selcheckbox {
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.SelectYourProfile .signupType label.Selcheckbox {
  padding: 20px 55px;
  border-bottom: 1px solid #ccc;
  font-size: 20px;
  flex-wrap: wrap;
  color: #585858;
  flex-direction: column;
  align-items: self-start;
}

.SelectYourProfile .signupType label.Selcheckbox span.d-block {
  font-size: 16px;
  font-weight: 600;
  color: #ababab;
}

.SelectYourProfile .signupType label.Selcheckbox .checkmark {
  position: absolute;
  top: 37px;
  left: 19px;
  width: 25px;
  height: 25px;
}

.Profile_details h4 span.VerifiedUser {
  color: #85b812;
  font-size: 14px;
}

.Profile_details h4 span.unVerifiedUser {
  color: #e46666;
  font-size: 14px;
}

.ScoregradeChart:hover {
  border: 0;
}

.ScoregradeChart {
  color: #329fff;
  cursor: pointer;
  text-align: right;
  background: #fff;
  margin-bottom: 10px;
  font-size: 14px;
}

.GradeChartTable th,
.GradeChartTable td {
  border: 1px solid #ccc;
  padding: 10px;
}

.form-title h3 .signupType label {
  padding-right: 0;
}

.submoduleList li.Resorveavlleson {
  color: #2868a9;
  cursor: pointer;
}

.form-title h3 .signupType label {
  padding-right: 0;
}

.HalfScreenAct h5.courChangeActivity {
  background: #f5faff;
  border-bottom: 1px solid #ccc;
  width: calc(100%);
  position: sticky;
  top: -1px;
  padding: 10px;
  margin-bottom: 5px;
  z-index: 9;
}

.Changesubmange {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ActivityViewFormat.HalfScreenAct {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 92vh;
  z-index: 9;
}

.PMSkillList {
  padding: 15px;
  background: #fff;
  box-shadow: 0 0rem 1rem rgb(0 0 0 / 15%) !important;
  margin: 0 0px 10px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  display: flex;
}

.PMSkillList .SlecteSub h5 {
  font-size: 15px;
  color: #555555;
  margin-bottom: 10px;
  padding: 10px;
  background: #f5faff;
  border-bottom: 1px solid #ccc;
}

/* swapSkills */
.SwapSkills .drag-node {
  padding: 3px 13px !important;
  font-size: 16px;
  color: #434343;
  position: relative;
  background: #fff;
  background: #f9f8f8 !important;
  margin: 8px !important;
  transition: all 0.3s;
  border: 1px solid #999999;
  border-radius: 17px;
}

.AddSwapSkills {
  color: #48cfae;
}

.SwapSkills .drag-node .crossmark {
  border-color: red;
  color: red;
}

.dragabeSkills1 {
  padding-top: 25vh !important;
  height: 50vh;
}

.dragabeSkills {
  display: flex;
  max-width: 47%;
  min-width: 47%;
  padding: 10;
  border: 1px solid #ccc;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: baseline;
  min-height: 50vh;
  align-content: space-between;
}

.AddTypeSkills {
  min-height: 20vh;
}

.core h4,
.core h3 {
  text-align: center;
}

.core .drag-node {
  width: fit-content;
}

.SwapSkills .dragabeSkills.dragabeSkills1 h4 {
  text-align: center;
}

.SwapSkills .dragabeSkills.dragabeSkills1 .parent-node {
  height: 24vh;
  align-content: baseline;
}

.SwapSkills .dragabeSkills.dragabeSkills0 .parent-node {
  height: 50vh;
  align-content: baseline;
  overflow: auto;
}

.SwapSkills {
  justify-content: space-between !important;
}

.SwapSkills .parent-node {
  overflow-anchor: none;
  background: #fff !important;
  margin: auto;
  display: flex;
  width: auto !important;
  margin-top: 0 !important;
  flex-wrap: wrap;
}

.ResoQuesPractice svg.svg-inline--fa.fa-angle-down {
  transform: rotate(-90deg);
}

span.dragdropweenIcon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 18px;
}

.SocialCoursecardWrap .intrestlisting {
  padding: 0 0px;
  box-shadow: 0px 3px 4px #e1e1e1;
}

.instlistitem h4 svg.fa-check {
  color: #48cfae;
  font-size: 16px;
  display: block;
}

.SocialCoursecardWrap .instlistitem {
  margin: 10px 10px 0 0px;
  padding: 4px;
}

.carditemCorsePercent {
  position: relative;
  padding: 3px 10px 3px;
}

.ActivitycrdTitle h3 svg.fa-circle-plus,
.Activity .dropdownlistmodl.show ul li svg.fa-circle-plus {
  color: #48cfae;
  font-size: 18px;
}

.LessonNameList {
  display: none;
  position: absolute;
  left: 178px;
  background: #fff;
  top: 73px;
  box-shadow: 0 0 10px #ccc;
  border: 1px solid #ccc;
}

.Activity .dropdownlistmodl.show ul li:last-child:hover .LessonNameList {
  display: inline-block;
}

img.internallogo {
  max-width: 157px;
  margin-left: 2px;
}

.CourseardDesc svg {
  font-size: 20px;
  color: #747474;
}

.CoreSkills,
.ElectiveSkills {
  width: 18px;
  height: 18px;
  position: absolute;
  text-align: center;
  left: 5px;
  line-height: 14px;
  top: 5px;
  font-weight: 600;
  border: 1px solid #fff;
  background: #48cfae;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
}

.centerPagePopup .modal-content {
  max-width: 80%;
}

.ElectiveSkills {
  background: #ff9900;
}

.SlecteSub .signupType label .checkmark {
  top: 6px;
}

.SlecteSub .signupType label {
  padding-right: 25px;
  font-size: 20px;
}

.SlecteSub li {
  padding: 8px 15px;
  display: flex;
  width: 100%;
  margin: 7px 0;
}

.teachername svg {
  width: 16px;
}

.SubmitSubBtn.text-right.mt-3 {
  padding: 7px 15px;
  background: #e7e7e7;
}

.SlecteSub.dropdown-menu.show {
  max-width: 410px;
  border: 1px solid #686868;
}

.Activity.selfmadeActivity.weeklyActivity .ActivitycrdTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SlecteSub p {
  font-weight: 400;
  font-size: 14px;
  padding: 5px 10px 10px !important;
}

.InSchoolCheckbox .checkmark {
  position: absolute;
  top: 1px;
  left: -22px;
  height: 18px;
  width: 18px;
  border-radius: 0;
  background-color: #bcbcbc;
}

.Subject_Curriculam .form-switch .form-check-input {
  margin-left: 0.5em;
  float: right;
}

/* Table */
.curriculumntable td,
.curriculumntable th {
  padding: 0;
  width: 20%;
  text-align: center;
  font-size: 17px;
  color: #1d103f;
  font-weight: 600;
  min-width: 100px;
  padding: 7px 4px;
  border-bottom: 1px solid #ebe8e8;
}

.curriculumntable td img {
  width: 26px;
}

.curriculumntable td {
  color: #232323;
  font-size: 16px;
  font-weight: 400;
  position: relative !important;
}

.curriculumntable td:first-child,
.curriculumntable th:first-child {
  text-align: left;
  min-width: 360px;
  max-width: 360px;
  white-space: normal;
}

.curriculumntable td:nth-child(2),
.curriculumntable th:nth-child(2) {
  min-width: 100px;
}

.Nestedtable th {
  font-size: 16px;
  color: #2f2f2f;
  font-weight: 600;
  background: #f3f3f3;
}

.Nestedtable th span.checkmark,
.Nestedtable td span.checkmark {
  width: 20px;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 0;
}

.curriculumntable td .checkmark {
  position: absolute;
  top: 1px;
  left: 50%;
  transform: translateX(-50%);
}

span.DownArrow {
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 0;
}

.dragabeSkills.dragabeSkills1 {
  border-top: 0;
  padding-top: 10px;
}

.core {
  position: absolute;
  right: 14px;
  width: 45.4%;
  border-top: 1px solid #ccc;
  border-bottom: 0;
}

.PercentCompleted,
.SocialGridcard .Activity.selfmadeActivity h3 span.PercentCompleted {
  position: absolute;
  top: 11px;
  border: 0;
  background: none;
  color: #232323;
  font-weight: 600;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: baseline;
}

span.Createspacing {
  opacity: 0.3;
}

.SidePopupList p {
  line-height: 1.4;
}

.SidePopupList {
  position: absolute;
  right: 347px;
  display: none;
  top: 184px;
  width: 200px;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 3px 2px #ebebeb;
}

.AddCorsebtn {
  position: absolute;
  right: 0;
  top: 0px;
  cursor: pointer;
  background: #48cfae;
  border-radius: 0px 0 0 10px;
  font-size: 14px;
  color: #fff;
  padding: 5px 7px;
}

.SidePopupList {
  display: none;
}

.dropdown-menu a.SidebarPro:hover + .SidePopupList {
  display: block;
}

.SidePopupList:hover {
  display: block;
}

/* .enrolledBtn button {
  background: #09b18a;
} */

.LeftDate {
  font-size: 12px;
  font-weight: 600;
  margin-right: auto;
  margin-left: 5px;
  color: #09b18a;
}

.__react_component_tooltip p {
  color: #fff;
  white-space:normal;
}

.ActVityMenViky img,
.Procourselevel img {
  width: 24px;
}

.quizPreview {
  margin: 0 auto;
  height: 100%;
  text-align: center;
}

.quizPreview button {
  text-align: center;
  align-items: center;
  width: 200px;
  margin: 0 auto;
  padding: 6px;
}

.ActivityTitle .videoDescription .CatItemList ul li {
  justify-content: flex-start;
}

.allYtypeactivityList .CatItemList svg {
  color: #747474;
  font-size: 18px;
  width: 20px;
  margin-right: 10px;
}

.SubscriptionModlPopup .modal-content {
  max-width: 75%;
  border-radius: 0;
  border: 0;
}

.CourseJournetCard .leftClosebar {
  line-height: 21px;
  left: -15px;
  top: 50%;
  text-align: center;
  animation-name: Coursejourneyremani;
  animation-duration: 0.8s;
  animation-timing-function: linear;
  transform: translateY(-50%);
}

.VicyslCourseTitle {
  color: #232323;
}

.VicyslCourseTitle svg {
  color: #808080;
}

@keyframes Coursejourneyremani {
  0% {
    right: 0%;
  }

  100% {
    right: -100%;
  }
}

.flipIcon svg {
  animation-name: notificationribbon;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes notificationribbon {
  0% {
    color: white;
  }

  100% {
    color: #1d103f;
  }
}

.readmoretxt svg {
  font-size: 12px !important;
  color: #329fff;
}

span.TotleProgressInsub {
  position: absolute;
  font-size: 8px;
  left: 3px;
  top: 12px;
  width: 21px;
}

.carditemCorsePercent .Course_progressbar {
  width: 25px;
  text-align: center;
  position: relative;
  line-height: 0;
  margin-left: 10px;
}

.Course_progressbar svg.rc-progress-circle {
  max-width: 30px;
  margin: auto;
}

.carditemCorsePercent circle.rc-progress-circle-trail {
  stroke-width: 10;
}

.coursejounercrditem h5.text-center svg {
  color: #374749;
}

.spacelistDesc .speclistdesctext img {
  max-width: 20px;
  margin-right: 3px;
}

/* Course Joureney again */
.CourseJourneyicon {
  width: calc(100% - 0px);
  height: 180px;
  background: #fff;
  padding: 4px 4px;
  border-radius: 0px;
}

.CourseJourneyicon h3 {
  font-size: 16px !important;
}

.CourseJourneyicon h3 span {
  font-size: 70px;
  line-height: 1;
}

.coursejounercrditem {
  text-align: center;
  position: relative;
  width: 19%;
  box-shadow: 0 0 4px #5c5c5c;
  border-radius: 0px;
}

.CourseJourneyicon img {
  max-width: 40px;
}

.coursejounercrditem.CJlevel1 p {
  color: #bd2e39;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: -32px;
  text-align: left;
}

.coursejounercrditem.CJlevel5 p {
  color: #a5c44d;
  font-size: 18px;
  font-weight: 600;
  position: absolute;
  top: -32px;
  text-align: left;
}

.CourseJourneyicon img {
  max-width: 26px;
  position: absolute;
  bottom: 20px;
  right: 20px;
}

/* .CJlevel2 .CourseJourneyicon {
  border-color: #d3653f;
  background: #ffe3d9;
  color: #d3653f;
}
.CJlevel3 .CourseJourneyicon {
  border-color: #dbb831;
  background: #fff7da;
  color: #dbb831;
}
.CJlevel4 .CourseJourneyicon {
  border-color: #0192a3;
  background: #e3fcff;
  color: #0192a3;
}
.CJlevel5 .CourseJourneyicon {
  border-color: #a5c44d;
  background: #f8ffe6;
  color: #a5c44d;
} */
/* .CourseJournetCard {
  justify-content: space-between;
  border: 1px solid #ccc;
  display: none;
  position: absolute;
  width: 100%;
  padding: 35px 10px;
  top: 0;
  background: #fff;
  z-index: 111;
} */
.JourneyCardShow .CourseJourney .CourseJournetCard {
  animation-name: CoursejourneyAnima;
  display: flex;
  animation-duration: 2.5s;
  animation-timing-function: ease-in-out;
}

@keyframes CoursejourneyAnima {
  0% {
    right: -100%;
  }

  100% {
    right: 0%;
  }
}

.JourneyCardShow .CourseJournetCard {
  display: flex;
}

.PMSkillList h5 {
  background: #ece9e9;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 5px #d8d8d8;
}

.PMSkillList h5 svg.svg-inline--fa.fa-bullseye-arrow.mr-2 {
  display: block;
  margin: auto !important;
  font-size: 24px;
  color: #0070d2;
  padding-top: 7px;
  padding-bottom: 3px;
}

/* BredCrum */
.breadCrum {
  width: calc(100% - 10px);
  box-shadow: 0 0px 5px rgb(0 0 0 / 15%) !important;
  border-radius: 4px;
  margin: 10px 0px 10px 10px;
  z-index: 9999;
  position: relative;
  border: 1px solid #dcdcdc;
}

.insceptionFilter .dropdown-menu {
  transform: none !important;
  top: 20px !important;
  right: 0;
  min-width: 250px;
  left: auto !important;
  overflow: auto;
}

.CourseardDesc span.textdesc {
  font-size: 14px;
  text-align: justify;
}

span.readmoretxt {
  position: relative;
}

.insceptionFilter .CatItemList span {
  color: #747474;
  background: #ffffff;
  border: 0;
  width: 100%;
  text-align: left;
}

.insceptionFilter #navbarDropdown {
  font-weight: 400;
  /* cursor: auto !important; */
}

.insceptionFilter {
  position: relative;
}

.breadCrum.breadsticky {
  position: sticky;
  top: 55px;
  z-index: 9999;
  width: calc(100% + 10px);
  margin: 0;
  border-radius: 0;
  right: 0;
  left: 0;
  margin-right: -10px;
  /* box-shadow: none !important; */
  border-right: 0;
  border-top: 0;
  border-left: 0;
}

.breadCrum li.Brumprofile ul li {
  min-width: 100%;
}

.stickyRibbon .breadCrum.breadsticky {
  position: sticky;
  top: 135px;
}

/* .stickyRibbon .RightbarPannel.p-0.rightpannelSticky {
  top: 235px;
} */

.breadCrum.breadsticky .heading {
  padding: 10px 20px;
  border-radius: 0;
}

.breadCrum li span {
  padding: 0 3px;
}

.breadCrum li {
  color: #232323;
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", sans-serif !important;
  display: inline-block;
  text-transform: initial;
}

.breadCrum li.bActivepage {
  color: #0070d2;
}

.MainContent.inactive .breadCrum ul li:nth-of-type(1n),
.MainContent.inactive .breadCrum ul li:nth-of-type(2n),
.MainContent.inactive .breadCrum ul li:nth-of-type(3n),
.breadCrum ul li:nth-of-type(3n),
.breadCrum ul li:nth-of-type(4n),
.breadCrum ul li:nth-of-type(5n) {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-width: 280px;
  white-space: nowrap;
  position: relative;
  top: 0px;
}

.breadCrum ul {
  min-width: 70%;
  display: flex;
}

.breadCrum .bredcrumbuser ul {
  min-width: 100%;
  display: inline-block;
}

.breadCrum .heading {
  border-radius: 4px;
  background: #fff;
  padding: 10px 10px;
}

.breadCrum li svg {
  margin-right: 5px;
  color: #232323;
}

.AddTypeSkills .CoreSkills,
.AddTypeSkills .ElectiveSkills {
  left: -4px;
  top: -3px;
}

/* .CourseCardWrapper .tabgrid {
  height: 40px;
} */

.Course_height {
  /* height: calc(100% - 40px); */
  flex: 1 1 0;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
  order: 2;
}

.mobilePannelDirction {
  margin-top: 10px;
  padding-bottom: 10px;
}

.AddChildPopup .modal-title.h4 img {
  max-width: 28px;
}

.CourseardDesc img {
  max-width: 25px;
  margin-right: 5px;
  margin-top: 2px;
}

.MessagefchooseSkills {
  padding: 15px;
  background: #329fff;
  color: #ffffff;
  font-size: 16px;
  margin-top: 30px;
}

.EnrollcConfimation img {
  max-width: 200px;
}

.LessionDtlOverview .d-flex.align-items-start {
  padding: 10px 0px 0px 10px;
  line-height: 1.3;
  position: relative;
}

.modellcfooter {
  margin: 0 -14px -14px -14px;
  position: sticky;
  bottom: 0;
  z-index: 99;
}

/* Key Area Card */
.PMSkillList li {
  padding: 0px 7px 0px;
  border: 1px solid #9c9a9a;
  width: inherit;
  position: relative;
  border-left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
}

.PMSkillList h5 img {
  max-width: 70px;
  display: block;
  padding: 0;
  margin: auto;
  margin-left: -8px;
}

.PMSkillList ul {
  width: 100%;
  display: flex;
  border-left: 1px solid #9c9a9a;
  background: #fff;
}

.PMSkillList li p {
  text-align: center;
  font-size: 14px;
  color: #232323;
  margin-top: 0px;
  line-height: 1.3;
  font-weight: 600;
}

.Course_progressbar {
  display: flex;
  padding: 2px 4px;
  width: 100%;
  margin: 0 0;
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 0;
  color: #3ec093;
  justify-content: center;
  font-weight: 600;
}

.SocialCoursecardWrap .Course_progressbar {
  width: auto;
  left: auto;
  right: 15px;
  font-size: 12px;
  top: 0px;
  cursor: auto;
  align-items: center;
}

.MultipleBranchSub .Course_progressbar svg {
  margin-left: 3px;
}

.PMSkillList li path.rc-progress-line-trail {
  stroke-width: 10px;
}

.PMSkillList li path.rc-progress-line-path {
  stroke-width: 10px;
  stroke: #3ec093;
}

.PMSkillList li svg.svg-inline--fa.fa-pencil.ml-3,
.PMSkillList li .infoiconimg,
.PMSkillList li svg.svg-inline--fa.fa-circle-info {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 15px;
  color: #6c6c6c;
}

.PMSkillList li svg.svg-inline--fa.fa-circle-info,
.PMSkillList li .infoiconimg {
  right: auto;
  max-width: 16px;
  left: 5px;
  color: #6c757d;
}

.PMSkillList li .Course_progressbar svg.svg-inline--fa.fa-circle-info {
  right: -3px;
  top: 2px;
  left: auto;
  position: relative;
}

.PMSkillList li svg.rc-progress-line {
  height: 15px;
  width: 100%;
}

.PMSkillList h5 {
  background: none;
  align-items: center;
  padding: 5px 0;
  border-radius: 4px;
  text-align: center;
  border: 0;
  box-shadow: none;
  font-size: 14px;
  display: flex;
  color: #232323;
  margin: 0;
  justify-content: space-between;
  margin: 0 25px 0 5px;
}

.PMSkillList li:first-child {
  background: #efefef;
  /* min-width: 150px; */
}

.skilcontroltitle {
  max-width: 150px;
  min-width: 150px;
}

.PMSkillList h5 svg.svg-inline--fa.fa-bullseye-arrow.mr-2 {
  color: #52d2b3;
}

.PMSkillList {
  padding: 10px;
  background: #ffffff;
  box-shadow: 0 0rem 1rem rgb(0 0 0 / 15%) !important;
  margin: 0 0px 10px;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  display: flex;
  position: relative;
}
.WelcomePOpScreen.fourth-place .PMSkillList {
  z-index: 999999;
  opacity: 0.8;
}
/* .WelcomePOpScreen.eighth-place
  .LeftbarPannel
  .CourseCardWrapper
  .heading.gridSection.d-flex.w-100.p-0.border-0 {
  z-index: 99999999;
  position: relative;
  opacity: 0.8;
} */
.PMSkillList .Progresslistitem.m-0 {
  height: 12px;
  width: 100% !important;
  background: #bcbcbc;
}

.PMSkillList .progressbar1 {
  height: 10px;
  top: 1px;
  left: 1px;
  background-color: #3ec093;
  background-image: none;
}

.PMSkillList span.TotleProgressInsub {
  font-size: 10px;
  z-index: 9;
}

/* .PMSkillList.PSticky {
  position: sticky;
  top: 94px;
  z-index: 999;
  box-shadow: 0 3px 5px rgb(0 0 0 / 15%) !important;
  border-top: 0;
} */
span.TotleProgressInsub {
  position: absolute;
  font-size: 12px;
  color: #000;
  top: 2px;
  margin-right: 3px;
  left: 50%;
  font-weight: 600;
  transform: translateX(-50%);
}

.abExplanation {
  position: absolute;
  width: 300px;
  background: #fff;
  text-align: left;
  justify-content: left;
  display: none;
  left: 0;
  top: 0;
  transition: all 0.4s;
  z-index: 1;
  border: 1px solid #ccc;
  box-shadow: 0 0 6px #767676;
}

.Abouttaginfo:hover .abExplanation {
  display: block;
  /* transition: all 0.4s; */
  top: 41px;
  border-radius: 10px;
  left: 32%;
  animation-name: infopopup;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  cursor: auto;
}

@keyframes infopopup {
  0% {
    width: 80%;
  }

  100% {
    width: 100%;
  }
}

.PMSkillList li .abExplanation p {
  font-weight: 400;
}

/* Quiz result on halfscreen */
.Quizresult {
  border: 1px solid #ccc;
  padding: 10px;
  background: #f5faff;
  margin: 20px 0 10px !important;
}

.Quizresult h5 {
  color: #14899a;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 7px;
}

.Quizresult p {
  font-size: 16px;
  padding: 5px 15px;
  border: 1px solid #e1dfdf;
  background: #eeeeee;
  border-radius: 20px;
  margin-top: 10px;
}

.Quizresult p span.Correctq {
  color: green;
  font-weight: 600;
}

.Quizresult p span.incorrctq {
  color: red;
  font-weight: 600;
}

.Quizresult h5 span {
  font-size: 14px;
  color: #232323;
}

.Quizresult h5 img {
  max-width: 20px;
}

.ActVityMenViky.mr-2 span {
  width: 25px;
  display: inline-block;
  font-weight: 600;
  height: 25px;
  text-align: center;
  padding: 0px;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.ShareYourActivity svg.svg-inline--fa.fa-share-nodes {
  display: none;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  margin-top: 0;
  margin-left: 0;
  background: #666666 !important;
  visibility: visible;
  max-width: 275px !important;
}

div#t3371a522-7db0-4b73-a82c-df5cf01342c4 {
  background: none !important;
}

.__react_component_tooltip {
  background: none !important;
}

.curriculumntable td .__react_component_tooltip {
  position: absolute !important;
  left: 57px !important;
  top: -47px !important;
}
.settingtable table td .__react_component_tooltip {
  position: absolute !important;
  left: 0px !important;
  top: -47px !important;
  min-width: 200px !important;
}

/* .curriculumntable th .__react_component_tooltip {
  left: 0 !important;
  top: -47px !important;
  min-width: 160px;
} */
.__react_component_tooltip::after {
  background: #666666 !important;
}

.t3371a522-7db0-4b73-a82c-df5cf01342c4.place-top::after {
  background: #666666 !important;
}

.SelectYourProfile .modal-dialog.modal-lg {
  min-width: 500px !important;
  max-width: 500px !important;
}

div#ProfileMenuPopup .SelectYourProfile .modal-dialog {
  transform: none;
  position: relative;
  right: auto;
  width: 100%;
  max-width: initial;
  margin: 0;
  top: auto;
}

.SelectYourProfile .modal-dialog.modal-lg .modal-content {
  max-width: 100%;
}

.SelectYourProfile .Selcheckbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 8px;
  height: 15px;
}

.BDsubmitbutton.Addchieldbtn .SelectYourProfile span {
  font-size: 20px;
  width: auto;
  height: auto;
  border: 0;
  line-height: 1.5;
}

#exampleModalCenter1 .modal-content {
  max-width: 500px;
}

/* Social card */
.SocialCoursecardWrap {
  padding: 0px 10px;
  position: relative;
}

.SocialCoursecardWrap h4.KATitle {
  padding: 8px 10px 8px;
  border-bottom: 1px solid #9c9a9a;
  color: #232323;
  background: #f0f5f6;
  margin: 20px 0 0;
  font-size: 16px;
}

.DiemensionDeshboared .SocialCoursecardWrap h4.KATitle {
  min-height: 46px;
  font-weight: 400;
}

.SocialCoursecardWrap h4.KATitle.skillandcours {
  min-height: auto;
}

.DimensionDashboadcard .SocialCoursecardWrap:first-child {
  position: sticky;
  z-index: 9;
  background: #f3f3f3;
  padding: 5px 7px 5px;
  margin: 0px;
  top: 0px;
}

.DiemensionDeshboared .SocialCoursecardWrap h4.KATitle[aria-expanded="true"] {
  background: #ebebeb;
  padding: 8px 10px;
}

.DiemensionDeshboared .SocialCoursecardWrap h4.KATitle:hover {
  background: #ebebeb;
  padding: 8px 10px;
  transition: all 0.3s;
}

.SocialCoursecardWrap h4.KATitle.skillandcours:hover {
  background: none;
}

.SocialCoursecardWrap h4.KATitle:hover {
  background: #e7e7e7;
  /* padding: 8px 5px; */
  transition: all 0.3s;
}

.SocialCoursecardWrap .intrestlisting ul {
  padding-right: 29px;
  padding-left: 5px !important;
}

/* .SocialCoursecardWrap h4.KATitle[aria-expanded="true"]{
  border-bottom: 1px solid #9c9a9a;
} */

/* .SocialCoursecardWrap div:last-child .Gridcard{
  margin-bottom: 0;
} */
.SocialCoursecardWrap h4.KATitle img {
  max-width: 24px;
  margin-right: 5px;
}

.menuList li.betaversion {
  width: auto;
  font-size: 22px;
  text-transform: capitalize;
  font-family: monospace;
  padding: 6px 0px;
  border-radius: 4px;
  color: #333333;
  padding-right: 32px;
  line-height: 1;
  margin-bottom: -24px;
}

.totlecout img {
  max-width: 24px;
}

.SocialCoursecardWrap h4.KATitle span {
  display: flex;
  align-items: center;
}
.SocialCoursecardWrap
  h4.KATitle
  .curiresoevent
  .resocurrinfopopup:nth-child(1) {
  position: absolute;
  right: 130px;
}
.SocialCoursecardWrap
  h4.KATitle
  .curiresoevent
  .resocurrinfopopup:nth-child(2) {
  position: absolute;
  right: 10px;
}
/* .AllsCaedwrap.collapse.show {
  padding: 15px 0 0;
} */
.rangetypeQuestion .QstrangeSlider,
.textAreatypeQuestion .textareabox {
  width: 100%;
  padding-left: 20px;
}
.textAreatypeQuestion .chat_icon{
  max-width: 16px;
  margin-top: -1px;
}

.textAreatypeQuestion .chat_icon {
  max-width: 16px;
}

.totlecout img {
  max-width: 32px;
}

.AllCoursesDimensionlist h3.DiemenTitle {
  color: #6c757d;
  font-size: 18px;
}

.textAreatypeQuestion .textareabox textarea {
  height: 160px;
  border: 1px solid #ccc;
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 600;
  padding: 5px;
}
.textAreatypeQuestion .textareabox textarea::placeholder {
  font-weight: normal;
  color: #f9f9f9;
  padding: 0px 3px;
  font-size: 14px;
}

.menuList li.betaversion {
  width: auto;
  font-size: 22px;
  text-transform: capitalize;
  font-family: monospace;
  padding: 4px 0px;
  border-radius: 4px;
  color: #333333;
  padding-right: 0px;
  margin-right: 20px;
  line-height: 1.1;
  margin-bottom: 0px;
  text-align: right;
}
.menuList li.betaversion p {
  font-size: 12px;
  color: #727272;
  text-transform: initial;
}
.menuList li.betaversion p a {
  font-size: 12px;
  color: #2196f3;
  font-weight: 600;
}

/* .halfPagePOpup.enrolledpopups .modal-body {
  min-height: auto;
} */
/* Home Page */
.leftRow,
.rightRow {
  width: calc(50% - 10px);
  min-height: calc(100vh - 0px);
}

.rightRow {
  margin-right: 10px;
}

.DDasborditem {
  background: #fff;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
}

.leftRow .DDasborditem {
  min-height: calc(50vh + 5px);
  max-height: calc(50vh + 5px);
}

.rightRow .DDasborditem {
  min-height: 33.33vh;
  max-height: 33.33vh;
}

.leftRow .DDasborditem:last-child,
.rightRow .DDasborditem:last-child {
  margin-bottom: 0;
}

.rightRow .DimensionDashboadcard {
  max-height: calc(33.33vh - 48px);
  min-height: calc(33.33vh - 48px);
  overflow: auto;
  padding-top: 0 !important;
}

.leftRow .DimensionDashboadcard {
  max-height: calc(44vh - 16px);
  min-height: calc(44vh - 16px);
  overflow: auto;
  padding-top: 0 !important;
}

.DDasborditem .heading {
  position: sticky;
  top: 0;
  z-index: 12;
}

.DDasborditem .heading a {
  font-size: 16px;
}

.HomePageDeshborad {
  width: calc(70% - 10px);
  margin-left: 10px !important;
}

.chartWrappannel .gUOCOV .canvas_chart {
  height: 300px;
  width: 300px;
  margin-block: 0px !important;
}

.chartWrappannel .gUOCOV .chart_keys {
  display: none;
}

.instlistitem .CoreSkills,
.instlistitem .ElectiveSkills {
  left: -8px;
  top: -5px;
}

.intrestlisting .diemensionalProgress.progressbar1 {
  background-image: none;
  max-width: 44%;
  background: #62ccb2;
  height: 12px;
}

.intrestlisting ul li {
  position: relative;
  font-size: 14px;
}

/* .intrestlisting ul li::before {
  width: 9px;
  height: 9px;
  content: "";
  position: absolute;
  background: #a2a2a2;
  left: 0;
  top: 13px;
  border-radius: 50%;
} */
.intrestlisting .Progresslistitem {
  height: 16px;
}

.intrestlisting .Progressbarlisting {
  border: 0;
  padding: 5px 0px 5px 0px;
  background: transparent;
}

.intrestlisting .Progressbarlisting img,
.AllCoursesDimensionlist h3 img {
  max-width: 24px;
}

span.SCourseLevel {
  font-weight: 600;
  color: #006dcf;
}

.SCourseLevel img {
  max-width: 28px;
}

.Coursecompprcent {
  width: 30px;
  /* margin-left: 38px; */
}

.Progressbarlisting .Coursecompprcent {
  width: 30px;
  position: relative;
}

.Progressbarlisting .Coursecompprcent .PercentCompleted {
  font-size: 10px;
  left: 4px;
  top: 4.5px;
  display: flex;
  justify-content: center;
  width: 22px;
  height: 20.5px;
  line-height: 22px;
}

.Progressbarlisting .Coursecompprcent .PercentCompleted .percenrSign,
.percenrSign {
  font-size: 8px;
  margin-left: 0px;
}

.SocialGridcard .Activity.selfmadeActivity h3 span.percenrSign {
  font-size: 9px !important;
  margin-left: 0px;
  border: 0;
  color: #232323;
  background: none;
}

/* .Progressbarlisting .Course_Achivement span.earnnoCoin {
  width: 30px;
} */
.Coursecompprcent circle.rc-progress-circle-trail {
  stroke-width: 12;
}

/* .Gridcard .CourseardDesc .readmoretxt:before,
.jrny_box .readmoretxt:before,
.CourseDetails .readmoretxt::before {
  position: absolute;
  content: "";
  left: -41px;
  transform: rotate(0deg);
  bottom: 0;
  height: 100%;
  width: 42px;
  background: rgba(255, 255, 255, 0);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(90%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1);
} */
.jrny_box .readmoretxt {
  font-size: 14px;
  white-space: nowrap;
}

/* Social course details */
.tabgrid.m-0.socialgridtab ul li {
  width: 30%;
  /* height: 40px; */
}

.tabgrid.m-0.socialgridtab ul {
  width: 85%;
}

.signupType.checktypequestion .Selcheckbox span.checkmark {
  border-radius: 0;
}

.bredcrumbuser {
  min-width: 10%;
}
/* .courseguide {
  min-width: 15%;
  position: fixed;
  bottom: 32px;
  right: 13%;
  z-index: 999;
} */
.courseguide span {
  font-size: 14px;
  color: #2861b3;
  padding: 6px 10px;
  border-radius: 5px;
  font-weight: 400;
  border: 1px solid #2861b3;
}
.courseguide span:hover {
  background: #568bd7 !important;
  color: #fff;
  transition: all ease 1s;
}
.LRSlidearrow span {
  display: inline-block;
  font-size: 16px;
  margin: 0 2px;
  color: #524f4f;
  text-align: center;
  transition: all 0.3s;
  padding: 0 7px;
  border-radius: 0%;
}

.LRSlidearrow span:hover {
  background: #ccc;
}

.LRSlidearrow span.AudioIcon:hover,
.LRSlidearrow span.ScenePagination {
  background: none;
  margin: 0;
}

.LRSlidearrow span.pointer:hover {
  background: #1e88e5;
  padding: 0 7px;
  color: #ffffff;
}

.ScenePagination {
  margin: 0;
}

.LRSlidearrow {
  text-align: right;
}

.AudioIcon {
  padding-left: 10px;
}

.LessionDtlOverview .AudioIcon {
  padding-left: 10px;
  position: absolute;
  right: 2px;
  top: 7px;
}

.AudioIcon img,
.CourseJourney .jrny_box .AudioIcon img {
  max-width: 32px;
  transform: rotate(180deg);
  vertical-align: sub;
}
.CourseJourney .jrny_box .AudioIcon img {
  border: 0;
  position: relative;
  padding: 0;
  top: -10px;
  left: 10px;
}

.NuggetPopupDesc .AudioIcon {
  padding-left: 10px;
  position: relative;
  right: 5px;
  top: 6px;
}

.Abouttaginfo .NuggetPopupDesc .AudioIcon {
  top: -3px;
}

.otherOptionssol {
  position: relative;
  min-width: 585px;
}

.NuggetPopupDesc .otherOptionssol .AudioIcon {
  padding-left: 10px;
  position: absolute;
  right: -35px;
  top: -4px;
}

.LRSlidearrow {
  margin-top: -3px;
  min-width: 136px;
}

.ScenecerelateddQuiz h4 span img {
  max-width: 20px;
}
.ScenecerelateddQuiz h4 span.imgrelatedquestion img {
  max-width: 100%;
}
.intrestlisting .Progressbarlisting h5 {
  font-size: 14px;
  font-weight: 400;
  min-width: 160px;
}

.CourseAchiveAndProgress {
  width: calc(100% - 160px);
  max-width: 150px;
}

/* Nuggets Popups */
.NuggetPopup {
  position: absolute;
  width: 650px;
  background: #fff;
  box-shadow: 0px 4px 10px #717070;
  right: 29%;
  border: 4px solid #2196f3;
  border-radius: 7px;
  top: 252px;
  z-index: 999;
}

.addChildProf .NuggetPopup {
  position: relative;
  top: 0;
  right: 0;
  border: 0;
  width: 100%;
}

.powrBulbicon {
  margin-right: -6px;
}

.NuggetPopupTitle h4 {
  font-size: 20px;
}

.EnrollTitle h4 {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  display: flex;
  line-height: 1.3;
  align-items: center;
}

.NuggetPopupTitle {
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
  align-items: center;
  background: #f5faff;
  border-radius: 4px 4px 0 0;
}

.NuggetPopupTitle .powrBulbicon img {
  max-width: 32px;
  min-width: 32px;
}

.medal_width {
  max-width: 32px;
  min-width: 32px;
}

.NuggetPopupDesc {
  padding: 10px 40px 10px 10px !important;
  min-height: 200px;
  max-height: 350px;
  overflow: auto;
  position: relative;
  padding-right: 0px;
}

.congratulationPopup .modal.show .modal-dialog {
  transform: none;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

/* .NuggetPopupDesc p {
  font-size: 18px;
  color: #323232;
} */
.NuggetPopupTitle.d-flex {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.NuggetPopupFooter {
  padding: 8px 0px 8px;
  text-align: right;
  background: #e7e7e7;
  border-radius: 0px 0px 4px 4px;
}

.congratesiocn {
  max-width: 200px;
  margin: auto;
}

.form-switch label.form-check-label {
  color: #329fff;
  font-weight: 600;
}
.chooseSubscription .form-switch label.form-check-label {
  width: 100%;
}

strong.SubjectName {
  position: absolute;
  max-width: 100px;
  width: 100px;
  left: -43px;
  top: 13px;
  text-align: center;
}

.earnplusCoin {
  font-size: 18px;
  width: 100px;
  transition: all 1s;
  height: 100px;
  position: relative;
  animation-name: myfirst;
  animation-duration: 3s;
  animation-timing-function: linear;
}

/* .earnplusCoin::before {
  content: "";
  position: absolute;
  right: -23px;
  top: -65px;
  background: url("./resources/images/congrats-confetti.gif");
  width: 150px;
  height: 152px;
} */

@keyframes myfirst {
  0% {
    color: #232323;
    font-size: 16px;
  }

  25% {
    color: green;
    font-size: 21px;
  }

  50% {
    font-size: 26px;
    color: red;
  }

  75% {
    font-size: 21px;
  }

  100% {
    color: #232323;
    font-size: 16px;
  }
}

.PProimage .uploadedPhoto {
  width: 100px;
  height: 100px;
  border: 0;
  padding: 0;
  box-shadow: none !important;
}

.PProimage .uploadedPhoto span.cameraiconImg {
  width: 100px;
  padding: 0;
  height: 100px;
  background: none;
  border: 0;
  box-shadow: none !important;
}

.PProimage .uploadedPhoto img {
  border: 0;
}

.PProimage .px-3.basicdetailsform {
  padding: 0 !important;
}

.PProimage .content.imgFrm {
  margin-top: 0;
}

.PProimage .uploadedPhoto span.cameraiconImg img {
  height: 20px;
}

.SelectYourProfile .modal {
  background-image: url("./resources/images/nodes.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.SelectYourProfile .modal::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: rgb(0 0 0 / 70%);
}

/* All Nuggets Questions */
.AllNuggQuestionList {
  margin: 10px 15px;
  text-align: center;
}

.AllNuggQuestionList ul li:last-child:before {
  display: none;
}

.AllNuggQuestionList ul ul li::before {
  content: "";
  position: absolute;
  width: 32px;
  top: 9px;
  z-index: -1;
  height: 0;
  border-bottom: 1px dashed #ccc;
  left: 18px;
  border-bottom: 1px dashed #ccc;
}

.AllNuggQuestionList ul li {
  display: inline-block;
  width: 20px;
  height: 20px;
  font-size: 10px;
  background: #ccc;
  margin: 0 15px;
  text-align: center;
  line-height: 20px;
  position: relative;
  border-radius: 50%;
}

.AllNuggQuestionList ul li.pointer {
  background: transparent;
  font-size: 16px;
  font-weight: 600;
}

.AllNuggQuestionList ul li.QAttempt {
  background: #388e3c;
  color: #fff;
}

.AllNuggQuestionList ul li.pointer:hover {
  background: #cecece78;
}

.coursedtlImage img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.AllNuggQuestionList ul li.QAttemptAct {
  background: #1e88e5;
  color: #fff;
}

.NuggetVickyImage img,
.unsubsvickyimg img {
  height: 35px;
}

.EnrollTitle .NuggetVickyImage img {
  min-width: 60px;
  max-width: 60px;
  height: auto;
}

.Course_Achivement img {
  max-width: 28px;
}

#widgetSocial .NuggetPopup.abExplanation,
#widgetEmotional .NuggetPopup.abExplanation,
#widgetSpiritual .NuggetPopup.abExplanation {
  width: 323px;
}

.signin_box_styl {
  width: 100%;
  padding: 7px 10px 0px 10px;
  min-height: 300px;
}

.halfPagePOpup.SchoolActivityPopup.modal.show {
  z-index: 999999;
}

div#coursedetailpage {
  z-index: 9999999;
}

.SubscriptionModlPopup,
div#subscriptionactivity,
.teacherdetailhalfsc div#coursedetailpage {
  z-index: 99999991;
}

/* Header */
.landing-page .outerPagesHeader .header .menuList.landingpageMenu li,
.landing-page .outerPagesHeader .menuList.landingpageMenu li a {
  color: #fff;
}

.landing-page
  .outerPagesHeader
  .header
  .menuList.landingpageMenu
  li.signup_styl {
  color: #fff;
  padding: 0;
}

.landing-page .outerPagesHeader .header {
  background: #1d103f !important;
  z-index: 999999;
}

/* Course details */
.Weektitledtl {
  width: 100px;
  font-size: 16px;
  text-align: center;
  font-weight: 600;
}

.CourseWeekdtl {
  margin-top: 5px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.weelyCoursedesc {
  width: calc(100% - 100px);
  padding-left: 10px;
}

.weelyCoursedesc h5 {
  font-size: 18px;
  color: #329fff;
  cursor: pointer;
}

.Weektitledtl span {
  display: block;
  font-size: 40px;
  line-height: 1.2;
}

.CourseDetails {
  padding: 10px 0;
}

.weeklyAssets {
  font-size: 16px;
}

.defaulttexturl {
  display: block;
  width: 100%;
  height: 200px;
  text-align: center;
  color: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  background: #eeeeee;
}

/* Dissable class */
span.pointer.DissableArrow {
  pointer-events: none;
  color: #ccc;
}

span.earnplusCoin,
.GridCardTitle h3 span.earnplusCoin {
  display: inline-block;
  color: red;
  width: 35px;
  font-size: 15px;
  font-family: "Poppins", sans-serif !important;
  text-align: end;
  border-radius: 50%;
  height: 20px;
  font-weight: 600;
  box-sizing: border-box;
  line-height: 15px;
  z-index: 1;
  margin-right: -8px;
  position: relative;
  top: -5px;
}

.panneloverflow {
  overflow: auto;
  max-height: calc(100vh - 46px);
}

#signin .modal-header,
.SignupRegistration .modal-header {
  padding: 0.5rem 10px;
}

li.pointer.DissableArrow {
  pointer-events: none;
  color: #ccc;
}

.menuList .HolisticView img {
  max-width: 100%;
}

.SocialCoursecardWrap
  h4[aria-expanded="false"]
  ~ .curiresoevent
  svg.svg-inline--fa.fa-angle-down,
.SocialCoursecardWrap
  h4[aria-expanded="false"]
  ~ .updown_arrow_singl
  svg.svg-inline--fa.fa-angle-down {
  transition: all 0.3s;
}
.updown_arrow_singl {
  position: absolute;
  right: 20px;
  font-size: 16px;
  top: 14px;
  line-height: 1;
}
.updown_arrow_singl svg.svg-inline--fa.fa-pencil {
  color: #a0a0a0;
  font-size: 15px;
  margin-top: 3px;
}
.SocialCoursecardWrap
  h4[aria-expanded="true"]
  ~ .curiresoevent
  svg.svg-inline--fa.fa-angle-down,
.SocialCoursecardWrap
  h4[aria-expanded="true"]
  ~ .updown_arrow_singl
  svg.svg-inline--fa.fa-angle-down {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.Homethree h4[aria-expanded="true"] svg.svg-inline--fa.fa-angle-down,
.Homethree
  h4[aria-expanded="true"]
  svg.svg-inline--fa.fa-chevron-down.icon-show {
  transform: rotate(180deg);
  transition: all 0.3s;
}

.Homethree h4[aria-expanded="false"] svg.svg-inline--fa.fa-angle-down,
.Homethree
  h4[aria-expanded="true"]
  svg.svg-inline--fa.fa-chevron-down.icon-show {
  transition: all 0.3s;
}

.courseskillbox svg.svg-inline--fa.fa-chevron-down.icon-show {
  font-size: 15px;
}
.priceWrap.enrolledBtn {
  padding: 10px 0px 0 0px;
  width: 100%;
  border-radius: 0px;
  text-align: center;
  font-size: 16px;
  color: #00b050;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.RatingPopupdiv .Progresslistitem {
  width: 100% !important;
  display: block;
  height: 16px;
}

.ratingitem .diemensionalProgress {
  background-image: none;
  height: 12px;
  background-color: #ffa41c;
}

.CourseJourney span.Lvlcount {
  font-size: 20px;
  font-weight: 600;
  min-width: 60px;
  background: #329fff;
  color: #fff;
  height: 60px;
  border: 0;
  line-height: 60px;
  padding: 0 !important;
  top: 50% !important;
  left: -30px;
  transform: translateY(-50%);
}

.CourseJourney .jrny_box {
  margin: 0px 0px 20px 29px;
  width: 100%;
  min-height: 100px;
  padding-left: 40px;
}

.CourseJourney .jrny_box:last-child {
  margin-right: 0;
}

.CourseJourney .jrny_box h4 {
  font-size: 18px;
  padding: 5px;
  color: #232323;
  text-align: left;
}

.pagebuttonStripEx {
  position: sticky;
  bottom: 0px;
  order: 2;
  background: #eee;
  padding: 8px 10px;
  left: 0;
  margin: 0 -10px -10px;
  width: calc(100% + 20px);
}

.Quizscreenheight {
  min-height: 72vh;
}

.swal2-styled.swal2-confirm {
  background: #329fff;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #43a047 !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: 0.25em solid rgb(67 160 71);
}

.noCardLock svg.svg-inline--fa.fa-lock {
  position: absolute;
  top: 12px;
  right: 26px;
}

span.SignupTypeparentguar {
  font-size: 15px;
  padding-left: 5px;
}

/* owl carosal*/
.PMSkillList .owl-carousel {
  width: calc(100% - 157px);
}

.PMSkillList.wpl_box_styl .owl-carousel .owl-nav button.owl-prev {
  left: -15px;
  border-radius: 0%;
  top: 50%;
  height: 98%;
  background: #9c9a9a;
  border: 1px solid #9c9a9a;
  width: 15px;
}

.PMSkillList.wpl_box_styl .owl-carousel .owl-nav button.owl-next {
  right: -7px;
  border-radius: 0%;
  background: #9c9a9a;
  border: 1px solid #9c9a9a;
  top: 50%;
  height: 100%;
  width: 15px;
}

.PMSkillList.wpl_box_styl .owl-carousel .owl-nav button.owl-prev span,
.PMSkillList.wpl_box_styl .owl-carousel .owl-nav button.owl-next span {
  font-size: 60px;
  position: relative;
  right: 3px;
  text-align: center;
  top: -14px;
  color: #fff;
  font-weight: 200;
}

.PMSkillList.wpl_box_styl .owl-carousel .owl-nav button.disabled,
.wpl_box_styl .owl-carousel .owl-nav button.disabled {
  font-size: 28px !important;
  /* background: transparent;
  border: 1px solid #2b468b; */
  opacity: 1;
  /* color: #2b468b; */
}

.infoiconimg {
  max-width: 17px !important;
}

.priceWrap.enrolledBtn img {
  max-width: 22px !important;
}

button.btn-blue.btn-login.d-block.w-auto.m-0.ml-3.procedecourse {
  background: none;
  color: #329fff;
}

img.infoicon {
  position: absolute;
  max-width: 16px;
  top: 4px;
  left: 4px;
}

.commingSoonLevel {
  font-size: 18px;
  color: #43a047;
  background: rgb(204 204 204 / 30%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 600;
  padding-left: 40px;
}

.jrny_box .commingSoonLevel img {
  max-width: 20px;
  position: static;
  padding: 0;
  border: 0;
}

.max-width-100 {
  max-width: 100% !important;
}

.halfPagePOpup.coursedetailpopuppage .modal-body.RatetousPopup {
  min-height: 160px;
}

.halfPagePOpup.coursedetailpopuppage .modal-content {
  max-width: 77%;
}
.halfPagePOpup.coursedetailpopuppage .modal-content {
  max-width: 77%;
}
.halfPagePOpup.coursedetailpopuppage .modal-dialog .modal-content {
  max-width: 100%;
}

.halfPagePOpup.coursedetailpopuppage
  .modal-content
  div#exampleModalLong.RatingsModelPopup
  svg.star-svg {
  width: 30px;
  height: 30px;
}

/* Nuggets on static  */
.nuggetsinmodal .NuggetPopup {
  top: 66%;
  left: 64%;
  transform: translate(-50%, -29%);
}

.nuggetsinmodal .modal {
  background: url("./resources/images/nuggetbackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* .nuggetsolitems {
  margin-bottom: 11px;
} */
.nuggetsolitems h6 {
  font-size: 18px;
  padding: 0px 0px 10px;
  font-style: italic;
  font-weight: 400;
  text-align: justify;
  /* width: 437px; */
}
.nuggetsolitems h5 {
  font-size: 17px;
}
/* .nuggetsinmodal  .NuggetPopupTitle {
    padding: 0px 10px 0;
    border-bottom: 0;
    align-items: center;
    background: #1ec1d9;
    border-radius: 4px 4px 0 0;
    color: #fff;
    border-radius: 0;
    border: 1px solid #1ec1d9;
    border-bottom: 0;
    border-top: 0;
  } */
/* Nuggets on static end */
.scroll-to-top {
  background-color: #2b468b !important;
  right: 40px;
  bottom: 40px;
  position: fixed;
  z-index: 99;
  cursor: pointer;
  border-radius: 7px;
  width: 50px;
  height: 35px;
  box-shadow: 0 9px 25px 0 rgb(132 128 177 / 28%);
  border: none;
}

.scroll-to-top:hover {
  background: #e46666 !important;
}

.scroll-to-top svg {
  width: 28px;
  height: 20px;
  fill: white;
}

section.help_banner {
  margin-top: 70px;
}

section.help_banner h2 {
  color: #fff;
  font-size: 52px;
}

.helptitle h2 {
  font-size: 40px;
  font-weight: 600;
  color: #000;
  text-align: center;
  padding: 10px 0px 30px 0px;
}

.helpcardlisting {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 60px;
  padding-top: 0px;
}

.helpcardlisting .helpcards {
  width: 24%;
  margin: 10px 0px;
  background: #f5faff;
  padding: 20px 5px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px #b7b7b7;
  cursor: pointer;
  text-align: center;
  min-height: 140px;
}

.helpcardlisting .helpcards img {
  max-width: 80px;
  text-align: center;
  padding: 4px;
}

.helpcardlisting .helpcards:hover {
  box-shadow: 10px 10px 10px #ccc;
  transition: all ease 0.6s;
}

.helpcardlisting .helpcards h4 {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.teachername .RatingPopupdiv.dropdown-menu::after {
  top: -2px;
  right: 5%;
}

.helpdetails {
  width: 100%;
}

.helpdetails .container {
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.WHDieList h3 {
  font-size: 18px;
  color: #2c2c2c;
  padding: 15px 0px;
}

.menuList.landingpageMenu li a.active {
  color: #fff !important;
  border: 1px solid #fff;
  padding: 4px 7px;
  border-radius: 10px;
}

.Resorctitledtl .ratepopupp .RatingPopupdiv.dropdown-menu.d-block {
  top: 1px;
  left: auto;
  right: 0px;
}

.Resorctitledtl .TopicTitle.ratepopupp .RatingPopupdiv.dropdown-menu.d-block {
  right: auto;
  left: -38px;
  top: 41px;
}
.congratulationPopup .modal-dialog {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0;
  width: 500px;
}
section.helpbody {
  min-height: 80vh;
  width: 100%;
}

.SocialCoursecardWrap h4.KATitle span svg.svg-inline--fa.fa-dash {
  font-size: 10px;
  font-weight: 600;
}

.query_style textarea.form-control {
  min-height: calc(7.5em + (0.75rem + 2px));
}

.query_style .input-group {
  display: inline-block;
}

.scrorecardWrap {
  width: 100%;
  margin: auto;
  text-align: left;
  margin-top: 10px;
  /* padding: 10px; */
  background: #e7e7e7;
  border: 2px solid #f0ae42;
}

.scrorecardWrap li {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 10px;
}

.scrorecardWrap li img {
  max-width: 28px;
  vertical-align: bottom;
}

.homeparents .bredcrumbuser {
  min-width: 0%;
}

.anythingels_btn {
  text-align: center;
  float: right;
}

/* .Toastify {
  z-index: 99999;
  position: absolute;
  top: 60px;
} */
.refeneceancortxt a {
  display: block;
  color: #329fff;
  word-break: break-word;
}

.refeneceancortxt p,
.providerflows .GridDetails.Intellprovidertype .GridCardTitle h3 {
  background: #f5faff;
  margin-bottom: 18px;
  padding: 20px;
  border: 1px solid #f9ca5c;
  cursor: pointer;
  border-left: 10px solid #f5b936;
  border-radius: 0 5px 5px 0;
}
.refeneceancortxt p,
.refeneceancortxt p a {
  font-size: 16px;
}
/* .providerflows {
  padding: 20px 15px;
} */
.providerflows .GridDetails {
  width: 100%;
}

.refeneceancortxt p:nth-child(2n + 2) {
  display: none;
}

.providerflows .GridDetails.Intellprovidertype {
  padding: 0 15px 0;
  box-sizing: border-box;
  margin-top: 20px;
}

.unsubscribe_styl {
  width: 100%;
  height: 100%;
  border: 1px solid #d0d0d0;
  margin-top: 90px;
}

.unsubscribe_styl h4 {
  font-size: 20px;
  padding-top: 20px;
}

.unsubscribepopup {
  min-height: calc(100vh - 47px);
  margin: 0 auto !important;
  max-width: 600px;
}

.unsubscribe_styl p {
  font-size: 16px;
}

.unsubscribe_ftr {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Activeskills {
  font-weight: 600;
  background: #eee;
}

.Toastify {
  z-index: 99999999;
  position: relative;
  /* top: 0px;
  left: 50%;
  transform: translateX(-50%); */
}

.modal.d-block.AddChildPopup.RatingsModelPopup.overflow-hidden {
  min-height: 100vh;
  top: 0;
}

.AddChildPopup.RatingsModelPopup .modal-dialog {
  max-width: 550px;
  margin: 0px auto;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.optvarification .roboimage img {
  max-width: 220px;
}

.otpinputscreen input {
  width: 50px !important;
  margin: 0 5px;
  font-size: 25px;
}

.modal-review {
  min-height: 80vh !important;
  height: 80vh !important;
  overflow-y: hidden;
}

.thanksyoupage p.tytxtmessage {
  font-size: 18px;
}

.thanksyoupage .robovicky {
  max-width: 145px;
}

.privacypolicyweb p,
.privacypolicyweb a,
.privacypolicyweb ul li {
  font-size: 18px;
}

.termslist ul {
  padding-left: 30px !important;
  margin: 10px 0;
}

.yourexistingplan {
  font-size: 18px;
  color: #388e3c;
  width: 86%;
  margin: auto;
  padding: 4px 0;
  border: 1px solid;
}

.Toastify__toast-container {
  width: 425px !important;
}

.areYouSuretext {
  font-size: 20px;
  line-height: 1.4;
  color: #424242;
  font-weight: 400;
  justify-content: center;
  display: flex;
  width: 100%;
}

.fixedtoppar {
  position: sticky;
  top: 44px;
  z-index: 9;
  background: #fff;
}

.fixedexpandall {
  top: 146px;
  padding: 10px 0;
}

.ListIcon ul li:first-child:hover {
  background: transparent;
  padding: 10px 5px;
}

.subtitleHeadinhg h4 strong.mr-auto.ml-2 {
  text-overflow: ellipsis;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
}

.TopicTitle .RatingPopupdiv.dropdown-menu::after {
  right: 69%;
}

.AllCoursesDimensionlist .GridiamgeCard .RatingPopupdiv.dropdown-menu::after {
  right: 71%;
}

.continuebtn {
  background: #eee;
  padding: 8px 10px !important;
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 1;
  order: 3;
  min-height: 48px;
}

.lSidebarcontentwrap {
  /* max-height: 75.6vh; */
  overflow: auto;

}

.AddChildPopup.verifyusereffect.modal {
  z-index: 9999999;
}

.Gridcard.SocialGridcard.lastIndex,
.lastIndex .Gridcard.SocialGridcard {
  margin-bottom: 230px;
  transition: all.5s;
}

.hoosrSubModlPopup.AreyousurePopup .modal.d-block {
  z-index: 9999999;
}
.hoosrSubModlPopup.AreyousurePopup.lockedcontentpopup .modal.d-block,
.enrollZindex .modal {
  z-index: 99999999;
}
.GuidePopupWrap .btn-primary:focus {
  background: #0d6efd;
  border-color: #0d6efd;
  box-shadow: none;
}

.GuidePopupWrap .btn-primary:hover {
  background: #0b5ed7;
  border-color: #0b5ed7;
}

.Personalitycategory .SocialCoursecardWrap h4.KATitle {
  font-size: 20px;
  background: #e3f2fd;
}

.resendotp {
  font-size: 14px;
  margin-top: 10px;
}

.active_profile {
  background: #ebebeb !important;
}

.active_profile_green {
  background: #e3f2fd !important;
}

/* .active_profile_green span {
  color: #ffff !important;
} */

.invite_frnd .modal-dialog {
  max-width: 650px;
}

.invite_frnd .modal-content {
  max-width: 77%;
}

/* Where You left Popup */
.wlCard {
  width: 100%;
  /* border: 1px solid #329fff; */
  padding: 7px 7px;
  box-shadow: 2px 2px 3px #5e5e5e;
  margin: 15px auto;
  background: #2196f3;
  border-radius: 4px;
}

.wlCard img,
.wlCard svg {
  max-width: 50px;
  margin-right: 10px;
  width: 100px;
  border: 2px solid #1565c0;
  border-radius: 50%;
  padding: 5px;
  background: #fff;
}
.wlCard svg {
  width: auto;
  height: auto;
}

.wlCard h3 {
  font-size: 18px;
}

.whereYouleftwtrap h2 {
  font-size: 21px;
  margin: 0px 0px 0;
  color: #000;
  padding-bottom: 7px;
  border-bottom: 1px solid #212121;
}

.whereYouleftwtrap .wlCard h3.whleftbtn {
  font-size: 21px;
  color: #fff;
}

.whereYouleftwtrap h2 span.bg-white {
  padding: 11px 8px;
  line-height: 1;
  font-size: 14px;
  border-radius: 50%;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  background: #329fff !important;
}

.whereYouleftwtrap h2.h1.text-center.m-minus-15.orbutton {
  margin: 28px 0px;
}

.wlCard:hover {
  background: #1565c0;
  /* border: 1px solid #2E7D32; */
}

/* .wlCard:hover img{
border: 1px solid #2E7D32;
outline:1px solid #2E7D32 ;
} */
.whereYouleftwtrap .LessionDtlOverview p {
  font-size: 21px !important;
  line-height: 1.6;
}

.whereYouleftwtrap .LessionDtlOverview p.leftofftxt {
  color: #009688;
}

.intellectual_card {
  background: #85b81299;
  border-color: #85b812;
}

.physical_card {
  background: #1ec1d999;
  border-color: #1ec1d9;
}

.spiritual_card {
  background: #a86adb99;
  border-color: #a86adb;
}

.emotional_card {
  background: #e4666699;
  border-color: #e46666;
}

.social_card {
  background: #ff9900;
  border-color: #ff9900;
}

.AlltypeDimensionbtn .intellectual_card:hover {
  background: #85b812;
}

.AlltypeDimensionbtn .physical_card:hover {
  background: #1ec1d9;
}

.AlltypeDimensionbtn .social_card:hover {
  background: #ff9900;
}

.AlltypeDimensionbtn .emotional_card:hover {
  background: #e46666;
}

.AlltypeDimensionbtn .spiritual_card:hover {
  background: #a86adb;
}

.AlltypeDimensionbtn .wlCard:last-child {
  margin-bottom: 5px;
}

.AlltypeDimensionbtn .intellectual_card {
  background: #85b81299;
  border-color: #85b812;
}

.AlltypeDimensionbtn .physical_card {
  background: #1ec1d999;
  border-color: #1ec1d9;
}

.AlltypeDimensionbtn .social_card {
  background: #ff9900;
  border-color: #ff9900;
}

.AlltypeDimensionbtn .emotional_card {
  background: #e4666699;
  border-color: #e46666;
}

.AlltypeDimensionbtn .spiritual_card {
  background: #a86adb99;
  border-color: #a86adb;
}

.AlltypeDimensionbtn .intellectual_card img {
  border-color: #85b812;
}

.AlltypeDimensionbtn .physical_card img {
  border-color: #1ec1d9;
}

.AlltypeDimensionbtn .social_card img {
  border-color: #ff9900;
}

.AlltypeDimensionbtn .emotional_card img {
  border-color: #e46666;
}

.AlltypeDimensionbtn .spiritual_card img {
  border-color: #a86adb;
}

.fcImage {
  width: 94px;
  margin: 7px;
}

.fccourseDetils {
  width: calc(100% - 94px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.firsttimecoursewrap h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.firsrcoursecard {
  border: 1px solid #9c9a9a;
  margin: 10px 0 0;
}

.firsrcoursecard:first-child {
  margin-top: 0;
}

.lockContentPOpups {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.4;
  padding: 28px 0px;
  border-radius: 5px;
  display: inline-block;
}
.pick_diemension span,
.fccourseDetils h4 {
  font-size: 18px;
}
.pick_diemension span {
  color: #329fff;
  cursor: pointer;
}
.Pickacourse .modal-dialog.modal-lg {
  min-width: 500px;
  max-width: 500px;
}
.topbarlandindwidth .menuList li ul.dropdown-menu {
  min-width: 220px !important;
  overflow: auto;
}
.sessiontimeouthdr .heading h2 {
  font-size: 18px !important;
}
/*-------Certificate css------ */

.certificatePopup {
  max-height: 600px;
  max-width: 970px;
}

.certificateMain {
  width: 100%;
  height: 100%;
  padding: 0px 0px;
}

.certificateMain .certificateContent {
  background: url(resources/images/certificateBack.png) top left no-repeat;
  margin: 0 auto;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  height: 460px;
  width: 100%;
  width: 690px;
  position: relative;
}

.certificateContent h3 {
  font-size: 24px;
  color: #232323;
  /* font-family: "Comic Neue" !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 600;
  padding-bottom: 25px;
  text-transform: uppercase;
  font-style: italic;
}

.certificateContent h4 {
  font-size: 21px;
  color: #232323;
  /* font-family: "Comic Neue" !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
}

.certificateContent h2 {
  font-size: 66px;
  color: #276082;
  font-family: "Dancing Script" !important;
  font-weight: 400;
  text-transform: capitalize;
}

.certificateContent p {
  font-size: 18px;
  color: #232323;
  font-family: "Comic Neue" !important;
  padding: 0px 70px;
  line-height: 1.3;
  font-weight: 400;
}
.certificateContent .cerficatedetails {
  bottom: 70px;
  position: absolute;
  width: 100%;
}

.certificateContent .cerficatedetails span.borderBottom {
  max-width: 75%;
  height: 2px;
  margin: 0 auto;
  background: #0d2a3b;
  left: 85px;
  position: absolute;
  width: 75%;
  bottom: 57px;
}

.sharecertificate {
  padding: 10px 0px;
  max-width: 950px;
  margin: 0px 10px 10px 0px;
  text-align: right;
}

.sharecertificate span {
  background: #329fff;
  color: #fff;
  padding: 7px 20px;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
}

.sharecertificate span.shareback {
  background: #6c757d;
  color: #fff;
  padding: 7px 10px;
  text-align: center;
  font-size: 16px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 400;
}

.sharecertificate span.shareback svg {
  border: 1px solid #fff;
  border-radius: 50%;
  font-size: 14px;
  padding: 0 2px;
  vertical-align: text-center;
}

.certificateclose {
  color: #0d6efd;
  border-color: #0d6efd;
  border-radius: 10rem !important;
  border: 1px solid;
  background: transparent;
}

.courserelatedCertificate {
  position: absolute;
  left: 30px;
  width: 30px;
  top: 0px;
}

.certificateIcon {
  position: relative;
  top: 2px;
}
.certificateIcon svg {
  font-size: 22px;
  color: #e26565;
}
.coursecomptcertifie img.certificateIcon {
  min-width: 30px;
}

.rs-slider-handle.active::before {
  /* background: #2764a3;
  width: 21px;
    height: 21px;
    margin-top: 3px; */
}

.rs-slider-handle.activeBall::before {
  background: #2764a3;
  width: 21px;
  height: 21px;
  margin-top: 3px;
}
/* .ratingservayques .activeslider{
  background: #2764a3;
} */
.RatingPopupdiv p {
  font-weight: 400;
}
img#getImage {
  max-width: 14px;
  background: #00a1f1;
  border-radius: 50%;
  padding-bottom: 1px;
  margin-left: 2px;
}

.unsubscribebox {
  width: 100%;
  max-width: 650px;
  margin: 0 auto;
  background: #1b476f;
  padding: 15px 30px 30px 30px;
}
.unsubscribeboxTable {
  width: 100%;
  border-spacing: 0;
  font-family: arial;
  font-size: 14px;
  line-height: normal;
}
.unsubscribeboxTableRow {
  background: #1b476f;
  width: 100%;
}
.unsubscribeboxTableRowSpace {
  background: #fff;
}
.unsubscribeboxTableBody {
  background: #fff;
}
.unsubscribetableData {
  font-size: 12px;
  text-align: center;
  width: 100%;
}
.unsubscribeVickyLogo {
  max-width: 200px;
  height: auto;
  padding-bottom: 5px;
}
.unsubscribeVickyLogodata {
  padding: 5px 0px;
  font-size: 12px;
  text-align: center;
}

/* Reward css */
.rewardPopupcontent ul li {
  padding: 10px 0;
}
.rewardPopupcontent .Progresslistitem {
  height: 16px;
  margin-top: 5px !important;
  vertical-align: text-top;
}
.rewardPopupcontent .diemensionalProgress {
  height: 16px;
}
.rewardPopupcontent ul li span {
  font-weight: 400;
  width: 106px;
  display: inline-block;
}
.rewarduploadimg img {
  max-width: 175px;
  border-radius: 5px;
  background: #f1f1f1;
  height: 175px;
}
.uploadederewardimg {
  border: 1px solid #ccc;
  width: 100px;
  text-align: center;
  height: 100px;
  line-height: 100px;
  margin-right: 10px;
  left: 0;
}
.photoUploded .uploadederewardimg img {
  max-width: 100px;
  width: 100px;
  height: 100%;
  vertical-align: baseline;
}
.photoUploded .uploadederewardimg img.cameraicon {
  max-width: 35px;
  height: auto;
}
/* reward image upload */
.setyourrewardtarget .reactEasyCrop_Container,
.setyourrewardtarget .reactEasyCrop_CropAreaRound {
  width: 175px !important;
  height: 175px !important;
  border-radius: 0 !important;
  position: absolute !important;
  right: auto;
}
.rewardPopupcontent .diemensionalProgress.progressbar1 {
  max-width: 50%;
  background-image: none;
  background: #20c997;
  top: 0px;
}
.Applyforall {
  position: absolute;
  bottom: 45px;
  right: 10px;
}
.Applyforall .Selcheckbox {
  font-size: 15px;
  margin-right: 10px;
  padding-right: 27px;
  color: #606060;
  font-weight: 600;
}
.Addrewardform .rewardPopupcontent ul li .input-group {
  width: calc(100% - 150px);
}
.Addrewardform .modal-dialog.modal-lg {
  max-width: 500px;
  width: 500px;
}
.Addrewardform .rewardPopupcontent ul li textarea {
  height: 100px;
}
.Addrewardform .rewardPopupcontent ul li span.TotleProgressInsub {
  text-align: center;
  margin-right: 0 !important;
  width: auto;
  left: 48%;
  top: 21px;
  z-index: 1;
}
.Addrewardform .rewardPopupcontent ul li span.rewarddaysleft {
  margin-left: 20px;
  background: #bf360c;
  text-align: center;
  color: #fff;
  position: relative;
  width: 140px;
}
.Addrewardform .rewardPopupcontent ul li span.rewarddaysleft:before {
  content: "";
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-right: 7px solid #fff;
  border-bottom: 13px solid transparent;
  position: absolute;
  right: 0;
  top: 0;
}
.Addrewardform .rewardPopupcontent ul li span.rewarddaysleft:after {
  content: "";
  content: "";
  width: 0;
  height: 0;
  border-top: 13px solid transparent;
  border-left: 7px solid #fff;
  border-bottom: 13px solid transparent;
  position: absolute;
  left: 0;
  top: 0;
}
.Addrewardform .rewardPopupcontent ul li span.rewarddaysleft.timeexpiretxt {
  width: 156px;
}
.expireddated {
  color: red;
  width: 150px;
}
.Applyforall .Selcheckbox .checkmark,
.settingtable .Selcheckbox .checkmark {
  border-radius: 0;
}
.Applyforall .Selcheckbox .checkmark {
  right: 0;
  left: auto;
  border: 1px solid #ccc;
}
.settingtable {
  margin: 30px 0;
  border-top: 1px solid #b3b3b3;
  border-bottom: 0;
}
.settingtable th .Selcheckbox .checkmark {
  /* background: #fff; */
  border: 1px solid #ccc;
  left: 50.5%;
}
.settingtable th:nth-child(4),
.settingtable th:nth-child(7) {
  justify-content: center;
}
.settingtable td img {
  max-width: 20px;
  z-index: 1;
  position: relative;}
.settingtable th,
.settingtable td {
  font-size: 14px;
  padding: 5px 1px;
  text-align: left;
  transition: all 0.3s;
  border-bottom: 1px solid #ebe8e8;
  width: 10%;
}
.enrolledcorse span.checkmark {
  border-radius: 50% !important;
}
.setuserprofile {
  position: absolute;
  top: 4px;
  right: 10px;
}
.setuserprofile .insceptionFilter ul li {
  position: relative;
  width: 100%;
  border-right: 0;
}
.setuserprofile .insceptionFilter ul li:hover {
  border-bottom: 1px dashed #ccc !important;
}
.enrolledcorse svg {
  color: #9e9e9e;
  font-size: 20px;
}
.settingtable th {
  background: #f3f3f3;
  border-bottom: 1px solid rgb(179, 179, 179);
  font-size: 15px;
  line-height: 1.3;
  padding: 12px 1px 30px;
}
.settingtable th .Selcheckbox {
  position: absolute;
  top: 29px;
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  left: 50%;
  transform: translateX(-50%);
}
.settingtable .Selcheckbox .checkmark {
  left: 50%;
  transform: translateX(-50%);
}
.settingtable td {
  border-bottom: 1px solid #b3b3b3;
  background: #ffffff;
}
.Settingpagination {
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  bottom: 0;
}
.Settingpagination button {
  font-size: 14px;
  color: #329fff;
  border-radius: 5px;
  margin: 0 16px;
}
.Settingpagination span {
  font-size: 16px;
  color: #606060;
}
.settingtable td span {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  font-weight: 600;
  color: #606060;
  -webkit-box-orient: vertical;
}
.settingtable table td .newrewardDropdwn span.juweeknametext {
  display: block;
  width: 161px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
table.w-100.child_Table td span {
  font-weight: 400;
  color: #000;
}
.settingtable .Selcheckbox .checkmark {
  top: -3px;
  left: 50%;
  transform: translateX(-50%);
  /* background: #fff; */
  border: 1px solid #ccc;
  /* background: #d1d1d1; */
}
.settingtable td svg.svg-inline--fa.fa-circle-plus {
  color: #00b050;
  font-size: 27px;
}
.settingtable table td ul li svg {
  font-size: 14px;
}
.settingtable table td:nth-child(2),
.settingtable table th:nth-child(2) {
  min-width: 275px;
}
.tabgrid.m-0.socialgridtab
  .jumptopagescene
  ul
  li.pointer
  svg.svg-inline--fa.fa-circle-video {
  padding-right: 3px;
}
.settingtable table td svg.svg-inline--fa.fa-eye,
.coursecardnav ul li svg.svg-inline--fa.fa-eye {
  color: #0096a6;
  border: 1px solid #a3a3a3;
  border-radius: 50%;
  padding: 4px 3.15px;
}
.settingtable table th:first-child,
.settingtable td table td:first-child,
.settingtable table th:last-child,
.settingtable td table td:last-child {
  max-width: 0%;
  min-width: 36px !important;
  min-height: 63.3px;
  width: 1% !important;
  text-align: left;
}
.settingtable table th:first-child {
  padding: 12px 7px 30px;
}
table.w-100.child_Table {
  padding: 10px 10px;
  background: #42a5f5;
}
table.w-100.child_Table td {
  padding: 10px 1px;
  background: #f2fbff;
  width: 15%;
}
table.w-100.child_Table td:nth-child(2) {
  padding: 10px 10px 10px 15px !important;
  min-width: 311px;
}
.settingtable td span a {
  font-size: 13px;
}
.sessionTimeout {
  font-size: 16px;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 10px;
  background: #3498ff;
  color: #ffff !important;
}
.sessionTimeout svg {
  font-size: 18px;
  color: #f00 !important;
}
.settingtable th:nth-child(2) {
  text-align: left;
}

.settingtable th {
  text-align: center;
  min-width: 10%;
  justify-content: center;
  position: relative;
}
.sessionTimeout {
  font-size: 14px;
  border: 2px solid #e46666;
  padding: 8px 17px;
  border-radius: 20px;
  background: #fdf3f0;
  /* position: absolute;

  left: 50px;
  width: calc(100% - 50px);
  top: 48px; */
}
.sessionTimeout svg {
  font-size: 16px;
  color: #e46666;
  vertical-align: middle;
}

.form-check.form-switch .avltogglebutton {
  margin-left: 7px;
  margin-top: 0;
  height: 1.3em;
  width: 2.5em;
}
.all_skills_list .profile_division .panel-collapse {
  border-top: 0;
}
.all_skills_list h4.panel-title {
  border-bottom: 1px solid #b9b9b9;
}
.Allcourselink {
  padding: 0px 0px;
}
.Allcourselink p {
  color: #329fff;
  font-size: 14px;
  font-family: "Poppins", sans-serif !important;
}
.ljourneypopup .modal .modal-dialog {
  margin: 10px auto;
  transform: none;
  top: 0;
  max-width: 65%;
}
.ljourneypopup .journeytrack {
  margin: 4px 50px;
}
.ljourneypopup .fronzerotodest h4 {
  font-size: 18px;
}
.editjourneydiemension a {
  font-size: 16px;
}
.editjourneydiemension a span {
  width: 10px;
  background: #ccc;
  padding: 6px 10px 10px;
  border-radius: 50%;
  color: #fff;
  line-height: 1;
  margin-right: 10px;
  font-size: 17px;
  text-transform: lowercase;
}
/* .ljourneypopup .modal-body {
  max-height: 700px;
} */
.setyourrewardtarget .input-group.calender .clenderIcon {
  top: 40px;
  min-width: auto;
}
.anythingels_btn .plswait {
  max-width: 140px;
}
/* Quiz tab */
.QuizContent {
  background: #fff;
  padding: 30px 20px;
  flex: 1 1 0;
  height: calc(100% - 47px);
}
form.lesson_Quiz {
  flex: 1 1;
  flex-direction: column;
  display: flex;
}
.QuizContent .ScenecerelateddQuiz h4 {
  font-size: 24px;
  padding: 8px 10px;
  background: #ffffff;
  width: 100%;
  align-items: baseline;
}
.QuizContent .ScenecerelateddQuiz .signupType h4 p,
.QuizContent .ScenecerelateddQuiz .signupType h4 p a {
  margin-bottom: 0 !important;
  font-size: 18px !important;
}
.QuizContent .ScenecerelateddQuiz label.Selcheckbox {
  font-size: 20px;
  width: 100%;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 4px #ccc;
}
.QuizContent .ScenecerelateddQuiz label.Selcheckbox.Qrightopyion {
  background: #e0ffe0;
  border: 1px solid #3dc03d;
}
.QuizContent .ScenecerelateddQuiz label.Selcheckbox.Qwrongopton {
  background: #ffe0e0;
  border: 1px solid #ff6161;
}
.QuizContent .ScenecerelateddQuiz label.Selcheckbox span.QQtitle {
  padding: 5px 10px;
  /* box-shadow: 1px 1px 8px #c1c1c1; */
}

.QuizContent .ScenecerelateddQuiz label.Selcheckbox span.checkmark {
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}
.Qwrongopton .WrongQuizAns {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  left: 10px;
}
.QuizContent .ScenecerelateddQuiz label.Selcheckbox input:checked ~ .checkmark {
  background-color: #00b050;
}
.QuizContent .ScenecerelateddQuiz label.Selcheckbox:hover {
  background: #f1f1f1;
  border: 1px solid #b3b3b3;
}
.QuizContent .ScenecerelateddQuiz label.Selcheckbox.ActiveQQst {
  background: #f1f1f1;
  border: 1px solid #b3b3b3;
}
.QuizContent .ScenecerelateddQuiz h4 span {
  margin-bottom: 0 !important;
  font-size: 18px !important;
}
.QuizContent .ScenecerelateddQuiz {
  padding: 30px 30px;
  border-radius: 12px;
  box-shadow: 0px 0px 1px #777373;
  border: 1px solid #ffcb5b;
  border-left: 10px solid #ffcb5b;
}
.QuizContent .Quizresult.mt-0 {
  margin: 0 0 20px !important;
}
.QuizContent
  .ScenecerelateddQuiz
  .checkboxtypeques
  label.Selcheckbox
  span.checkmark {
  border-radius: 0;
}
.modal-footer button svg {
  vertical-align: middle;
}
.unanweredQuizqust {
  border: 1px solid #f0ae42;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  border-radius: 4px;
  background: #ffe8ea;
  margin: 10px 0;
}
.unanweredQuizqust strong {
  padding: px;
  border: 1px solid red;
  border-radius: 50%;
  margin: 5px 5px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}

.blue {
  scroll-margin: 20px;
}

.ScenecerelateddQuiz .signupType label span {
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-size: 16px;
}
.lessoncourseTitleimg {
  max-width: 60px;
  width: 60px;
  height: 60px;
  border: 0px solid #68b2ff;
  margin-right: 10px;
}
.LCtitle {
  width: calc(100% - 72px) !important;
}
.lessonCNameList {
  padding: 10px;
}
ul.weeklist {
  padding-left: 0px !important;
  padding-top: 0px;
}

ul.weeklist li.weellistname {
  font-size: 16px;
  margin: 0px 25px;
  padding: 10px 0 10px;
  background: none;
  border: 0;
}
.lessonCNameList ul li {
  padding: 10px;
  border: 2px solid #ffcb5b;
  background: #f5faff;
  padding: 15px 0;
  border: 1px solid #f9ca5c;
  cursor: pointer;
  border-left: 10px solid #f5b936;
  border-radius: 0 5px 5px 0;
}
.LCtitle p a {
  font-size: 16px;
}
.weellistname ul.flex.flex-wrap {
  padding-left: 20px !important;
}
ul.weeklist li.weellistname ul li {
  padding: 5px 5px;
  border: 1px solid #ccc;
  background: #fff;
  margin: 10px 0px 0px 0px;
  width: 49%;
  box-shadow: 2px 2px 4px #ccc;
  border-radius: 4px;
}
.settingsbtn {
  text-align: right;
  margin-top: 10px;
}

.UploadProimagepopup .modal-title svg.addphoto {
  font-size: 18px;
}
.sessionTimeOut {
  z-index: 999999999 !important;
}
.PMSkillList li ul.dropdown-menu.dropdown-menu-end {
  padding: 0 !important;
  border: 0;
  display: none;
}
.PMSkillList li ul.dropdown-menu.dropdown-menu-end li {
  height: auto;
  display: block;
  margin: 0px 0px !important;
  text-align: left;
  padding: 10px 5px;
  border-bottom: 1px dashed #ccc;
  cursor: pointer;
  color: #747474;
  width: 100%;
}
.PMSkillList li ul.dropdown-menu.dropdown-menu-end.show {
  display: block;
  transform: none !important;
  left: -46px !important;
  top: 3px !important;
}

.owl-grab .owl-stage-outer {
  overflow: initial;
}
.PMSkillList .Activitymenubar {
  position: absolute;
  top: 6px;
  right: 16px;
}
.jumptodropicn {
  padding: 2px 3px 0px;
  margin: 0 5px;
  border: 1px solid #8e8c8c;
  background: #ffffff;
}

.tabgrid.m-0.socialgridtab .jumptodropicn svg {
  color: #fff !important;
}

.jumptodropicn svg {
  color: #524f4f;
  font-size: 16px;
}
.tabgrid.m-0.socialgridtab .jumptodropicn:hover {
  background: #005fb2;
  color: #fff;
  border-color: #005fb2;
}
.tabgrid.m-0.socialgridtab .jumptodropicn:hover svg {
  color: #fff;
}
.tabgrid.m-0.socialgridtab .jumptodropicn svg{
  color: #fff !important;
}
.tabgrid.m-0.socialgridtab .jumptodropicn {
  padding: 5px 4px 2px;
  margin: 0 10px 0 0;
  border: 1px solid #329fff;
  border-radius: 5px;
  background: #329fff;
}
.tabgrid.m-0.socialgridtab
  .jumptopagescene
  ul.dropdown-menu.dropdown-menu-end.show {
  max-width: 250px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  max-height: 505px;
  overflow: auto;
  z-index: 991;
}
.tabgrid.m-0.socialgridtab .jumptopagescene ul li.pointer,
.RPenrolledCourseList ul li ul.enrollassetsval li,.jumptopagescene.coursemodelDropdwn ul li{
  width: 100% !important;
  border-right: 0 !important;
  padding: 10px 5px;
  transition: all 0.3s;
  height: auto;
  min-width: 100%;
  max-height: max-content;
  border-bottom: 1px dashed #ccc;
}
.RPenrolledCourseList ul li ul.enrollassetsval li {
  background: none;
  margin: 0;
}
.RPenrolledCourseList ul li ul.enrollassetsval li p {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.tabgrid.m-0.socialgridtab .jumptopagescene ul li:hover,
.RPenrolledCourseList ul li ul.enrollassetsval li:hover,
.jumptopagescene.coursemodelDropdwn ul li:hover,
.tabgrid.m-0.socialgridtab .jumptopagescene ul li.SceneActivePage {
  padding: 10px 10px;
  background: #dcdfec;
}
.jumptopagescene.coursemodelDropdwn ul li{
  padding: 10px 10px;
}
.jumptopagescene.coursemodelDropdwn ul li:hover{
  padding: 10px 15px;
}
.tabgrid.m-0.socialgridtab .jumptopagescene ul li.SceneActivePage {
  padding: 10px 5px;
}
.tabgrid.m-0.socialgridtab .jumptopagescene ul li.SceneActivePage {
  cursor: auto;
}
.RPenrolledCourseList ul li .enrollassetsval img.pointmoneybegimg {
  max-width: 45px;
}
.RPenrolledCourseList ul li .enrollassetsval span.earnnoCoin {
  max-width: 100px;
  top: 0;
  overflow: revert;
  margin-right: 0;
  width: auto;
  font-family: "Lato", sans-serif !important;
  color: #3b3b3b;
  padding-right: 3px;
}
.Addrewardform button.btn-blue.btn-login.d-block.mb-5.cancelbutton.m-0 {
  margin-right: 0 !important;
}
.Addrewardform button.btn-blue.btn-login.d-block.mb-5.Addrewardbtn {
  margin-left: 10px;
}
ul.dropdown-menu.dropdown-menu-end.enrollassetsval.show {
  left: auto !important;
  right: 92px;
  min-width: 350px;
}
.LessionDtlOverview p .enbtn {
  max-width: 80px;
  border-radius: 4px;
}
.tabgrid.m-0.socialgridtab .jumptopagescene ul li.pointer p {
  width: 230px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
span.juweeknametext {
  display: block;
  width: 161px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.juweeknametextSubTitle {
  font-size: 13px;
  color: #000;
  font-weight: 500;
  display: block;
  width: 161px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
span.jumponscene {
  margin-right: 5px;
  border-radius: 50%;
  min-width: 36px;
  height: 36px;
  display: inline-block;
  text-align: center;
  background: #1675e0;
  color: #fff;
  font-size: 13px;
  border: 2px solid #34c3ff;
  text-transform: capitalize;
  line-height: 2.4;
}
/* .assetscourselistitem span.jumponscene {
  background: #fff;
} */
.setyourrewardtarget .Courseimage {
  width: 175px;
  height: 175px;
}
.setyourrewardtarget .photoUploded img {
  max-width: 170px;
  z-index: 1;
  background: #fff;
  height: 100%;
}
.setyourrewardtarget .uploadederewardimg {
  border: 1px solid #ccc;
  width: 175px;
  text-align: center;
  height: 175px;
  line-height: 175px;
  margin-right: 10px;
  left: 0;
  position: absolute;
  background: #fff;
  text-align: center;
}
.cameraimgicon img {
  max-width: 20px !important;
}
.cameraimgicon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.settingtable table td {
  position: relative !important;
}
.jumptopagescene ul li.disable,
.disabledevent {
  cursor: not-allowed;
  pointer-events: none;
}
.jumptopagescene ul li.disable p,
.disabledevent {
  opacity: 0.8;
  color: #bcbcbc;
}

.RPenrolledCourseList ul li {
  font-size: 16px;
  display: flex;
  padding: 6px 10px;
  background: #eee;
  margin: 10px;
  min-height: 42px;
}
.RPenrolledCourseList ul li span {
  white-space: nowrap;
  max-width: 330px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.setyourrewardtarget .react-date-picker__calendar {
  z-index: 999 !important;
}
.Rpprogresbar .ProgressDetails ul li {
  padding: 0;
  border: 0;
  background: transparent;
}
.Quizgenrateimage {
  max-width: 100% !important;
}

.selectedCurrectCourse {
  background: #fff6f6 !important;
}

.assetscourselistitem span.jumponscene {
  background: none;
  border: 0;
  border-radius: 0;
}
.assetscourselistitem span.jumponscene img {
  max-width: 28px;
}
.EnrollTitle .knwyourself {
  top: 5px;
  margin-left: 30px;
}
.Subject_Curriculam .EnrollTitle .knwyourself {
  top: -0px;
}
.EnrollTitle .NuggetVickyImage .knwyourself {
  top: 0px;
}
.EnrollTitle .knwyourself p {
  font-size: 15px !important;
  text-align: left;
  /* font-family: "Comic Neue", cursive !important; */
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  padding-bottom: 10px;
}
.popupboxstyl p {
  font-size: 16px !important;
  text-align: center;
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  line-height: 1.4;
  color: #000;
  padding-top: 10px;
}

.sessionTimeoutt .LessionDtlOverview p {
  text-align: left;
}
.react-calendar__tile--now {
  background: #329fff !important;
  color: #fff !important;
}
.scoringtable td,
.scoringtable th {
  text-align: center;
  padding: 4px;
  background: #fff;
  width: 33%;
}
ul.scorecard_table {
  /* box-shadow: 2px 2px 4px -1px #828282; */
  background: #fff;
  padding: 10px !important;
}
.scrorecardWrap .scorecard_table li {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-bottom: 0;
  background: #fff;
  padding: 10px 0;
}
.scrorecardWrap .scorecard_table li.flex.scorecardtitle {
  background: #d7e9fb;
  padding: 5px 10px;
}
.scoringtable td:first-child,
.scoringtable th:first-child {
  text-align: left;
  min-width: 175px;
}
.scorecardtitle svg.rc-progress-circle {
  background: #ffffff;
  border-radius: 50%;
}

.completeTitle {
  background: #d7e9fb;
  padding: 5px 10px;
  border: 1px solid #329fff;
  border-radius: 30px;
  min-height: 41.6px;
}

.understandtitle {
  background: #fff6da;
  padding: 5px 10px;
  border: 1px solid #ffc107;
  border-radius: 30px;
  min-height: 41.6px;
}
.scrorecardWrap p.mt-2 {
  font-size: 16px;
  color: #000;
}
.popularcourse ul.courseComminS li {
  padding: 4px 0;
}
.termslist h3 strong,
.termscondition ul li strong {
  text-decoration: underline;
}
.termscondition ul li ul li strong {
  text-decoration: none;
}
.ListIcon ul.dropdown-menu.dropdown-menu-end.show {
  overflow: auto;
}
.understandtitle .Coursecompprcent circle.rc-progress-circle-path,
.blueprogresscircle .Coursecompprcent circle.rc-progress-circle-path {
  stroke: #329fff !important;
}
.flexone {
  display: flex;
  align-items: center;
}
.flextwo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ActivitycrdTitle h3 .pointer div.__react_component_tooltip {
  max-width: initial;
  overflow: initial;
  text-overflow: initial;
  white-space: normal;
}
.popularcourse ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: decimal;
  padding: 6px 5px;
  font-size: 16px;
  color: #0066dc;
  margin: 5px 0;
  border-radius: 5px;
  background: #f7f7f7;
  border-bottom: 1px solid #e3e3e3;
}
.popularcourse ul li:last-child{
  background: transparent;
  padding: 10px 0;
  border-bottom: 0;
}
/* .popularcourse ul li span{
  padding-right: 10px;
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.popularcourse {
  padding: 10px;
  /*   overflow: auto;
  max-height: 85vh; */
}
.popularcourse p {
  font-size: 17px;
  line-height: 1.3;
  margin-bottom: 0px;
  text-align: left;
  margin-top: 10px;
  font-weight: 600;
}
.SocialCoursecardWrap p.notenrolledcourses {
  font-size: 16px;
  padding-left: 13px;
  padding-top: 8px;
}
.SocialCoursecardWrap p.notenrolledcourses a {
  font-size: 16px;
}
.popularcourse p.dmskills,
.courseskillbox p.dmskills {
  font-size: 17px;
  line-height: 1;
  padding: 15px 10px 15px;
  text-align: left;
  color: #212121;
}

.popularcourse h4 {
  font-size: 17px;
  font-weight: 600;
  line-height: 1.3;
}
.popularcourse ul.courseComminS li {
  padding: 7px 0;
  color: #000;
}
.Courseporogresspopup {
  padding: 10px 15px;
  background: #666666;
  display: none;
  width: 348px;
  border: 1px solid #dbe4ec;
  box-shadow: 0 1px 5px #d3dbe2;
  border-radius: 7px;
  z-index: 9999999;
}
.skillprogrs p:hover ~ .Courseporogresspopup {
  display: block;
}
.popularcourse h4 span {
  font-size: 17px;
  min-width: 120px;
}

.popularcourse h4 span img {
  max-width: 16px;
}
.skillprogrs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.skillprogrs p {
  cursor: pointer;
  margin-top: 5px;
  font-size: 16px;
  line-height: 1.3;
  margin-bottom: 10px;
}
.skillprogrs p img {
  max-width: 16px;
  margin-left: 3px;
}
.skillprogrs span {
  background: #0096fe;
  border-radius: 50%;
  padding: 5px;
  font-size: 18px;
  width: 60px;
  font-weight: 600;
  height: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.progressstyl {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  justify-items: center;
  text-align: center;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 0px;
}

/* .courseskillbox {
  padding: 10px 10px 10px;
} */
.progressstyl span {
  text-align: center;
  font-size: 14px;
}
span.TimProgress,
.proficencyprog,
.CompltProgress {
  min-width: 50px;
}
.indivisectionprog {
  padding: 30px 10px 20px;
}
.progressstyl .Coursecompprcent {
  width: 50px;
}
.progressstyl span.PercentCompleted {
  text-align: center;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
}
.NHDimeProgName h4,
.NHDimeProgName h4 a {
  font-size: 16px;
}
.NhPageWrap h3.statementabthome {
  font-size: 19px;
  line-height: 1.5;
  padding: 0px 18px;
  
}
/* .Selcheckbox.ActiveQQst.EnroledJournyCourse {
  background: #e3f2fd !important;
  border: 1px solid #ccc;
  padding: 10px 0 10px 32px !important;
  border-left: 0;
  border-right: 0;
} */
.alltypeCourseFilter .EnroledJournyCourse .checkmark {
  left: 7px;
  top: 13px;
}
.SocialCoursecardWrap span.resocurrinfopopup {
  line-height: 1;
}
.SocialCoursecardWrap .resocurrinfopopup strong {
  color: #329fff;
  font-size: 16px;
  font-weight: 600;
}
.SocialCoursecardWrap .resocurrinfopopup img {
  max-width: 16px;
  margin-top: -3px;
}
.updown_arrow {
  font-size: 14px;
  line-height: 1;
}
span.curiresoevent {
  position: absolute;
  top: 12px;
  right: 10px;
  display: flex;
  align-items: center;
  min-width: 270px;
}
.nocourseenrolAvvl {
  font-size: 16px;
  padding: 5px 0 5px;
}
.Holisticcharttengent {
  padding: 30px 75px 30px;
}
.Holisticcharttengent li {
  font-size: 14px;
  font-weight: 600;
  width: 50%;
}
.Holisticcharttengent li:nth-child(2n + 2) {
  text-align: left;
  padding-left: 20px;
}
span.GRtengent {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: red;
  margin-right: 10px;
  border-radius: 50%;
}
.SocialTeggent {
  background: #00aaff;
}
span.GRtengent.SocialTeggent {
  background: #ff9900;
}
span.GRtengent.EmotionalTeggent {
  background: #e46666;
}
span.GRtengent.SpritiualTeggent {
  background: #a769d9;
}
span.GRtengent.PhysicalTeggent {
  background: #1ec1d9;
}
span.GRtengent.intelTeggent {
  background: #83b612;
}
span.GRtengent.OverallTeggent {
  background: royalblue;
}

span.GRtengent.MindfulnessTeggent {
  background: #a769d9;
}

span.GRtengent.IntellectualTeggent {
  background: #83b612;
}

.popularcourse ul.courseComminS li span {
  min-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Card .skillprogrs span {
  font-size: 20px;
  width: 50px;
  font-weight: 600;
  padding: 7px;
  height: 50px;
  background: #ff9900;
}
.Card .skillprogrs span.percentage_sign {
  font-size: 13px;
  height: auto;
  padding: 0;
  top: 3px;
  width: auto;
}

.notinterestedribon {
  position: absolute;
  left: -6px;
  top: 29px;
  background: #e46666;
  color: #fff;
  padding: 1px 2px 1px;
  font-size: 14px;
  transform: rotate(-45deg);
}
.notinterestedribon::after {
  content: "";
  position: absolute;
  right: -22px;
  height: 0;
  border-left: 10px solid;
  border-left: 0px solid transparent;
  border-right: 23px solid transparent;
  border-bottom: 23px solid #e46666;
  top: 0;
}
.notinterestedribon::before {
  content: "";
  position: absolute;
  left: -24px;
  width: 0;
  height: 0;
  border-left: 10px solid;
  border-left: 25px solid transparent;
  transform: rotate();
  border-right: 0px solid transparent;
  border-bottom: 23px solid #e46666;
  top: 0px;
  transform: rotate(-3deg);
}




.recommandedribon {
  background: #e46666;
  color: #fff;
  padding: 4px 10px 7px;
  font-size: 14px;
  border-radius: 0 10px;
  margin-left: 10px;
  color: #fff !important;
  margin-right: auto;
}
/* .recommandedribon::after {
  content: "";
  position: absolute;
  right: -22px;
  height: 0;
  border-left: 10px solid;
  border-left: 0px solid transparent;
  border-right: 23px solid transparent;
  border-bottom: 23px solid #88e466;
  top: 0;
}
.recommandedribon::before {
  content: "";
  position: absolute;
  left: -24px;
  width: 0;
  height: 0;
  border-left: 10px solid;
  border-left: 25px solid transparent;
  transform: rotate();
  border-right: 0px solid transparent;
  border-bottom: 23px solid #88e466;
  top: 0px;
  transform: rotate(-3deg);
} */

.fixedtoppar .tabgrid ul li {
  width: 25%;
}
.videoinfotext {
  position: absolute;
  right: 8px;
  top: 10px;
  background: #fff;
  padding: 4px 8px 4px;
  z-index: 9;
  border-radius: 0px;
  border: 3px solid #35322f;
}
.videoinfotext p {
  color: #000;
  font-size: 16px;
}
.intructionsBox.ScenecerelateddQuiz.Analysistxt p svg {
  font-size: 36px;
}
.intructionsBox.ScenecerelateddQuiz.Analysistxt.seneriotext p {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 400;
  color: #232323;
  display: inline;
  text-align: justify;
}
/* @keyframes seefullvidintr {
  0%   { right:-48%}
  25%  {right: 0%;}
  50%  {right: 0%;}
  75%  {right: 0%;}
  100% {right: -48%;}
} */

.helpvideos .helpvideosection {
  justify-content: space-evenly;
  display: flex;
  margin-bottom: 30px;
}
.helpvideos .helpvideosectio span {
  text-align: center;
}
.helpvideos .helpvideosection img {
  max-width: 350px;
  cursor: pointer;
  border: 1px solid #1d103f;
  border-radius: 5px;
  margin: 0px 15px;
  box-shadow: 5px 5px 7px #ccc;
}
.helpvideos .helpvideosection h4 {
  font-size: 20px;
  font-weight: 600;
  padding: 15px 0px;
}

.empathy_header {
  background: #f5faff;
  border: 2px solid #ffcb5b;
  padding: 8px 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 10px;
  border-left: 10px solid #ffcb5b;
  box-shadow: 0px 2px 5px #ccc;
}
.checkout_header {
  padding: 8px 10px;
  margin-bottom: 0px;
  text-align: center;
}
.empathy_header h4 {
  font-size: 36px;
  font-weight: 600;
  line-height: 1.5;
  color: #e46666;
}
.checkout_header h4 {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 10px;
}
.empathy_header p {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
}

.LessionDtlOverview ul.empathylist li {
  list-style: none;
  line-height: 1.2;
  padding-bottom: 10px;
  margin-left: 5px;
  display: flex;
}
.empathybtn {
  display: flex;
  justify-content: space-between;
}
.empathybtn button.btn-blue.btn-login.d-block {
  margin-left: 0px !important;
}
.chipsback img {
  max-width: 220px;
  border: 1.5px solid #ffcb5b;
  border-radius: 10px;
}

.chipsback {
  display: flex;
  padding: 30px 0px 10px;
  align-items: center;
}
.chipsback p {
  font-size: 17px;
  padding: 0px 20px 20px 20px;
  line-height: 1.3;
  font-weight: 400;
  text-align: justify;
}

.resultright p {
  padding-bottom: 0px;
}
.Progresslistitem .empathybar {
  top: 0px;
}
.empathybarhgt {
  height: auto;
}

.empathyborderstyle {
  padding: 0px;
  max-width: 200px;
  margin: 0 auto;
}
.empathy_header .chipsback img {
  border-color: #e46666;
}
.Toastify__toast p {
  color: #fff !important;
}
.Toastify__toast svg {
  fill: #fff !important;
}
.Toastify__toast {
  background: #203764 !important;
}
.Toastify__close-button--light {
  opacity: 1 !important;
}
.Toastify__progress-bar--info {
  background: #e66666;
}

.scrollMovement {
  overflow: hidden;
  min-height: 0 !important;
  max-height: 86vh;
}

.fade.AddChildPopup.parent_signin.modal.show {
  z-index: 99999999;
}
.textAreatypeQuestion .textareabox textarea::placeholder {
  opacity: 0.3;
}
textarea::-moz-placeholder, /* Firefox 18- */
textarea::-moz-placeholder, /* Firefox 19+ */
textarea:-ms-input-placeholder, /* IE 10+ */
textarea::-webkit-input-placeholder, /* Webkit based */
textarea::placeholder {
  /* Modern browsers */
  color: green !important;
  font-size: 50px !important;
}
div#signup,
.verifyusereffect {
  padding: 100px 0 10px;
  /* background: #ECEFF1; */
  min-height: 93.3vh;
  box-shadow: 0 0 10px #cccc;
}
.SignupPopup .modal-dialog .SignupRegistration,
.Sign_in {
  box-shadow: 0 0 5px #838383cc;
}
.highlightCard {
  border: 1px solid #5eb3ff;
  box-shadow: 0px 0px 7px 1px #0096fe;
}
.signin_box_styl button.btn-blue.btn-login.d-block {
  font-family: "Figtree", sans-serif !important;
}
.signinsignupline,
.signinsignupline a {
  font-size: 16px;
  font-family: "Figtree", sans-serif !important;
}
.rembrme span {
  vertical-align: middle;
}
.fsignupBtn.m-0.bottom-dwn button.btn-blue.btn-login.d-block.w-auto {
  padding: 12px 47px;
  margin-bottom: 0 !important;
  margin-left: 15px !important;
  font-family: "Figtree", sans-serif !important;
}
.signin_uptitle h2 span {
  margin-right: 47px;
}

.videoActivityPopup {
  z-index: 9999999;
}

.pagination li {
  display: inline-block;
  margin: 0 2px;
}
.pagination ul {
  display: flex;
  align-items: center;
}
.pagination li span {
  padding: 1px 4px;
  display: flex;
  cursor: pointer;
  align-items: center;
  background: transparent;
  font-weight: 600;
  border: 1px solid transparent;
  font-size: 13px;
}
.pagination li span:hover {
  background: #e1f0ff;
  color: #578fc0;
  border: 1px solid #e1f0ff;
}
.pagination li span:hover svg {
  color: #578fc0;
}
.pagination li span svg {
  font-size: 18px;
  color: #6a6a6a;
}
.pagination li span.btn-primary.active {
  border: 1px solid #578fc0;
  color: #578fc0;
  background-color: #e1f0ff;
}
.pagination li span:disabled {
  color: #ccc;
}
.pagination li span:disabled svg {
  color: #ccc;
}
.pagination li span:disabled:hover {
  background: none;
  color: #ccc;
}
.pagination li span:disabled:hover svg {
  color: #ccc;
}
.verifyusereffect #signin {
  box-shadow: none;
}
.verifyusereffect .modal-content.w-50 {
  border-radius: 4px 0 0 4px !important;
}
.pagination li span svg.svg-inline--fa.fa-angles-left,
.pagination li span svg.svg-inline--fa.fa-angles-right {
  font-size: 22px;
}
.pagination.pagedown_pagination {
  justify-content: right;
  margin: 20px 0 10px;
}
.pr-0 {
  padding-right: 0 !important;
}

.pagination .disabledIcon {
  pointer-events: none;
  opacity: 0.4;
}
span.rewarddwnicn {
  position: absolute;
  left: 0;
  top: 8px;
  font-size: 10px;
  right: -30px;
  color: #329fff;
}
.settingtable table td ul.dropdown-menu.dropdown-menu-end.newrewardDropdwn {
  transform: none !important;
  right: 24px;
  top: 28px !important;
  left: auto !important;
  max-width: 200px !important;
  width: 230px;
  min-width: 230px;
  display: none;
}
.settingtable table td ul.dropdown-menu.dropdown-menu-end.newrewardDropdwn.show{
  display: block;
}
.settingtable table td ul.dropdown-menu.dropdown-menu-end.newrewardDropdwn::after{
  display: none;
}
.settingtable table td ul li{
  width: 100% !important;
}
.settingtable table td ul li{
  display: flex;
  margin: 0px 0px !important;
  text-align: left;
  padding: 10px 5px;
  border-bottom: 1px dashed #ccc;
  cursor: pointer;
  color: #747474;
  min-height: fit-content;
  width: 100%;
  max-height: none;
}
.settingtable table td ul li span{
  overflow: visible;
}
.settingtable table td ul li span.jumponscene{
  color: #fff;
}
.settingtable table td ul li:hover {
  border-bottom: 1px dashed #ccc !important;
  background: #dcdfec !important;
  padding: 10px 10px;
  transition: all 0.3s;
}
span.rewarddwnicn svg {
  color: #329fff;
}
.Green_reward{
color: #00b050 !important;
}
.Red_reward{
  color: #e46666 !important;
}
.tabgrid .pagination ul li {
  border-right: 0;
  text-align: center;
  padding: 0px !important;
  margin: 0 2px;
  width: auto;
  min-height: 0;
}
.tabgrid .pagination ul li:hover{
  border-bottom: 0 !important;
}
.tabgrid .pagination ul li span {
  justify-content: center;
  padding: 2px 6px !important;
}
.SignupPopup p.text-center.signinsignupline.mb-3 {
  max-width: 100%;
}

/* Start Added by Alfaiz on 8-3-2024 for certificate css */ 
#certificateMainDiv {
  width: 700px;
  height: 501px;
  margin: auto;
}

#certificateMainDiv .main-div {
  background-image: url('/src/resources/images/bloomster-certificate-bg-img.png');
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
}

#certificateMainDiv .contant-div {
  width: 460px;
  text-align: center;
  position: absolute;
  top: 43%;
}

#certificateMainDiv .student-name {
  margin: 0px;
  color: #31568A;
  font-family: 'Beau Rivage' !important;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
  padding-top: 10px;
  border-bottom: 3px dotted #31568A;
}

#certificateMainDiv p {
  font-family: 'Charm' !important;
  margin: 10px 4px;
  font-size: 16px;
}

#certificateMainDiv span {
  font-size: 18px;
  font-weight: 600;
  color: #4169E1;
}
/* End by Alfaiz on 8-3-2024 for certificate css */
.wel_clisting_inst .welcomscreenContent ul li h4,.welcomeGmae p {
  font-size: 16px;
  text-align: left;
  font-weight: 400;
}
.welcomeGmae.p-3.mt-3 {
    background: #E3F2FD;
    margin: 5px !important;
    border: 15px solid transparent;
    padding: 5px 3px;
    background-size: cover !important;
    border: 1px solid #d9d9d9;
    background: #eee;
    padding: 0px 0px 10px;
    border: 2px solid #ffcb5b;
}
.wel_clisting_inst .welcomscreenContent ul {
  border: 0;
  border-left: 0;
  padding-bottom: 0;
  padding: 0 !important;
}
.welcomeGmae.p-2 {
  border: 0;
  background: #eee;
  border-left: 4px solid #329fff;
  border-radius: 5px;
}
.welcomeGmae img{max-width: 24px;margin-right: 5px;}

/*--------------------Start Added By alfaiz on 10-4-2024 for course pagination---------------- */
.CourseSlidearrrow {
  margin-right: 0px;
  border-radius: 50px;
  padding-top: 0px;
  padding-bottom: 4px;
  margin-top: 0px;
  min-width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

 .CourseSlidearrrow .pointer {
   width: 23px;
   height: 23px;
   color: #fff;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
 }



.CourseSlidearrrow span {
  display: inline-block;
  font-size: 14px;
  margin: 0 15px;
  color: #ffffff;
  text-align: center;
  transition: all 0.3s;
  padding: 0 7.1px;
  border-radius: 50%;
  background-color: #329fff;
}

.CourseSlidearrrow .ScenePagination {
  color: #000000;
  border-radius: 20px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 0px 13px;
  padding-top: 1px;
}

.CourseSlidearrrow span:hover {
  background: none;
}

span.pointer.DissableArrow {
  pointer-events: none;
  color: #929191;
  background-color: #eee;
}

.CourseSlidearrrow span.pointer:hover {
  background: #005fb2;
  padding: 0 7.5px;
  color: #ffffff;
}

@media (min-width: 1201px) and (max-width: 1366px) {
  .CourseSlidearrrow {
    min-width: 200px !important;
  }

  .CourseSlidearrrow span {
    font-size: 13px !important;
    margin: 0 8px !important;
    padding: 0 7px !important;
  }
}

@media screen and (max-width: 1024px) {
  .CourseSlidearrrow {
    min-width: 200px !important;
  }

  .CourseSlidearrrow span {
    font-size: 13px !important;
    margin: 0 8px !important;
    padding: 0 7px !important;
  }
}

/*----------------------------End Added By alfaiz on 10-4-2024 for course pagination----------------- */

/*--------Start Added By Alfaiz this css use for knowledge check pagination button---------------------  */
.QuestionListSlide ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.QuestionListSlide ul li {
  display: inline-block;
  width: 25px;
  height: 25px;
  font-size: 14px;
  background: #ffffff;
  margin: 0 -40px;
  text-align: center;
  line-height: 25px;
  position: relative;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  color: #000000;
}

.QuestionListSlide ul li.pointer {
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  line-height: 10px;
  color: #ffffff;
  box-shadow: unset;
  width: auto;
  padding: 4px 8px !important;
  margin: 0px;
  /* border: 3px solid #0D47A1; */
  border-radius: 13px;
  background: #329fff;
  box-shadow:1px 1px 1px 0px #3F51B5;
}

.QuestionListSlide svg.svg-inline--fa.fa-angle-right {
  padding-left: 8px;
}

.QuestionListSlide svg.svg-inline--fa.fa-angle-left {
  padding-right: 8px;
}

.QuestionListSlide ul li.pointer:hover {
  background: #005fb2;
}

.QuestionListSlide li.pointer.DissableArrow {
  pointer-events: none;
  /* color: #fcf9f9 !important; */
  opacity: .5;
}

.QuestionListSlide ul li.QAttemptAct:before {
  all: initial;
}

.QuestionListSlide svg.svg-inline--fa.fa-angle-left,
.AllNuggQuestionList svg.svg-inline--fa.fa-angle-right {
  font-size: 16px;
}

.QuestionListSlide ul li.QAttemptAct {
  background: #329fff;
  color: #fff;
}

/*--------End Added By Alfaiz this css use for knowledge check pagination button---------------------  */

/*--------Start Added By Alfaiz this css use for More Tabs Menu Items---------------------  */
.learner_pLan_dropdown ul.lPDropdwnList.tabingmenuDropdown {
  width: 235px !important;
  flex-wrap: wrap;
  top: 31px;
  display: none;
  transform: none !important;
  left: auto!important;
  top: 30px !important;
  right: -92px !important;

  /* transform: translate3d(426px, 31px, 0px) !important; */
}
.learner_pLan_dropdown ul.lPDropdwnList.tabingmenuDropdown.show {
  display: block;
}
.tabgrid ul li ul.tabingmenuDropdown li:nth-child(1):hover,
.tabgrid ul li ul.tabingmenuDropdown li:nth-child(2):hover,
.tabgrid ul li ul.tabingmenuDropdown li:nth-child(3):hover {
  border-bottom: 1px solid #1ec1d9;
  background: #f5faff;
}

@media (min-width: 1367px) and (max-width: 1440px) {
  /* .learner_pLan_dropdown ul.lPDropdwnList.tabingmenuDropdown {
    transform: translate3d(377px, 31px, 0px) !important;
  } */
  .learner_pLan_dropdown ul li {
    min-width: 100% !important;
  }
}

@media (min-width: 1201px) and (max-width: 1366px) {
  /* .learner_pLan_dropdown ul.lPDropdwnList.tabingmenuDropdown {
    transform: translate3d(313px, 31px, 0px) !important;
  } */

  .learner_pLan_dropdown ul li {
    min-width: 100% !important;
  }
}

@media screen and (max-width: 1024px) and (min-width: 767px) {
  /* .learner_pLan_dropdown ul.lPDropdwnList.tabingmenuDropdown {
    transform: translate3d(320px, 31px, 0px) !important;
    display: flex;
    flex-direction: column;
  } */
}

.ParentToolRewardImage {
  margin-left: -5px !important;
}

.coursecardnav.dropdownlistmodl ul li ul.dropdown-menu.dropdown-menu-end li {
  display: block !important;
}

.moreTabsDownIcon{
  margin-right: 0px !important;
  padding-right: 0px !important ;
  margin-left: 25px !important;
}
.moreTabs{
width: 155px !important;
/* width: auto !important; */
}

/*--------End Added By Alfaiz this css use for More Tabs Menu Items---------------------  */

.dobulecheckedmsgIcon{ 
  color: green;
  font-size: 12px;
}
.signin_pg .verifyaccount{ 
  font-size: 12px;
  text-decoration: underline;
}

.CourseGuideDropdown li:before{
  background-color: unset !important;
}
.pleasewaitbtn {
  min-width: 125px !important;
}
#learnerPasswordInstruction {
  z-index: 999;
}
.LessionDtlOverview table th,.LessionDtlOverview table td {
  border:1px solid #232323;
  padding: 5px;
  text-align: left;
  font-size: 14px;
}
.myJournalTextArea {
  height: 510px;
  resize: none;
}
/* .heading h2 span {
  display: flex;
  align-items: center;
} */

/*----------------- Create a new items ------------------------ */
.enrollPlan span.checkmark{
  border-radius:50% !important;
  height: 30px;
  width: 30px;
}
.enrollPlan .coursecardnav.dropdownlistmodl ul.dropdown-menu.dropdown-menu-end {
  width: 240px;
}
.enrollPlan .PaceModeSel label.Selcheckbox.m-0.ActiveQQst{
  padding: 10px 0px 10px 60px !important;
}
.enrollPlan .PaceModeSel label.Selcheckbox.m-0.ActiveQQst span {
  font-size: 16px;
  padding-left: 0px;
  top: 10px;
  color: #000;
}
.enrollPlan .PaceModeSel .Selcheckbox .checkmark::after {
  left: 8px;
  top: 2px;
  width: 12px;
  height: 22px;
  border-width: 0 6px 6px 0;
}

.enrollPlan .selectecPaceWeek{
  margin: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.enrollPlan .selectecPaceWeek .checkmark{
  border-radius:0px !important;
  height: 20px;
  width: 20px;
}
.enrollPlan .selectecPaceWeek  .DayName{
    font-size:18px;
    color:#000;
}
.enrollPlan .selectecPaceWeek .Selcheckbox{
    padding-left:29px !important;
}
.enrollPlan ul.dropdown-menu li:hover{
  background-color:white !important;
  padding:10px !important;
}
.myJournalTextArea{
  height: 510px;
}
form .form_sec .form_styl .inner_part.notes_class {display: block;line-height: 14px;}

.inner_part.notes_class span {
    display: inline;
    line-height: 1.5;
}
.VKprofile .vkprofilename span img {
  border-radius: 50%;
  border-radius: 50%;
  max-width: 25px;
}
.VKprofile .vkprofilename span span.ProfileChild {
  width: 26px !important;
  height: 26px;
  font-size: 12px !important;
  margin-right: .5rem;
  min-width: 26px;
}
.VKprofile .vkprofilename svg.svg-inline--fa.fa-screen-users.mr-2 {
  font-size: 18px;
  color: #000;
  margin-left: 0;
}
.tabgrid ul li svg.svg-inline--fa.fa-chevron-down.ml-3{font-size:15px}
.notification_setting .LP_chevron[aria-expanded="true"] svg.svg-inline--fa.fa-chevron-down {
  transform: rotate(180deg);
  transition: all .3s;
}

.notification_setting .LP_chevron[aria-expanded="false"] svg.svg-inline--fa.fa-chevron-down {
  transform: rotate(0deg);
  transition: all .3s;
}
.shimmer-card {
  min-height: 73vh;
}