.TeacherDashboard .Coursecompprcent {
  width: 100%;
  background-color: #fff;
  padding: 12px;
  text-align: center;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 2px solid #e5e2e2; */
  margin: 0 10px 0px;
  border-radius: 10px;
}

.TeacherDashboard .PercentCompleted {
  position: absolute;
  top: 27%;
  border: 0;
  background: none;
  color: #232323;
  font-weight: 600;
  font-size: 48px;
  line-height: 1;
  left: 2px;
}

.TeacherDashboard .rc-progress-circle {
  border-radius: 50%;
  width: 75%;
}

.TeacherDashboard .Coursecompprcent circle.rc-progress-circle-trail {
  stroke-width: 20px;
}

.TeacherDashboard .rc-progress-circle-path {
  stroke-width: 20px;
}

.TeacherDashboard img {
  width: 26px;
}

.TeacherDashboard .Coursecompprcent .circleNames {
  font-size: 18px;
  font-weight: 700;
}

.TeacherDashboard .percenrSign {
  font-size: 22px;
  margin-left: 0px;
}

.TeacherDashboard #planCalendarView {
  background-color: white;
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 2px solid #e5e2e2; */
    margin: 10px;
    padding: 12px;
    border-radius: 10px;
  }

  .TeacherDashboard #planCalendarView .tableResponsive{
    height: 650px;
    overflow-y: scroll;
   }

  /* .TeacherDashboard .reminderCourse{
      margin-left: 5px;
      margin-right: 2px;
  } */
  .TeacherDashboard .reminderCourse img{
      width: 26px;
  }

    .studentClsDetails .reminderCourse .reminderCourseContent {
      background-color: #f7f1f1;
      padding: 12px;
      margin-right: 4px;
      border: none !important;
      box-shadow: unset !important;
      border-radius: unset !important;
      border-bottom: unset !important;
    }

  .TeacherDashboard .reminderCourse .reminderCourseContent{
    background-color: #f7f1f1;
    padding: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-bottom: 2px solid #a86adb;
    border: 2px solid #e5e2e2;
    border-radius: 5px;
  }
  .TeacherDashboard .reminderCourse .reminderCourseContent img{
    width: 34px;
  }
  .TeacherDashboard .reminderCourse .reminderCourseContent span svg{
    color: royalblue;
  }

  /* This css use for scroll */
.TeacherDashboard #planCalendarView .tableResponsive::-webkit-scrollbar{
    width: 4px !important;
}
.TeacherDashboard #planCalendarView .tableResponsive::-webkit-scrollbar-track{
    background: #f1f1f1; 
}

.TeacherDashboard #planCalendarView .tableResponsive {
  height: 650px;
  margin-left: -8px;
  margin-right: -8px;
  overflow-y: scroll;
}

.TeacherDashboard .reminderCourse {
  margin-left: 5px;
  margin-right: 2px;
}

.TeacherDashboard .reminderCourse img,.Acquisioton_survy_Plan img {
  width: 25px;
}

.TeacherDashboard .reminderCourse .reminderCourseContent {
  background-color: #e8f5ff;
  padding: 5px 12px 5px;
  border-bottom: 2px solid #329fff;
  border: 2px solid #329fff;
  border-radius: 5px;
}

.TeacherDashboard .reminderCourse .reminderCourseContent img {
  width: 25px;
}

.TeacherDashboard .reminderCourse .reminderCourseContent span svg {
  color: royalblue;
}

/* This css use for scroll */
.TeacherDashboard #planCalendarView .tableResponsive::-webkit-scrollbar {
  width: 4px !important;
}

.TeacherDashboard #planCalendarView .tableResponsive::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.TeacherDashboard #planCalendarView .tableResponsive::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 0 rgba(0, 0, 0, 0.1), inset 0 -1px 0 rgba(0, 0, 0, 0.07);
  scrollbar-width: thin;
}

.TeacherDashboard .Coursecompprcent:nth-child(1) {
  margin-left: 0;
}

.TeacherDashboard .Coursecompprcent:nth-child(4){
  margin-right: 0;
}

.reminderCourseContent .progressstyl {
  max-width: 500px;
  margin: auto;
}
.calenderviewNextsesinon {
  background: #ffffff;
  font-size: 48px;
  font-weight: 600;
  color: #000;
  text-align: center;
  padding: 1px 0px;
  border: 2px solid #329fff;
  border-radius: 5px;
  /* box-shadow: 3px 3px 0px #d32f2f; */
  /* text-shadow: 1px 2px 0px #283593; */
  width: 80px;
  height: 80px;
  line-height: 1;
}
.calenderviewNextsesinon span {
  font-size: 14px;
  display: block;
  background: #329fff;
  color: #ffffff;
  padding: 3px 0px 3px;
  margin-top: -2px;
  border-radius: 5px 5px 0 0;
  margin-bottom: 5px;
}
.coursemodelDropdwn h4,.coursemodelDropdwn h4 {
  border-bottom:4px solid #4cb1e5;
  padding: 6px 15px 7px;
  background: #e5e5e5;
  color: #000000;
  display: flex;
  font-size: 16px;
  align-items: center;
  border-radius: 0 20px;
}
.student_dash_brd_dtl{
  width: 420px;
}
.TeacherDashboard .reminderCourse .coursemodelDropdwn  span svg {
  font-size: 15px;
}
.coursemodelDropdwn.jumptopagescene.dropdownlistmodl ul.dropdown-menu.dropdown-menu-end{
  width: 418px;
  left: auto !important;
  transform: none !important;
  border-radius: 0px 0px;
  right: 0;
  top: 42px !important;
}
.coursedrpdwn .jumponscene {
  background: #4cb1e5;
}
.coursemodelDropdwn.jumptopagescene span.juweeknametext {
  width: 315px;
}
.moduleandcrs {
  padding-left: 30px;
}
.moduleandcrs h4 {
  font-weight: 400;
  font-size: 16px;
}
.learner_pLan_dropdown ul.dropdown-menu li.select_reports {
  background: #e8f5ff;
  padding: 10px 15px;
  cursor: auto;
}
.learner_pLan_dropdown ul.dropdown-menu li.select_reports:hover{
  background: #e8f5ff;
}
.indivisualprogressivecourse .progressstyl .Coursecompprcent p {
  cursor: auto;
}
.indivisualprogressivecourse p.Aboutmedesc {
  min-height: 55px;
}
.schoolstutabingthree.tabgrid ul li span {
  /* width: 182px; */
  text-overflow: ellipsis;
  white-space: nowrap;
  /* display: inline-block; */
  overflow: hidden;
}
.reporttabbing.tabgrid ul li{
  width: 25% !important;
}

ul.dropdown-menu.dropdown-menu-end.lPDropdwnList.moteitem_list {
  display: none;
}
.schoolstutabingthree.tabgrid ul li.show ul.dropdown-menu.dropdown-menu-end.lPDropdwnList.moteitem_list {
  display: inline-block;
  transform: none !important;
  /* width: 200px; */
  left: auto !important;
  right: 0;
  /* max-width: 200px; */
  top: 30px !important;
  min-width: 25%;
}
.schoolstutabingthree.tabgrid ul li .moteitem_list li {
  max-height: fit-content;
  border: 0;
}
.schoolstutabingthree.tabgrid ul li .moteitem_list li:hover{
  background: #dcdfec;
}
.schoolstutabingthree.tabgrid ul li .moteitem_list li p{
  color: #fff;
}
.schoolstutabingthree.tabgrid ul::after {
  display: none;
}